import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver-es';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Area } from '../objetos/area';
import { CurveService } from './curve.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient, private curveService: CurveService) {}

  /** UTILIDADES */

  /**
   * Función para dar formato a cada fila de un CSV con los datos pasados por parametro
   * @param selectedFecha1 Fecha seleccionada grupo 1
   * @param selectedFecha2 Fecha seleccionada grupo 2
   * @param text1 Gráficas tipo texto grupo 1
   * @param text2 Gráficas tipo texto grupo 2
   * @param grid1 Gráficas tipo grid grupo 1
   * @param grid2 Gráficas tipo grid grupo 2
   * @param optim1 Gráficas tipo óptimo grupo 1
   * @param optim2 Gráficas tipo óptimo grupo 2
   * @param other1 Gráficas tipo otro grupo 1
   * @param other2 Gráficas tipo otro grupo 2
   * @returns Array con datos correspondientes a cada fila del CSV
   */
  formatearCsv(
    selectedFecha1: moment.MomentInput,
    selectedFecha2: moment.MomentInput,
    text1: string | any[],
    text2: {
      data: { area: any; data: string | number; nubes: { data: string } };
    }[],
    grid1: string | any[],
    grid2: { texto: any; data: { data: string | number } }[],
    optim1: string | any[],
    optim2: { data: { data: string | number } }[],
    other1: any,
    other2: any,
  ) {
    var formateado: any[] = [];

    // Ids
    var ids = [1]; // Para poner más ids en un futuro

    for (let i = 0; i < ids.length; i++) {
      // Fechas
      formateado.push([
        ids[i],
        moment(selectedFecha1).toISOString(true).split('T')[0],
        moment(selectedFecha2).toISOString(true).split('T')[0],
      ]);

      // Tipo Grid
      for (let j = 0; j < grid1.length; j++) {
        if (grid1[j].texto) formateado[i] = formateado[i].concat([grid1[j].data.data]);
        if (grid2[j].texto) formateado[i] = formateado[i].concat([grid2[j].data.data]);
      }

      // Tipo Óptimo
      for (let j = 0; j < optim1.length; j++) {
        formateado[i] = formateado[i].concat([optim1[j].data.data]);
        formateado[i] = formateado[i].concat([optim2[j].data.data]);
      }

      // Tipo Otro
      if (other1[1].producto) {
        for (let j = 0; j < other1[1].data.length; j++) {
          formateado[i] = formateado[i].concat([other1[1].data[j].data + '%']);
          formateado[i] = formateado[i].concat([other2[1].data[j].data + '%']);
        }
      }
      if (other1[0].texto) {
        formateado[i] = formateado[i].concat([other1[0].data.data]);
      }
      if (other2[0].texto) {
        formateado[i] = formateado[i].concat([other2[0].data.data]);
      }

      // Tipo Texto
      for (let j = 0; j < text1.length; j++) {
        if (text1[j].data.data) {
          formateado[i] = formateado[i].concat([text1[j].data.data]);
          formateado[i] = formateado[i].concat([text2[j].data.data]);
        } else {
          if (text1[j].data.area) formateado[i] = formateado[i].concat([text1[j].data.area.data]);
          if (text2[j].data.area) formateado[i] = formateado[i].concat([text2[j].data.area.data]);
          if (text1[j].data.nubes) formateado[i] = formateado[i].concat([text1[j].data.nubes.data + '%']);
          if (text2[j].data.nubes) formateado[i] = formateado[i].concat([text2[j].data.nubes.data + '%']);
        }
      }
    }

    return formateado;
  }

  /**
   * Función para montar y descargar el csv formateado
   * @param selectedFecha1 Fecha seleccionada grupo 1
   * @param selectedFecha2 Fecha seleccionada grupo 2
   * @param text1 Gráficas tipo texto grupo 1
   * @param text2 Gráficas tipo texto grupo 2
   * @param grid1 Gráficas tipo grid grupo 1
   * @param grid2 Gráficas tipo grid grupo 2
   * @param optim1 Gráficas tipo óptimo grupo 1
   * @param optim2 Gráficas tipo óptimo grupo 2
   * @param other1 Gráficas tipo otro grupo 1
   * @param other2 Gráficas tipo otro grupo 2
   * @param filename Nombre para el archivo CSV
   */
  downloadCsvGraphs(
    selectedFecha1: moment.MomentInput,
    selectedFecha2: moment.MomentInput,
    text1: string | any[],
    text2: {
      data: { area: any; data: string | number; nubes: { data: string } };
    }[],
    grid1: string | any[],
    grid2: { texto: any; data: { data: string | number } }[],
    optim1: string | any[],
    optim2: { data: { data: string | number } }[],
    other1: any,
    other2: any,
    filename: string,
  ) {
    var formateado = this.formatearCsv(
      selectedFecha1,
      selectedFecha2,
      text1,
      text2,
      grid1,
      grid2,
      optim1,
      optim2,
      other1,
      other2,
    );
    var fecha1 = moment(selectedFecha1).toISOString(true).split('T')[0];
    var fecha2 = moment(selectedFecha2).toISOString(true).split('T')[0];

    var cabeceras = [
      'id',
      `Fecha (${fecha1})`,
      `Fecha (${fecha2})`,
      `Hídrico (${fecha1})`,
      `Hídrico (${fecha2})`,
      `Clorofila (${fecha1})`,
      `Clorofila (${fecha2})`,
      `NDVI (${fecha1})`,
      `NDVI (${fecha2})`,
      `Biomasa (${fecha1})`,
      `Biomasa (${fecha2})`,
      `Hídrico Óptimo (${fecha1})`,
      `Hídrico Óptimo (${fecha2})`,
      `Clorofila Óptimo (${fecha1})`,
      `Clorofila Óptimo (${fecha2})`,
      `NDVI Óptimo (${fecha1})`,
      `NDVI Óptimo (${fecha2})`,
      `Biomasa Óptimo (${fecha1})`,
      `Biomasa Óptimo (${fecha2})`,
      `Posible Despoblamiento (${fecha1})`,
      `Posible Despoblamiento (${fecha2})`,
      `Sin Anomalias (${fecha1})`,
      `Sin Anomalias (${fecha2})`,
      `Posibles Malezas (${fecha1})`,
      `Posibles Malezas (${fecha2})`,
      `Producción (${fecha1})`,
      `Producción (${fecha2})`,
      `Área (${fecha1})`,
      `Área (${fecha2})`,
      `Nubes (${fecha1})`,
      `Nubes (${fecha2})`,
      `Precipitación (${fecha1})`,
      `Precipitación (${fecha2})`,
      `Radiación (${fecha1})`,
      `Radiación (${fecha2})`,
    ];

    let message: string = ``;

    for (var i = 0; i < formateado.length; i++) {
      //construimos cabecera del csv
      if (i == 0) message += cabeceras.join(';') + '\n';
      //resto del contenido
      message +=
        Object.keys(formateado[i])
          .map(function (key) {
            // Hay que quitarle los acentos a esta cadena pero es imposible
            return formateado[i][key];
          })
          .join(';') + '\n';
    }

    // crear csv
    var blob = new Blob([message], { type: 'text/csv' });

    saveAs(blob, `${filename}.csv`);
  }

  getDateFromYears(input: number, selectedFecha: string | number | Date) {
    // Función que te devuelve la fecha de hace X años (a través del input)
    var fecha = new Date(selectedFecha);
    var fecha2 = fecha.setFullYear(fecha.getFullYear() - input);
    return moment(fecha2).toISOString(true).split('T')[0];
  }

  /**
   * Función para obtener las fechas min y max de parcelas que tienen una edad
   * @param input
   * @returns
   */
  getDatesFromEdad(input: number) {
    var date1 = new Date(new Date().setMonth(new Date().getMonth() - input));
    var date2 = new Date(new Date().setMonth(new Date().getMonth() - (input + 1)));

    date1.setDate(date1.getDate() - 1); // Ade coherence

    return [date2.toISOString().split('T')[0], date1.toISOString().split('T')[0]];
  }

  /**
   * Función para obtener las fechas min y max de parcelas que tienen una edad
   * @param input
   * @param selectedFecha
   * @returns
   */
  getDatesFromEdad2(input: string | any[], selectedFecha: string | number | Date) {
    var d = new Date(selectedFecha);
    var date1 = new Date(new Date(d).setMonth(new Date(d).getMonth() - input[0] + 1));
    var date2 = new Date(new Date(d).setMonth(new Date(d).getMonth() - input[input.length - 1]));
    return [moment(date1).toISOString(true).split('T')[0], moment(date2).toISOString(true).split('T')[0]];
  }

  getDateFromMonth(input: any) {
    //Función para obtener las fechas de X meses atrás
    var d = new Date();
    var month_days = this.getMonthDays(d);
    var mes = d.getMonth();
    var days = 0;
    var contador_mes = mes - Number(input);

    if (contador_mes < 0) contador_mes = 11 + contador_mes;

    for (var i = 0; i < Number(input); i++) {
      if (contador_mes == 12) contador_mes = 0;
      days = days + month_days[contador_mes];
      contador_mes = contador_mes + 1;
    }

    d.setDate(d.getDate() - days);
    return d.toISOString().split('T')[0];
  }

  /**
   * Función que devuelve un array con los días que contiene cada mes, y, según el año que se envíe, enviará un valor u otro de febrero
   */
  getMonthDays(d: Date) {
    return [
      31,
      d.getFullYear() % 400 === 0 ? 29 : d.getFullYear() % 100 === 0 ? 28 : d.getFullYear() % 4 === 0 ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];
  }

  getDateForDays(input: number) {
    // Función que te devuelve la fecha de dentro de X días (a través del input)
    var d = new Date();
    d.setDate(d.getDate() + input);
    return d.toISOString().split('T')[0];
  }

  cleanDataArray(array: string | any[]) {
    // Función para poner a type nodata cuando una gráfica no tiene datos
    for (var i = 0; i < array.length; i++) {
      if (array[i].type != 'info') {
        if (
          (array[i].data &&
            array[i].data.length == 0 &&
            (array[i].type == 'bar' || array[i].type == 'line' || array[i].type == 'zonas')) ||
          (Object.keys(array[i].data).length == 0 && array[i].type == 'pie')
        ) {
          array[i].data = null;
          array[i].nodata = 'true';
        } else {
          array[i].nodata = false;
        }
      } else {
        if (!array[i].area || array[i].area == null) {
          array[i].nodata = 'true';
        }
      }

      if (array[i].type == 'optim' && (!array[i].data.data || (array[i].data.data && array[i].data.data.length == 0))) {
        array[i].data.data = null;
        array[i].nodata = 'true';
      }

      if (
        array[i].texto &&
        array[i].texto == 'Produccion' &&
        Array.isArray(array[i].data.data) &&
        array[i].data.data.length == 0
      )
        array[i].nodata = 'true';

      if (array[i].type == 'grid' && (!array[i].data.porcentajes || !array[i].data.rangos) && !array[i].texto)
        array[i].nodata = 'true';

      if (
        array[i].type == 'grid' &&
        Array.isArray(array[i].data.data) == true &&
        array[i].data.data.length == 0 &&
        array[i].texto
      )
        array[i].nodata = 'true';

      if (array[i].type == 'grid' && !array[i].data.data && array[i].texto) array[i].nodata = 'true';

      if (array[i].type == 'text' && (array[i].data.data == 0 || (array[i].data.area && !array[i].data.area.data))) {
        array[i].nodata = 'true';
      }

      if (array[i].producto && array[i].producto == 'anomalias' && !array[i].data) array[i].nodata = 'true';
    }
    return array;
  }

  /** FORMATEOS */
  /**
   * Función que devuelve los elementos de un array con comillas dobles para filtrar correctamente
   * @param input
   * @returns
   */
  getArrayWithQM(input: any[]) {
    input = input[0];
    return input.map((e) => `"${e}"`).join(',');
  }

  /**
   * Función para formatear el filtro a json para enviarlo en el body de la petición
   * @param filters
   * @param options
   * @param selectedFecha
   * @param tipo
   * @returns
   */
  formatFilter(filters: any[], options: {}[], selectedFecha: any, tipo: number) {
    // * obtenemos las keys de las opciones (filtros posibles)
    var keys = Object.keys(options[!tipo || (tipo && tipo == 1) ? 0 : 1]);
    var keys_unidades = ['id', 'unidad_01', 'unidad_02', 'unidad_03', 'unidad_04', 'unidad_05'];
    keys = keys.concat(keys_unidades);

    // * generamos el string del filtro que enviaremos en el body
    var filter2send = '';
    filter2send = filter2send + '{';

    // * si existen filtros, generamos el string de filtrado
    if (filters) {
      for (let key of keys) {
        // * para cada key, buscamos si existen applyedFilters de ese tipo. Si existen, formateamos la condicion
        var values = filters.filter((x) => x.attribute == key).map((x) => x.value);
        if (values.length) {
          var condition = this.formatCondicion(key, values, selectedFecha);
          filter2send = filter2send + condition;
        }
      }
    }

    // * eliminamos si existe una coma final y cerramos el string de filtro
    if (filter2send[filter2send.length - 1] == ',') {
      filter2send = filter2send.substring(0, filter2send.length - 1);
    }
    filter2send = filter2send + '}';

    // * añadimos la condición de parcela activa siempre al filtro
    return this.addActivo2Filter(filter2send);
  }

  /**
   * Función que añade el filtro activo siempre a las peticiones de filtro finales
   * @param filtro
   * @returns
   */
  addActivo2Filter(filtro: string) {
    return !filtro.length || filtro == '{}' ? '{"activo": true}' : filtro.replace('}', ',"activo": true}');
  }

  /**
   * Función que formatea el body para la petición, dependiendo del tipo de gráfica
   * @param area
   * @param filter
   * @param product
   * @param aux
   * @param type
   * @param atr
   * @param selectedFecha
   * @returns
   */
  formatJSON(
    area: number[],
    filter: string,
    product: string | null,
    type: number,
    atr: string | null,
    selectedFecha?: string,
    dateYear?: string,
  ) {
    switch (type) {
      case 0:
        return `{"idArea": ${JSON.stringify(
          area,
        )},"filtroparcelas": ${filter},"filtro": {"fk_fecha__fk_producto__nombre": "${product}","fk_fecha__fecha__gte": "${dateYear}", "fk_fecha__fecha__lte": "${selectedFecha}", "mean__isnull": false}}`;
      case 1:
        return `{"idArea": ${JSON.stringify(
          area,
        )},"filtroparcelas": ${filter},"filtro": {"fk_producto__nombre": "${product}","fecha": "${selectedFecha}"}}`;
      case 2:
        return `{"idArea": ${JSON.stringify(
          area,
        )},"filtroparcelas": ${filter},"filtro": {"fecha": "${selectedFecha}","atributo": "${atr}"}}`;
      case 3:
        return `{"idArea": ${JSON.stringify(
          area,
        )},"filtroparcelas": ${filter}, "filtro": {"fecha": "${selectedFecha}"}}`;
      case 4:
        return JSON.stringify({
          fecha__range: [dateYear, selectedFecha],
          areas: area,
          filtro: typeof filter === 'string' ? JSON.parse(filter) : filter,
        });
      default:
        return ``;
    }
  }

  /**
   * Función que formatea el body para la petición, dependiendo del tipo de gráfica
   * @param area
   * @param filter
   * @param product
   * @param aux
   * @param type
   * @param atr
   * @param selectedFecha
   * @returns
   */
  formatJSONCurve(
    area: number[],
    filter: string,
    product: string | null,
    type: number,
    atr: string | null,
    selectedFecha?: string,
    dateYear?: string,
  ) {
    let result;
    let filterObject = JSON.parse(filter);
    switch (type) {
      case 0:
        /*         return `{"idArea": ${JSON.stringify(
          area,
        )},"filtroparcelas": ${filter},"filtro": {"fk_fecha__fk_producto__nombre": "${product}","fk_fecha__fecha__gte": "${dateYear}", "fk_fecha__fecha__lte": "${selectedFecha}", "mean__isnull": false}}`; */
        result = {
          areas: area,
          plot_filter: filterObject,
          product_name: product,
          start_date: dateYear,
          end_date: selectedFecha,
        };
        break;
      case 1:
        /* return `{"idArea": ${JSON.stringify(
          area,
        )},"filtroparcelas": ${filter},"filtro": {"fk_producto__nombre": "${product}","fecha": "${selectedFecha}"}}`; */
        result = {
          areas: area,
          plot_filter: filterObject,
          product_name: product,
          start_date: selectedFecha,
          end_date: selectedFecha,
        };
        break;
      case 2:
        /* return `{"idArea": ${JSON.stringify(
          area,
        )},"filtroparcelas": ${filter},"filtro": {"fecha": "${selectedFecha}","atributo": "${atr}"}}`; */
        result = {
          areas: area,
          plot_filter: filterObject,
          start_date: selectedFecha,
          end_date: selectedFecha,
        };
        break;
      case 3:
        /* return `{"idArea": ${JSON.stringify(
          area,
        )},"filtroparcelas": ${filter}, "filtro": {"fecha": "${selectedFecha}"}}`; */
        result = {
          areas: area,
          plot_filter: filterObject,
          start_date: selectedFecha,
          end_date: selectedFecha,
        };
        break;
      case 4:
        /* return JSON.stringify({
          fecha__range: [dateYear, selectedFecha],
          areas: area,
          filtro: typeof filter === 'string' ? JSON.parse(filter) : filter,
        }); */
        result = {
          areas: area,
          plot_filter: filterObject,
          start_date: dateYear,
          end_date: selectedFecha,
        };
        break;
      default:
        return '';
    }

    return JSON.stringify(result);
  }

  /**
   * Función que formatea los filtros por unidad de agrupación
   * @param array
   * @param atr
   * @returns
   */
  formatUnidadFilter(array: any[], atr: string) {
    let values = array[0];
    return values.length ? `"${atr}__in": [${values.map((e: any) => `"${e}"`).join(', ')}],` : '';
  }

  /**
   * Función que formatea las condiciones del filtro dependiendo de que condición se recibe.
   * @param key
   * @param value
   * @param selectedFecha
   * @returns
   */
  formatCondicion(key: string, value: any, selectedFecha: string | number | Date) {
    switch (key) {
      case 'variedades':
        return `"variedad__in": [${this.getArrayWithQM(value)}],`;
      case 'cultivos':
        return `"cultivo__in": [${this.getArrayWithQM(value)}],`;
      case 'zafra':
        return `"zafra__in": [${value}],`;
      case 'id':
        return value.length ? this.formatUnidadFilter(value, key) : '';
      case 'unidad_01':
        return value.length ? this.formatUnidadFilter(value, key) : '';
      case 'unidad_02':
        return value.length ? this.formatUnidadFilter(value, key) : '';
      case 'unidad_03':
        return value.length ? this.formatUnidadFilter(value, key) : '';
      case 'unidad_04':
        return value.length ? this.formatUnidadFilter(value, key) : '';
      case 'unidad_05':
        return value.length ? this.formatUnidadFilter(value, key) : '';
      case 'edad':
        var ordenado = value[0].sort();
        //ordenar los valores del array
        var dates_edad = this.getDatesFromEdad2(ordenado, selectedFecha);
        return `"fs__gt": "${dates_edad[0]}", "fs__lt": "${dates_edad[1]}",`;
      case 'soca':
        return `"soca__in": [${value[0]}],`;
      default:
        break;
    }
  }

  /**
   * Get ids from selectedAreas
   * @param selectedArea array of areas
   * @returns number array
   */
  private getIdSelectedAreas(selectedArea: Area[] = []): number[] {
    return selectedArea.filter((e) => e.nombre.toLowerCase() != 'global').map((value) => value.id);
  }

  graficasPorAgrupacion(
    group: any,
    petitions: any[],
    selectedArea: any = [],
    filter2send: string,
    dateYear: string,
    types: any[],
    user: { fk_cliente: any },
    options: { cultivos: any[] },
    selectedFecha: string,
  ) {
    let areas = this.getIdSelectedAreas(selectedArea);
    switch (group) {
      case 1:
        // * Hídrico
        petitions.push(
          this.getDashboardGeneral(this.formatJSON(areas, filter2send, null, 3, null, selectedFecha, dateYear)).pipe(
            catchError((err) => of({ producto: 'info' })),
          ),
        );
        types.push('info');
        //if(user.fk_cliente.id != 104){

        if (options.cultivos && options.cultivos.find((x) => x.value == 'canha') && user.fk_cliente.id != 104) {
          petitions.push(
            this.getDashboardCurva(
              this.formatJSONCurve(areas, filter2send, 'agua_filt', 0, null, selectedFecha, dateYear),
            ).pipe(catchError((err) => of({ producto: 'agua_filt', data: [] }))),
          );
          types.push('bar');
        } else {
          petitions.push(
            this.getDashboardCurva(
              this.formatJSONCurve(areas, filter2send, 'agua', 0, null, selectedFecha, dateYear),
            ).pipe(catchError((err) => of({ producto: 'agua', data: [] }))),
          );
          types.push('bar');
        }

        petitions.push(
          this.getDashboardCurva(
            this.formatJSONCurve(areas, filter2send, 'agua_optimo', 0, null, selectedFecha, dateYear),
          ).pipe(catchError((err) => of({ producto: 'agua_optimo', data: [] }))),
        );
        types.push('bar');
        petitions.push(
          this.getDashboardHistogram(
            this.formatJSON(areas, filter2send, 'agua_optimo', 1, null, selectedFecha, dateYear),
          ).pipe(catchError((err) => of({ producto: 'agua_optimo', data: [] }))),
        );
        types.push('pie');

        if (options.cultivos && options.cultivos.find((x) => x.value == 'arroz')) {
          petitions.push(
            this.getDashboardHistogram(
              this.formatJSON(areas, filter2send, 'inundacion', 1, null, selectedFecha, dateYear),
            ).pipe(catchError((err) => of({ producto: 'inundacion', data: [] }))),
          );
          types.push('pie');
        }
        //}
        break;
      case 2:
        // * Salud
        petitions.push(
          this.getDashboardGeneral(this.formatJSON(areas, filter2send, null, 3, null, selectedFecha, dateYear)).pipe(
            catchError((err) => of({ producto: 'info' })),
          ),
        );
        types.push('info');

        if (user.fk_cliente.id != 104) {
          petitions.push(
            this.getDashboardCurva(
              this.formatJSONCurve(areas, filter2send, 'lai_filt', 0, null, selectedFecha, dateYear),
            ).pipe(catchError((err) => of({ producto: 'lai_filt', data: [] }))),
          );
          types.push('bar');
        } else {
          petitions.push(
            this.getDashboardCurva(
              this.formatJSONCurve(areas, filter2send, 'lai', 0, null, selectedFecha, dateYear),
            ).pipe(catchError((err) => of({ producto: 'lai', data: [] }))),
          );
          types.push('bar');
        }

        petitions.push(
          this.getDashboardCurva(
            this.formatJSONCurve(areas, filter2send, 'lai_optimo', 0, null, selectedFecha, dateYear),
          ).pipe(catchError((err) => of({ producto: 'lai_optimo', data: [] }))),
        );
        types.push('bar');

        if (options.cultivos && options.cultivos.find((x) => x.value == 'canha')) {
          petitions.push(
            this.getDashboardCurva(
              this.formatJSONCurve(areas, filter2send, 'clorofila', 0, null, selectedFecha, dateYear),
            ).pipe(catchError((err) => of({ producto: 'clorofila', data: [] }))),
          );
          types.push('bar');
        } else {
          petitions.push(
            this.getDashboardCurva(
              this.formatJSONCurve(areas, filter2send, 'clorofila_filt', 0, null, selectedFecha, dateYear),
            ).pipe(catchError((err) => of({ producto: 'clorofila_filt', data: [] }))),
          );
          types.push('bar');
        }

        petitions.push(
          this.getDashboardCurva(
            this.formatJSONCurve(areas, filter2send, 'clorofila_optimo', 0, null, selectedFecha, dateYear),
          ).pipe(catchError((err) => of({ producto: 'clorofila_optimo', data: [] }))),
        );
        types.push('bar');

        if (options.cultivos && options.cultivos.find((x) => x.value == 'canha') && user.fk_cliente.id != 104) {
          petitions.push(
            this.getDashboardCurva(
              this.formatJSONCurve(areas, filter2send, 'ndvi_filt', 0, null, selectedFecha, dateYear),
            ).pipe(catchError((err) => of({ producto: 'ndvi_filt', data: [] }))),
          );
          types.push('bar');
        } else {
          petitions.push(
            this.getDashboardCurva(
              this.formatJSONCurve(areas, filter2send, 'ndvi', 0, null, selectedFecha, dateYear),
            ).pipe(catchError((err) => of({ producto: 'ndvi', data: [] }))),
          );
          types.push('bar');
        }
        petitions.push(
          this.getDashboardCurva(
            this.formatJSONCurve(areas, filter2send, 'ndvi_optimo', 0, null, selectedFecha, dateYear),
          ).pipe(catchError((err) => of({ producto: 'ndvi_optimo', data: [] }))),
        );
        types.push('bar');

        petitions.push(
          this.getDashboardHistogram(
            this.formatJSON(areas, filter2send, 'lai_optimo', 1, null, selectedFecha, dateYear),
          ).pipe(catchError((err) => of({ producto: 'lai_optimo', data: [] }))),
        );
        types.push('pie');
        petitions.push(
          this.getDashboardHistogram(
            this.formatJSON(areas, filter2send, 'clorofila_optimo', 1, null, selectedFecha, dateYear),
          ).pipe(catchError((err) => of({ producto: 'clorofila_optimo', data: [] }))),
        );
        types.push('pie');
        petitions.push(
          this.getDashboardHistogram(
            this.formatJSON(areas, filter2send, 'ndvi_optimo', 1, null, selectedFecha, dateYear),
          ).pipe(catchError((err) => of({ producto: 'ndvi_optimo', data: [] }))),
        );
        types.push('pie');
        break;
      case 3:
        // * Desarrollo
        petitions.push(
          this.getDashboardGeneral(this.formatJSON(areas, filter2send, null, 3, null, selectedFecha, dateYear)).pipe(
            catchError((err) => of({ producto: 'info' })),
          ),
        );
        types.push('info');
        petitions.push(
          this.getDashboardHistogramZonas(
            this.formatJSON(areas, filter2send, 'anomalias', 1, null, selectedFecha, dateYear),
          ).pipe(catchError((err) => of({ producto: 'anomalias', data: [] }))),
        );
        types.push('zonas');
        if (user.fk_cliente.id != 104) {
          petitions.push(
            this.getDashboardHistogram(
              this.formatJSON(areas, filter2send, 'nitrogenado', 1, null, selectedFecha, dateYear),
            ).pipe(catchError((err) => of({ producto: 'nitrogenado', data: [] }))),
          );
          types.push('pie');
          if (options.cultivos && options.cultivos.find((x) => x.value == 'canha')) {
            petitions.push(
              this.getDashboardHistogram(
                this.formatJSON(areas, filter2send, 'maduracion_filt', 1, null, selectedFecha, dateYear),
              ).pipe(catchError((err) => of({ producto: 'maduracion_filt', data: [] }))),
            );
            types.push('pie');
          }
        }
        break;
      case 4:
        // * Producción
        petitions.push(
          this.getDashboardGeneral(this.formatJSON(areas, filter2send, null, 3, null, selectedFecha, dateYear)).pipe(
            catchError((err) => of({ producto: 'info' })),
          ),
        );
        types.push('info');
        petitions.push(
          this.getDashboardCurva(
            this.formatJSONCurve(areas, filter2send, 'produccion', 0, null, selectedFecha, dateYear),
          ).pipe(catchError((err) => of({ producto: 'produccion', data: [] }))),
        );
        types.push('line');
        if (user.fk_cliente.id == 104) {
          petitions.push(
            this.getDashboardHistogramZonas(
              this.formatJSON(areas, filter2send, 'cosechado', 1, null, selectedFecha, dateYear),
            ).pipe(catchError((err) => of({ producto: 'cosechado', data: [] }))),
          );
          types.push('zonas');
        }
        if (options.cultivos && options.cultivos.find((x) => x.value == 'canha')) {
          petitions.push(
            this.getDashboardTablaCosechado(
              this.formatJSON(areas, filter2send, 'cosechado', 2, 'variedad', selectedFecha, dateYear),
            ).pipe(catchError((err) => of({ producto: 'cosechado', data: [] }))),
          );
          types.push('table');
          petitions.push(
            this.getDashboardTablaCosechado(
              this.formatJSON(areas, filter2send, 'cosechado', 2, 'soca', selectedFecha, dateYear),
            ).pipe(catchError((err) => of({ producto: 'cosechado', data: [] }))),
          );
          types.push('table');
        }
        break;
      default:
        break;
    }

    return [petitions, types];
  }

  graficasAll(
    petitions: any[],
    selectedArea: any[] | undefined,
    filter2send: string,
    selectedFecha: string,
    types: any[],
    user: { fk_cliente: { id: number } },
    options: { cultivos: any[] },
  ) {
    var areas: number[] = this.getIdSelectedAreas(selectedArea);
    var dateYear: any = `${new Date(selectedFecha).getUTCFullYear()}-01-01`;

    // Informacion general
    petitions.push(
      this.getDashboardGeneral(this.formatJSON(areas, filter2send, null, 3, null, selectedFecha, dateYear)).pipe(
        map((x: any) => {
          return {
            data: {
              nubes: { data: x.noinfo_nubes, texto: '% nubes', icono: 'cloud' },
              area: { data: x.area, texto: 'ha.', icono: 'straighten' },
            },
          };
        }),
        catchError((err) => of({ producto: 'info' })),
      ),
    );
    types.push('text');

    // CURVAS
    // Curva Agua
    if (options.cultivos && options.cultivos.find((x) => x.value == 'canha')) {
      petitions.push(
        this.getDashboardCurva(
          this.formatJSONCurve(areas, filter2send, 'agua_filt', 0, null, selectedFecha, dateYear),
        ).pipe(
          map((x: any) => {
            if (x.data && x.data.length > 0) {
              x.data = x.data.pop().mean;
            }
            return { data: x, texto: 'Valor medio Agua' };
          }),
          catchError((err) => of({ producto: 'agua_filt', data: [] })),
        ),
      );
      types.push('grid');
    } else {
      petitions.push(
        this.getDashboardCurva(this.formatJSONCurve(areas, filter2send, 'agua', 0, null, selectedFecha, dateYear)).pipe(
          map((x: any) => {
            if (x.data && x.data.length > 0) {
              x.data = x.data.pop().mean;
            }
            return { data: x, texto: 'Valor medio Agua' };
          }),
          catchError((err) => of({ producto: 'agua', data: [] })),
        ),
      );
      types.push('grid');
    }

    // Curva clorofila
    if (options.cultivos && options.cultivos.find((x) => x.value == 'canha')) {
      petitions.push(
        this.getDashboardCurva(
          this.formatJSONCurve(areas, filter2send, 'clorofila', 0, null, selectedFecha, dateYear),
        ).pipe(
          map((x: any) => {
            if (x.data && x.data.length > 0) {
              x.data = x.data.pop().mean;
            }
            return { data: x, texto: 'Valor medio Clorofila' };
          }),
          catchError((err) => of({ producto: 'clorofila', data: [] })),
        ),
      );
      types.push('grid');
    } else {
      petitions.push(
        this.getDashboardCurva(
          this.formatJSONCurve(areas, filter2send, 'clorofila_filt', 0, null, selectedFecha, dateYear),
        ).pipe(
          map((x: any) => {
            if (x.data && x.data.length > 0) {
              x.data = x.data.pop().mean;
            }
            return { data: x, texto: 'Valor medio Clorofila' };
          }),
          catchError((err) => of({ producto: 'clorofila_filt', data: [] })),
        ),
      );
      types.push('grid');
    }

    // Curva NDVI
    if (options.cultivos && options.cultivos.find((x) => x.value == 'canha')) {
      petitions.push(
        this.getDashboardCurva(
          this.formatJSONCurve(areas, filter2send, 'ndvi_filt', 0, null, selectedFecha, dateYear),
        ).pipe(
          map((x: any) => {
            if (x.data && x.data.length > 0) {
              x.data = x.data.pop().mean;
            }
            return { data: x, texto: 'Valor medio NDVI' };
          }),
          catchError((err) => of({ producto: 'ndvi_filt', data: [] })),
        ),
      );
      types.push('grid');
    } else {
      petitions.push(
        this.getDashboardCurva(this.formatJSONCurve(areas, filter2send, 'ndvi', 0, null, selectedFecha, dateYear)).pipe(
          map((x: any) => {
            if (x.data && x.data.length > 0) {
              x.data = x.data.pop().mean;
            }
            return { data: x, texto: 'Valor medio NDVI' };
          }),
          catchError((err) => of({ producto: 'ndvi', data: [] })),
        ),
      );
      types.push('grid');
    }

    // Curva LAI
    petitions.push(
      this.getDashboardCurva(
        this.formatJSONCurve(areas, filter2send, 'lai_filt', 0, null, selectedFecha, dateYear),
      ).pipe(
        map((x: any) => {
          if (x.data && x.data.length > 0) {
            x.data = x.data.pop().mean;
          }
          return { data: x, texto: 'Valor medio Biomasa' };
        }),
        catchError((err) => of({ producto: 'lai_filt', data: [] })),
      ),
    );
    types.push('grid');

    // Curva Agua Optimo
    petitions.push(
      this.getDashboardCurva(
        this.formatJSONCurve(areas, filter2send, 'agua_optimo', 0, null, selectedFecha, dateYear),
      ).pipe(
        map((x: any) => {
          if (x.data && x.data.length > 0) {
            x.data = x.data.pop().mean;
          }
          return { data: x, texto: 'Valor medio óptimo Agua' };
        }),
        catchError((err) => of({ producto: 'agua_optimo', data: [] })),
      ),
    );
    types.push('optim');

    // Curva Lai Optimo
    petitions.push(
      this.getDashboardCurva(
        this.formatJSONCurve(areas, filter2send, 'lai_optimo', 0, null, selectedFecha, dateYear),
      ).pipe(
        map((x: any) => {
          if (x.data && x.data.length > 0) {
            x.data = x.data.pop().mean;
          }
          return { data: x, texto: 'Valor medio óptimo Biomasa' };
        }),
        catchError((err) => of({ producto: 'lai_optimo', data: [] })),
      ),
    );
    types.push('optim');

    // Curva Clorofila Optimo
    petitions.push(
      this.getDashboardCurva(
        this.formatJSONCurve(areas, filter2send, 'clorofila_optimo', 0, null, selectedFecha, dateYear),
      ).pipe(
        map((x: any) => {
          if (x.data && x.data.length > 0) {
            x.data = x.data.pop().mean;
          }
          return { data: x, texto: 'Valor medio óptimo Clorofila' };
        }),
        catchError((err) => of({ producto: 'clorofila_optimo', data: [] })),
      ),
    );
    types.push('optim');

    // Curva produccion
    petitions.push(
      this.getDashboardCurva(
        this.formatJSONCurve(areas, filter2send, 'produccion', 0, null, selectedFecha, dateYear),
      ).pipe(
        map((x: any) => {
          if (x.data && x.data.length > 0) {
            x.data = x.data.pop().mean;
          }
          return { data: x, texto: 'Produccion' };
        }),
        catchError((err) => of({ producto: 'produccion', data: [] })),
      ),
    );
    types.push('default');

    // Curva NDVI Optimo
    petitions.push(
      this.getDashboardCurva(
        this.formatJSONCurve(areas, filter2send, 'ndvi_optimo', 0, null, selectedFecha, dateYear),
      ).pipe(
        map((x: any) => {
          if (x.data && x.data.length > 0) {
            x.data = x.data.pop().mean;
          }
          return { data: x, texto: 'Valor medio óptimo NDVI' };
        }),
        catchError((err) => of({ producto: 'ndvi_optimo', data: [] })),
      ),
    );
    types.push('optim');

    // Curva Precipitacion
    petitions.push(
      this.getClima(this.formatJSON(areas, filter2send, 'precip', 4, null, selectedFecha, dateYear)).pipe(
        map((x: any) => {
          var sum = x
            .map((obj: any) => {
              return Number(obj.precip);
            })
            .reduce((acc: any, c: any) => acc + c, 0);
          return {
            data: { data: sum, texto: 'mm acum.', icono: 'rainy' },
            producto: 'precip',
          };
        }),
        catchError((err) => of({ producto: 'precip', data: [] })),
      ),
    );
    types.push('text');

    // Curva Radiacion
    petitions.push(
      this.getClima(this.formatJSON(areas, filter2send, 'radiation', 4, null, selectedFecha, dateYear)).pipe(
        map((x: any) => {
          var sum = x
            .map((obj: any) => {
              return Number(obj.radiation);
            })
            .reduce((acc: any, c: any) => acc + c, 0);
          return {
            data: { data: sum, texto: 'w/m2 acum.', icono: 'sunny' },
            producto: 'radiation',
          };
        }),
        catchError((err) => of({ producto: 'radiation', data: [] })),
      ),
    );
    types.push('text');

    // HISTOGRAMA

    // Histogram Agua Optimo
    petitions.push(
      this.getDashboardHistogram(this.formatJSON(areas, filter2send, 'agua_optimo', 1, null, selectedFecha)).pipe(
        catchError((err) => of({ producto: 'agua_optimo', data: [] })),
      ),
    );
    types.push('grid');

    // Histogram LAI Optimo
    petitions.push(
      this.getDashboardHistogram(
        this.formatJSON(areas, filter2send, 'lai_optimo', 1, null, selectedFecha, dateYear),
      ).pipe(catchError((err) => of({ producto: 'lai_optimo', data: [] }))),
    );
    types.push('grid');

    // Histogram Clorofila Optimo
    petitions.push(
      this.getDashboardHistogram(
        this.formatJSON(areas, filter2send, 'clorofila_optimo', 1, null, selectedFecha, dateYear),
      ).pipe(catchError((err) => of({ producto: 'clorofila_optimo', data: [] }))),
    );
    types.push('grid');

    // Histogram NDVI Optimo
    petitions.push(
      this.getDashboardHistogram(
        this.formatJSON(areas, filter2send, 'ndvi_optimo', 1, null, selectedFecha, dateYear),
      ).pipe(catchError((err) => of({ producto: 'ndvi_optimo', data: [] }))),
    );
    types.push('grid');

    // Histogram Anomalias
    petitions.push(
      this.getDashboardHistogram(
        this.formatJSON(areas, filter2send, 'anomalias', 1, null, selectedFecha, dateYear),
      ).pipe(
        map((x: any) => {
          var data: any[] = [];
          if (x.data) {
            var carencia = x.data.porcentajes[0] + x.data.porcentajes[1] + x.data.porcentajes[2];
            var exceso = x.data.porcentajes[4] + x.data.porcentajes[5] + x.data.porcentajes[6];
            data = [
              { data: carencia, texto: 'Posible Despoblamiento' },
              { data: x.data.porcentajes[3], texto: 'Sin anomalias' },
              { data: exceso, texto: 'Posibles Malezas' },
            ];
          }
          data.map((value) => {
            if (isNaN(value.data)) {
              value.data = null;
            }
          });
          return { data: data, producto: 'anomalias' };
        }),
        catchError((err) => of({ producto: 'anomalias', data: [] })),
      ),
    );
    types.push('default');

    return [petitions, types];
  }

  /** PETICIONES */
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  buildForkJoin(
    selectedArea: any[],
    selectedFecha: any,
    filters: any[],
    user: { fk_cliente: { id: number } },
    group: any,
    options: any[],
    tipo: number,
  ) {
    // Función que devuelve las peticiones en un forkJoin para madarlas a la API
    // Caso específico de Guabira (104): Solo información de dashboardGeneral

    // * formateamos el filtro que se enviará en el body
    var filter2send = this.formatFilter(filters, options, selectedFecha, tipo);

    // * Pedimos la fecha de hace 2 años para las gráficas de curva
    var dateYear: string = this.getDateFromYears(1, selectedFecha);

    // * Formateamos la fecha
    if (selectedFecha && selectedFecha.toString().includes('GMT')) {
      const offset = selectedFecha.getTimezoneOffset();
      var yourDate = new Date(selectedFecha.getTime() - offset * 60 * 1000);
      selectedFecha = yourDate.toISOString().split('T')[0];
    }

    // * Creamos las variables de las peticiones y los tipos de cada gráfica
    var petitions: any[] = [];
    var types: any[] = [];

    return !tipo
      ? this.graficasPorAgrupacion(
          group,
          petitions,
          selectedArea,
          filter2send,
          dateYear,
          types,
          user,
          options[0],
          selectedFecha,
        )
      : this.graficasAll(petitions, selectedArea, filter2send, selectedFecha, types, user, options[0]);
  }

  getDashboardCurva(filter: string | undefined): Observable<JSON> {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/dashboardCurvaFilter`, filter, this.httpOptions);
  }

  getDashboardHistogram(filter: string | undefined): Observable<JSON> {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/dashboardHistogramaFilter`, filter, this.httpOptions);
  }

  getDashboardHistogramZonas(filter: string | undefined): Observable<JSON> {
    return this.http.post<JSON>(
      `${environment.databaseURL}/rest/dashboardHistogramaZonasFilter`,
      filter,
      this.httpOptions,
    );
  }

  getDashboardTablaCosechado(filter: string | undefined): Observable<JSON> {
    return this.http.post<JSON>(
      `${environment.databaseURL}/rest/dashboardTablaCosechadoFilter`,
      filter,
      this.httpOptions,
    );
  }

  getDashboardGeneral(filter: string | undefined): Observable<JSON> {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/dashboardGeneralFilter`, filter, this.httpOptions);
  }

  getUniquesLimited(idArea: number | number[], filter: Object | string): Observable<string[]> {
    let body: Object = typeof filter === 'string' ? JSON.parse(filter) : filter;
    body = {
      ...body,
      fk_area__in: typeof idArea === 'number' ? [idArea] : idArea,
    };
    return this.http.post<string[]>(`${environment.databaseURL}/rest/uniqueslimited`, body, this.httpOptions);
    //return this.http.post<string[]>(`${environment.databaseURL}/rest/areas/${idArea}/uniqueslimited`,filter,this.httpOptions);
  }

  getFechas_graficas(body: string): Observable</*Object[]*/ string[]> {
    return this.http.post</*Object[]*/ string[]>(
      `${environment.databaseURL}/rest/dashboardFechas`,
      body,
      this.httpOptions,
    );
  }

  getClima(filter: string | undefined): Observable<JSON> {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/areasclima`, filter, this.httpOptions);
  }

  setFilters(filtro: any[], attr: string, val: any) {
    if (filtro && filtro.length > 0) {
      var filters: any[] = JSON.parse(JSON.stringify(filtro));
      // Cuando hay mas de un filtro al ser una array hay que buscar en el array de valores
      var aux2 = filters.find((x) => x.attribute == attr);
      var indice = aux2 ? aux2.value.findIndex((element: any) => element == val) : -1;
      if (filters.some((x) => x.attribute == attr)) {
        var ind = filters.findIndex((x) => x.attribute == attr);
        if (indice !== -1) {
          filters[ind].value.splice(indice, 1);
          !filters[ind].value.length && filters.splice(ind, 1);
        } else filters[ind].value.push(val);
      } else {
        filters.push({ attribute: attr, value: [val] });
      }
      return filters;
    }
    return [{ attribute: attr, value: [val] }];
  }
}
