/* 
1: tipo number -> slider
2: tipo date -> slider date
3: tipo string -> checkbox 
4: tipo boolean -> checkbox boolean 
5: nada
*/

export enum tiposAtributosOperacion {
  area = 1,
  cantidad = 1,
  codigo = 3,
  comentario = 3,
  descripcion = 3,
  equipo_aplicacion = 3,
  fecha_fin = 2,
  fecha_inicio = 2,
  operacion = 3,
  producto = 3,
  tipo_aplicacion = 3,
  fk_parcela_id = 3,
}
