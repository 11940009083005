import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadDateInfo } from '@src/app/@pages/navigation/mapa/componentes/workspace/componentes/workspace-inner-container/productos/state/productos.actions';
import {
  getDateInfo,
  getFechasProductSelected,
} from '@src/app/@pages/navigation/mapa/componentes/workspace/componentes/workspace-inner-container/productos/state/productos.selector';
import { AppState } from '@src/app/store/app.state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-calendar-info',
  templateUrl: './calendar-info.component.html',
  styleUrls: ['./calendar-info.component.scss'],
})
export class CalendarInfo implements OnInit, OnDestroy {
  data: any;
  loadingClima: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private store: Store<AppState>, private translocoService: TranslocoService) {}

  /**
   * component initializated
   */
  ngOnInit(): void {
    this.store
      .select(getDateInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((element) => {
        if (element) {
          this.data = {
            ...this.data,
            valor_medio: element['porcentajes']
              ? element['porcentajes'].reduce((act: any, e: any) => act + e, 0) / element['porcentajes'].length
              : null,
            ...element,
          };
        }
        this.loadingClima = false;
      });
    // escuchar al cambio de fecha selected para cargar nueva info
    this.store
      .select(getFechasProductSelected)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value) this.loadingClima = true;
        // pedir datos clima
        value && this.store.dispatch(loadDateInfo());
      });
  }

  /**
   * component destroyed
   */
  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
