export class Historico {
  id: number | undefined;
  cultivo: string | undefined;
  variedad: string | undefined;
  zafra: number | undefined;
  soca: number | undefined;
  fs: string | undefined;
  fc: string | undefined;
  fi: string | undefined;
  fe: string | undefined;
  rend: number | undefined;
  rend_est: number | undefined;
  tah: number | undefined;
  tah_ind: number | undefined;
  tah_core: number | undefined;
  fk_parcela_id: number | undefined;
  sacarosa: number | undefined;
  espacio_surcos: number | undefined;

  constructor(
    id?: number,
    cultivo?: string,
    variedad?: string,
    zafra?: number,
    soca?: number,
    fs?: string,
    fc?: string,
    fi?: string,
    fe?: string,
    rend?: number,
    rend_est?: number,
    tah?: number,
    tah_ind?: number,
    tah_core?: number,
    fk_parcela_id?: number,
    sacarosa?: number,
    espacio_surcos?: number,
  ) {
    this.id = id;
    this.cultivo = cultivo;
    this.variedad = variedad;
    this.zafra = zafra;
    this.soca = soca;
    this.fs = fs;
    this.fc = fc;
    this.fi = fi;
    this.fe = fe;
    this.rend = rend;
    this.rend_est = rend_est;
    this.tah = tah;
    this.tah_ind = tah_ind;
    this.tah_core = tah_core;
    this.fk_parcela_id = fk_parcela_id;
    this.sacarosa = sacarosa;
    this.espacio_surcos = espacio_surcos;
  }
}
