import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@src/app/store/app.state';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { checkToken, sendNewPassword, setResultChangePassword, setResultToken } from './recover-password.actions';
import { RecoverPasswordService } from '@src/app/servicios/recover-password.service';

@Injectable()
export class RecoverPasswordEffects {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private recoverPasswordService: RecoverPasswordService,
  ) {}

  checkToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(checkToken),
      exhaustMap((action) => {
        const { token } = action;
        return this.recoverPasswordService.verifyToken(token).pipe(
          map((result: any) => {
            return setResultToken({
              resultToken:
                result &&
                ((result['message'] && result['message'].toLowerCase().includes('validado')) ||
                  result.includes('validado')),
            });
          }),
          catchError((error) => {
            return of(setResultToken({ resultToken: false }));
          }),
        );
      }),
    );
  });

  sendNewPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendNewPassword),
      exhaustMap((action) => {
        const { password, token } = action;
        return this.recoverPasswordService.restorePassword(password, token).pipe(
          map((result: any) => {
            return setResultChangePassword({
              resultChangePassword: result && result.toLowerCase().includes('restaurada'),
            });
          }),
          catchError((error) => {
            return of(setResultChangePassword({ resultChangePassword: false }));
          }),
        );
      }),
    );
  });
}
