import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Agreement } from '../interfaces/Mapa/objetos/agreement';
import { Usuario } from '../objetos/usuario';

@Injectable({
  providedIn: 'root',
})
export class AgreementService {
  private readonly PRIVACY = 'privacy';
  private readonly CONFIDENCY = 'confidency';
  private readonly NAX = 'nax';
  private readonly FIRA = 'fira';

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Función que realiza la petición de aceptación de términos
   * @param body términos aceptados
   * @returns resultado de la acción de verificar (Verificado | Token expirado)
   */
  agreementSign(body: any) {
    return this.http.post<any>(`${environment.databaseURL}/rest/confidentialsign`, body, this.httpOptions);
  }

  getAgreementPermission(user: Usuario): boolean {
    const isFira: boolean = this.isFira(user);
    const privacy_fira: boolean = this.acceptedPrivacyFIRA(user);
    const privacy_nax: boolean = this.acceptedPrivacyNax(user);
    const confidency_nax: boolean = this.acceptedConfidencyNax(user);
    return !(!user.superuser && ((isFira && !privacy_fira) || !privacy_nax || !confidency_nax));
  }

  isFira(user: Usuario): boolean {
    return user?.fk_cliente?.metodo_pago?.toLowerCase() === this.FIRA;
  }

  acceptedPrivacyFIRA(user: Usuario): boolean {
    return user.agreements?.some(
      (agreement: Agreement) => agreement.issuer == this.FIRA && agreement.type == this.PRIVACY,
    );
  }

  acceptedPrivacyNax(user: Usuario): boolean {
    return user.agreements?.some(
      (agreement: Agreement) => agreement.issuer == this.NAX && agreement.type == this.PRIVACY,
    );
  }

  acceptedConfidencyNax(user: Usuario): boolean {
    return user.agreements?.some(
      (agreement: Agreement) => agreement.issuer == this.NAX && agreement.type == this.CONFIDENCY,
    );
  }
}
