import { Component, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { autoLogin } from './@pages/login/state/auth.actions';
import { Alert } from './interfaces/alert';
import { AuthService } from './servicios/auth.service';
import { AppState } from './store/app.state';
import { getAlert } from './store/share/share.selector';
import { setPlatformDevice } from './store/share/shared.actions';
import { downloadLinkEnable } from './@pages/navigation/mapa/componentes/workspace/componentes/tool-container/state/tool-container.actions';
import { Usuario } from './objetos/usuario';
import { DialogDownloadEmailComponent } from './@pages/navigation/mapa/componentes/workspace/componentes/tool-container/tools-template/downloads/dialog-download-email/dialog-download-email.component';
import { MatDialog } from '@angular/material/dialog';
import { getUser } from './@pages/inicio/state/user.selector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Nax Solutions';
  alert: Observable<Alert | null>;
  imagen: string | null;
  tokenParam: string;
  user: Observable<Usuario | null>;

  constructor(
    private store: Store<AppState>,
    public AuthService: AuthService,
    private actions: Actions,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.user = this.store.select(getUser);
    //window.location.href='https://www.naxsolutions.com/'
    this.imagen = '../assets/images/satelite-icon.png';
    this.checkplataforma();
    this.checkDialogAlert();
    this.subscribeDownloadsLink();
    // checkear que se trata de downloads y se tiene acceso
    // si no, se realizará el auto login

    if (!this.checkDownloadAvailable()) {
      this.store.dispatch(
        autoLogin({
          notRedirect: window.location.href.includes('inicio') ? false : true,
        }),
      );
    }
  }

  checkplataforma() {
    this.store.dispatch(
      setPlatformDevice({
        device: /Android|BlackBerry|Windows Phone/i.test(navigator.userAgent)
          ? 1
          : /iPod|webOS|iPhone|iPad/i.test(navigator.userAgent)
          ? 2
          : 3,
      }),
    );
  }

  checkDialogAlert() {
    this.alert = this.store.select(getAlert);
  }

  /**
   * Subscripción a la espera de que alguna descarga se haga mediante link
   */
  subscribeDownloadsLink() {
    this.actions.pipe(ofType(downloadLinkEnable)).subscribe((data) => {
      let { id } = data;
      this.openDownloadEmailDialog(id);
    });
  }

  /**
   * Abre diálogo de descarga con os datos del usuario y el id de descarga
   * @param id de la descarga
   */
  openDownloadEmailDialog(id: number) {
    this.dialog.open(DialogDownloadEmailComponent, {
      disableClose: true,
      data: {
        user: this.user,
        id: id,
      },
    });
  }

  /**
   * Comprobar que la ruta pertenece a downloads y se tiene al menos un token de acceso
   */
  checkDownloadAvailable() {
    return window.location.href.includes('download');
  }
}
