import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ParcelaState } from './parcelas.state';

export const PARCELA_STATE_NAME = 'parcelas';

const getParcelaState = createFeatureSelector<ParcelaState>(PARCELA_STATE_NAME);

export const getHistoricos = createSelector(getParcelaState, (state) => {
  return state.historicos;
});

export const getManejos = createSelector(getParcelaState, (state) => {
  return state.operaciones;
});

export const getRiegos = createSelector(getParcelaState, (state) => {
  return state.riegos;
});

export const getLaboratorios = createSelector(getParcelaState, (state) => {
  return state.laboratorios;
});

export const getEstadoOperacion = createSelector(getParcelaState, (state) => {
  return state.operacionSelected;
});

export const getParcelaSelected = createSelector(getParcelaState, (state) => {
  return state.parcelaSelected;
});

//AQUI TENGO QUE DECIDIR SI LO METO TODO EN UNA Y ME LO SACO TODO DE GOLPE O ME HAGO VARIOS SELECTORS DEPENDIENDO DE LA OPERACIÓN EN LA QUE ESTE

export const getSelects = createSelector(getParcelaState, (state) => {
  return {
    variedad: state.variedades,
    cultivo: state.cultivo,
    riego: state.tipoRiegos,
    operacion: state.tipoOperaciones,
  };
});
