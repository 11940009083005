import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RecoverPasswordState } from './recover-password.state';

export const RECOVER_PASSWORD_STATE_NAME = 'recover password';

const getRecoverPasswordState = createFeatureSelector<RecoverPasswordState>(RECOVER_PASSWORD_STATE_NAME);

export const getResultToken = createSelector(getRecoverPasswordState, (state) => {
  return state.resultToken;
});

export const getResultChangePassword = createSelector(getRecoverPasswordState, (state) => {
  return state.resultChangePassword;
});
