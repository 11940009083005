import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthbynumber',
})
export class MonthByNumberPipe implements PipeTransform {
  transform(value: number): any {
    let month = value;
    if (value < 1) {
      let a = Math.floor(Math.abs(value) / 12) + 1;
      month = value + 12 * a;
    } else if (value > 12) {
      let a = Math.floor(Math.abs(value) / 12);
      month = value - 12 * a || 12;
    }

    switch (month) {
      case 1:
        return 'enero';
      case 2:
        return 'febrero';
      case 3:
        return 'marzo';
      case 4:
        return 'abril';
      case 5:
        return 'mayo';
      case 6:
        return 'junio';
      case 7:
        return 'julio';
      case 8:
        return 'agosto';
      case 9:
        return 'septiembre';
      case 10:
        return 'octubre';
      case 11:
        return 'noviembre';
      case 12:
        return 'diciembre';
    }
  }
}
