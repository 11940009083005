import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate',
})
export class formatDatepickerDate implements PipeTransform {
  transform(val: any): Date | any {
    if (val && val instanceof Date) {
      const offset = val.getTimezoneOffset();
      var yourDate = new Date(val.getTime() - offset * 60 * 1000);
      var d = yourDate.toISOString().split('T')[0];
      var v = d.split('-');
      var result = new Date(parseInt(v[0]), parseInt(v[1]) - 1, parseInt(v[2]));
      return result;
    }
    return val;
  }
}
