import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBbox } from '@src/app/interfaces/Mapa/openlayers/bbox';
import { Novedad } from '@src/app/interfaces/Novedad.interface';
import { Fecha } from '@src/app/objetos/fecha';
import { Parcela2 } from '@src/app/objetos/parcela2';
import { Producto } from '@src/app/objetos/producto';
import { environment } from '@src/environments/environment';
import { View } from 'ol';
import Map from 'ol/Map';
import { defaults } from 'ol/control';
import { Extent } from 'ol/extent';
import { GeoJSON, WKT } from 'ol/format';
import { DragPan, defaults as defaultInteractions } from 'ol/interaction';
import BaseLayer from 'ol/layer/Base';
import TileLayer from 'ol/layer/Tile';
import { BingMaps } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapaService {
  variableMapColors: string[] = [
    '#695e53',
    '#e2e3c8',
    '#738678',
    '#ffdead',
    '#5e3c58',
    '#7b6148',
    '#a9aa96',
    '#8b4726',
    '#4d5d53',
    '#ead61c',
  ];

  private map: Map;
  private readonly id_map: string = 'map';
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  public httpOptionsXML = {
    headers: new HttpHeaders({ 'Content-Type': 'application/xml' }),
  };

  constructor(private http: HttpClient) {
    this.map = new Map({});
  }

  /**
   * Rests map instance with the default value
   */
  resetMap(): void {
    this.map = this.initializeMap(this.id_map);
  }

  /**
   * Get map instance
   * @returns map instance
   */
  getMap(): Map {
    return this.map;
  }

  /**
   * Inicializamos el mapa de fondo
   * @param idMap identifcador del mapa
   * @returns
   */
  private initializeMap(idMap: string): Map {
    let baseLayer = new TileLayer({
      preload: Infinity,
      source: new BingMaps({
        key: environment.bigMapsKey,
        imagerySet: 'AerialWithLabels',
      }),
    });

    baseLayer.setZIndex(0);

    let new_map = new Map({
      target: idMap,
      layers: [baseLayer],
      view: new View({
        projection: 'EPSG:4326',
        center: [0, 0],
        zoom: 4,
        minZoom: 4,
        showFullExtent: true,
        maxZoom: 19,
      }),
      controls: defaults({
        zoom: false,
        //attribution : false,
        rotate: false,
      }),
      interactions: defaultInteractions({ dragPan: false }).extend([
        new DragPan({
          condition: function (mapBrowserEvent) {
            return mapBrowserEvent.originalEvent.isPrimary && mapBrowserEvent.originalEvent.button < 2;
          },
        }),
      ]),
    });

    setTimeout(() => {
      new_map.updateSize();
    }, 1);

    return new_map;
  }

  /**
   * Get the highest zIndex in the map.
   * If returns -1, the map instance is not initializated
   * @returns
   */
  getHigherLayer(): number {
    if (!this.map) return -1;
    return Math.max(
      ...this.map
        .getLayers()
        .getArray()
        .map((layer: BaseLayer) => layer.getZIndex()),
    );
  }

  /**
   *
   * @param idArea id del area seleccionado
   * @param datos string tipo json para aplicar a la petición
   * @returns observable con el tipo de generico que llama a la función
   */
  getUniquesGeneral<T>(idArea: number | number[], datos: Object | string): Observable<T> {
    let body: Object = typeof datos === 'string' ? JSON.parse(datos) : datos;
    body = {
      ...body,
      fk_area__in: typeof idArea === 'number' ? [idArea] : idArea,
    };
    //return this.http.post<any>(`${environment.databaseURL}/rest/areas/${area}/uniques`,datos,this.httpOptions);
    return this.http.post<any>(`${environment.databaseURL}/rest/uniques`, body, this.httpOptions);
  }

  getValoresAtributo(idArea: number | number[], datos: Object | string) {
    let body: Object = typeof datos === 'string' ? JSON.parse(datos) : datos;
    body = !body
      ? {
          filtro: {
            fk_area__in: typeof idArea === 'number' ? [idArea] : idArea,
          },
        }
      : {
          ...body,
          filtro: {
            ...body['filtro'],
            fk_area__in: typeof idArea === 'number' ? [idArea] : idArea,
          },
        };
    //return this.http.post(`${environment.databaseURL}/rest/areas/${idArea}/parcelasmaxmin`, body)
    return this.http.post(`${environment.databaseURL}/rest/parcelasmaxmin`, body);
  }

  /**
   * obtenemos el bounding box del area
   * @param idArea id del area seleccionado
   * @returns
   */
  getBbox(idArea: number): Observable<IBbox> {
    return this.http.get<IBbox>(`${environment.databaseURL}/rest/areas/${idArea}/extent`);
  }

  /**
   * Obtenemos todos los poligonos del area segun el filtro
   * @param area id del area seleccionado
   * @param body filtros para aplicar
   * @returns json con todas los poligonos del area
   */
  getParcelasFilter(area: number, body: string): Observable<JSON> {
    return this.http.post<JSON>(
      `${environment.databaseURL}/rest/areas/${area}/parcelasGeojson`,
      body,
      this.httpOptions,
    );
  }

  /**
   * Obtenemos los productos asignados al usuario del area
   * @param area identificador del area seleccionado
   * @returns
   */
  getProductos(area: number): Observable<Producto[]> {
    return this.http.get<Producto[]>(`${environment.databaseURL}/rest/areas/${area}/productos`);
  }

  getProductosArea(area: number): Observable<Producto[]> {
    return this.http.get<Producto[]>(`${environment.databaseURL}/rest/areas/${area}/productos/forceall`);
  }

  deleteOperaciones(id: number, estado: string) {
    return this.http.delete<any>(`${environment.databaseURL}/rest/${estado}/${id}`);
  }

  getOperaciones(operacion: string, idnax: any) {
    return this.http.get<any>(`${environment.databaseURL}/rest/parcelas/${idnax}/${operacion}page`);
  }

  getOperacionesPaginadas(url: string) {
    return this.http.get<any>(`${environment.databaseURL}${url}`);
  }

  updateParcela(idnax: number, body: Parcela2) {
    return this.http.put<any>(`${environment.databaseURL}/rest/parcelas/${idnax}`, body);
  }

  updateOperacion(operacion: string, body: any, idOperacion: number) {
    return this.http.put<any>(`${environment.databaseURL}/rest/${operacion}/${idOperacion}`, body);
  }

  postOperacion(operacion: string, body: any, idnax: number) {
    return this.http.post<any>(`${environment.databaseURL}/rest/parcelas/${idnax}/${operacion}`, body);
  }

  //--------------------------------------------
  /**
   * Función que transforma el producto al pedir las curvas (no existen curvas de optimos ni de nivel 2)
   * @param producto producto seleccionado
   * @param optimo si es óptimo
   * @returns string del producto a pedir
   */
  transformarProducto(producto: Producto, optimo: boolean) {
    //reducir a un solo if con regexp
    if (producto.nombre.includes('_filt')) {
      producto.nombre = producto.nombre.replace('_filt', '');
    }

    if (producto.nombre.includes('_optimo')) {
      producto.nombre = producto.nombre.replace('_optimo', '');
      if (producto.id_filt) {
        producto.id = producto.id_filt;
      } else {
        producto.id = producto.id_copy;
      }
    }

    if (producto.nubes && !optimo) {
      producto.nombre = producto.nombre + '_filt';
      producto.id = producto.id_filt;
    }

    if (producto.optimo && optimo) {
      producto.nombre = producto.nombre + '_optimo';
      producto.id = producto.id_optimo;
    }

    return producto;
  }

  /**
   * Función para formatear la petición de las fechas del producto
   * @param dateInput mes a pedir
   * @returns body de la petición con el date range (+- 2 meses)
   */
  getNewDatePetition(dateInput: Date) {
    var month = dateInput.getMonth();
    var year = dateInput.getFullYear();
    return `{"fecha__month": ${month + 1},"fecha__year": ${year}}`;
  }

  /**
   *
   * @param producto
   * @returns
   */
  getFechas(producto: number): Observable<Fecha[]> {
    return this.http.get<Fecha[]>(`${environment.databaseURL}/rest/productos/${producto}/fechas`);
  }

  /**
   *
   * @param producto
   * @param body Filtros para aplicas
   * @returns
   */
  getFechasFilter(producto: number, body: string): Observable<Fecha[]> {
    return this.http.post<Fecha[]>(
      `${environment.databaseURL}/rest/productos/${producto}/fechasFilter`,
      body,
      this.httpOptions,
    );
  }

  getFechaClima(body: string): Observable<any> {
    //return this.http.post<any>(`${environment.databaseURL}/rest/areas/${idArea}/clima`,body,this.httpOptions);
    return this.http.post<any>(`${environment.databaseURL}/rest/areasclima`, body, this.httpOptions);
  }

  getFechasAvaiable(idProducto: number): Observable<any> {
    return this.http.get<any>(`${environment.databaseURL}/rest/rangosFechas/${idProducto}`, this.httpOptions);
  }

  /**
   *
   * @param url
   * @param body
   * @returns
   */
  sendRequestPost<T>(url: string, body: any, option: number): Observable<T> {
    return this.http.post<T>(url, body, this.getHttpOptions(option));
  }

  /**
   *
   * @param url
   * @returns
   */
  sendRequestGet<T>(url: string): Observable<T> {
    return this.http.get<T>(url);
  }

  /**
   * Función que te devuelve las httpOptions dependiendo del layer y su atributo httpOptions
   * @param input
   * @returns
   */
  getHttpOptions(input: number) {
    switch (input) {
      case 0:
        return this.httpOptions;
      case 1:
        return {};
      default:
        break;
    }
  }

  getRandomColor() {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  }

  getRandomLightColor(colores: string[]): string {
    var num = (Math.floor(Math.random() * 4) * 4).toString(16);
    var letters = ['0', 'F', num];
    var color = '#';

    for (var i = 0; i < 3; i++) {
      let pos = Math.floor(Math.random() * letters.length);
      color += letters[pos];
      letters.splice(pos, 1);
    }

    //para evitar que se repitan colores
    if (colores.includes(color)) return this.getRandomLightColor(colores);
    else colores.push(color);

    return color;
  }

  getVariableMapColor(index: number) {
    if (index < this.variableMapColors.length) {
      return this.variableMapColors[index];
    } else {
      return this.getRandomColor();
    }
  }

  featuresForUpload(capa: number, features: any[]): any {
    var wkt = new WKT();
    return features.map((element) => {
      return {
        fk_capa: capa,
        geometry: wkt.writeGeometry(element.getGeometry()),
      };
    });
  }

  /**
   * generate vector source
   * @param data
   * @returns
   */
  createSourceJson(data: JSON | Object): VectorSource {
    var vectorSource = new VectorSource({});
    data && vectorSource.addFeatures(new GeoJSON().readFeatures(data));
    return vectorSource;
  }

  /**
   * Aggregate correct zoom to layer with correct extent
   * @param extent
   * @param map
   */
  zoomToLayer(extent: Extent | undefined) {
    if (!extent?.length || extent?.includes(Infinity) || extent?.includes(-Infinity)) return;
    extent.findIndex(Number.isNaN) < 0 && this.map.getView().fit(extent, { size: this.map.getSize() });
  }

  /** PETITIONS */

  getLeyenda(cliente: string, area: string, producto: string) {
    // responseType text para poder obtener el error en xml en caso que se produzca
    return this.http.get(
      `${environment.ip}/geoserver/${cliente}/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=application/json&WIDTH=10&LAYER=${cliente}:${area}_${producto}&TILED=true&TRANSPARENT=true&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0xFFFFFF;fontSize:12;dpi:70`,
      { responseType: 'text' },
    );
  }

  getLeyendaDefault(product_name: string, body: any) {
    return this.http.post(`${environment.databaseURL}/rest/legends/${product_name}`, body, this.httpOptions);
  }

  putLeyenda(producto: number, datos: string /*product_name, body*/) {
    return this.http.post<JSON>(
      `${environment.databaseURL}/rest/productos/${producto}/estilo`,
      datos,
      this.httpOptionsXML,
    );
    //return this.http.put(`${environment.databaseURL}/rest/legends/${product_name}`, body, this.httpOptions)
  }

  getMinMaxProducto(nombre_producto: string) {
    return this.http.get(`${environment.databaseURL}/rest/rangosProductos/${nombre_producto}`);
  }

  getNovedad(id: number) {
    return this.http.get<Novedad>(`${environment.databaseURL}/rest/novedades/${id}`);
  }

  /**
   * Función que guarda los datos de una subida al servidor en BD
   * @param data datos a guardar
   * @returns estado de la operación
   */
  saveLogUpload(data: any) {
    return this.http.post(`${environment.databaseURL}/rest/uploads/`, data, this.httpOptions);
  }

  createUploadsProducts(areaId: number, uploadId: number, data: any) {
    return this.http.post(
      `${environment.databaseURL}/rest/areas/${areaId}/uploads/${uploadId}/product`,
      data,
      this.httpOptions,
    );
  }
  editUploadsProducts(areaId: number, uploadId: number, productId: number, data: any) {
    return this.http.put(
      `${environment.databaseURL}/rest/areas/${areaId}/uploads/${uploadId}/product/${productId}`,
      data,
      this.httpOptions,
    );
  }

  /**
   * Get values from layer id
   * @param id from layer
   * @returns feauturess from layer id
   */
  getPersonalizableLayer(id: number): Observable<JSON> {
    return this.http.get<JSON>(`${environment.databaseURL}/rest/capas/${id}/geometrias`);
  }

  /**
   * Post to get JSON with geometries of input in body
   * @param body
   * @returns
   */
  postPersonalizableLayer(body: any): Observable<JSON> {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/geometrys`, body);
  }
}
