import { MarcadoresFilterObject, MarcadorFilterOutput } from '@src/app/interfaces/Mapa/tools/MarcadoresInput.interface';
import { Tablero } from '@src/app/objetos/tablero';

export enum LabelsMarcadoresFilterState {
  USERS = 'usuario',
  IS_LOADING = 'isLoading',
  IS_LOADING_USERS = 'isLoadingUsers',
  IS_LOADING_TABLEROS = 'isLoadingTableros',
  TABLEROS = 'tableros',
  FILTER = 'filter',
  MARCADORES = 'marcadores',
}

export interface MarcadoresFilterState {
  usuario: { id: number; workspace: string }[];
  isLoadingUsers: boolean;
  isLoadingTableros: boolean;
  isLoading: boolean;
  tableros: Tablero[];
  filter: MarcadoresFilterObject;
  marcadores: { type: string; features: MarcadorFilterOutput[] } | null;
}

export const initialMarcadoresFilterState: MarcadoresFilterState = {
  usuario: [],
  isLoadingUsers: false,
  isLoadingTableros: false,
  isLoading: false,
  tableros: [],
  filter: {
    titulo: null,
    prioridad: null,
    tablero: null,
    usuario: null,
    estado: null,
  },
  marcadores: null,
};
