import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { updateStateVariableTools } from '../@pages/navigation/mapa/componentes/workspace/componentes/tool-container/state/tool-container.actions';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';
import { editLayerAttribute } from '../@pages/navigation/mapa/componentes/workspace/componentes/openlayers/state/openlayers.actions';
import { Pagination } from '../interfaces/Admin/pagination';
import { AppState } from '../store/app.state';
import { editProgressBar } from '../store/share/shared.actions';
import { OpenlayersService } from './openlayers.service';
import { Z_INDEX_TASKS } from '../@pages/navigation/mapa/componentes/workspace/componentes/tool-container/tools-template/tareas/tareas.component';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, private store: Store<AppState>, private openlayersService: OpenlayersService) {}

  openLink(link: string) {
    link && window.open(link);
  }

  /** POLYGONS */
  resetPolygons() {
    this.store.dispatch(editLayerAttribute({ zIndex: 5, attribute: 'create', value: false }));
    this.store.dispatch(editLayerAttribute({ zIndex: 11, attribute: 'create', value: false }));

    this.store.dispatch(updateStateVariableTools({ variable: 'addLayerFeatures', value: null }));
    this.store.dispatch(editProgressBar({ mode: 'query', display: false, value: 0 }));
    this.openlayersService.subjectComparePolygon.next(null);
  }

  /** DRON */
  /**
   * Función que pide orthophotos guardadas
   * @param area id del área
   * @param filter posible filtro que se le pueda aplicar
   * @returns datos de la paginación
   */
  getDataDronImages(area: number, filter?: Object): Observable<Pagination> {
    return this.http.post<Pagination>(`${environment.databaseURL}/rest/areas/${area}/imagesDronpage`, filter);
  }

  /**
   * Obtención mediante url de datos de paginación
   * @param url cadena de búsqueda
   * @param filter posible filtro que se le pueda aplicar
   * @returns datos de la paginación
   */
  getByUrl(url: string, filter?: Object): Observable<Pagination> {
    return this.http.post<Pagination>(`${environment.databaseURL}${url}`, filter);
  }

  /**
   * Obtiene la url para mostrar la imagen
   * @param workspace
   * @param area
   * @param date
   * @param boundingBox
   * @returns url
   */
  getImagesBoundingBox(workspace: string, area: string, date: string, boundingBox: any): string {
    return `${
      environment.ip
    }/geoserver/${workspace}/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image/png8&TRANSPARENT=true&LAYERS=${
      workspace ? workspace.toLocaleLowerCase() : ''
    }:${area.toLocaleLowerCase()}_${
      workspace + area.toLocaleLowerCase()
    }ortofoto&TIME=${date}&WIDTH=256&HEIGHT=256&CRS=EPSG:4326&STYLES=&BBOX=${boundingBox}`;
  }

  getDronErrorImages(area: number, status: string): Observable<any> {
    return this.http.get<any>(`${environment.databaseURL}/rest/areas/${area}/imagesDron?status=${status}`);
  }

  deleteDronErrorImage(area: number, id: number): Observable<any> {
    return this.http.delete<any>(`${environment.databaseURL}/rest/areas/${area}/imagesDron/${id}`);
  }

  deleteDronImages(area: number, ids: number[]): Observable<any> {
    return this.http.post<any>(
      `${environment.databaseURL}/rest/areas/${area}/imagesDron/remove`,
      ids,
      this.httpOptions,
    );
  }
  /** TASKS */
  /**
   *
   * @returns generic layer for tasks
   */
  getTasksLayer() {
    return this.openlayersService.createVectorLayer(
      window.screen.width,
      new VectorSource({}),
      'image',
      Z_INDEX_TASKS,
      this.getLayerStyleMarcadoresTask(),
    );
  }

  private colorTask = '#00FFFF';
  private widthTask = 2;

  /**
   * Get specific style of marks in map
   * @returns
   */
  private getLayerStyleMarcadoresTask(): Style {
    let style = new Style({});
    style.setFill(
      new Fill({
        color: this.colorTask,
      }),
    );
    style.setStroke(
      new Stroke({
        width: this.widthTask,
        color: this.colorTask,
      }),
    );
    style.setImage(
      new CircleStyle({
        radius: this.widthTask,
        fill: new Fill({
          color: this.colorTask,
        }),
        stroke: new Stroke({
          width: this.widthTask * 2,
          color: this.colorTask,
        }),
      }),
    );
    return style;
  }
}
