import { createAction, props } from '@ngrx/store';
import { rango } from '@src/app/enumerations/Precosecha.enum';
import { Pagination } from '@src/app/interfaces/Admin/pagination';
import { Capa } from '@src/app/interfaces/Mapa/tools/capas';
import { Area } from '@src/app/objetos/area';
import { Parcela2 } from '@src/app/objetos/parcela2';
import { Producto } from '@src/app/objetos/producto';
import { Usuario } from '@src/app/objetos/usuario';

/** GENERAL */
export const UPDATE_STATE_VARIABLE_TOOLS = '[toolContainer page] update state variable tools';

export const updateStateVariableTools = createAction(
  UPDATE_STATE_VARIABLE_TOOLS,
  props<{ variable: string; value: any }>(),
);

/** TOOLS COMPONENT */
export const SET_TOOLCONTAINER = '[ToolContainer page] Set ToolContainer';
export const SET_TOOLS = '[toolContainer page] Set Tools';
export const SET_ACTIVE_TOOL = '[toolContainer page] Set active tool';

export const setToolcontainer = createAction(SET_TOOLCONTAINER);

export const setTools = createAction(
  SET_TOOLS,
  props<{
    area: Area | null;
    producto: Producto | null;
    user: Usuario | null;
  }>(),
);

export const setActiveTool = createAction(SET_ACTIVE_TOOL, props<{ tool: any }>());

/** DOWNLOADS COMPONENT */
export const DOWNLOAD_ERA5 = '[toolContainer page] download ERA5';
export const DOWNLOAD_TIFF = '[toolContainer page] download TIFF';
export const DOWNLOAD_RGB = '[toolContainer page] download RGB';
export const DOWNLOAD_XLSX = '[toolContainer page] download XLSX';
export const DOWNLOAD_SHAPE = '[toolContainer page] download SHAPE';

export const downloadERA5 = createAction(DOWNLOAD_ERA5, props<{ dateBegin: string | null; dateEnd: string | null }>());

export const downloadTIFF = createAction(DOWNLOAD_TIFF, props<{ dateBegin: string; dateEnd: string }>());

export const downloadRGB = createAction(DOWNLOAD_RGB, props<{ dateBegin: string; dateEnd: string }>());

export const downloadXLSX = createAction(
  DOWNLOAD_XLSX,
  props<{ dateBegin: string; dateEnd: string; attribute?: string }>(),
);

export const downloadSHAPE = createAction(DOWNLOAD_SHAPE);

export const DOWNLOAD_LINK_ENABLE = '[toolContainer page] download link enable';
export const downloadLinkEnable = createAction(DOWNLOAD_LINK_ENABLE, props<{ id: number }>());

export const CHANGE_EMAIL_DOWNLOAD = '[toolContainer page] change email download';
export const changeEmailDownload = createAction(CHANGE_EMAIL_DOWNLOAD, props<{ id: number; email: string }>());

export const CHANGE_EMAIL_DOWNLOAD_SUCCESS = '[toolContainer page] change email download success';
export const changeEmailDownloadSuccess = createAction(CHANGE_EMAIL_DOWNLOAD_SUCCESS, props<{ status: boolean }>());

export const GET_ZIP_DOWNLOAD = '[toolContainer page] get zip download';
export const getZipDownload = createAction(GET_ZIP_DOWNLOAD, props<{ token: string; normalStatus?: boolean }>());

export const GET_ZIP_DOWNLOAD_SUCCESS = '[toolContainer page] get zip download success';
export const getZipDownloadSuccess = createAction(
  GET_ZIP_DOWNLOAD_SUCCESS,
  props<{ status: boolean; message?: string; normalStatus?: boolean }>(),
);

/** CAPAS COMPONENT */
export const LOAD_CAPAS = '[toolContainer page] load capas';
export const LOAD_CAPAS_SUCCESS = '[toolContainer page] load capas success';

export const UPLOAD_CAPA = '[toolContainer page] upload capa';
export const UPLOAD_CAPA_GEOMETRIES = '[toolContainer page] upload capa geometries';

export const DELETE_CAPA = '[toolContainer page] delete capa';
export const DELETE_CAPA_SUCCESS = '[toolContainer page] delete capa success';

export const LOAD_CAPA_GEOMETRIES = '[toolContainer page] load capa geometries';
export const LOAD_CAPA_GEOMETRIES_SUCCESS = '[toolContainer page] load capa geometries success';

export const GET_CAPA_GEOMETRIES = '[toolContainer page] get capa geometries';
export const GET_CAPA_GEOMETRIES_SUCCESS = '[toolContainer page] get capa geometries success';

export const EDIT_CAPA = '[toolContainer page] edit capa';

export const loadCapas = createAction(LOAD_CAPAS);
export const loadCapasSuccess = createAction(LOAD_CAPAS_SUCCESS, props<{ capa: Capa[] | null }>());

export const loadCapaGeometries = createAction(LOAD_CAPA_GEOMETRIES, props<{ capa: Capa[] }>());
export const loadCapasGeometriesSuccess = createAction(
  LOAD_CAPA_GEOMETRIES_SUCCESS,
  props<{ geometries: any[] | null }>(),
);

export const getCapaGeometries = createAction(GET_CAPA_GEOMETRIES, props<{ form: FormData }>());
export const getCapasGeometriesSuccess = createAction(
  GET_CAPA_GEOMETRIES_SUCCESS,
  props<{ geometries: any[] | null }>(),
);

export const deleteCapa = createAction(DELETE_CAPA, props<{ capa: Capa }>());
export const deleteCapaSuccess = createAction(DELETE_CAPA_SUCCESS, props<{ capa: Capa | null }>());

export const uploadCapa = createAction(UPLOAD_CAPA, props<{ id: number | null; geometries: any[]; name?: string }>());
export const uploadCapaGeometries = createAction(UPLOAD_CAPA_GEOMETRIES, props<{ capa: Capa; geometries: any[] }>());

export const editCapa = createAction(EDIT_CAPA, props<{ capa: Capa }>());

/** MAPA VARIABLE */

export const DOWNLOAD_MAPA_VARIABLE = '[toolContainer page] download mapa variable';
export const DOWNLOADING_MAPA_VARIABLE = '[toolContainer page] downloading mapa variable';
export const SAVE_MV_DIALOG_STATE = '[toolContainer page] save mapa variable dialog state';
export const SAVE_RANGES = '[toolContainer page] save ranges';

export const downloadMapaVariable = createAction(DOWNLOAD_MAPA_VARIABLE, props<{ selection: number; rangos: any }>());
export const downloadingMapaVariable = createAction(DOWNLOADING_MAPA_VARIABLE, props<{ value: boolean }>());
export const saveMPdialogState = createAction(SAVE_MV_DIALOG_STATE, props<{ propertys: Object }>());
export const saveRanges = createAction(SAVE_RANGES, props<{ ranges: Array<Object> }>());

/** PRECOSECHA COMPONENT */
export const GENERATE_PRECOSECHA_POINTS = '[toolContainer page] generate precosecha points';
export const ADD_MARCADOR = '[toolContainer page] add marcador';
export const EDIT_MARCADOR = '[toolContainer page] edit marcador';
export const EDIT_MARCADOR_SELECTED = '[toolContainer page] edit marcador selected';
export const DELETE_MARCADOR = '[toolContainer page] deleteMarcador';

export const generatePrecosechaPoints = createAction(GENERATE_PRECOSECHA_POINTS, props<{ rango: rango }>());

export const addMarcador = createAction(ADD_MARCADOR, props<{ marcador: any; update: boolean }>());

export const editMarcador = createAction(EDIT_MARCADOR, props<{ value: any; attribute: string; feature_id: number }>());

export const editMarcadorSelected = createAction(EDIT_MARCADOR_SELECTED, props<{ marcador: any }>());

export const deleteMarcador = createAction(DELETE_MARCADOR, props<{ marcador: any }>());

/** POLYGONS */
export const UPLOAD_POLYGONS = '[toolContainer page] upload polygons';

export const uploadPolygons = createAction(UPLOAD_POLYGONS, props<{ polygons: Parcela2[]; previousShape?: boolean }>());

export const UPLOAD_POLYGONS_SUCCESS = '[toolContainer page] upload polygons success';
export const uploadPolygonsSuccess = createAction(
  UPLOAD_POLYGONS_SUCCESS,
  props<{ result: boolean; message?: string }>(),
);

/** VIDEOS COMPONENT */
export const SAVE_DATA_VIDEOS = '[toolContainer page] save dataVideos';

export const saveDataVideos = createAction(SAVE_DATA_VIDEOS, props<{ videos: Array<any> }>());

/** TAREAS COMPONENT */
export const UPDATE_TAREADATA = '[toolContainer page] update tarea data';

export const updateTareaData = createAction(UPDATE_TAREADATA, props<{ attribute: string; value: any }>());

export const RESET_TOOLS_STATE = '[toolContainer page] reset tools state';

export const resetToolsState = createAction(RESET_TOOLS_STATE);

/** COMPARE SHAPE COMPONENT */
export const UPDATE_SHAPE = '[toolContainer page] update shape';
export const updateShape = createAction(UPDATE_SHAPE, props<{ area: number; upload: any[]; update: any[] }>());

export const SET_SAVING_TAREA = '[toolContainer page] set saving tarea';
export const setSavingTarea = createAction(SET_SAVING_TAREA, props<{ status: boolean }>());

/** DRON */
export const GET_DATA_IMAGES_DRON = '[toolContainer page] get data images dron';
export const getDataImagesDron = createAction(GET_DATA_IMAGES_DRON, props<{ fecha?: string | null; url?: string }>());
export const GET_ERROR_DATA_IMAGES_DRON = '[toolContainer page] get error data images dron';
export const getErrorDataImagesDron = createAction(GET_ERROR_DATA_IMAGES_DRON);
export const GET_ERROR_DATA_IMAGES_DRON_SUCCESS = '[toolContainer page] get error data images dron success';
export const getErrorDataImagesDronSuccess = createAction(
  GET_ERROR_DATA_IMAGES_DRON_SUCCESS,
  props<{ data: any | null }>(),
);

export const REMOVE_DRON_ERROR_IMAGE = '[toolContainer page] remove error dron image';
export const removeDronErrorImage = createAction(REMOVE_DRON_ERROR_IMAGE, props<{ id: number }>());

export const REMOVE_DRON_ERROR_IMAGE_SUCCESS = '[toolContainer page] remove error dron image success';
export const removeDronErrorImageSuccess = createAction(REMOVE_DRON_ERROR_IMAGE_SUCCESS, props<{ id: number }>());

export const REMOVE_DRON_IMAGES = '[toolContainer page] remove dron images';
export const removeDronImages = createAction(REMOVE_DRON_IMAGES, props<{ ids: any; areaId: number }>());

export const REMOVE_DRON_IMAGES_SUCCESS = '[toolContainer page] remove dron images success';
export const removeDronImagesSuccess = createAction(REMOVE_DRON_IMAGES_SUCCESS);

export const GET_DATA_IMAGES_DRON_SUCCESS = '[toolContainer page] get data images dron success';
export const getDataImagesDronSuccess = createAction(
  GET_DATA_IMAGES_DRON_SUCCESS,
  props<{ data: Pagination | null }>(),
);

export const SET_LOADING_IMAGES = '[toolContainer page] set loading images';
export const setLoadingImages = createAction(SET_LOADING_IMAGES, props<{ status: boolean }>());

export const CREATE_UPLOAD_PRODUCT = '[toolContainer page] create upload product';
export const createUploadProduct = createAction(
  CREATE_UPLOAD_PRODUCT,
  props<{ uploadId: number; data: any; areaId: number }>(),
);

export const EDIT_UPLOAD_PRODUCT = '[toolContainer page] edit upload product';
export const editUploadProduct = createAction(
  EDIT_UPLOAD_PRODUCT,
  props<{ uploadId: number; productId: number; data: any; areaId: number }>(),
);

export const UPLOAD_PRODUCT_SUCCESS = '[toolContainer page] upload product success';
export const uploadProductSuccess = createAction(UPLOAD_PRODUCT_SUCCESS, props<{ uploadProduct: any }>());
export const UPDATE_SHAPE_SUCCESS = '[toolContainer page] update shape success';
export const updateShapeSuccess = createAction(UPDATE_SHAPE_SUCCESS, props<{ result: boolean; message?: string }>());

export const LOAD_COMPARE_SHAPE = '[toolContainer page] load compare shape';
export const LOAD_COMPARE_SHAPE_SUCCESS = '[toolContainer page] load compare shape success';
export const loadCompareShape = createAction(LOAD_COMPARE_SHAPE, props<{ idArea: number; form: FormData }>());
export const loadCompareShapeSuccess = createAction(LOAD_COMPARE_SHAPE_SUCCESS, props<{ data: any }>());

/** DRIVE */
/** Folders */

export const REMOVE_DRON_FROM_DRIVE = '[toolContainer page] remove dron from drive';
export const removeDronFromDrive = createAction(REMOVE_DRON_FROM_DRIVE, props<{ images: any[] }>());

export const MOVE_FILES_DRIVE = '[toolContainer page] move files drive';
export const moveFilesDrive = createAction(MOVE_FILES_DRIVE, props<{ images: any[]; name: string; folders: any[] }>());

/** Upload */
export const GET_RESUMABLE_IMAGES = '[toolContainer page] get resumable images';
export const getResumableImages = createAction(GET_RESUMABLE_IMAGES, props<{ files: any }>());

export const UPLOAD_CHUNK_DATA = '[toolContainer page] upload chunk data';
export const uploadChunkData = createAction(
  UPLOAD_CHUNK_DATA,
  props<{ files: any[] | null; filesSuccess?: any[] | null }>(),
);

export const UPLOAD_CHUNK_DATA_SUCCESS = '[toolContainer page] upload chunk data success';
export const uploadChunkDataSuccess = createAction(
  UPLOAD_CHUNK_DATA_SUCCESS,
  props<{ result: boolean; filesData: Object[] | null }>(),
);

export const UPLOAD_CHUNK_DATA_PAUSE = '[toolContainer page] upload chunk data pause';
export const uploadChunkDataPause = createAction(
  UPLOAD_CHUNK_DATA_PAUSE,
  props<{ files: any[] | null; filesSuccess?: any[] | null }>(),
);

export const SET_PERCENTAGE_UPLOAD = '[toolContainer page] set percentage upload';
export const setPercentageUpload = createAction(SET_PERCENTAGE_UPLOAD, props<{ newPercentage: number | null }>());

export const SAVE_LOG_UPLOAD = '[toolContainer page] save log upload';
export const saveLogUpload = createAction(SAVE_LOG_UPLOAD, props<{ data: Object[] }>());

export const SAVE_LOG_UPLOAD_SUCCESS = '[toolContainer page] save log upload success';
export const saveLogUploadSuccess = createAction(SAVE_LOG_UPLOAD_SUCCESS);

export const SET_CANCEL_UPLOAD = '[toolContainer page] set cancel upload';
export const setCancelUpload = createAction(SET_CANCEL_UPLOAD, props<{ status: boolean }>());

export const SET_PAUSE_UPLOAD = '[toolContainer page] set pause upload';
export const setPauseUpload = createAction(SET_PAUSE_UPLOAD, props<{ status: boolean }>());

/** Get files */
export const GET_FILES_DRIVE = '[toolContainer page] get files drive';
export const getFilesDrive = createAction(GET_FILES_DRIVE, props<{ fileId: string }>());

export const GET_FILES_DRIVE_SUCCESS = '[toolContainer page] get files drive success';
export const getFilesDriveSuccess = createAction(GET_FILES_DRIVE_SUCCESS, props<{ files: any[] | null }>());

export const GET_FILES_DRIVE_DOWNLOAD_LINK = '[toolContainer page] get files drive download link';
export const getFilesDriveDownloadLink = createAction(GET_FILES_DRIVE_DOWNLOAD_LINK, props<{ id: string }>());

export const GET_GEOJSON_DRIVE_SUCCESS = '[toolContainer page] get geojson drive success';
export const getGeojsonDriveSuccess = createAction(GET_GEOJSON_DRIVE_SUCCESS, props<{ data: any }>());

/** Delete files */
export const DELETE_DRIVE_FILES = '[toolContainer page] delete drive files';
export const deleteDriveFiles = createAction(DELETE_DRIVE_FILES, props<{ name: string; file: any }>());
