<form #form="ngForm" (ngSubmit)="onSubmit(form)" *ngIf="tableros | async as tablerosData">
  <div class="span-size" *ngIf="!isPrecosecha || (isPrecosecha && tipoMuestreo == 'Muestreo General')">
    <label for="tablero"
      >{{ 'mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas.table' | transloco
      }}<span class="required"> * </span></label
    >
    <select style="max-width: 23vh" required (change)="setSelectedTablero($event)" id="tablero" name="tablero">
      <option *ngIf="!selectedTablero">
        {{
          'mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas.tablero' | transloco
        }}
      </option>
      <option [value]="tablero.id" *ngFor="let tablero of tablerosData">
        {{ tablero.nombre }}
      </option>
    </select>
  </div>
  <div class="span-size">
    <label for="titulo"
      >{{ 'mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas.titulo' | transloco
      }}<span class="required"> * </span></label
    >
    <input required ngModel type="text" id="titulo" name="titulo" />
  </div>
  <div class="span-size">
    <label for="prioridad"
      >{{ 'mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas.prioridad' | transloco
      }}<span class="required"> * </span></label
    >
    <select required ngModel id="prioridad" name="prioridad">
      <option value="0">
        {{ 'mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas.baja' | transloco }}
      </option>
      <option value="1">
        {{ 'mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas.normal' | transloco }}
      </option>
      <option value="2">
        {{ 'mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas.alta' | transloco }}
      </option>
      <option value="3">
        {{
          'mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas.urgente' | transloco
        }}
      </option>
    </select>
  </div>
  <div class="span-size">
    <label for="vencimiento">{{
      'mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas.vencimiento' | transloco
    }}</label>
    <input ngModel name="vencimiento" id="vencimiento" type="date" />
  </div>
  <div class="span-size">
    <label for="descripcion">{{
      'mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas.descripcion' | transloco
    }}</label>
    <textarea ngModel name="descripcion" id="descripcion" style="width: 225px" cols="30" rows="3"></textarea>
  </div>
  <div class="span-size" id="miembros" style="display: flex">
    <div style="width: 35%">
      <label for="miembros">{{
        'mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas.miembros' | transloco
      }}</label>
    </div>
    <div style="overflow-y: auto; display: flex; align-items: center">
      <button
        type="button"
        [disabled]="!selectedTablero"
        [matMenuTriggerFor]="menuMiembros"
        (menuOpened)="miembrosOpened()"
      >
        <mat-icon>people</mat-icon>
      </button>
      <button style="margin-right: 0.2em; margin-left: 0.2em" type="button" *ngFor="let member of arrayMembersName">
        <span>{{ member.substring(0, 2).toUpperCase() }}</span>
        <!--TODO TRANSLATE-->
      </button>
    </div>
    <mat-menu xPosition="before" #menuMiembros="matMenu" (closed)="buscarMiembros.value = ''">
      <div id="menuMiembros" style="padding: 0.5em">
        <section>
          <input
            #buscarMiembros
            (input)="search($event)"
            style="width: 100%"
            (click)="$event.stopPropagation()"
            type="text"
          />
        </section>
        <section
          *ngIf="listaUsuarios | async as listaUsuariosData; else notFoundUsers"
          infiniteScroll
          [infiniteScrollDistance]="3"
          [scrollWindow]="false"
          [infiniteScrollThrottle]="150"
          (scrolled)="onScrollDown(listaUsuariosData.url)"
          id="resultadosMiembros"
        >
          <ng-container *ngIf="listaUsuariosData?.usuarios?.length; else notFoundUsers">
            <div
              id="listaMembers"
              *ngFor="let usuario of listaUsuariosData.usuarios"
              (click)="addMember(usuario); buscarMiembros.value = ''"
            >
              <span>{{ usuario.user }}</span>
              <!--TODO TRANSLATE-->
              <mat-icon *ngIf="arrayMembersId.includes(usuario.id)" style="color: green">done</mat-icon>
            </div>
          </ng-container>
        </section>
        <ng-template #notFoundUsers>
          <div class="not-users-found">{{ 'not_users_found' | transloco }}.</div>
        </ng-template>
      </div>
    </mat-menu>
  </div>
  <div class="span-size" id="submit">
    <button *ngIf="buttonBack" type="button" style="margin-right: 0.5em" mat-button (click)="goBack()">
      <span>{{
        'mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas.atras'
          | transloco
          | titlecase
      }}</span>
    </button>
    <button [disabled]="!form.valid" type="submit" mat-button>
      <span
        matTooltipPosition="left"
        [matTooltip]="
          !form.valid
            ? ('mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas.camposObli'
              | transloco)
            : 'Enviar'
        "
      >
        {{ 'mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas.enviar' | transloco }}
      </span>
    </button>
  </div>
</form>
