import { createAction, props } from '@ngrx/store';

export const LAOD_INFO_TABLERO = '[tareas page] get info tablero';

export const LOAD_TAREAS = '[tareas page] get tareas';
export const LOAD_TAREAS_SUCCESS = '[tareas page] get tareas success';

export const CLEAR_ALL_TAREAS = '[tareas page] clear todas tareas';

export const LOAD_FILTROS_TAREAS = '[tareas page] get body filtros';
export const UPDATE_BODY_FILTROS = '[tareas page] update body filtros';

export const UPDATE_TAREAS = '[tareas page] update tareas';
export const UPDATE_TAREAS_SUCCESS = '[tareas page] update tareas success';

export const GET_TAREA = '[tareas page] get tarea';
export const GET_TAREA_SUCCESS = '[tareas page] get tarea success';

export const CLEAR_TAREA = '[tareas page] clear tarea';

export const DELETE_TAREA = '[tareas page] delete tarea';
export const DELETE_TAREA_SUCCESS = '[tareas page] delete tarea success';

export const SAVE_TAREA = '[tareas page] create tarea';
export const SAVE_TAREA_SUCCESS = '[tareas page] create tarea success';

export const UPDATE_TAREA = '[tareas page] update tarea';
export const UPDATE_TAREA_SUCCESS = '[tareas page] update tarea success';

export const GET_IMAGE_TAREA = '[tareas page] get image tarea';
export const GET_IMAGE_TAREA_SUCCESS = '[tareas page] get image tarea success';

export const SAVE_MARCADORES = '[tareas page] save marcadores';

export const EXPORT_TAREAS = '[tareas page] export tareas';

export const LOADING_IMAGE_TAREAS = '[tareas page] get loading image';

/* COSAS DE TAREAS */

export const loadTareas = createAction(LOAD_TAREAS);

export const loadTareasSuccess = createAction(LOAD_TAREAS_SUCCESS, props<{ tareas: any }>());

export const clearAllTareas = createAction(CLEAR_ALL_TAREAS);

export const updateDatosFiltros = createAction(UPDATE_BODY_FILTROS, props<{ datos: any }>());

export const updateTareas = createAction(UPDATE_TAREAS, props<{ datos: any }>());

export const updateTareasSuccess = createAction(UPDATE_TAREAS_SUCCESS, props<{ id: any }>());

export const getTarea = createAction(GET_TAREA, props<{ idTarea: any }>());

export const getTareaSuccess = createAction(GET_TAREA_SUCCESS, props<{ infoTarea: any }>());

export const clearTarea = createAction(CLEAR_TAREA);

export const deleteTarea = createAction(DELETE_TAREA, props<{ idTarea: any; estado: any }>());

export const deleteTareaSuccess = createAction(DELETE_TAREA_SUCCESS, props<{ idTarea: any; estado: any }>());

export const saveTarea = createAction(SAVE_TAREA, props<{ boardId?: number | string | null; datos: Object }>());

export const saveTareaSuccess = createAction(SAVE_TAREA_SUCCESS, props<{ tarea: any }>());

export const updateTarea = createAction(UPDATE_TAREA, props<{ datos: any; idTarea: any }>());

export const updateTareaSuccess = createAction(UPDATE_TAREA_SUCCESS, props<{ tarea: any }>());

export const getImageTarea = createAction(GET_IMAGE_TAREA, props<{ idTarea: any }>());

export const getImageTareaSuccess = createAction(GET_IMAGE_TAREA_SUCCESS, props<{ imagen: any }>());

export const saveMarcadores = createAction(SAVE_MARCADORES, props<{ datos: any }>());

export const setLoadingImage = createAction(LOADING_IMAGE_TAREAS, props<{ loading: boolean }>());

/* COSAS DE TABLERO QUE SE NECESITA ACTUALIZAR LA INFO DEL TABLERO ACTUAL */

export const UPDATE_TABLERO_ACTUAL = '[tareas page] update tablero';
export const UPDATE_TABLERO_ACTUAL_SUCCESS = '[tareas page] update tablero success';
export const SAVE_SELECTED_TABLERO = '[tareas page] save selected tablero';

export const setSelectedTablero = createAction(SAVE_SELECTED_TABLERO, props<{ tableroActual: any }>());

export const updateTableroActual = createAction(UPDATE_TABLERO_ACTUAL, props<{ nombre: string }>());

export const updateTableroActualSuccess = createAction(UPDATE_TABLERO_ACTUAL_SUCCESS, props<{ datos: any }>());

/* COSAS DE CONFIGURACIONES DE TABLERO */

export const ADD_CONFIGURACION = '[tareas page] add configuracion';
export const ADD_CONFIGURACION_SUCCESS = '[tareas page] add configuracion success';

export const DELETE_CONFIGURACION = '[tareas page] delete configuracion';
export const DELETE_CONFIGURACION_SUCCESS = '[tareas page] delete configuracion success';

export const ADD_OPCIONES = '[tareas page] add opciones';
export const ADD_OPCIONES_BD = '[tareas page] add opciones bd';

export const DELETE_OPCIONES = '[tareas page] delete opciones';

export const UPDATE_CONFIGURACION = '[tareas page] update configuracion';
export const UPDATE_OPCIONES_BD = '[tareas page] update opciones bd';
export const UPDATE_CONFIGURACION_SUCCESS = '[tareas page] update configuracion success';

export const addConfiguracion = createAction(ADD_CONFIGURACION, props<{ configuracion: any[] }>());

export const addConfiguracionSuccess = createAction(
  ADD_CONFIGURACION_SUCCESS,
  props<{ configuracion: any; opciones: any }>(),
);

export const deleteConfiguracion = createAction(DELETE_CONFIGURACION, props<{ configuracion: any }>());

export const deleteConfiguracionSuccess = createAction(
  DELETE_CONFIGURACION_SUCCESS,
  props<{ idConfiguracion: any; tableroActual: any }>(),
);

export const addOpciones = createAction(ADD_OPCIONES, props<{ opciones: any[] }>());

export const addOpcionesBD = createAction(ADD_OPCIONES_BD, props<{ config: any }>());

export const deleteOpciones = createAction(DELETE_OPCIONES, props<{ indice: any }>());

export const updateConfiguracion = createAction(UPDATE_CONFIGURACION, props<{ config_ant: any; config_act: any }>());

export const updateOpcionesBD = createAction(
  UPDATE_OPCIONES_BD,
  props<{ opciones_act: any; opciones_ant: any; config: any }>(),
);

export const updateConfiguracionSuccess = createAction(UPDATE_CONFIGURACION_SUCCESS, props<{ configuracion: any }>());

/* VALOR DE CONFIGURACIONES */

export const ADD_VALUE_CONFIGURACION = '[tareas page] add value configuracion';
export const ADD_VALUE_CONFIGURACION_SUCCESS = '[tareas page] add value configuracion success';

export const CLEAR_VALUE_CONFIGURACION = '[tareas page] clear value configuracion success';

export const POST_VALUE_CONFIGURACION = '[tareas page] post value configuracion';
export const POST_VALUE_CONFIGURACION_SUCCESS = '[tareas page] post vvalue configuracion success';

export const DELETE_VALUE_CONFIGURACION = '[tareas page] delete value configuracion';
export const DELETE_VALUE_CONFIGURACION_SUCCESS = '[tareas page] delete value configuracion success';

export const addValueConfiguracion = createAction(ADD_VALUE_CONFIGURACION, props<{ idTarea: any }>());

export const addValueConfiguracionSuccess = createAction(ADD_VALUE_CONFIGURACION_SUCCESS, props<{ valores: any }>());

export const clearValueConfiguracion = createAction(CLEAR_VALUE_CONFIGURACION);

export const postValueConfiguracion = createAction(POST_VALUE_CONFIGURACION, props<{ datos: any; idTarea: any }>());

export const postValueConfiguracionSuccess = createAction(POST_VALUE_CONFIGURACION_SUCCESS, props<{ datos: any }>());

export const deleteValueConfiguracion = createAction(
  DELETE_VALUE_CONFIGURACION,
  props<{ idTarea: any; eliminados: any }>(),
);
export const deleteValueConfiguracionSuccess = createAction(
  DELETE_VALUE_CONFIGURACION_SUCCESS,
  props<{ eliminados: any }>(),
);

/* ASIGNAR TABLERO A USUARIO */

export const GET_LISTA_USUARIOS_ASIGNADOS = '[tareas page] get lista usuarios asignados';
export const GET_LISTA_USUARIOS_ASIGNADOS_SUCCESS = '[tareas page] get lista usuarios asignados success';

export const GET_SCROLL_LISTA_USUARIOS = '[tareas page] get scroll lista usuarios';
export const GET_SCROLL_LISTA_USUARIOS_SUCCESS = '[tareas page] get scroll lista usuarios success';

export const UPDATE_LISTA_ASIGNADOS = '[tareas page] update lista asignadas';
export const UPDATE_LISTA_ASIGNADOS_SUCCESS = '[tareas page] update lista asignadas success';

export const GET_USUARIOS_FILTRADOS = '[tareas page] get usuarios filtrados';
export const GET_USUARIOS_FILTRADOS_SUCCESS = '[tareas page] get usuarios filtrados success';

export const listaUsuariosAsignados = createAction(GET_LISTA_USUARIOS_ASIGNADOS, props<{ idTablero: any }>());

export const listaUsuariosAsignadosSuccess = createAction(
  GET_LISTA_USUARIOS_ASIGNADOS_SUCCESS,
  props<{ idTablero: any; usuarios: any[] }>(),
);

export const scrollListaUsuariosAsignados = createAction(
  GET_SCROLL_LISTA_USUARIOS,
  props<{ next: any; texto: string }>(),
);

export const scrollListaUsuariosAsignadosSuccess = createAction(
  GET_SCROLL_LISTA_USUARIOS_SUCCESS,
  props<{ usuarios: any[] }>(),
);

export const updateListaAsignados = createAction(
  UPDATE_LISTA_ASIGNADOS,
  props<{ nuevosAsignados: any[]; borradosAsignados: any[] }>(),
);

export const updateListaAsignadosSuccess = createAction(
  UPDATE_LISTA_ASIGNADOS_SUCCESS,
  props<{ nuevosAsignados: any[]; borradosAsignados: any[] }>(),
);

export const getUsuariosFiltrados = createAction(GET_USUARIOS_FILTRADOS, props<{ texto: string; idTablero: any }>());

export const getUsuariosFiltradosSuccess = createAction(GET_USUARIOS_FILTRADOS_SUCCESS, props<{ usuarios: any }>());

export const exportTareas = createAction(EXPORT_TAREAS);
