import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { WKT } from 'ol/format';
import VectorSource from 'ol/source/Vector';
import { Observable, Subject } from 'rxjs';
import { Parcela2 } from '../objetos/parcela2';

@Injectable({
  providedIn: 'root',
})
export class PolygonsService {
  attributes_number = [
    'idnax',
    'rend',
    'soca',
    'zafra',
    'riego',
    'rend_est',
    'zona_eco',
    'agua_disp',
    'agua_aprov',
    'agua_act',
    'rend_neto',
    'ha_corte',
    'rend_core',
    'rend_ind',
    'semana_mad',
    'tercio',
    'riegos_pre',
    'riegos_pos',
    'fk_pixel',
    'resiembra_pct',
  ];

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  public compareHttpOptions = {
    headers: new HttpHeaders({}),
  };

  public subjectPolygonsSelection = new Subject<any>();

  constructor(private http: HttpClient) {}

  feature2Parcela(data: any[]): Parcela2[] {
    var result: Parcela2[] = [];
    data?.forEach((element, index) => {
      var newObj = element.clone().getProperties();
      newObj.geometry = new WKT().writeGeometry(newObj.geometry);
      delete newObj.status;
      delete newObj.area;
      Object.keys(newObj).forEach((key) => {
        if (newObj[key] == null) {
          delete newObj[key];
        }
        if ((newObj[key] || newObj[key] == 0) && this.attributes_number.includes(key)) {
          newObj[key] = Number(newObj[key]);
        }
      });
      result.push(newObj);
    });

    return result;
  }

  /**
   * Returns string with correct extension
   * @param type
   * @returns
   */
  getCorrectExtension(type: string): string[] {
    if (['shapefile', 'kml'].includes(type)) {
      return ['application/zip', 'application/x-zip-compressed'];
    }

    if (type == 'csv') {
      return ['text/csv'];
    }

    return [''];
  }

  /**
   * Function to clean features depending on zIndex or tool
   * @param features
   */
  cleanNotOverlappedFeatures(source: VectorSource) {
    source.getFeatures().forEach((feature) => {
      if (feature.getProperties().status && feature.getProperties().status == 3) {
        source.removeFeature(feature);
      }
    });
    return source;
  }

  /** LISTENERS */
  /**
   * Observable de la comparación de polígonos. Se envían los datos de cuantos polígonos: son nuevos, son iguales, hay que eliminar, tienen errores de geometría, tienen intersecciones
   */
  polygonsSelection(): Observable<any> {
    return this.subjectPolygonsSelection.asObservable();
  }

  /** PETITIONS */
  fileToGeojson(file: any) {
    return this.http.post(`${environment.databaseURL}/rest/fileToGeojson`, file);
  }

  bulkUploadPolygons(areaId: number, datos: any) {
    return this.http.post(
      `${environment.databaseURL}/rest/areas/${areaId}/parcelas/bulk_upload`,
      datos,
      this.httpOptions,
    );
  }

  bulkEditPolygons(datos: any) {
    return this.http.put(`${environment.databaseURL}/rest/parcelas/bulk_update`, datos, this.httpOptions);
  }

  compareShapePost(idArea: number, body: any): Observable<any> {
    return this.http.post(
      `${environment.databaseURL}/rest/areas/${idArea}/compareshape`,
      body,
      this.compareHttpOptions,
    );
  }
}
