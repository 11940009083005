import { createAction, props } from '@ngrx/store';
import { Token } from '@src/app/models/token.model';

export const LOGIN_START = '[ auth page] login start';
export const LOGIN_SUCCESS = '[ auth page] login success';
export const LOGIN_FAIL = '[ auth page] login fail';
export const AUTH_LOGIN = '[ auth page] auto login';
export const AUTH_LOGOUT = '[ auth page] auto logout';
export const AUTH_LOGOUT_SUCCESS = '[ auth page] auto logout success';
export const AUTH_LOGIN_FAIL = '[ auth page] auto login fail';
export const CHECK_USER = '[ auth page] check user';
export const SET_VALIDATIONS = '[ auth page] set validations';
export const REGISTER_USER = '[ auth page] register user';
export const REGISTER_STATUS = '[ auth page] register status';
export const RESEND_EMAIL = '[ auth page] resend email';
export const VERIFY_USER = '[ auth page] verify user';
export const VERIFY_RESULT = '[ auth page] verify result';
export const AGREEMENT_SIGN = '[ auth page] agreement sign';
export const AGREEMENT_SIGN_SUCCESS = '[ auth page] agreement sign success';
export const AUTH_TOKEN_DRIVE = '[ auth page] auto token drive';
export const AUTH_TOKEN_DRIVE_SUCCESS = '[ auth page] auto token drive success';

export const loginStart = createAction(LOGIN_START, props<{ user: string; password: string }>());

export const loginSuccess = createAction(LOGIN_SUCCESS, props<{ token: Token }>());

export const autoLogin = createAction(AUTH_LOGIN, props<{ notRedirect?: boolean }>());
export const logout = createAction(AUTH_LOGOUT);

export const logoutSuccess = createAction(AUTH_LOGOUT_SUCCESS, props<{ token: Token | null }>());

export const autoLoginFail = createAction(AUTH_LOGIN_FAIL);

export const checkUser = createAction(CHECK_USER, props<{ data: any; isLogin?: boolean }>());

export const setValidations = createAction(
  SET_VALIDATIONS,
  props<{ validations: any; label: string; isLogin?: boolean }>(),
);

export const registerUser = createAction(REGISTER_USER, props<{ data: any }>());

export const registerStatus = createAction(REGISTER_STATUS, props<{ result: boolean | null; message?: string }>());

export const resendEmail = createAction(RESEND_EMAIL, props<{ user?: string; email?: string }>());

export const verifyUser = createAction(VERIFY_USER, props<{ token: string }>());

export const agreementSign = createAction(
  AGREEMENT_SIGN,
  props<{
    privacy_nax: boolean;
    privacy_fira: boolean;
    confidency_nax: boolean;
  }>(),
);

export const agreementSignSuccess = createAction(AGREEMENT_SIGN_SUCCESS, props<{ success: boolean }>());

export const verifyResult = createAction(VERIFY_RESULT, props<{ result: boolean | null }>());

export const authTokenDrive = createAction(AUTH_TOKEN_DRIVE);

export const authTokenDriveSuccess = createAction(AUTH_TOKEN_DRIVE_SUCCESS, props<{ tokenDrive: string }>());
