import { Injectable } from '@angular/core';
import { Fill, Image, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { Observable, Subject } from 'rxjs';
import { LegendType } from '../enumerations/legend';
import { Leyenda } from '../interfaces/leyenda';
import { environment } from '@src/environments/environment';
import { MapaService } from './api/map.service';
import { ColorsDefault } from '../enumerations/Colors.enum';

@Injectable({
  providedIn: 'root',
})
export class sldService {
  public subjectPreviewLegend = new Subject<any>();

  // Parcelas filtradas
  style6: Style = new Style({
    stroke: new Stroke({
      width: 2,
      color: '#FF0000',
    }),
  });

  // Parcelas seleccionadas manualmente
  style7: Style = new Style({
    stroke: new Stroke({
      width: 3,
      color: '#00FF00',
    }),
  });

  // Parcela seleccionada
  style8: Style = new Style({
    stroke: new Stroke({
      width: 3,
      color: '#4964FF',
    }),
  });

  // Añadir capa
  style9: Style = new Style({
    stroke: new Stroke({
      width: 2,
      color: '#FFFF00',
    }),
  });

  // Añadir poligonos
  style10: Style = new Style({
    stroke: new Stroke({
      width: 2,
      color: '#FFFF00',
    }),
  });

  // Compare shape
  style11: Style = new Style({
    stroke: new Stroke({
      width: 2,
      color: '#FFFF00',
    }),
  });

  // Marcadores tarea
  style13: Style = new Style({
    stroke: new Stroke({
      width: 2,
      color: '#00FFFF',
    }),
    fill: new Fill({
      color: '#00FFFF',
    }),
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({
        color: '#00FFFF',
      }),
      stroke: new Stroke({
        width: 2,
        color: 'rgba(255, 255, 255, 0.2)',
      }),
    }),
  });

  // Marcadores precosecha
  style14: Style = new Style({
    stroke: new Stroke({
      width: 2,
      color: '#00FFFF',
    }),
    fill: new Fill({
      color: '#00FFFF',
    }),
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({
        color: '#00FFFF',
      }),
      stroke: new Stroke({
        width: 2,
        color: 'rgba(255, 255, 255, 0.2)',
      }),
    }),
  });

  // Medicion
  style15: Style = new Style({
    stroke: new Stroke({
      width: 2,
      color: ColorsDefault.MEASURE,
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
      }),
      stroke: new Stroke({
        width: 2,
        color: ColorsDefault.MEASURE,
      }),
    }),
  });

  // Capas
  style20: Style = new Style({
    stroke: new Stroke({
      width: 2,
      color: '#FFFFFF',
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.1)',
    }),
  });

  returnSLD(workspace: any, nombre: any, color: string, width: any, label: boolean) {
    return `<?xml version="1.0" encoding="ISO-8859-1"?>
      <StyledLayerDescriptor version="1.0.0" 
        xsi:schemaLocation="http://www.opengis.net/sld StyledLayerDescriptor.xsd" 
        xmlns="http://www.opengis.net/sld" 
        xmlns:ogc="http://www.opengis.net/ogc" 
        xmlns:xlink="http://www.w3.org/1999/xlink" 
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
      <NamedLayer>
        <Name>${environment.ip.includes('54') ? '' : 'postgis:'}${workspace}_${nombre}</Name>
        <UserStyle>
          <Title>Title</Title>
          <FeatureTypeStyle>
            <Rule>
              <PolygonSymbolizer>
                <Stroke>
                  <CssParameter name="stroke">${color}</CssParameter>
                  <CssParameter name="stroke-width">${width}</CssParameter>
                </Stroke>
              </PolygonSymbolizer>
              ${
                label && color != '#0000FF'
                  ? `<TextSymbolizer>
                <Geometry>
                  <ogc:Function name="centroid">
                    <ogc:PropertyName>geometry</ogc:PropertyName>
                  </ogc:Function>
                </Geometry>
                <Label>
                  <ogc:PropertyName>id</ogc:PropertyName>
                </Label>
                <Font>
                  <CssParameter name="font-family">Arial</CssParameter>
                  <CssParameter name="font-size">15</CssParameter>
                  <CssParameter name="font-weight">bolder</CssParameter>
                </Font>
                <LabelPlacement>
                  <PointPlacement>
                    <AnchorPoint>
                      <AnchorPointX>
                        <ogc:Literal>0.0</ogc:Literal>
                      </AnchorPointX>
                      <AnchorPointY>
                        <ogc:Literal>0.5</ogc:Literal>
                      </AnchorPointY>
                    </AnchorPoint>
                    <Rotation>
                      <ogc:Literal>0</ogc:Literal>
                    </Rotation>
                  </PointPlacement>
                </LabelPlacement>
                <Halo>
                  <Radius>
                    <ogc:Literal>1.0</ogc:Literal>
                  </Radius>
                  <Fill>
                    <CssParameter name="fill">#FFFFFF</CssParameter>
                  </Fill>
                </Halo>
                <VendorOption name="conflictResolution">true</VendorOption>
                <VendorOption name="goodnessOfFit">0.5</VendorOption>
                <VendorOption name="autoWrap">100</VendorOption>
              </TextSymbolizer>`
                  : ''
              }
            </Rule>
            </FeatureTypeStyle>
          </UserStyle>
        </NamedLayer>
      </StyledLayerDescriptor>`;
  }

  formatLegend(geoserverLegend: any, nax: boolean, product: string, titulo: string, minmax: any): Leyenda {
    // creamos la leyenda
    var legend: Leyenda = {
      title: titulo,
      original: nax ? true : false,
      editable: true,
      type: geoserverLegend.type ? geoserverLegend.type : LegendType[product] ? LegendType[product] : 'ramp',
      data: [],
    };

    // Si el último rango de la leyenda supera el máximo del producto, se igualan
    if (geoserverLegend.entries[geoserverLegend.entries.length - 1].quantity > minmax.max) {
      geoserverLegend.entries[geoserverLegend.entries.length - 1].quantity = minmax.max;
    }

    if (geoserverLegend && geoserverLegend instanceof Array && geoserverLegend.length > 0) {
      if (
        ['color', 'textcolor', 'opacity', 'quantity', 'label'].every((element) =>
          Object.keys(geoserverLegend[0]).includes(element),
        )
      ) {
        legend.data = geoserverLegend;
      }
    } else if (geoserverLegend)
      geoserverLegend.entries.forEach((element: any) => {
        legend.data.push({
          color: element.color,
          textcolor: this.getTextColor(element.color),
          opacity: Number(element.opacity),
          quantity: Number(element.quantity),
          label: element.label,
        });
      });

    return legend;
  }

  createSLD(leyenda: Leyenda, name: string): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
    <StyledLayerDescriptor xmlns="http://www.opengis.net/sld" xmlns:sld="http://www.opengis.net/sld" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" version="1.0.0">
    <NamedLayer>
      <Name>${name}</Name>
      <UserStyle>
        <Name>Default Styler</Name>
        <Title>Default Raster</Title>
        <Abstract></Abstract>
        <FeatureTypeStyle>
          <Name>name</Name>
          <Rule>
            <Name>rule</Name>
            <Title>Opaque Raster</Title>
            <Abstract>A raster with 100% opacity</Abstract>
            <RasterSymbolizer>
              <ChannelSelection>
                <GrayChannel>
                  <SourceChannelName>1</SourceChannelName>
                  <ContrastEnhancement>
                    <GammaValue>1.0</GammaValue>
                  </ContrastEnhancement>
                </GrayChannel>
              </ChannelSelection>
              <ColorMap type="${leyenda.type}">
                ${leyenda.data.map(
                  (e: any) =>
                    `<ColorMapEntry color="${e.color}" opacity="${e.opacity}" quantity="${e.quantity}" label="${e.label}"/>`,
                )}
              </ColorMap>
              <ContrastEnhancement/>
            </RasterSymbolizer>
          </Rule>
        </FeatureTypeStyle>
      </UserStyle>
    </NamedLayer>
    </StyledLayerDescriptor>`;
  }

  getTextColor(color: string) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;

    return brightness > 155 ? '#000000' : '#FFFFFF';
  }

  /** SUBJECTS */
  previewLegend(): Observable<any> {
    return this.subjectPreviewLegend.asObservable();
  }
}
