import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MarcadoresFilterState } from './filter-marcadores.state';

export const MARCADORES_FILTER_STATE_NAME = 'marcadores_filter';

const getMarcadoresFilterState = createFeatureSelector<MarcadoresFilterState>(MARCADORES_FILTER_STATE_NAME);

export const getUsersMarcadores = createSelector(getMarcadoresFilterState, (state) => {
  return state.usuario;
});

export const isLoadingMarcadores = createSelector(getMarcadoresFilterState, (state) => {
  return state.isLoading;
});

export const isLoadingMarcadoresUsers = createSelector(getMarcadoresFilterState, (state) => {
  return state.isLoadingUsers;
});

export const isLoadingMarcadoresTableros = createSelector(getMarcadoresFilterState, (state) => {
  return state.isLoadingTableros;
});

export const getTablerosMarcadores = createSelector(getMarcadoresFilterState, (state) => {
  return state.tableros;
});

export const getFilterMarcadores = createSelector(getMarcadoresFilterState, (state) => {
  return state.filter;
});

export const getMarcadores = createSelector(getMarcadoresFilterState, (state) => {
  return state.marcadores;
});

export const getTareas = createSelector(getMarcadoresFilterState, (state) => {
  return state && state.marcadores
    ? state.marcadores.features
        .map((marcador) => marcador.properties.tarea)
        .filter((value, index, self) => self.indexOf(value) === index)
    : [];
});
