export class Riego {
  id: number | undefined;
  fecha: string | undefined;
  ha_regadas: number | undefined;
  operacion: number | undefined;
  lamina_riego: number | undefined;
  horas: number | undefined;
  hora_inic: number | undefined;
  valido: boolean | undefined;
  fk_parcela_id: number | undefined;
  fecha_fin: string | undefined;

  constructor(
    id?: number,
    fecha?: string,
    ha_regadas?: number,
    operacion?: number,
    lamina_riego?: number,
    horas?: number,
    hora_inic?: number,
    valido?: boolean,
    fk_parcela_id?: number,
    fecha_fin?: string,
  ) {
    this.id = id;
    this.fecha = fecha;
    this.ha_regadas = ha_regadas;
    this.operacion = operacion;
    this.lamina_riego = lamina_riego;
    this.horas = horas;
    this.hora_inic = hora_inic;
    this.valido = valido;
    this.fk_parcela_id = fk_parcela_id;
    this.fecha_fin = fecha_fin;
  }
}
