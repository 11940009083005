import { createAction, props } from '@ngrx/store';
import { curveOptionElement } from '@src/app/models/curveModels/curve.model';

/** SCREEN */
// * edit showCurve
export const SHOW_CURVE = '[curve page] showCurve';

export const showCurve = createAction(SHOW_CURVE, props<{ value: boolean }>());

// * Edit screen selected
export const CHANGE_SCREEN = '[curve page] change screen';

export const changeScreen = createAction(CHANGE_SCREEN, props<{ value: number }>());

/** CURVE */
// * Load Curve
export const LOAD_CURVE = '[curve page] load curve';

export const loadCurve = createAction(LOAD_CURVE, props<{ body: any }>());

// * Fill Curve
export const FILL_CURVE = '[curve page] fill curve';

export const fillCurve = createAction(FILL_CURVE, props<{ date: string | number | Date }>());

// * Load Optim Curve
export const LOAD_OPTIM_CURVE = '[curve page] load optim curve';

export const loadOptimCurve = createAction(
  LOAD_OPTIM_CURVE,
  props<{ init_date: string; idnax?: number[] | null; filter: boolean }>(),
);

// * Add curve to graph
export const ADD_CURVE = '[curve page] add curve';

export const addCurve = createAction(ADD_CURVE, props<{ curve: any; fill: boolean | null }>());

// * Edit graph curve
export const EDIT_CURVE = '[curve page] edit curve';

export const editCurve = createAction(EDIT_CURVE, props<{ label: string; value: boolean }>());

// * Edit graph curve
export const DELETE_CURVE = '[curve page] delete curve';

export const deleteCurve = createAction(DELETE_CURVE, props<{ label: string }>());

/** CONFIGURATION */
// * Edit configuration curve
export const EDIT_CONFIGURATION_CURVE = '[curve page] edit configuration curve';

export const editConfigurationCurve = createAction(EDIT_CONFIGURATION_CURVE, props<{ label: string; value: any }>());

/** OPTIONS */
// * Edit curve option
export const EDIT_OPTION_CURVE = '[curve page] edit option curve';

export const editOptionCurve = createAction(
  EDIT_OPTION_CURVE,
  props<{ opt: curveOptionElement; parent: string; index: number }>(),
);

// * Load Option Curve
export const LOAD_OPTION_CURVE1 = '[curve page] load option curve 1';
export const LOAD_OPTION_CURVE2 = '[curve page] load option curve 2';

export const loadOptionCurve1 = createAction(
  LOAD_OPTION_CURVE1,
  props<{
    init_date: any;
    fill: boolean | null;
    option: string;
    finish_date?: any;
    filter?: boolean;
  }>(),
);
export const loadOptionCurve2 = createAction(LOAD_OPTION_CURVE2, props<{ options: any }>());

/** CURVE LEGEND */
// * Curve legend
export const LOAD_CURVE_LEGEND = '[curve page] load curve legend';

export const loadCurveLegend = createAction(LOAD_CURVE_LEGEND);

/** CURVE INFO */
// * Curve info
export const LOAD_CURVE_INFO = '[curve page] load curve info';

export const loadCurveInfo = createAction(LOAD_CURVE_INFO, props<{ info: any }>());

/** DOWNLOAD CURVE */
// * Curve info
export const DOWNLOAD_CURVE = '[curve page] download curve ';

export const downloadCurve = createAction(DOWNLOAD_CURVE);

/** LOAD CLOUD LIMIT */
// * Curve info
export const LOAD_CLOUDLIMIT = '[curve page] load cloud limit ';

export const loadCloudLimit = createAction(LOAD_CLOUDLIMIT, props<{ value: number }>());

/** REPORT CURVE */
// * Curve info
export const REPORT_OPTIM_CURVE = '[curve page] report optim curve ';

export const reportOptimCurve = createAction(REPORT_OPTIM_CURVE);

export const RESET_CURVE_STATE = '[ curve page] reset curve state';

export const resetCurveState = createAction(RESET_CURVE_STATE);
/** DELETE POINTS */
// * Curve info
export const ADD_POINT2DELETE = '[curve page] add point to delete';

export const addPoint2Delete = createAction(ADD_POINT2DELETE, props<{ value: number | string | null }>());

export const DELETEPOINTS = '[curve page] delete points curve';

export const deletePoints = createAction(DELETEPOINTS);

export const CHANGE_DELETEPOINTS = '[curve page] change delete points';

export const changeDeletePoints = createAction(CHANGE_DELETEPOINTS);
