import { Action, createReducer, on } from '@ngrx/store';
import { initialStateRecoverPassword, RecoverPasswordState } from './recover-password.state';
import { setResultChangePassword, setResultToken } from './recover-password.actions';

const _recoverPasswordReducer = createReducer(
  initialStateRecoverPassword,
  on(setResultToken, (state, action) => {
    return {
      ...state,
      resultToken: action.resultToken,
    };
  }),
  on(setResultChangePassword, (state, action) => {
    return {
      ...state,
      resultChangePassword: action.resultChangePassword,
    };
  }),
);

export function RecoverPasswordReducer(state: RecoverPasswordState | undefined, action: Action) {
  return _recoverPasswordReducer(state, action);
}
