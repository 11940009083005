import { IBbox } from '@src/app/interfaces/Mapa/openlayers/bbox';
import { ILayer } from '@src/app/interfaces/Mapa/openlayers/layer';

export interface OpenlayerState {
  bbox: IBbox | null;
  epsg: { epsg_code: string; epsg_proj: string } | null;
  manualSelection: boolean | null;
  actualPixel: number | null;
}
/*
 * Initial state
 */
export const initialStateOpenlayers: OpenlayerState = {
  bbox: null,
  epsg: null,
  manualSelection: false,
  actualPixel: null,
};
