import { createAction, props } from '@ngrx/store';
import { MarcadoresFilterObject } from '@src/app/interfaces/Mapa/tools/MarcadoresInput.interface';
import { LabelsMarcadoresFilterState } from './filter-marcadores.state';

export const LOAD_USERS_MARCADORES = '[marcadores_filter page] load users marcadores';
export const LOAD_TABLEROS_MARCADORES = '[marcadores_filter page] load tableros marcadores';
export const LOAD_MARCADORES_FILTER = '[marcadores_filter page] load marcadores filter';
export const SET_KEY_VALUE_MARCADORES_FILTER = '[marcadores_filter page] set key value marcadores filter';

export const loadUsersMarcadores = createAction(LOAD_USERS_MARCADORES, props<{ idClient: number; filter?: Object }>());

export const setKeyValueMarcadoresFilter = createAction(
  SET_KEY_VALUE_MARCADORES_FILTER,
  props<{ key: LabelsMarcadoresFilterState; value: any }>(),
);

export const loadTablerosClient = createAction(
  LOAD_TABLEROS_MARCADORES,
  props<{ idClient: number; filter?: Object }>(),
);

export const loadMarcadoresFilter = createAction(LOAD_MARCADORES_FILTER, props<{ filter: MarcadoresFilterObject }>());
