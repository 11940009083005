<div class="climaInfo animate__animated animate__faster" *ngIf="!loadingClima && data">
  <!-- info general -->
  <div class="container-row border-bottom-info textClima-container-up">
    <div class="icons-clima">
      <mat-icon>info</mat-icon>
    </div>
    <!--*ngIf="data.general"-->
    <span
      ><b>{{ 'shared.nubes' | transloco }}</b> {{ (data.cloud_coverage * 100 | number : '1.0-2') + '%' }}</span
    >
    <span
      ><b>{{ 'shared.valorMedio' | transloco }}</b>
      {{ data.valor_medio !== null ? (data.valor_medio | number : '1.0-2') : '-' }}</span
    >
    <!--<span>Tipo: sentinel</span>-->
  </div>
  <div class="container-row container-row-down" *ngIf="data.clima">
    <!-- info clima -->
    <div class="icons-clima">
      <mat-icon>device_thermostat</mat-icon>
    </div>
    <!-- *ngIf="data.clima" -->
    <!-- center second row-->
    <span *ngIf="data.clima.tmin || data.clima.tmin === 0"
      ><b>T. Min:</b> {{ data.clima.tmin | number : '1.0-2' }}ºC</span
    >
    <span *ngIf="data.clima.tmed || data.clima.tmed === 0"
      ><b>T. Med:</b> {{ data.clima.tmed | number : '1.0-2' }}ºC</span
    >
    <!-- right second row -->
    <span *ngIf="data.clima.tmax || data.clima.tmax === 0"
      ><b>T. Max:</b> {{ data.clima.tmax | number : '1.0-2' }}ºC</span
    >
    <span *ngIf="data.clima.radiation || data.clima.radiation === 0"
      ><b>Rad.:</b> {{ data.clima.radiation | number : '1.0-2' }}w/m2</span
    >
    <span *ngIf="data.clima.precip || data.clima.precip === 0" class="precipitacion"
      ><b>{{ 'shared.precipitation' | transloco | titlecase }}:</b> {{ data.clima.precip | number : '1.0-2' }}mm</span
    >
    <span *ngIf="data.clima.et0 || data.clima.et0 === 0" class="et0"
      ><b>Evapotrans.:</b> {{ data.clima.et0 ? (data.clima.et0 | number : '1.0-2') : '-'
      }}{{ 'shared.mm_day' | transloco }}</span
    >
  </div>
  <div *ngIf="!loadingClima && (!data || !data.clima)" class="no-data-message animate__animated animate__faster">
    {{ 'shared.noHay' | transloco }}
  </div>
</div>
<div *ngIf="loadingClima" id="spinner-clima" class="animate__animated animate__faster">
  <mat-spinner></mat-spinner>
</div>
