import { Tablero } from '@src/app/objetos/tablero';
import { Tarea } from '@src/app/objetos/tarea';

//interfaz de los datos con los que voy a trabajar, se guarda en el store.
export interface TareaState {
  tareasPendientes: Tarea[];
  tareasProceso: Tarea[];
  tareasHechas: Tarea[];
  tableroActual: Tablero;
  dataFiltros: any;
  opcionesConfiguraciones: any[];
  listaUsuariosAsignados: {
    usuarios: any[];
    url: string;
  };
  selectedTarea: any;
  loadingImage: boolean;
}

// se inicializa la interfaz con valores nulos con todos los datos con los que quieres trabajar
export const initialStateTareas: TareaState = {
  tareasPendientes: [],
  tareasProceso: [],
  tareasHechas: [],
  tableroActual: {
    id: null,
    nombre: '',
    fk_cliente: null,
    configuraciones: [],
  },
  dataFiltros: {
    mis_tareas: true,
    prioridad: undefined,
    vencimiento__lte: undefined,
  },
  opcionesConfiguraciones: [],
  listaUsuariosAsignados: {
    usuarios: [],
    url: '',
  },
  selectedTarea: {},
  loadingImage: false,
};
