import { Producto } from '@src/app/objetos/producto';

export interface ProductArea {
  data: Producto[];
  show: boolean;
  idArea: number;
}
export interface Country {
  name: string;
  independent: boolean;
}

export interface UserTableState {
  data: string[];
  areasProductLoaded: number[];
  lastLoadedAreaProduct: Producto[];
  countries: Country[];
  productsAreas: any[];
  productsConf: any[];
}
/**
 * Initial state
 */
export const initialStateUserTable: UserTableState = {
  data: [],
  productsAreas: [],
  areasProductLoaded: [],
  lastLoadedAreaProduct: [],
  countries: [],
  productsConf: [],
};
