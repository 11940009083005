import { Component, Inject, OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Downloads } from '@src/app/interfaces/Downloads/downloads.interface';
import { AppState } from '@src/app/store/app.state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { loadUserSuccess } from '../inicio/state/user.actions';
import { getUser } from '../inicio/state/user.selector';
import { autoLogin } from '../login/state/auth.actions';
import { DialogDownloadEmailComponent } from '../navigation/mapa/componentes/workspace/componentes/tool-container/tools-template/downloads/dialog-download-email/dialog-download-email.component';
import { deleteDownloads, getDownloads } from './state/downloads.actions';
import { getDataDownloads } from './state/downloads.selector';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-downloads-view',
  templateUrl: './downloads-view.component.html',
  styleUrls: ['./downloads-view.component.scss'],
})
export class DownloadsViewComponent implements OnInit, OnDestroy {
  token: string;
  errorDownload: boolean = false;
  viewTable: boolean = true;
  verAdmin: boolean = true;
  downloadDone: boolean = false;
  downloadDoing: boolean = false;
  columns = ['will_expire', 'date_download', 'date_created', 'type', 'format', 'status', 'size', 'email', 'acciones'];
  dataSource: Downloads[] | null;
  actualData: Downloads[] = [];
  pageSize: number = 10;
  actualPage: number = 0;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    public dialog: MatDialog,
    private actions: Actions,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.downloadDoing = true;
    this.store
      .select(getUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value) {
          this.verAdmin = value.ver_admin;
          !value.ver_admin && this.store.dispatch(autoLogin({}));
        }
      });

    // loguear si no se ha hecho, por si lo tuviera guardado en caché
    this.store.dispatch(autoLogin({ notRedirect: true }));

    this.viewTable = true;
    this.store
      .select(getDataDownloads)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.dataSource =
          data && data.length
            ? data
                .sort((a, b) => {
                  return !a ? 1 : !b ? -1 : new Date(b.date_created).getTime() - new Date(a.date_created).getTime();
                })
                .filter((element) => !element.size || element.size > 0)
            : data;
        this.downloadDoing = false;
        data &&
          data.length &&
          this.onPageChange({
            pageIndex: this.actualPage,
            pageSize: this.pageSize,
            length: this.dataSource?.length,
          });
        this.pageSize = this.dataSource && this.dataSource.length < 10 ? this.dataSource.length : this.pageSize;
      });
    this.actions.pipe(takeUntil(this.ngUnsubscribe), ofType(loadUserSuccess)).subscribe((data) => {
      this.downloadDoing = true;
      this.store.dispatch(getDownloads());
    });
  }

  /**
   * Retornar a la página principal de la app
   */
  backToApp() {
    this.router.navigate(['/area/mapa']);
  }

  /**
   * Volver a cargar las descargas
   */
  reloadDownloads() {
    this.downloadDoing = true;
    this.store.dispatch(getDownloads());
  }

  /**
   * Evento de cambio de página
   * @param event datos del cambio de página
   */
  onPageChange(event: { pageIndex: number; pageSize: any; length: any }) {
    let { pageIndex, pageSize, length } = event;
    if (this.dataSource) pageSize * pageIndex > this.dataSource.length - 1 && pageIndex--;
    this.actualPage = pageIndex;
    this.pageSize = pageSize;
    if (this.dataSource) this.actualData = this.dataSource.slice(pageSize * pageIndex, pageSize * pageIndex + pageSize);
  }

  /**
   * Función que abre diálogo para confirmar borrado
   * @param download
   */
  deleteDownload(download: Downloads) {
    Swal.fire({
      icon: 'question',
      title: this.translocoService.translate(`downloads.eliminar_descarga`),
      text: this.translocoService.translate(`downloads.pregunta_elimi`),
      showCancelButton: true,
      cancelButtonText: this.translocoService.translate(`downloads.cancelar`),
      confirmButtonText: this.translocoService.translate(`downloads.eliminar`),
      confirmButtonColor: '#f95959',
    }).then((result) => {
      this.downloadDoing = result.isConfirmed;
      result.isConfirmed && this.store.dispatch(deleteDownloads({ id: download.id }));
    });
  }

  /**
   * Función que abre diálogo para editar correo
   * @param download
   */
  editDownload(download: Downloads) {
    this.dialog
      .open(DialogDownloadEmailComponent, {
        disableClose: true,
        data: {
          simpleEdit: true,
          title: this.translocoService.translate(`downloads.cambiar_correo`),
          id: download.id,
        },
      })
      .afterClosed()
      .pipe()
      .subscribe((value) => {
        this.downloadDoing = true;
        this.store.dispatch(getDownloads());
      });
  }

  /**
   * Función que lanza el efecto de descargar el archivo
   * @param download
   */
  /*downloadFile(download: Downloads) {
    this.downloadDoing = true;
    this.store.dispatch(getZipDownload({ token: download.id + '', normalStatus: true }))
  }*/

  /**
   * Renderizar la tabla por el id
   */
  trackById(index: number, element: Downloads) {
    return element.id;
  }

  /**
   * Close tool
   */
  ngOnDestroy(): void {
    this.dialog.closeAll();
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}

@Component({
  selector: 'dialog-token-expired-downloads',
  templateUrl: './dialog-token-expired-downloads.html',
  styleUrls: ['./downloads-view.component.scss'],
})
export class DialogTokenExpiredDownloads {
  constructor(
    public dialogRef: MatDialogRef<DialogTokenExpiredDownloads>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  /**
   * Método que retorna a la app
   */
  backToApp() {
    this.router.navigate(['/login']);
    this.dialogRef.close();
  }

  /**
   * Método que cierra el diálogo
   */
  closeViewDescargas() {
    this.router.navigate(['/downloads']);
    this.dialogRef.close();
  }
}
