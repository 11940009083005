import { TitleCasePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '@src/environments/environment';
import { saveAs } from 'file-saver-es';
import { Map } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TareaActualState, TareaPriority } from '../enumerations/Tareas.enum';
import {
  MarcadorFeature,
  MarcadorFilterOutput,
  MarcadoresFilterObject,
} from '../interfaces/Mapa/tools/MarcadoresInput.interface';
import { Tablero } from '../objetos/tablero';
import { Tarea } from '../objetos/tarea';
import { Usuario } from '../objetos/usuario';
import { CommonService } from './Common.service';
import { MapaService } from './api/map.service';
import { OpenlayersService } from './openlayers.service';

@Injectable({
  providedIn: 'root',
})
export class MarcadoresFilterService {
  private colorLayer: string = '#FF0000';
  private zIndexLayer: number = 17;
  private radius: number = 5;
  private width: number = 2;
  private titleCasePipe = new TitleCasePipe();

  constructor(
    private http: HttpClient,
    private mapaService: MapaService,
    private openlayersService: OpenlayersService,
    private translocoService: TranslocoService,
    private commonService: CommonService,
  ) {}

  /**
   *
   * @param idClient
   * @returns
   */
  usersClient(idClient: number, simple: boolean = true): Observable<{ id: number; nombre: string }[]> {
    const queryParams = simple ? `?simple=true` : '';
    return this.http.get<Usuario[]>(`${environment.databaseURL}/rest/clientes/${idClient}/usuarios${queryParams}`).pipe(
      map((value) =>
        value.map((e) => {
          return { id: e.id, nombre: e.user };
        }),
      ),
      catchError((error) => []),
    );
  }

  tablerosClient(idClient: number): Observable<{ id: number; nombre: string }[]> {
    return this.http.get<Tablero[]>(`${environment.databaseURL}/rest/clientes/${idClient}/tableros`).pipe(
      map((value) =>
        value.map((e: any) => {
          return { id: e.id, nombre: e.nombre };
        }),
      ),
      catchError((error) => []),
    );
  }

  /**
   * Returns a new filter object with new [label, value] value. As default, return a initial MarcadoresFilterObject (all null)
   * @param filter
   * @param label
   * @param value
   * @returns
   */
  setFilter(filter?: MarcadoresFilterObject, label?: string, value: any = null): MarcadoresFilterObject | any {
    if (!label) {
      return {
        titulo: null,
        prioridad: null,
        tablero: null,
        usuario: null,
        estado: null,
      };
    }
    // copy object
    filter = JSON.parse(JSON.stringify(filter));
    return { ...filter, [label]: value && value.length ? value : null };
  }

  private getQueryParamsFilter(idArea: number, filter: MarcadoresFilterObject): string {
    let queryParams = `area=${idArea}`;
    return Object.entries(filter).reduce((acc, [key, value]) => {
      if (!value?.length) {
        return acc;
      }

      if (!(value.constructor.name === 'Array')) {
        return `${acc}&${key}=${value}`;
      }

      return acc + '&' + value.map((e: any) => `${key}=${e}`).join('&');
    }, queryParams);
  }

  /**
   * Get marcadores filter url with his params
   * @param idArea selected area
   * @param filter actual filter in marcadores
   * @returns definitive url to filter marcardores
   */
  private getMarcadoresFilterUrl(idArea: number, filter: MarcadoresFilterObject): string {
    const queryParams = this.getQueryParamsFilter(idArea, filter);
    return `${environment.databaseURL}/rest/marcadores?${queryParams}`;
  }

  /**
   * Call to API with url
   * @param idArea selected area
   * @param filter actual filter in marcadores
   * @returns features
   */
  getMarcadoresFilter(
    idArea: number,
    filter: MarcadoresFilterObject,
  ): Observable<{ type: string; features: MarcadorFilterOutput[] }> {
    const url = this.getMarcadoresFilterUrl(idArea, filter);
    return this.http.get<{ type: string; features: MarcadorFilterOutput[] }>(url).pipe(
      map((e) => e),
      /* catchError(() => []), */
    );
  }

  /**
   * If feauture is like a marcador, returns true
   * @param feature feature selected
   * @returns state of action
   */
  isMarcadorFeatures(feature: FeatureLike): boolean {
    if (!feature || !feature['values_']) return false;
    let marcador = feature['values_'];
    return MarcadorFeature.isMarcadorFeature(marcador);
  }

  /**
   * Returns key which corresponds to the value
   * @param priority number
   * @param defEnum enum
   * @returns key
   */
  private getLabelEnum(priority: number = 0, defEnum: any): string | null {
    if (defEnum[priority] === undefined) return null;
    return Object.entries(defEnum).find(([key, value]) => value === priority)![0];
  }

  /**
   * Returns a string that corresponds to a color according to priority
   * @param priority number
   * @returns color
   */
  private getColorPriority(priority: number): string {
    switch (priority) {
      case TareaPriority.URGENTE:
        return 'red';
      case TareaPriority.ALTA:
        return 'orange';
      case TareaPriority.NORMAL:
        return 'green';
      case TareaPriority.BAJA:
        return 'blue';
      default:
        return 'black';
    }
  }

  /**
   * Returns a string that corresponds to a color according to actual state
   * @param priority number
   * @returns color
   */
  private getColorState(priority: number): string {
    switch (priority) {
      case TareaActualState.EN_PROCESO:
        return 'orange';
      case TareaActualState.PENDIENTE:
        return 'red';
      case TareaActualState.HECHO:
        return 'green';
      default:
        return 'black';
    }
  }

  /**
   * Returns if d is greater than date that corresponds to today.
   * @param d date
   * @returns state of the comparison
   */
  private isGreaterThanToday(d: string): boolean {
    if (!d) return true;
    let dDate = new Date(d);
    let today = new Date(Date.now());
    return today < dDate;
  }

  /**
   * Return a template of task popup to insert in the map
   * @param tarea tarea to display
   * @returns string with html
   */
  popupTareasTemplate(tarea: Tarea, coords: number[] = []): string {
    if (!tarea) return '';
    let coordsDiv =
      coords.length >= 2
        ? `<div style="padding: 0.3em 0">
            <div>
            Lat: ${coords[1].toFixed(5)}
            </div>
            <div>
            Long: ${coords[0].toFixed(5)}
            </div>
        </div>`
        : '';
    return `
        <div>
            <h5 style="width: 100%; text-align: center;">${
              tarea.titulo ? (tarea.titulo.length < 20 ? tarea.titulo : tarea.titulo.slice(0, 20) + '...') : '-'
            }</h5>
            <div style="margin-bottom: 0.5px; border: 0.5px solid whitesmoke;"></div>
            <div style="padding: 0.3em 0">
                <div>
                ${this.translocoService.translate(
                  'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.popup_tarea.f_venc',
                )}: <span style="color: ${this.isGreaterThanToday(tarea.vencimiento) ? 'black' : 'red'}">${
      tarea.vencimiento || '-'
    }</span>
                </div>
                <div>
                ${this.titleCasePipe.transform(
                  this.translocoService.translate(
                    'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.prioridad',
                  ),
                )}: <span style="color: ${this.getColorPriority(tarea.prioridad)}">${this.translocoService
      .translate('tablas.' + this.getLabelEnum(tarea.prioridad, TareaPriority)?.toLocaleLowerCase())
      .toLocaleUpperCase()}</span>
                </div>
                <div>
                ${this.titleCasePipe.transform(
                  this.translocoService.translate(
                    'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.estado',
                  ),
                )}: <span style="color: ${this.getColorState(tarea.estado)}">${this.translocoService
      .translate('tablas.' + this.getLabelEnum(tarea.estado, TareaActualState)?.toLocaleLowerCase())
      .toLocaleUpperCase()}</span>
                </div>
            </div>
            <div style="margin-bottom: 0.5px; border: 0.5px solid whitesmoke;"></div>
            ${coordsDiv}
        <div>`;
  }

  /**
   * Add marks directly in map
   * @param marcadores marks to display in map
   * @param map actual map instance
   * @returns created layer
   */
  addMarcadoresToLayer(marcadores: { type: string; features: any[] }, map: Map): VectorLayer<VectorSource> {
    let vectorSource = this.mapaService.createSourceJson(marcadores);
    let style = this.getLayerStyleMarcadores();

    let layer = this.openlayersService.createVectorLayer(
      window.screen.width,
      vectorSource,
      'image',
      this.zIndexLayer,
      style,
    );

    map.addLayer(layer);

    this.mapaService.zoomToLayer(layer.getSource()!.getExtent());
    return layer;
  }

  /**
   * Get specific style of marks in map
   * @returns
   */
  private getLayerStyleMarcadores(): Style {
    let style = new Style({});
    style.setFill(
      new Fill({
        color: this.colorLayer,
      }),
    );
    style.setStroke(
      new Stroke({
        width: this.width,
        color: this.colorLayer,
      }),
    );
    style.setImage(
      new CircleStyle({
        radius: this.radius,
        fill: new Fill({
          color: this.colorLayer,
        }),
        stroke: new Stroke({
          width: this.width,
          color: this.colorLayer,
        }),
      }),
    );
    return style;
  }

  /**
   * Download marks in json format
   * @param layer layer that contains marks
   */
  downloadMarcadores(layer: VectorLayer<VectorSource>): void {
    var format = new GeoJSON({ featureProjection: 'EPSG:4326' });
    let features = layer.getSource()!.getFeatures();
    let json = format.writeFeatures(features);

    saveAs(new Blob([json], { type: 'text/json;charset=utf-8' }), 'marcadores.json');
  }

  /**
   * Download actual filtered tasks
   * @param tareas task to download
   */
  downloadTareas(tareas: Tarea[]): Observable<Object[]> {
    let param = {
      filtro: JSON.stringify({ id__in: tareas.map((e) => e.id) }),
    };
    const url = this.commonService.serializeParamsQuery('/rest/tareasexport', param);
    return this.http.get<Object[]>(`${environment.databaseURL}${url}`);
  }
}
