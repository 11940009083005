import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { validarEmail } from '@src/app/@pages/register/validaciones/validarEmail.validator';
import { AppState } from '@src/app/store/app.state';
import { changeEmailDownload, changeEmailDownloadSuccess } from '../../../state/tool-container.actions';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-dialog-download-email',
  templateUrl: './dialog-download-email.component.html',
  styleUrls: ['./dialog-download-email.component.scss'],
})
export class DialogDownloadEmailComponent implements OnInit {
  email: string;
  changeEmail: boolean = false;
  errorEmail: boolean = false;
  loadingChange: boolean = false;
  newEmail: UntypedFormControl;
  verAdmin: boolean = false;
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogDownloadEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
    private actions: Actions,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.changeEmail = this.data.simpleEdit;
    !this.data.simpleEdit &&
      this.data.user.pipe().subscribe((value: any) => {
        this.email = value.fk_contacto.email;
        this.verAdmin = value.ver_admin;
        this.changeEmail = this.email === null || this.email === undefined;
      });
    this.newEmail = new UntypedFormControl('', [Validators.required, Validators.email, validarEmail]);

    this.actions.pipe(ofType(changeEmailDownloadSuccess)).subscribe((result) => {
      const { status } = result;
      this.loadingChange = false;
      status && this.closeTool();
    });
  }

  /**
   * Función que realiza la llamada a cambiar el email de la descarga,
   * comprobando si el email cumple con el formato adecuado
   * @returns
   */
  changeEmailRequest() {
    if (this.newEmail.valid) {
      // enviar el cambio del correo
      this.store.dispatch(
        changeEmailDownload({
          id: this.data.id,
          email: this.changeEmail ? this.newEmail.value : this.email,
        }),
      );
      this.loadingChange = true;
      // s cerraría la herramienta
      return;
    }
  }

  /**
   * Ir a downloads
   */
  goToDownloads() {
    this.router.navigate(['/downloads']);
    this.closeTool();
  }

  changeEmailOrClose() {
    if (this.data.simpleEdit) {
      this.closeTool();
    } else {
      this.changeEmail = false;
    }
  }

  /**
   * Cerrar herramienta
   */
  closeTool(email?: string) {
    email && this.store.dispatch(changeEmailDownload({ id: this.data.id, email: email }));
    this.dialogRef.close();
  }
}
