import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TableroState } from './tareas.state';

export const TABLEROS_STATE_NAME = 'tableros';

const getTablerosState = createFeatureSelector<TableroState>(TABLEROS_STATE_NAME);

export const getTableros = createSelector(getTablerosState, (state) => {
  return state.tableros;
});
