import { Injectable } from '@angular/core';
import { Usuario } from '@src/app/objetos/usuario';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import * as telData from 'country-telephone-data';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  getNumNotificaciones(user: Usuario): number {
    if (!user.notificaciones) return 0;
    // num de notis que no están vistas
    return user.notificaciones.filter((e) => !e.visto).length;
  }

  saveLocalStorage(data: Usuario): void {
    localStorage.setItem('user', JSON.stringify(data));
  }

  deleteToken(): void {
    localStorage.clear();
  }

  /**
   * Obtiene info de user pasado en el token de encabezado
   * @returns
   */
  getUser(): Observable<Usuario> {
    return this.http.get<Usuario>(`${environment.databaseURL}/rest/user`);
  }

  editUser(idUser: number, dataUser: string): Observable<Usuario> {
    return this.http.put<Usuario>(`${environment.databaseURL}/rest/usuarios/${idUser}`, dataUser, this.httpOptions);
  }

  /**
   * Checkea si existe usuario o email en bbdd
   * @param body puede ser: { email: "email" } o { user: "user"}
   * @returns
   */
  checkUserAndEmail(body: any) {
    return this.http.post<any>(`${environment.databaseURL}/rest/usuarios/check`, body, this.httpOptions);
  }

  /**
   * Función que realiza la llamada al registro del usuario
   * @param body parámetros del usuario
   * @returns estado del registro
   */
  register(body: any) {
    return this.http.post<any>(`${environment.databaseURL}/rest/clientes/request`, body, this.httpOptions);
  }

  /**
   * Función que reenvía el correo de verificación
   * @param body
   * @returns resultado de la acción y token actual dado
   */
  resendVerify(body: string) {
    return this.http.post<any>(`${environment.databaseURL}/rest/usuarios/resend_email`, body, this.httpOptions);
  }

  /**
   * Función que realiza la petición de verificar usuario
   * @param token token del usuario
   * @returns resultado de la acción de verificar (Verificado | Token expirado)
   */
  verifyUser(token: string) {
    return this.http.post<any>(`${environment.databaseURL}/rest/usuarios/verify`, { token: token }, this.httpOptions);
  }

  /**
   * Función que formatea el teléfono y lo devuelve perfecto
   * @param phone telefono con formato
   * @param country_code código del teléfono
   * @returns teléfono perfecto
   */
  formatPhone(phone: string | any, country_code: string) {
    if (phone) {
      // juntar con el código
      phone = '+' + country_code + phone.replaceAll(/\(|\)|-| /gi, '');
    }
    return phone;
  }
}
