<div style="padding: 1em">
  <h2 style="text-align: center">
    {{ data.title || 'La descarga va a tardar un poco...' }}
  </h2>
  <div style="padding: 1em">
    <p *ngIf="!email && !data.simpleEdit" style="text-align: center; padding: 1em">
      {{ 'mapa.componentes.workspace.componentes.tool_container.tool_template.downloads.dialogos.noEmail' | transloco }}
    </p>
    <p *ngIf="email" style="text-align: center; padding: 1em">
      {{ 'mapa.componentes.workspace.componentes.tool_container.tool_template.downloads.dialogos.enlace' | transloco }}
    </p>
    <p *ngIf="email || (changeEmail && !data.simpleEdit)" style="text-align: center; font-weight: 800; font-size: 16px">
      {{ changeEmail ? newEmail.value : email }}
    </p>
    <p *ngIf="!changeEmail" style="text-align: center">
      {{ 'mapa.componentes.workspace.componentes.tool_container.tool_template.downloads.dialogos.mail_ok' | transloco }}
    </p>
    <div style="display: flex; justify-content: center" *ngIf="!changeEmail && !data.simpleEdit">
      <button class="btn btn-success" style="padding: 0 3em; margin-right: 0.5em" (click)="closeTool(email)">
        {{
          'mapa.componentes.workspace.componentes.tool_container.tool_template.downloads.dialogos.btn_si' | transloco
        }}
      </button>
      <button class="btn btn-warning" style="margin-left: 0.5em; margin-right: 0.5em" (click)="changeEmail = true">
        {{
          'mapa.componentes.workspace.componentes.tool_container.tool_template.downloads.dialogos.cambiar' | transloco
        }}
      </button>
      <button class="btn btn-dark" *ngIf="verAdmin" style="margin-left: 0.5em" (click)="goToDownloads()">
        {{
          'mapa.componentes.workspace.componentes.tool_container.tool_template.downloads.dialogos.verDescargas'
            | transloco
        }}
      </button>
    </div>
    <form (keyup.enter)="changeEmailRequest()">
      <div class="form-email-change" *ngIf="changeEmail">
        <mat-form-field appaerance="fill" floatLabel="always">
          <mat-label>{{
            'mapa.componentes.workspace.componentes.tool_container.tool_template.downloads.dialogos.email' | transloco
          }}</mat-label>
          <mat-icon matPrefix style="font-size: 16px; margin-right: 0.5em; height: 1em" class="material-icons-outlined"
            >email</mat-icon
          >
          <input
            matInput
            type="email"
            (keyup.enter)="changeEmailRequest()"
            [formControl]="newEmail"
            [placeholder]="
              'mapa.componentes.workspace.componentes.tool_container.tool_template.downloads.dialogos.placeEmail'
                | transloco
            "
          />
          <mat-error>{{
            'mapa.componentes.workspace.componentes.tool_container.tool_template.downloads.dialogos.formatoNo'
              | transloco
          }}</mat-error>
        </mat-form-field>
        <mat-icon class="icon-button" matSuffix (click)="changeEmailRequest()">send</mat-icon>
      </div>
    </form>
    <div
      style="display: flex; justify-content: center; padding-top: 2em"
      *ngIf="(changeEmail && this.email) || data.simpleEdit"
    >
      <button class="btn btn-danger" style="margin-right: 0.5em" (click)="changeEmailOrClose()">
        {{
          'mapa.componentes.workspace.componentes.tool_container.tool_template.downloads.dialogos.cancelar' | transloco
        }}
      </button>
      <button class="btn btn-dark" style="margin-left: 0.5em" *ngIf="verAdmin" (click)="goToDownloads()">
        {{
          'mapa.componentes.workspace.componentes.tool_container.tool_template.downloads.dialogos.verDescargas'
            | transloco
        }}
      </button>
    </div>
  </div>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="loadingChange"></mat-progress-bar>
