import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GoogleTagService } from '@src/app/servicios/googletag.service';

import { UserService } from '@src/app/servicios/user.service';
import { AppState } from '@src/app/store/app.state';
import { setLoadingSpinner } from '@src/app/store/share/shared.actions';
import { of } from 'rxjs';

import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { loadNotifications } from '../../navigation/components/notifications/state/notifications.actions';
import { loadCloudLimit } from '../../navigation/mapa/componentes/workspace/componentes/curve/state/curve.actions';
import {
  noData,
  setSelectedDataOpenlayers,
} from '../../navigation/mapa/componentes/workspace/componentes/openlayers/state/openlayers.actions';
import { getAreasCliente } from '../../navigation/state/navigation.actions';
import { loadUser, loadUserSuccess, updateUser, updateUserSuccess } from './user.actions';

@Injectable()
export class UserEffects {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private actions$: Actions,
    private userService: UserService,
    private snackbar: MatSnackBar,
    private googleTagService: GoogleTagService,
    private translocoService: TranslocoService,
  ) {}

  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUser),
      exhaustMap((action) => {
        return this.userService.getUser().pipe(
          map((data) => {
            this.googleTagService.addToDataLayer(data);
            //this.userService.saveLocalStorage(data)
            this.store.dispatch(loadNotifications({ user: data }));
            this.store.dispatch(getAreasCliente({ cliente: data.fk_cliente.id }));

            //si el idioma es portugues le pongo por defecto en la traducción
            if (localStorage.getItem('language') && ['es', 'en', 'pt'].includes(localStorage.getItem('language')!)) {
              this.translocoService.setActiveLang(localStorage.getItem('language')!);
            } else {
              switch (data.fk_contacto.language) {
                case 'portuguese':
                  this.translocoService.setActiveLang('pt');
                  break;
                case 'english':
                  this.translocoService.setActiveLang('en');
                  break;
                case 'spanish':
                  this.translocoService.setActiveLang('es');
                  break;
              }
            }

            return loadUserSuccess({ user: data });
          }),
          catchError((err) => {
            this.router.navigate(['/login']);
            return of(noData());
          }),
        );
      }),
    );
  });

  loadUserRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loadUserSuccess),
        tap((action) => {
          if (window.location.pathname == '/login') {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.router.navigate(['/area/mapa']);
          }
        }),
      );
    },
    { dispatch: false },
  );

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateUser),
      exhaustMap((action) => {
        return this.userService.editUser(action.id, action.user).pipe(
          map((data) => {
            this.snackbar.open('Contraseña cambiada correctamente', undefined, {
              duration: 4000,
            });
            //this.userService.setUser(data);
            return updateUserSuccess({ user: data });
          }),
        );
      }),
    );
  });
}
