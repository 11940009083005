import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductosState } from './productos.state';

export const PRODUCTOS_STATE_NAME = 'productos';

const getProductosState = createFeatureSelector<ProductosState>(PRODUCTOS_STATE_NAME);

export const getProductsArea = createSelector(getProductosState, (state) => {
  if (state.productsArea) {
    return { data: state.productsArea, selected: state.productSelected };
  } else {
    return null;
  }
});

export const getProductSelected = createSelector(getProductosState, (state) => {
  return state ? state.productSelected : null;
});
export const getProductCompareSelected = createSelector(getProductosState, (state) => {
  return state.productCompareSelected;
});
export const getProductSource = createSelector(getProductosState, (state) => {
  return state.productSource;
});

export const getFechasProduct = createSelector(getProductosState, (state) => {
  return state ? state.fechasProduct : null;
});

export const getFechasCompareProduct = createSelector(getProductosState, (state) => {
  return state.fechasCompareProduct;
});

export const getFechasProductSelected = createSelector(getProductosState, (state) => {
  return state ? state.fechaSelected : null;
});

export const getFechasCompareProductSelected = createSelector(getProductosState, (state) => {
  return state.fechaCompareSelected;
});

export const getEnableChangeDate = createSelector(getProductosState, (state) => {
  return state.enableChangeDate;
});

export const getPidiendoDate = createSelector(getProductosState, (state) => {
  return state.pidiendo;
});

export const getAgrupacionesArea = createSelector(getProductosState, (state) => {
  return state.agrupacionesArea;
});

export const getDateInfo = createSelector(getProductosState, (state) => {
  return state.dateInfo;
});

export const getFechasAvaiable = createSelector(getProductosState, (state) => {
  return state.fechasAvaiableRange;
});

export const getFechasAvaiableCompare = createSelector(getProductosState, (state) => {
  return state.fechasAvaiableRangeCompare;
});

export const getLoadingProducts = createSelector(getProductosState, (state) => {
  return state.loadingProducts;
});

export const getLoadingDatesProduct = createSelector(getProductosState, (state) => {
  return state.loadingDatesProduct;
});
