<div class="content-download" *ngIf="!errorDownload && !viewTable && !downloadDone; else templateError">
  <div style="display: flex; flex-direction: row; width: 100%; margin-top: 1em">
    <span>{{ 'downloads.descargando' | transloco }}</span>
    <mat-progress-spinner id="spinner-download" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #templateError>
  <div class="content-download error-download">
    <mat-progress-bar
      style="position: absolute; top: 0; z-index: 99"
      mode="indeterminate"
      *ngIf="downloadDoing"
    ></mat-progress-bar>
    <div *ngIf="viewTable" class="title-page-download">
      <div>
        <button (click)="backToApp()" class="btn btn-dark">
          {{ 'downloads.volverApp' | transloco }}
        </button>
      </div>
      <h2>{{ 'downloads.misDescargas' | transloco }}</h2>
      <div style="display: flex; justify-content: right; margin-right: 1em">
        <button (click)="reloadDownloads()" class="btn btn-dark">
          {{ 'downloads.recargar' | transloco }}
        </button>
      </div>
    </div>
    <div class="container-table-downloads">
      <div *ngIf="viewTable && dataSource && dataSource.length" class="table-downloads mat-elevation-z8">
        <mat-table [dataSource]="actualData" [trackBy]="trackById">
          <ng-container *ngFor="let column of columns" [matColumnDef]="column">
            <mat-header-cell
              *matHeaderCellDef
              [ngClass]="{
                'sticky-column sticky-column-header': column === 'acciones'
              }"
              >{{ 'downloads.columns.' + column | transloco }}</mat-header-cell
            >
            <ng-container *ngIf="column !== 'acciones'; else actionsDownloads">
              <mat-cell *matCellDef="let element">
                {{
                  column.includes('date') || column.includes('expire')
                    ? (element[column] | date : 'dd/MM/yyyy HH:mm') || '-'
                    : column === 'size'
                    ? !element[column]
                      ? '-'
                      : element[column] < 0.001
                      ? (element[column] * 1024 * 1024 | number : '1.2-2') + ' KB'
                      : element[column] < 1
                      ? (element[column] * 1024 | number : '1.2-2') + ' MB'
                      : (element[column] | number : '1.2-2') + ' GB'
                    : element[column] || '-'
                }}
              </mat-cell>
            </ng-container>
            <ng-template #actionsDownloads>
              <mat-cell *matCellDef="let element" class="sticky-column">
                <div class="container-actions">
                  <mat-icon
                    [title]="'downloads.eliminar' | transloco"
                    style="color: red"
                    (click)="deleteDownload(element)"
                    >delete</mat-icon
                  >
                  <mat-icon [title]="'downloads.editar_correo' | transloco" (click)="editDownload(element)"
                    >edit</mat-icon
                  >
                  <a [href]="element.download_link" *ngIf="element.status === 'finished'" target="_blank">
                    <mat-icon [title]="'downloads.descargar' | transloco">arrow_downward</mat-icon>
                  </a>
                </div>
              </mat-cell>
            </ng-template>
          </ng-container>
          <mat-header-row *matHeaderRowDef="columns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: columns" class="element-row"></mat-row>
        </mat-table>
        <mat-paginator
          *ngIf="dataSource && dataSource.length"
          class="sticky-paginator"
          [pageIndex]="actualPage"
          [length]="dataSource.length"
          [pageSize]="pageSize"
          [pageSizeOptions]="[5, 10, 25, 50] | pageSize : dataSource.length || 0"
          (page)="onPageChange($event)"
        >
        </mat-paginator>
      </div>
    </div>
    <div *ngIf="viewTable && (!dataSource || (dataSource && !dataSource.length)) && !downloadDoing" class="no-results">
      {{ 'downloads.no_hay' | transloco }}
    </div>
  </div>
</ng-template>
