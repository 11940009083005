import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { NgxCaptureModule, NgxCaptureService } from 'ngx-capture';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { DialogAlertComponent } from './components/dialogAlert/dialogAlert.component';
import { CustomCalendarComponent } from './components/custom-calendar/custom-calendar.component';
import { CustomHeaderComponent } from './components/custom-calendar/custom-header/custom-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CalendarInfo } from './components/calendar-info/calendar-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { SearchSelectCustomComponent } from './components/search-select-custom/search-select-custom.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslocoRootModule } from '../transloco/transloco-root.module';
import { MainPipe } from '../pipes/main-pipe.module';
import { LoadingPointsComponent } from './components/loading-points/loading-points.component';

@NgModule({
  declarations: [
    LoadingSpinnerComponent,
    DialogAlertComponent,
    CustomCalendarComponent,
    CustomHeaderComponent,
    CalendarInfo,
    SearchSelectCustomComponent,
    LoadingPointsComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgxCaptureModule,
    FormsModule,

    /** material modules */
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatSnackBarModule,

    /** Transloco */
    TranslocoRootModule,

    /** pipe */
    MainPipe,
  ],
  providers: [NgxCaptureService, MatDatepickerModule, MatNativeDateModule, MatDatepicker],
  exports: [
    CommonModule,
    LoadingSpinnerComponent,
    DialogAlertComponent,
    CustomCalendarComponent,
    CalendarInfo,
    SearchSelectCustomComponent,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MainPipe,
    TranslocoRootModule,
    LoadingPointsComponent,
  ],
})
export class SharedModule {}
