import { createAction, props } from '@ngrx/store';
import { Usuario } from '@src/app/objetos/usuario';

export const LOAD_USER = '[user page] load user';
export const LOAD_USER_SUCCESS = '[user page] load user success';

export const RELOAD_USER_SUCCESS = '[user page] reload user success';
export const DELETE_USER = '[user page] delete user';
export const DELETE_USER_SUCCESS = '[user page] delete user success';

export const UPDATE_USER = '[user page] update user';
export const UPDATE_USER_SUCCESS = '[user page] update user success';

export const loadUser = createAction(LOAD_USER);
export const loadUserSuccess = createAction(LOAD_USER_SUCCESS, props<{ user: Usuario }>());
export const reloadUser = createAction(RELOAD_USER_SUCCESS, props<{ user: Usuario }>());
export const updateUser = createAction(UPDATE_USER, props<{ user: string; id: number }>());
export const updateUserSuccess = createAction(UPDATE_USER_SUCCESS, props<{ user: Usuario | null }>());
