import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { getUser } from '@src/app/@pages/inicio/state/user.selector';
import { getAreaSelected } from '@src/app/@pages/navigation/mapa/state/mapa.selector';
import { TareaActualState, TareaPriority } from '@src/app/enumerations/Tareas.enum';
import {
  MarcadorFeature,
  MarcadorFilterOutput,
  MarcadoresFilterObject,
  MarcadoresInput,
  TypesMarcadores,
} from '@src/app/interfaces/Mapa/tools/MarcadoresInput.interface';
import { Area } from '@src/app/objetos/area';
import { Tarea } from '@src/app/objetos/tarea';
import { CommonService } from '@src/app/servicios/Common.service';
import { MapaService } from '@src/app/servicios/api/map.service';
import { GoogleTagService } from '@src/app/servicios/googletag.service';
import { MarcadoresFilterService } from '@src/app/servicios/marcadores-filter.service';
import { AppState } from '@src/app/store/app.state';
import { saveAs } from 'file-saver-es';
import { Map, MapBrowserEvent, Overlay } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Subject, of } from 'rxjs';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  loadMarcadoresFilter,
  loadTablerosClient,
  loadUsersMarcadores,
  setKeyValueMarcadoresFilter,
} from './state/filter-marcadores.actions';
import {
  getFilterMarcadores,
  getMarcadores,
  getTablerosMarcadores,
  getTareas,
  getUsersMarcadores,
  isLoadingMarcadores,
  isLoadingMarcadoresTableros,
  isLoadingMarcadoresUsers,
} from './state/filter-marcadores.selector';
import { LabelsMarcadoresFilterState } from './state/filter-marcadores.state';

const ZINDEX_MARKS: number = 17;

@Component({
  selector: 'app-filter-marcadores',
  templateUrl: './filter-marcadores.component.html',
  styleUrls: ['./filter-marcadores.component.scss'],
})
export class FilterMarcadoresComponent implements OnInit {
  map: Map = new Map({});
  tareaSelected: Tarea | null = null;
  popup: Overlay;
  funcKey: { name: string; callback: (p0: any) => void }[] = [];
  tareas: Tarea[] = [];
  marcadores: { type: string; features: MarcadorFilterOutput[] } | null = null;
  listenerIdClient: Subject<number> = new Subject();
  idClient: number = -1;
  layer: VectorLayer<VectorSource> | null = null;
  inputs: MarcadoresInput = {
    titulo: {
      type: TypesMarcadores.TEXT,
      values: null,
    },
    prioridad: {
      type: TypesMarcadores.MULTISELECT,
      values: Object.entries(TareaPriority)
        .map(([key, value]) => {
          return {
            id: value,
            nombre: key.replace(/_/gi, ' ').toLocaleLowerCase(),
          };
        })
        .filter((value, index, array) => index >= array.length / 2),
    },
    tablero: {
      type: TypesMarcadores.MULTISELECT,
      values: [],
    },
    usuario: {
      type: TypesMarcadores.MULTISELECT,
      values: [],
    },
    estado: {
      type: TypesMarcadores.MULTISELECT,
      values: Object.entries(TareaActualState)
        .map(([key, value]) => {
          return {
            id: value,
            nombre: key.replace(/_/gi, ' ').toLocaleLowerCase(),
          };
        })
        .filter((value, index, array) => index >= array.length / 2),
    },
  };
  filteredUsers: any[] = [];

  isLoading: {
    usuario: boolean;
    tablero: boolean;
    general: boolean;
    tareas: boolean;
  } = {
    usuario: false,
    tablero: false,
    general: false,
    tareas: false,
  };

  labelInput: string = Object.keys(this.inputs)[0];
  TareaPriority = TareaPriority;
  TypesMarcadores = TypesMarcadores;
  filter: MarcadoresFilterObject;
  verTareaUser: boolean = true;
  auxFilter: MarcadoresFilterObject = {
    titulo: null,
    prioridad: null,
    tablero: null,
    usuario: null,
    estado: null,
  };

  elementTarea: HTMLElement | null = document.getElementById('tareaPopup');
  selectedArea: Area | null;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private store: Store<AppState>,
    private marcadoresService: MarcadoresFilterService,
    private mapaService: MapaService,
    private translocoService: TranslocoService,
    private commonService: CommonService,
    private tagManager: GoogleTagService,
  ) {}

  /** INITIALIZATION */
  ngOnInit(): void {
    this.map = this.mapaService.getMap();
    this.createListener();

    this.popup = new Overlay({
      element: this.elementTarea ? this.elementTarea : undefined,
      stopEvent: false,
    });

    this.map.addOverlay(this.popup);

    this.listenerIdClient.pipe(takeUntil(this.ngUnsubscribe)).subscribe((idClient) => {
      idClient && this.store.dispatch(loadUsersMarcadores({ idClient: idClient }));
      idClient && this.store.dispatch(loadTablerosClient({ idClient: idClient }));
    });

    this.store
      .select(getUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value) {
          this.listenerIdClient.next(value.fk_cliente.id);
          this.verTareaUser = value.ver_tarea;
        }
      });

    this.store
      .select(getUsersMarcadores)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.inputs.usuario.values = value;
      });

    this.store
      .select(getMarcadores)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.marcadores = value;
        this.layer?.getSource()?.dispose();
        this.layer && this.map.removeLayer(this.layer);

        this.layer = this.marcadores?.features?.length
          ? this.marcadoresService.addMarcadoresToLayer(this.marcadores, this.map)
          : null;
      });

    this.store
      .select(getTablerosMarcadores)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.inputs.tablero.values = value;
      });

    this.store
      .select(getTareas)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.tareas = value || [];
      });

    this.store
      .select(isLoadingMarcadores)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.isLoading.general = value;
      });

    this.store
      .select(getFilterMarcadores)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.filter = value;
        if (this.filter) this.auxFilter = JSON.parse(JSON.stringify(this.filter));
      });

    this.store
      .select(isLoadingMarcadoresUsers)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.isLoading.usuario = value;
      });

    this.store
      .select(isLoadingMarcadoresTableros)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.isLoading.tablero = value;
      });

    /** Listen to selected area */
    this.store
      .select(getAreaSelected)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.selectedArea = value;
      });
  }

  /** LISTENERS */
  private createListener() {
    let pointerMarcador = ({ pixel }: MapBrowserEvent<UIEvent>) => {
      if (
        this?.map
          ?.getLayers()
          .getArray()
          .some((el) => el.getZIndex() == ZINDEX_MARKS)
      ) {
        const type =
          this.map.hasFeatureAtPixel(pixel) &&
          this.map.getFeaturesAtPixel(pixel).some((feature) => this.marcadoresService.isMarcadorFeatures(feature))
            ? 'pointer'
            : 'inherit';
        this.map.getViewport().style.cursor = type;
      }
    };
    let clickShowTareaInfo = ({ pixel, originalEvent, coordinate, dragging }: MapBrowserEvent<UIEvent>) => {
      if (
        this?.map
          ?.getLayers()
          .getArray()
          .some((el) => el.getZIndex() == ZINDEX_MARKS)
      ) {
        if (this.map.hasFeatureAtPixel(pixel)) {
          let features = this.map
            .getFeaturesAtPixel(pixel)
            .find((feature) => this.marcadoresService.isMarcadorFeatures(feature));
          if (features) {
            let marcador: MarcadorFeature = features['values_'];
            this.popup.setPosition([coordinate[0], coordinate[1]]);
            if (this.elementTarea && marcador?.tarea) {
              this.elementTarea!.click();
              setTimeout(() => {
                const eleTareaContainer = document.getElementById('content-poptareas');
                if (eleTareaContainer) {
                  eleTareaContainer.innerHTML = this.marcadoresService.popupTareasTemplate(marcador.tarea!, [
                    coordinate[0] % 360,
                    coordinate[1],
                  ]);
                }
                this.elementTarea!.style.visibility = 'visible';
              }, 1000);
              this.tareaSelected = marcador['tarea'];
            }
          }
        } else if (!dragging) {
          this.elementTarea!.style.visibility = 'hidden';
          this.tareaSelected = null;
        }
      }
    };
    this.map.on('pointermove', pointerMarcador);
    this.map.on('singleclick', clickShowTareaInfo);
    this.funcKey.push({ name: 'pointermove', callback: pointerMarcador });
    this.funcKey.push({ name: 'singleclick', callback: clickShowTareaInfo });
  }

  private unListeners() {
    this.funcKey.forEach(({ name, callback }: any) => {
      this.map.un(name, callback);
    });
  }

  /** DOWNLOAD */
  /**
   * Dispatch when download Marks button is clicked
   */
  downloadFileMarcadores() {
    this.layer && this.marcadoresService.downloadMarcadores(this.layer);
  }

  /**
   * Dispatch when download tasks button is clicked
   */
  downloadFileTareas(): void {
    if (!this.tareas?.length) {
      Swal.fire({
        icon: 'info',
        title: this.translocoService.translate(
          'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.no_tareas',
        ),
      });
      return;
    }
    this.isLoading.tareas = true;
    this.marcadoresService
      .downloadTareas(this.tareas)
      .pipe(
        take(1),
        map((results) => {
          return { result: true, value: results };
        }),
        catchError((error) => {
          this.isLoading.tareas = false;
          return of({ result: true, value: error });
        }),
      )
      .subscribe(({ result, value }) => {
        if (!result) {
          Swal.fire({
            icon: 'error',
            title: this.translocoService.translate(
              'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.no_results',
            ),
          });
        } else {
          if (!value?.length) {
            Swal.fire({
              icon: 'info',
              title: this.translocoService.translate(
                'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.no_tareas',
              ),
            });
          } else {
            let csv: string = this.commonService.convertObjectToCsvString(value);
            saveAs(new Blob([csv]), 'tareas.csv');
          }
        }
        this.isLoading.tareas = false;
      });
  }

  /** FILTERS */

  clearFilter(label?: string): void {
    this.auxFilter = this.marcadoresService.setFilter({ ...this.auxFilter }, label);
    if (!label || Object.entries(this.auxFilter).every(([key, value]) => value === null)) {
      this.store.dispatch(
        setKeyValueMarcadoresFilter({
          key: LabelsMarcadoresFilterState.FILTER,
          value: this.marcadoresService.setFilter(),
        }),
      );
      // drop marcadores from map
      this.store.dispatch(
        setKeyValueMarcadoresFilter({
          key: LabelsMarcadoresFilterState.MARCADORES,
          value: null,
        }),
      );
      return;
    }
    // load with new filter
    this.store.dispatch(loadMarcadoresFilter({ filter: this.auxFilter }));
  }

  /**
   * Change actual filter when user puts new data (insert o delete data)
   * @param label
   * @param value
   */
  changeFilter(label: string, value: any) {
    this.auxFilter = this.marcadoresService.setFilter({ ...this.auxFilter }, label, value);
  }

  /**
   * Function to filter task georeferenced points
   */
  searchMarcadoresFilter(): void {
    this.store.dispatch(loadMarcadoresFilter({ filter: { ...this.auxFilter } }));
    this.auxFilter = JSON.parse(JSON.stringify(this.filter));

    this.tagManager.sendEvent(
      'engage',
      this.selectedArea,
      'filter_applied_task',
      'Herramientas',
      'Tareas',
      'Botón',
      'Filtro tareas marcadores',
      null,
      'Análisis',
      'Activa',
      'Filtrado',
    );
  }

  /** CLOSE ELEMENT */
  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();

    this.unListeners();
    // remove marks layer
    this.layer?.getSource()?.dispose();
    this.layer && this.map.removeLayer(this.layer);

    this.store.dispatch(
      setKeyValueMarcadoresFilter({
        key: LabelsMarcadoresFilterState.MARCADORES,
        value: null,
      }),
    );
    this.store.dispatch(
      setKeyValueMarcadoresFilter({
        key: LabelsMarcadoresFilterState.FILTER,
        value: this.marcadoresService.setFilter(),
      }),
    );
  }
}
