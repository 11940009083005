import { createAction, props } from '@ngrx/store';

export const SET_RESULT_TOKEN = '[recover password page] set result token';
export const SET_RESULT_CHANGE_PASSWORD = '[recover password page] set result change password';
export const CHECK_TOKEN = '[recover password page] check token';
export const SEND_NEW_PASSWORD = '[recover password page] send new password';

export const setResultToken = createAction(SET_RESULT_TOKEN, props<{ resultToken: boolean | null }>());

export const setResultChangePassword = createAction(
  SET_RESULT_CHANGE_PASSWORD,
  props<{ resultChangePassword: boolean | null }>(),
);

export const checkToken = createAction(CHECK_TOKEN, props<{ token: string }>());

export const sendNewPassword = createAction(SEND_NEW_PASSWORD, props<{ password: string; token: string }>());
