import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@src/environments/environment';
import { tabHeader, tabindex } from '@src/app/enumerations/admin';
import { Usuario } from '@src/app/objetos/usuario';
import { Area } from '@src/app/objetos/area';
import { selectorHtml } from '@src/app/interfaces/Admin/filter';
import { OptionsRequest } from '@src/app/interfaces/Admin/optionsrequest';
import { Pagination } from '@src/app/interfaces/Admin/pagination';
import { Country } from '@src/app/@pages/navigation/admin/tablefactory/table-usuario/state/usertable.state';
import { OptionsPlantillaRequest } from '@src/app/interfaces/Admin/OptionsPlantillaRequest';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  typeTabHeader = tabHeader;
  public static cultivesBBDD = [
    'canha',
    'arroz',
    'soja',
    'trigo',
    'maiz',
    'garbanzo',
    'frijol',
    'barbecho',
    'poroto',
    'represa',
    'girasol',
    'algodon',
    'horticultura',
    'fruticultura',
    'arbol',
    'otro',
    'nogal',
    'pecano',
    'cebada',
    'pastura',
  ];

  public httpJSONOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  tabIndex = tabindex;

  constructor(private http: HttpClient) {}

  showActivedFieldsFilter(label: string, lab?: any) {
    if (lab) return false;
    return [
      tabHeader.PARCELAS.valueOf(),
      tabHeader.RIEGOS.valueOf(),
      tabHeader.LABORATORIO.valueOf(),
      tabHeader.HISTORICOS.valueOf(),
      tabHeader.MANEJOS.valueOf(),
    ].includes(label);
  }

  sendRequest(typeRequest: string, options_page: OptionsRequest): Observable<any> {
    switch (typeRequest) {
      case this.typeTabHeader.USUARIO.valueOf():
        return this.getUsuarios();
      case this.typeTabHeader.AREAS.valueOf():
        return this.getAreas(options_page);
      case this.typeTabHeader.PARCELAS.valueOf():
        return this.getParcelas(options_page);
      case this.typeTabHeader.RIEGOS.valueOf():
        return this.getRiegos(options_page);
      case this.typeTabHeader.MANEJOS.valueOf():
        return this.getOperaciones(options_page);
      case this.typeTabHeader.HISTORICOS.valueOf():
        return this.getHistoricos(options_page);
      case this.typeTabHeader.LABORATORIO.valueOf():
        return this.getLaboratorio(options_page);
      case this.typeTabHeader.PRODUCTOS.valueOf():
        return this.getProductos(options_page);
      case this.typeTabHeader.ESTACIONES.valueOf():
        return this.getEstaciones(options_page);
      case this.typeTabHeader.CLIMAS.valueOf():
        return this.getEstaciones(options_page);
      default:
        return of(null);
    }
  }

  sendRequestFilter(typeRequest: string, options_page: OptionsRequest, datos: string): Observable<any> {
    switch (typeRequest) {
      case this.typeTabHeader.USUARIO.valueOf():
        return this.getUsuariosFilter(datos);
      case this.typeTabHeader.AREAS.valueOf():
        return this.getAreasFilter(options_page, datos);
      case this.typeTabHeader.PARCELAS.valueOf():
        return this.getParcelasFilter(options_page, datos);
      case this.typeTabHeader.RIEGOS.valueOf():
        return this.getRiegosFilter(options_page, datos);
      case this.typeTabHeader.MANEJOS.valueOf():
        return this.getManejosFilter(options_page, datos);
      case this.typeTabHeader.HISTORICOS.valueOf():
        return this.getHistoricosFilter(options_page, datos);
      case this.typeTabHeader.LABORATORIO.valueOf():
        return this.getLaboratoriosFilter(options_page, datos);
      case this.typeTabHeader.PRODUCTOS.valueOf():
        return this.getProductosFilter(options_page, datos);
      case this.typeTabHeader.ESTACIONES.valueOf():
        return this.getEstacionesFilter(options_page, datos);
      case this.typeTabHeader.CLIMAS.valueOf():
        return this.getEstacionesFilter(options_page, datos);
      default:
        return of(null);
    }
  }

  /** SEND */
  sendRequestUrl(url: string | undefined): Observable<Pagination> {
    return this.http.get<Pagination>(`${environment.databaseURL}${url}`);
  }

  sendRequestFilterUrl(url: string | undefined, data: any): Observable<Pagination> {
    return this.http.post<Pagination>(`${environment.databaseURL}${url}`, data);
  }

  /** GET */
  getUsuarios(): Observable<Pagination> {
    return this.http.get<Pagination>(`${environment.databaseURL}/rest/usuariospage`);
  }

  getAreas(options_page: OptionsRequest): Observable<Pagination> {
    return this.http.get<Pagination>(`${environment.databaseURL}/rest/clientes/${options_page.fk_cliente}/areaspage`);
  }

  getParcelas(options_page: OptionsRequest): Observable<Pagination> {
    return this.http.get<Pagination>(`${environment.databaseURL}/rest/areas/${options_page.id}/parcelaspage`);
  }

  getProductos(options_page: OptionsRequest): Observable<Pagination> {
    return this.http.get<Pagination>(`${environment.databaseURL}/rest/areas/${options_page.id}/productospage`);
  }

  getRiegos(options_page: OptionsRequest) {
    return this.http.get<Pagination>(`${environment.databaseURL}/rest/areas/${options_page.id}/riegospage`);
  }

  getOperaciones(options_page: OptionsRequest) {
    return this.http.get<Pagination>(`${environment.databaseURL}/rest/areas/${options_page.id}/operacionespage`);
  }

  getHistoricos(options_page: OptionsRequest) {
    return this.http.get<Pagination>(`${environment.databaseURL}/rest/areas/${options_page.id}/historicospage`);
  }

  getLaboratorio(options_page: OptionsRequest) {
    return this.http.get<Pagination>(`${environment.databaseURL}/rest/areas/${options_page.id}/laboratoriopage`);
  }

  getEstaciones(options_page: OptionsRequest) {
    return options_page.id
      ? this.http.get<Pagination>(
          `${environment.databaseURL}/rest/estaciones_locales/${options_page.id}/climapage/-fecha`,
        )
      : this.http.get<Pagination>(
          `${environment.databaseURL}/rest/clientes/${options_page.fk_cliente}/estaciones_localespage`,
        );
  }

  /** FILTROS */
  getUsuariosFilter(datos: any): Observable<Pagination> {
    return this.http.post<Pagination>(`${environment.databaseURL}/rest/usuariospage`, datos, this.httpJSONOptions);
  }

  getAreasFilter(options_page: OptionsRequest, datos: any): Observable<Pagination> {
    return this.http.post<Pagination>(
      `${environment.databaseURL}/rest/clientes/${options_page.fk_cliente}/areaspage`,
      datos,
      this.httpJSONOptions,
    );
  }

  getManejosFilter(options_page: OptionsRequest, datos: any): Observable<Pagination> {
    return this.http.post<Pagination>(
      `${environment.databaseURL}/rest/areas/${options_page.id}/operacionespage`,
      datos,
      this.httpJSONOptions,
    );
  }

  getHistoricosFilter(options_page: OptionsRequest, datos: any): Observable<Pagination> {
    return this.http.post<Pagination>(
      `${environment.databaseURL}/rest/areas/${options_page.id}/historicospage`,
      datos,
      this.httpJSONOptions,
    );
  }

  getParcelasFilter(options_page: OptionsRequest, datos: any): Observable<Pagination> {
    return this.http.post<Pagination>(
      `${environment.databaseURL}/rest/areas/${options_page.id}/parcelaspage`,
      datos,
      this.httpJSONOptions,
    );
  }

  getProductosFilter(options_page: OptionsRequest, datos: any): Observable<Pagination> {
    return this.http.post<Pagination>(
      `${environment.databaseURL}/rest/areas/${options_page.id}/productospage`,
      datos,
      this.httpJSONOptions,
    );
  }

  getLaboratoriosFilter(options_page: OptionsRequest, datos: any): Observable<Pagination> {
    return this.http.post<Pagination>(
      `${environment.databaseURL}/rest/areas/${options_page.id}/laboratoriospage`,
      datos,
      this.httpJSONOptions,
    );
  }

  getRiegosFilter(options_page: OptionsRequest, datos: any): Observable<Pagination> {
    return this.http.post<Pagination>(
      `${environment.databaseURL}/rest/areas/${options_page.id}/riegospage`,
      datos,
      this.httpJSONOptions,
    );
  }

  getEstacionesFilter(options_page: OptionsRequest, datos: any) {
    return options_page.id
      ? this.http.post<Pagination>(
          `${environment.databaseURL}/rest/estaciones_locales/${options_page.id}/climapage/-fecha`,
          datos,
          this.httpJSONOptions,
        )
      : this.http.post<Pagination>(
          `${environment.databaseURL}/rest/clientes/${options_page.fk_cliente}/estaciones_localespage`,
          datos,
          this.httpJSONOptions,
        );
  }

  getCountries() {
    return this.http.get<Country[]>(`https://restcountries.com/v2/all?fields=name`);
  }

  getSelector(label: string, cliente?: number): Observable<any> {
    switch (label) {
      case 'Area':
        return this.http.get<Area[]>(`${environment.databaseURL}/rest/clientes/${cliente}/areas`);
      case 'Estacion':
        return this.http.get<any[]>(`${environment.databaseURL}/rest/clientes/${cliente}/estaciones_locales`);
      default:
        return of(null);
    }
  }

  /** EDIT */
  editUser(user_id: number, datos: string) {
    return this.http.put(`${environment.databaseURL}/rest/usuarios/${user_id}`, datos, this.httpJSONOptions);
  }

  /** parcelas */
  bulkEditParcelas(datos: any) {
    return this.http.put(`${environment.databaseURL}/rest/parcelas/bulk_update`, datos, this.httpJSONOptions);
  }

  /** areas */
  editArea(area_id: number | undefined, datos: any) {
    return this.http.put(`${environment.databaseURL}/rest/areas/${area_id}`, datos, this.httpJSONOptions);
  }

  /** riegos */
  bulkEditRiegos(datos: any) {
    return this.http.put(`${environment.databaseURL}/rest/riegos/bulk_update`, datos, this.httpJSONOptions);
  }

  /** laboratorios */
  bulkEditLaboratiorio(datos: any) {
    return this.http.put(`${environment.databaseURL}/rest/laboratorio/bulk_update`, datos, this.httpJSONOptions);
  }

  /** historicos */
  bulkEditHistoricos(datos: any) {
    return this.http.put(`${environment.databaseURL}/rest/historicos/bulk_update`, datos, this.httpJSONOptions);
  }

  /** operaciones */
  editManejos(id: any, datos: any) {
    return this.http.put(`${environment.databaseURL}/rest/operaciones/${id}`, datos, this.httpJSONOptions);
  }

  /*productos*/
  editProducto(producto_id: number | undefined, datos: any) {
    return this.http.put(`${environment.databaseURL}/rest/productos/${producto_id}`, datos, this.httpJSONOptions);
  }

  /** estaciones */
  editEstacion(estacion: any, datos: any) {
    return this.http.put(`${environment.databaseURL}/rest/estaciones_locales/${estacion}`, datos, this.httpJSONOptions);
  }

  /** climas */
  editClimaEstacion(clima: any, datos: any) {
    return this.http.put(`${environment.databaseURL}/rest/clima_estaciones/${clima}`, datos, this.httpJSONOptions);
  }

  /** ADD */
  addUser(datos: string) {
    return this.http.post(`${environment.databaseURL}/rest/usuarios`, datos, this.httpJSONOptions);
  }

  /** DELETE */
  deleteUser(userID: number) {
    return this.http.delete(`${environment.databaseURL}/rest/usuarios/${userID}`);
  }

  /** UTILS*/
  formatArea(data: any): selectorHtml[] {
    return data
      .filter((x: any) => x.nombre !== 'global')
      .map((item: any) => {
        return { label: item.nombre, value: item.id };
      });
  }

  formatSelector(label: string, data: any): selectorHtml[] {
    switch (label) {
      case 'Area':
        return this.formatArea(data);
      case 'Estacion':
        return this.formatArea(data);
      default:
        return this.formatArea(data);
    }
  }

  formatOptions(user: Usuario, selectedOptions: any): OptionsRequest {
    var options = new OptionsRequest();
    options.fk_cliente = user.fk_cliente.id;
    if (selectedOptions.idSelected) {
      options.id = selectedOptions.idSelected;
    }
    return options;
  }

  editTabSelected(body: any, tab_selected: number, idDato?: number): Observable<any> {
    switch (tab_selected) {
      case this.tabIndex.USUARIO:
        return this.editUsuarios(body);
      case this.tabIndex.PRODUCTOS:
        return this.editProducto(idDato, body);
      case this.tabIndex.AREAS:
        return this.editArea(idDato, body);
      case this.tabIndex.PARCELAS:
        return this.editParcelas(body);
      case this.tabIndex.RIEGOS:
        return this.editRiegos(body);
      case this.tabIndex.HISTORICOS:
        return this.editHistoricos(body);
      case this.tabIndex.MANEJOS:
        return this.editOperaciones(body);
      case this.tabIndex.LABORATORIO:
        return this.editLaboratorios(body);
      case this.tabIndex.ESTACIONES:
        return this.editEstaciones(body);
      case this.tabIndex.CLIMAS:
        return this.editEstacionesClima(body);
      default:
        return of(null);
    }
  }

  addTabSelected(body: any, tab_selected: number, cliente: number): Observable<JSON> {
    switch (tab_selected) {
      case this.tabIndex.USUARIO:
        return this.addUsuarios(body);
      case this.tabIndex.RIEGOS:
        return this.addRiegos(body);
      case this.tabIndex.HISTORICOS:
        return this.addHistoricos(body);
      case this.tabIndex.MANEJOS:
        return this.addOperaciones(body);
      case this.tabIndex.LABORATORIO:
        return this.addLaboratorios(body);
      case this.tabIndex.ESTACIONES:
        return this.addEstaciones(body);
      case this.tabIndex.CLIMAS:
        return this.addEstacionesClima(body);
      default:
        return of(JSON);
    }
  }

  private containsFkParcela(index: number) {
    return [this.tabIndex.RIEGOS, this.tabIndex.HISTORICOS, this.tabIndex.MANEJOS, this.tabIndex.LABORATORIO].includes(
      index,
    );
  }

  getPlantillaTab(options: OptionsPlantillaRequest, url?: string, body?: any): Observable<any> {
    let { tab_selected, idArea, opcion, idCliente, isTemplate, idEstacion, idTablero, activas }: any = options;
    // Si tiene body, debe buscarse en los filtros
    if (body && [this.containsFkParcela(tab_selected)]) return this.getPlantilla(idArea, opcion, false, body);
    if (body) return this.sendRequestFilterUrl(url, body);
    // Si tiene tablero, hay que obtenerlo
    if (idTablero) return this.getPlantillaLabTablero(idTablero, isTemplate);
    let activeFilter = activas ? this.getActiveFieldsObject() : undefined;

    // Casos de tabs
    switch (tab_selected) {
      case this.tabIndex.ESTACIONES:
        return this.getPlantillaEstaciones(idCliente, isTemplate);
      case this.tabIndex.PARCELAS:
        return this.getPlantillaParcelas(idArea, opcion, activas);
      case this.tabIndex.CLIMAS:
        return this.getPlantillaEstacion(idEstacion, isTemplate);
      case this.tabIndex.USUARIO:
        return this.getPlantillaUsuario(isTemplate);
      case this.tabIndex.PRODUCTOS:
      case this.tabIndex.AREAS:
        return this.sendRequestUrl(url);
      default:
        return this.getPlantilla(idArea, opcion, isTemplate, activeFilter);
    }
  }

  private getActiveFieldsObject() {
    return { filtro: { fk_parcela__activo: true } };
  }

  getPlantillaUsuario(isTemplate?: boolean) {
    let template = !isTemplate ? '' : '?template=true';
    return this.http.get<any>(`${environment.databaseURL}/rest/usuarios/template${template}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  getPlantilla(idArea: number, opcion: string, isTemplate?: boolean, body?: Object): Observable<any> {
    let template = !isTemplate ? '' : '/template';
    return this.http.post<any>(`${environment.databaseURL}/rest/areas/${idArea}/${opcion}${template}`, body, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  getPlantillaParcelas(idArea: number, opcion: string, activas?: boolean): Observable<Array<Object>> {
    let all = activas ? '' : '/all';
    return this.http.get<Array<Object>>(`${environment.databaseURL}/rest/areas/${idArea}/${opcion}${all}`);
  }

  getPlantillaLabTablero(idTablero: number, isTemplate?: boolean | null) {
    let template = !isTemplate ? '' : '/template';
    return this.http.get<any>(`${environment.databaseURL}/rest/tableros/${idTablero}/marcadores${template}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  getPlantillaEstacion(idEstacion: number, isTemplate?: boolean): Observable<any> {
    let template = !isTemplate ? '' : '/template';
    return this.http.get<any>(
      `${environment.databaseURL}/rest/estaciones_locales/${idEstacion}/clima/download${template}`,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
  }

  getPlantillaEstaciones(idCliente: string, isTemplate?: boolean): Observable<any> {
    let template = !isTemplate ? '' : '/template';
    return this.http.get<any>(
      `${environment.databaseURL}/rest/clientes/${idCliente}/estaciones_locales/download${template}`,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
  }

  /** BULK EDIT  */
  editParcelas(body: any): Observable<JSON> {
    return this.http.put<JSON>(`${environment.databaseURL}/rest/parcelas/bulk_update`, body, this.httpJSONOptions);
  }

  editUsuarios(body: any): Observable<JSON> {
    return this.http.put<JSON>(`${environment.databaseURL}/rest/usuarios/bulk_update`, body, this.httpJSONOptions);
  }

  editRiegos(body: any): Observable<JSON> {
    return this.http.put<JSON>(`${environment.databaseURL}/rest/riegos/bulk_update`, body, this.httpJSONOptions);
  }

  editHistoricos(body: any): Observable<JSON> {
    return this.http.put<JSON>(`${environment.databaseURL}/rest/historicos/bulk_update`, body, this.httpJSONOptions);
  }

  editOperaciones(body: any): Observable<JSON> {
    return this.http.put<JSON>(`${environment.databaseURL}/rest/operaciones/bulk_update`, body, this.httpJSONOptions);
  }

  editLaboratorios(body: any): Observable<JSON> {
    return this.http.put<JSON>(`${environment.databaseURL}/rest/laboratorio/bulk_update`, body, this.httpJSONOptions);
  }

  editEstaciones(body: any): Observable<JSON> {
    return this.http.put<JSON>(
      `${environment.databaseURL}/rest/estaciones_locales/bulk_update`,
      body,
      this.httpJSONOptions,
    );
  }

  editEstacionesClima(body: any): Observable<JSON> {
    return this.http.put<JSON>(`${environment.databaseURL}/rest/clima/bulk_update`, body, this.httpJSONOptions);
  }

  /** ADD NEW */
  // En admin no se pueden añadir parcelas
  addRiegos(body: any): Observable<JSON> {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/riegos/bulk_upload`, body, this.httpJSONOptions);
  }

  addUsuarios(body: any): Observable<JSON> {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/usuarios/bulk_upload`, body, this.httpJSONOptions);
  }

  addHistoricos(body: any): Observable<JSON> {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/historicos/bulk_upload`, body, this.httpJSONOptions);
  }

  addOperaciones(body: any): Observable<JSON> {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/operaciones/bulk_upload`, body, this.httpJSONOptions);
  }

  addLaboratorios(body: any): Observable<JSON> {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/laboratorio/bulk_upload`, body, this.httpJSONOptions);
  }

  addEstaciones(body: any): Observable<JSON> {
    return this.http.post<JSON>(
      `${environment.databaseURL}/rest/estaciones_locales/bulk_upload`,
      body,
      this.httpJSONOptions,
    );
  }

  addEstacionesClima(body: any): Observable<JSON> {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/clima/bulk_upload`, body, this.httpJSONOptions);
  }

  /**
   * Método que cambia los valores de idioma a los aceptados en BBDD
   * @param value
   * @returns
   */
  changeLanguageOptions(value: string) {
    let valueFormated = this.removeAccents(value).toLocaleLowerCase();
    switch (valueFormated) {
      case 'espanol':
      case 'castellano':
        return 'spanish';
      case 'ingles':
        return 'english';
      case 'portugues':
        return 'portuguese';
      default:
        return valueFormated.toLocaleLowerCase();
    }
  }

  /**
   * Comprueba que value es uno de los cultivos aceptados en BBDD
   * @param value valor a comprobar
   * @returns si es un cultivo aceptado
   */
  changeCultiveOptions(value: string): boolean {
    return AdminService.cultivesBBDD.includes(value);
  }

  /**
   * Función que elimina acentos de una cadena de texto
   * @param str cadena de texto a eliminar acentos
   * @returns cadena modificada
   */
  removeAccents(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}
