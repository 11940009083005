import { Pipe, PipeTransform } from '@angular/core';
import { Parcela2 } from '../objetos/parcela2';

@Pipe({
  name: 'typeForPipe',
})
export class typeForPipe implements PipeTransform {
  transform(array: any, type: string): any[] {
    var p = new Parcela2();
    switch (type) {
      case 'number':
        return Object.keys(p).filter((el) => p[el] instanceof Number);
      case 'string':
        return Object.keys(p).filter((el) => p[el] instanceof String);
      case 'date':
        return Object.keys(p).filter((el) => p[el] instanceof Date && array[el]);
      case 'boolean':
        return Object.keys(p).filter((el) => p[el] instanceof Boolean);
      default:
        return array;
    }
  }
}
