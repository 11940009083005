import { Area } from '@src/app/objetos/area';

export interface MapaState {
  areaSelected: Area | null;
  zonesArea: string[] | null;
  zoneSelected: string | null;
  harvestsArea: string[] | null;
  harvestSelected: string | null;

  // Workspace inner container
  controlProductos: {
    oculto: boolean;
    expandido: boolean;
  };
  controlParcelas: {
    oculto: boolean;
    expandido: boolean;
    visible: boolean;
  };
  controlFiltros: {
    oculto: boolean;
    expandido: boolean;
  };
  // Herramientas
  curveEnabled: boolean;
  histogramEnabled: boolean;
  compareEnabled: boolean;
  fieldLabelEnabled: boolean;
  compareSwipe: number;
  openNovedades: boolean;
  tareasEnabled: boolean;
  productsDisabled: boolean;
}

/*
 * Initial state
 */
export const initialStateMapa: MapaState = {
  areaSelected: null,
  zonesArea: null,
  zoneSelected: null,
  harvestsArea: null,
  harvestSelected: null,

  // Workspace inner container
  controlProductos: {
    oculto: false,
    expandido: true,
  },
  controlParcelas: {
    oculto: true,
    expandido: false,
    visible: false,
  },
  controlFiltros: {
    oculto: false,
    expandido: false,
  },

  // Herramientas
  curveEnabled: false,
  histogramEnabled: false,
  compareEnabled: false,
  fieldLabelEnabled: false,
  compareSwipe: 50,
  openNovedades: false,
  tareasEnabled: false,
  productsDisabled: false,
};
