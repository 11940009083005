import { Injectable } from '@angular/core';
import moment from 'moment';
import { Moment, isMoment } from 'moment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  public changeViewSubject = new Subject<any>();

  constructor() {}

  changeView(): Observable<any> {
    return this.changeViewSubject.asObservable();
  }

  /**
   * Change date format to string yyyy-mm-dd
   * @param inputDate date to change
   * @returns new format string date
   */
  changeToFormatDate(inputDate: Date | Moment): string {
    if (inputDate instanceof Date || isMoment(inputDate)) {
      if (isMoment(inputDate)) {
        return moment(inputDate.toDate()).toISOString(true).split('T')[0];
      } else if (inputDate instanceof Date) {
        return moment(inputDate).toISOString(true).split('T')[0];
      }
    }
    return '';
  }
}
