import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AreaService } from '@src/app/servicios/area.service';
import { AppState } from '@src/app/store/app.state';
import { of } from 'rxjs';
import { exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { getUser } from '../../inicio/state/user.selector';
import { noData } from '../mapa/componentes/workspace/componentes/openlayers/state/openlayers.actions';
import { getAreaClienteSuccess, getAreasCliente } from './navigation.actions';

@Injectable()
export class NavigationEffects {
  constructor(private actions$: Actions, private areaservice: AreaService, private store: Store<AppState>) {}

  getAreasClienteEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getAreasCliente),
      withLatestFrom(this.store.select(getUser)),
      exhaustMap((action) => {
        let { cliente }: any = action[0];
        let user = action[1];
        // por si no pasan el id
        cliente = cliente ? cliente : user ? user.fk_cliente.id : null;
        if (!cliente) return of(noData());
        return this.areaservice.getAreas(cliente).pipe(
          map((data) => {
            let areas = data.filter(function (area) {
              return area.nombre !== 'global';
            });
            return getAreaClienteSuccess({ areas_cliente: areas });
          }),
        );
      }),
    );
  });
}
