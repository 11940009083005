import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { saveAs } from 'file-saver-es';
import { NgxCaptureService } from 'ngx-capture';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Downloads } from '../interfaces/Downloads/downloads.interface';
import { Capa } from '../interfaces/Mapa/tools/capas';
import { CommonService } from './Common.service';

export type BodyStatsXMLNX = {
  start_date: string;
  end_date: string;
  area_id: number;
  filtered_plots?: number[];
};

@Injectable({
  providedIn: 'root',
})
export class DownloadsService {
  public httpFileOptions = {
    responseType: 'arraybuffer' as 'json',
  };

  // obtener la respuesta para ver el estado
  public httpErrorsFileOptions = {
    responseType: 'arraybuffer' as 'json',
    observe: 'response' as 'response',
  };

  constructor(
    private http: HttpClient,
    public captureService: NgxCaptureService,
    private commonService: CommonService,
  ) {}

  /**
   * Determines if data is downloable
   * @param data
   * @returns
   */
  isDialogDownload(data: any): boolean {
    return (
      data['type'] == 'FeatureCollection' || data instanceof Blob || data instanceof Array || data instanceof String
    );
  }

  /** FUNCIONES GENERALES */
  downloadCsv(filename: string, obj: { title: any; data: any[] }[]) {
    const stringCsv = obj
      .map(({ title, data }) => `${title};${data.constructor.name === 'Array' ? data.join(';') : data};`)
      .join('\n');
    this.downloadFile(new Blob([stringCsv]), `${filename}.csv`);
  }

  downloadFile(file: string | Blob, fileName: string | undefined) {
    saveAs(file, fileName);
  }

  downloadImage(element: HTMLElement, input: string) {
    this.captureService
      .getImage(element, true)
      .pipe(
        tap((img) => {
          var file = this.convertBase64ToFile(img, input);
          this.downloadFile(file, input);
        }),
      )
      .subscribe();
  }

  /** FUNCIONES AUXILIARES */
  convertBase64ToFile(base64String: string, fileName: string) {
    let arr: any = base64String.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    return new File([uint8Array], fileName, { type: mime });
  }

  /** URLS */
  getERA5(filter: any): Observable<any> {
    return this.http
      .post<any>(`${environment.databaseURL}/rest/climas/filter`, filter, this.httpFileOptions)
      .pipe(map((response) => response));
  }

  getStatsXSLX(body: BodyStatsXMLNX, attribute?: string): Observable<any> {
    const queryParams = {
      atributo: attribute || null,
    };
    const url = this.commonService.serializeParamsQuery(`/rest/stats/filter`, queryParams);
    return this.http.post<any>(`${environment.databaseURL}${url}`, body);
  }

  getTiff(filter: any): Observable<any> {
    return this.http.post<any>(`${environment.databaseURL}/rest/downloadTiff/filter`, filter);
  }

  getRGB(filter: any) {
    return this.http.post<any>(`${environment.databaseURL}/rest/downloadRGB/filter`, filter);
  }

  getShape(filter: any): Observable<any> {
    return this.http
      .post<any>(`${environment.databaseURL}/rest/downloadShape/filter`, filter, this.httpFileOptions)
      .pipe(map((response) => response));
  }

  getCapas(idArea: Number): Observable<Capa[]> {
    return this.http.get<Capa[]>(`${environment.databaseURL}/rest/areas/${idArea}/capas`);
  }

  getMapaVariable(filter: any): Observable<ArrayBuffer> {
    return this.http
      .post<ArrayBuffer>(`${environment.databaseURL}/rest/downloadVariableMap`, filter, this.httpFileOptions)
      .pipe(
        map((file: ArrayBuffer) => {
          return file;
        }),
      );
  }

  /**
   * Función que envía el correo para mandar la descarga seleccionada con el id
   * @param email email al que se va a cambiar
   * @param id de la descarga
   * @returns estado de la acción
   */
  setEmailDownload(email: string, id: number) {
    return this.http.patch(`${environment.databaseURL}/rest/downloads/${id}`, {
      email: email,
    });
  }

  /**
   * Función que envía el token para obtener el zip de la descarga previamente establecida
   * @param token de la descarga
   * @returns estado de la acción
   */
  getZipDownload(token: string) {
    return this.http.get<any>(`${environment.databaseURL}/rest/downloads/${token}`, this.httpErrorsFileOptions).pipe(
      map((response) => {
        if (response.status !== 604) return response.body;
        return JSON.parse(String.fromCharCode.apply(null, [...new Uint8Array(response.body)]));
      }),
    );
  }

  /**
   * Obtiene una lista con todas las descargas del usuario
   * @returns descargas del usuario
   */
  getDownloads(): Observable<Downloads[]> {
    return this.http.get<Downloads[]>(`${environment.databaseURL}/rest/downloads`);
  }

  /**
   * Elimina una descarga determinada por el id
   * @param id de la descarga
   * @returns resltado de la acción
   */
  deleteDownload(id: number) {
    return this.http.delete(`${environment.databaseURL}/rest/downloads/${id}`);
  }
  /**
   * Función que inidica si value está entre los valores a y b
   * @param value
   * @param a
   * @param b
   * @returns
   */
  numberInRange(value: number, a: number, b: number): boolean {
    return value >= a && value <= b;
  }
}
