import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformAttributes',
})
export class transformAttributesPipe implements PipeTransform {
  transform(attribute: string, operacion: string): string {
    operacion = operacion.toLowerCase();
    switch (operacion) {
      case 'filtro':
        return attribute == 'stats__mean' ? `attributes.productos.producto` : `attributes.parcela.${attribute}`;
      case 'laboratorio':
        return 'attributes.laboratorios.' + attribute;
      case 'parcela':
      case 'historicos':
      case 'laboratorios':
      case 'riegos':
      case 'operaciones':
        return `attributes.${operacion}.${attribute}`;
      default:
        return '';
    }
  }
}
