import { Pipe, PipeTransform } from '@angular/core';
import { Area } from '../objetos/area';

@Pipe({
  name: 'formatUnidadAgrupacion',
})
export class unidadesAgrupacion implements PipeTransform {
  transform(val: string, areas: Area[], same?: boolean): string {
    if (areas?.length) {
      let label = val;
      if (val == 'id') val = 'id_label';
      if (!['id_label', 'unidad_01', 'unidad_02', 'unidad_03', 'unidad_04', 'unidad_05'].includes(val)) return val;
      return (areas[0] && areas.length == 1) || (same && same == true) ? areas[0][val] : label;
    }
    return val;
  }
}
