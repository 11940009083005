import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Feature, Map } from 'ol';
import TileState from 'ol/TileState';
import { Extent } from 'ol/extent';
import { WKT } from 'ol/format';
import { Geometry, LineString } from 'ol/geom';
import MultiPoint from 'ol/geom/MultiPoint';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Polygon from 'ol/geom/Polygon';
import BaseLayer from 'ol/layer/Base';
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { Cluster, Raster, TileWMS } from 'ol/source';
import ImageSource from 'ol/source/Image';
import TileSource from 'ol/source/Tile';
import VectorSource from 'ol/source/Vector';
import { ServerType } from 'ol/source/wms';
import { Fill, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { StyleLike } from 'ol/style/Style';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { getBBDDGeojson } from '../@pages/navigation/mapa/componentes/workspace/componentes/workspace-inner-container/filtros/state/filtros.actions';
import { Area } from '../objetos/area';
import { FeatureCollection } from '../objetos/featureCollection';
import { Parcela2 } from '../objetos/parcela2';
import { Usuario } from '../objetos/usuario';
import { AppState } from '../store/app.state';
import { MapaService } from './api/map.service';
import { CompressionService } from './compression.service';
import { sldService } from './sld.service';

@Injectable({
  providedIn: 'root',
})
export class OpenlayersService {
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  public subjectCreateMarcador = new Subject<any>();
  public subjectShowMarcador = new Subject<any>();
  public subjectDeleteMarcador = new Subject<any>();
  public subjectCreatePolygon = new Subject<any>();
  public subjectDeletePolygon = new Subject<any>();
  public subjectSelectPolygon = new Subject<any>();
  public subjectComparePolygon = new Subject<any>();

  public subjectCheckPolygon = new Subject<any>();
  public zoomToBBox = new Subject<any>();
  public subjectCapaCreated = new Subject<any>();
  public subjectCapaLoaded = new Subject<any>();

  public subjectCreateLayer = new Subject<any>();

  constructor(
    private http: HttpClient,
    private compressionService: CompressionService,
    private sldService: sldService,
    private mapService: MapaService,
    private store: Store<AppState>,
  ) {}

  /** LAYERS */
  /**
   * Función que genera un vector layer a partir de los parametros de configuracion
   * @param renderBuffer
   * @param source
   * @param renderMode
   * @param zIndex
   * @param style
   * @returns
   */
  createVectorLayer(
    renderBuffer: number | undefined,
    source: VectorSource<Geometry>,
    renderMode: string,
    zIndex: number,
    style: StyleLike | null | undefined,
  ): VectorLayer<VectorSource<Geometry>> {
    let vectorLayer = new VectorLayer({
      renderBuffer: renderBuffer,
      source: source,
      zIndex: zIndex,
      updateWhileInteracting: true,
    });
    style && vectorLayer.setStyle(style);
    return vectorLayer;
  }
  /**
   * Función que genera un vector source a partir de las features
   * @param features
   * @returns
   */
  createVectorSource(features: Feature<Geometry>[]): VectorSource<Geometry> {
    return new VectorSource({
      features: features,
    });
  }
  /**
   * Función que genera un cluster source a partir las features
   * @param source
   * @param geometryFunction
   * @returns
   */
  createClusterSource(source: any, geometryFunction: any): Cluster {
    return new Cluster({
      distance: 10,
      source: source,
    });
  }
  /**
   * Función que genera un wms tile a partir de los parametros de configuracion
   * @param url
   * @param crossOrigin
   * @param params
   * @param transition
   * @param serverType
   * @param tileLoadFunction
   * @returns
   */
  createTileWMS(
    url: any,
    crossOrigin: any,
    params: any,
    transition: number | undefined,
    serverType: ServerType | undefined,
    tileLoadFunction: boolean,
  ): TileWMS {
    let that = this;

    function imagePostFunction(image: any, src: string) {
      var img = image.getImage();
      if (typeof window.btoa === 'function') {
        var xhr = new XMLHttpRequest();
        /**GET ALL THE PARAMETERS OUT OF THE SOURCE URL*/
        var dataEntries = src.split('&');
        var url: any;
        var params = '';
        dataEntries.forEach((element, index) => {
          index === 0 ? (url = element) : (params = `${params}&${element}`);
        });

        xhr.responseType = 'blob';
        // openlayers api version v3.0 (deivid)
        xhr.addEventListener('loadend', function (evt) {
          const data = this.response;
          if ((data !== undefined && data instanceof Blob) || data instanceof MediaSource) {
            img.src = URL.createObjectURL(data);
          } else {
            image.setState(TileState.ERROR);
          }
        });
        xhr.addEventListener('error', function () {
          image.setState(TileState.ERROR);
        });
        xhr.open('POST', url, true);

        //xhr.responseType = 'arraybuffer';
        /*xhr.onload = async function(e) {
          if(this.status === 200) {
            // option v1.0 (refactor app)
            let uInt8Array = new Uint8Array(this.response);
            let i = uInt8Array.length;
            let binaryString = new Array(i);
            while (i--) {
              binaryString[i] = String.fromCharCode(uInt8Array[i]);
            }
            let data = binaryString.join('');
            let type: any = xhr.getResponseHeader('content-type');

            if (type.indexOf('image') === 0) {
              img.src = 'data:' + type + ';base64,' + window.btoa(data);
            }
            // option with error detect v2.0 (pascu)
            if(this.response?.type?.includes('xml')) {
              img.src = ''
              let response = await this.response?.text();

              if(response.includes('ServiceException')) {
                /*Swal.fire({
                  icon: 'error',
                  title: 'Error al cargar el shape'
                })
              }
            } else {
              img.src = URL.createObjectURL(this.response)
            }
          }
        };*/
        //SET THE PROPER HEADERS AND FINALLY SEND THE PARAMETERS
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.send(params);
      } else {
        img.src = src;
      }
    }

    function imagePostFunction2(tile: any, src: string) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.addEventListener('loadend', function (evt) {
        const data = this.response;
        if (data !== undefined && (data instanceof Blob || data instanceof MediaSource)) {
          tile.getImage().src = URL.createObjectURL(data);
        } else {
          tile.setState(TileState.ERROR);
        }
      });
      xhr.addEventListener('error', function () {
        tile.setState(TileState.ERROR);
      });
      xhr.open('GET', src);
      xhr.send();
    }

    let tilewms = new TileWMS({
      url: url,
      crossOrigin: crossOrigin,
      params: params,
      transition: transition,
      serverType: serverType,
    });

    tileLoadFunction &&
      tilewms.setTileLoadFunction(function (image, src) {
        imagePostFunction2(image, src);
      });
    return tilewms;
  }
  /**
   * Función que genera un Tile layer a partir de los parametros de configuracion
   * @param opacity
   * @param source
   * @param zIndex
   * @returns
   */
  createTileLayer(opacity: number = 1, source: TileWMS, zIndex: number | null): TileLayer<TileSource> {
    let tilelayer = new TileLayer({
      source: source,
    });
    zIndex !== null && tilelayer.setZIndex(zIndex);
    tilelayer.setOpacity(opacity || 1);
    return tilelayer;
  }
  /**
   * Función que genera un raster a partir de los tiles de entrada
   * @param sources
   * @param operation
   * @returns
   */
  createRaster(sources: any, operation: boolean): Raster {
    let raster = new Raster({
      sources: sources,
    });
    operation &&
      raster.setOperation(function (pixels: any) {
        return pixels[1][3] == 0 ? [0, 0, 0, 1] : pixels[0];
      });
    return raster;
  }
  /**
   * Función que genera un layer de imagen a partir de los parametros de entrada
   * @param source
   * @param zIndex
   * @returns
   */
  createImageLayer(source: Raster, zIndex: number, extent: Extent): ImageLayer<ImageSource> {
    let imageLayer = new ImageLayer<ImageSource>({
      source: source,
      extent: extent,
    });
    zIndex && imageLayer.setZIndex(zIndex);
    return imageLayer;
  }

  /** GEOMETRIES */
  /**
   * Función que genera un multipoint a partir de features (points)
   * @param features
   * @returns multipoint
   */
  calculateMultiPoint(features: any[]): MultiPoint {
    return new MultiPoint(features?.map((element) => element.getGeometry().getCoordinates()));
  }
  features2multipolygon(features: any[]): MultiPolygon {
    let polygons = features
      .filter((obj) => obj.geometry.type == 'Polygon')
      .map((obj) => new Polygon(obj.geometry.coordinates));

    let multipolygons = features
      .filter((obj) => obj.geometry.type == 'MultiPolygon')
      .map((obj) => new MultiPolygon(obj.geometry.coordinates).getPolygons());

    // [[1, 2], [3, 4], [5, 6]] ==> [1,2,3,4,5,6]
    let multipolygons2: Polygon[] = multipolygons.reduce((act, value) => [...act, ...value], []);
    return new MultiPolygon(polygons.concat(multipolygons2));
  }

  findFeatureFromCoordinates(features: any[], coordinate1: number, coordinate2: number) {
    if (features && coordinate1 && coordinate2) {
      return features.find(
        (el) =>
          new Polygon(el.geometry.coordinates).intersectsCoordinate([coordinate1, coordinate2]) && el.properties.activo,
      );
    }
    return null;
  }

  createMarcadorFeature(id: string | number, idnax: any) {
    // Marcador
    let marcador = {
      feature_id: id,
      identificador: 'Marcador' + id,
      parcela: idnax,
    };
    // Enviamos
    this.subjectCreateMarcador.next({ data: marcador });
    return marcador;
  }

  /**
   * Funcion para añadir un layer de features (parcelas filtradas, capas, etc)
   * @param source
   * @param renderMode
   * @param width
   * @param color
   * @param zIndex
   */
  addVectorLayer(source: VectorSource, renderMode: string, width: number, color: string, zIndex: number, map?: Map) {
    map = map || this.mapService.getMap();
    let style: Style = this.getLayerStyle({ enabled: true, function: true }, zIndex, color);
    // creamos el layer
    let layer = this.createVectorLayer(window.screen.width, source, renderMode, zIndex, style);
    // Añadimos el layer al mapa
    map.addLayer(layer);
  }

  createPolygonFeature(id: number, feature: any) {
    // Parcela
    let p: any = new Parcela2();
    p.id = 'id_' + id;
    p.activo = true;
    p.alta_freq = false;
    Object.keys(p).forEach((element: string) => {
      if (!['id', 'activo', 'alta_freq'].includes(element)) {
        p[element] = null;
      }
    });
    if (feature.getProperties().idnax) {
      p['idnax'] = feature.getProperties().idnax;
    }
    p['geometry'] = new WKT().writeGeometry(feature.getGeometry());
    // Enviamos
    this.subjectCreatePolygon.next({ data: [p] });
    return [p];
  }

  filterCompareShapeFeatures(features: any[], status: number[]) {
    /**
     * 0: Overlapped polygons to deactivate
     * 1: Equal polygons to edit
     * 2: New polygons to add
     * 3: Not overlapped polygons to deactivate
     */

    return features.filter((obj) => {
      return status.includes(obj.properties.status);
    });
  }

  /** UTILS */
  /**
   * Función que devuelve el cql_filter de la petición wms del shape
   * @param parcelasFiltered parcelas filtradas
   * @returns cql_filter
   */
  getCQL_FILTER(
    parcelasFiltered: number[],
    harvestSelected: string | null,
    zoneSelected: string | null,
    user: any | null,
  ) {
    // Inicializamos las variables
    let id: string[] = user && user.id__in ? user.id : [];
    let idnax: number[] =
      parcelasFiltered && user && user.idnax__in
        ? [...parcelasFiltered, ...user.idnax__in]
        : parcelasFiltered
        ? parcelasFiltered
        : user && user.idnax__in
        ? user.idnax__in
        : [];
    let unidad_01: string[] = user && user.unidad_01__in ? user.unidad_01__in : [];
    let unidad_02: string[] = user && user.unidad_02__in ? user.unidad_02__in : [];
    let unidad_03: string[] = user && user.unidad_03__in ? user.unidad_03__in : [];
    let unidad_04: string[] = user && user.unidad_04__in ? user.unidad_04__in : [];
    let unidad_05: string[] = user && user.unidad_05__in ? user.unidad_05__in : [];

    // Generamos el filtro
    return (
      `${harvestSelected ? `zafra=${harvestSelected}` : `activo='true'`}` +
      `${zoneSelected ? ` AND zonas='${zoneSelected}'` : ''}` +
      `${idnax.length ? ` AND idnax IN (${idnax.map((obj) => `'${obj}'`)})` : ''}` +
      `${id.length ? ` AND id IN (${id.map((obj) => `'${obj}'`)})` : ''}` +
      `${unidad_01.length ? ` AND unidad_01 IN (${unidad_01.map((obj) => `'${obj}'`)})` : ''}` +
      `${unidad_02.length ? ` AND unidad_02 IN (${unidad_01.map((obj) => `'${obj}'`)})` : ''}` +
      `${unidad_03.length ? ` AND unidad_03 IN (${unidad_01.map((obj) => `'${obj}'`)})` : ''}` +
      `${unidad_04.length ? ` AND unidad_04 IN (${unidad_01.map((obj) => `'${obj}'`)})` : ''}` +
      `${unidad_05.length ? ` AND unidad_05 IN (${unidad_01.map((obj) => `'${obj}'`)})` : ''}`
    );
  }

  getSelectedShapeFiltered(
    data: any,
    activeFilters: any[],
    zoneSelected: string | null,
    harvestSelected: string | null,
  ) {
    let shape; // shape guardado en localStorage
    let features; // features a devolver
    let idnaxs: number[] = []; // idnaxs filtrados
    let ids: string[]; // ids filtrados
    let active: boolean = !(harvestSelected || (activeFilters && activeFilters.some((el) => el.table == 'historicos'))); //parcelas activas

    // Descomprimir el shape de localStorage
    if (localStorage.getItem('selectedShape')) {
      shape = JSON.parse(this.compressionService.decompressString(localStorage.getItem('selectedShape')!)!);
    }
    // Obtener las features
    if (shape) {
      // Sacamos los idnaxs de las parcelas filtradas
      if (data) {
        idnaxs = data.map((obj: { idnax: any }) => obj.idnax);
        ids = data.map((obj: { id: any }) => obj.id);
      }
      // Si hay parcelas filtradas, las filtramos del shape
      if (idnaxs && idnaxs.length > 0) {
        if (
          zoneSelected ||
          harvestSelected ||
          (activeFilters && activeFilters.some((el) => el.table == 'historicos'))
        ) {
          features = {
            features: shape.features.filter((item: { properties: { id: string; idnax: number } }) => {
              item.properties.id = ids[idnaxs.findIndex((el) => el == item.properties.idnax)];
              return idnaxs.includes(item.properties.idnax);
            }),
            type: 'FeatureCollection',
            crs: shape.crs,
          };
        } else {
          features = {
            features: shape.features.filter((item: { properties: { id: string; idnax: number; activo: boolean } }) => {
              item.properties.id = ids[idnaxs.findIndex((el) => el == item.properties.idnax)];
              return idnaxs.includes(item.properties.idnax) && item.properties.activo == active;
            }),
            type: 'FeatureCollection',
            crs: shape.crs,
          };
        }
      }
      // Si no hay parcelas filtradas, se devuelve todo el shape
      else {
        if (!activeFilters && !zoneSelected && !harvestSelected) {
          features = {
            features: shape.features.filter(
              (item: { properties: { activo: boolean } }) => item.properties.activo == active,
            ),
            type: 'FeatureCollection',
            crs: shape.crs,
          };
        } else {
          features = {
            features: [],
            type: 'FeatureCollection',
            crs: shape.crs,
          };
        }
      }
    }
    return features;
  }

  updateCacheShape(parcelas: any[]) {
    /** get shape from cache */

    let shape = this.compressionService.decompressObject(localStorage.getItem('selectedShape'));
    let changed: boolean = false;

    /** Loop parcelas */
    parcelas.forEach((element) => {
      let index = shape.features.findIndex((el: any) => el.properties.idnax == element.idnax);
      if (index > -1) {
        shape.features[index].properties.activo = element.activo;
        changed = true;
      }
    });

    /** Update the shape if one parcela changed */
    if (changed) {
      const compressed = this.compressionService.compressObject(shape);
      localStorage.setItem('selectedShape', compressed);
    }
  }

  restoreShapeCache(area: Area, user: Usuario) {
    /** restore cache shape */
    localStorage.removeItem('selectedShape');
    localStorage.removeItem('selectedShapeId');
    this.store.dispatch(getBBDDGeojson({ area: area, products: user.filtro ? false : true }));
  }
  /**
   * Función que recorta el raster al tener parcelas filtradas
   * @param productSource source del layer del producto
   * @param layerSource source del layer de las features (no se añade al mapa)
   * @param zIndex zIndex del layer del producto
   * @returns raster recortado
   */
  crop_raster(productSource: TileWMS, layerSource: VectorSource<Geometry>, zIndex: number, extent: Extent) {
    // create vector layer
    let clipLayer = this.createVectorLayer(window.screen.width, layerSource, 'image', zIndex, null);
    // create tile layer
    let tileLayer = this.createTileLayer(1, productSource, zIndex);
    // create raster
    let rasterSource = this.createRaster([tileLayer, clipLayer], true);

    // create image
    return this.createImageLayer(rasterSource, zIndex, extent);
  }

  getFieldsFromShape(idnax: number[]): Feature[] {
    let shape: any;
    if (localStorage.getItem('selectedShape')) {
      shape = JSON.parse(this.compressionService.decompressString(localStorage.getItem('selectedShape')!)!);
      return shape.features.filter((feature: any) => {
        return idnax.some((el: number) => el == feature.properties.idnax);
      });
    } else {
      return [];
    }
  }

  public unListeners(funcKey: any, map: Map, inputName: string | null, inputCallback: string | null) {
    funcKey.forEach(({ name, callback }: any) => {
      if (name && callback && name == inputName && callback.name == inputCallback) {
        map.un(name, callback);
      } else if (!inputName && !inputCallback) {
        map.un(name, callback);
      }
    });
  }

  getHigherLayer(map: Map): number {
    return Math.max(
      ...map
        .getLayers()
        .getArray()
        .map((layer: BaseLayer) => {
          return layer.getZIndex();
        }),
    );
  }

  /**
   * Función que devuelve el estilo del tooltip de medición
   * @param input dibuando (0) o ya dibujado (1)
   * @param measureTooltipElement elemento html
   * @returns elemento con style
   */
  getMeasureTooltipElementStyle(input: number, measureTooltipElement: HTMLElement, id: string) {
    measureTooltipElement.id = id;
    measureTooltipElement.style.borderRadius = '4px';
    measureTooltipElement.style.position = 'relative';
    measureTooltipElement.style.whiteSpace = 'nowrap';
    measureTooltipElement.style.padding = '2px 4px';
    measureTooltipElement.style.border = '1px solid white';
    measureTooltipElement.style.fontSize = 'small';
    if (input == 0) {
      measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
      measureTooltipElement.classList.add('preview-measure');
      measureTooltipElement.style.color = 'white';
      measureTooltipElement.style.background = 'rgba(0, 0, 0, 0.5)';
      measureTooltipElement.style.opacity = '0.7';
    } else if (input == 1) {
      measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
      measureTooltipElement.classList.add('view-measure');
      measureTooltipElement.style.backgroundColor = '#ffcc33';
      measureTooltipElement.style.color = 'black';
      measureTooltipElement.style.opacity = '1';
    }
    return measureTooltipElement;
  }

  /** FORMAT */
  /**
   * Función que te devuelve la edad a partir de una fecha
   * @param date fecha input
   * @returns edad
   */
  getEdad(date: any) {
    if (date) {
      // Get age from javascript Date calculations
      let age = new Date().getFullYear() - new Date(date).getFullYear();
      let m = new Date().getMonth() - new Date(date).getMonth();
      let total_age = age * 12 + m;

      // Get todays date
      let variable_date = new Date();

      // Calculate the exact date of the age calculated
      for (let index = 0; index < total_age; index++) {
        variable_date.setMonth(variable_date.getMonth() - 1);
      }

      // If the birth date is lower or equal than the calculated -> age = age - 1
      // If the birth date is greater than the calculated -> age = age
      if (
        variable_date <= new Date(date) ||
        variable_date.toISOString().split('T')[0] == new Date(date).toISOString().split('T')[0]
      ) {
        return total_age - 1;
      } else {
        return total_age;
      }
    }
    return null;
  }

  /**
   * Función que calcula y formatea el area de una medición
   * @param polygon polígono que se está midiendo
   * @returns datos calculados
   */
  formatArea(polygon: any) {
    /**Función para medir el área al medir en el mapa */
    let area = polygon.getArea();
    return area > 10000
      ? Math.round((area / 10000) * 100) / 100 + ' ' + 'ha'
      : Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
  }
  /**
   * Función que calcula y formatea la longitud de una medición
   * @param line linea que se está midiendo
   * @returns datos calculados
   */
  formatLength(line: LineString) {
    /**Función para obtener la longitud al medir en el mapa*/
    let length = line.getLength();
    return length > 1000
      ? Math.round((length / 1000) * 100) / 100 + ' ' + 'km'
      : Math.round(length * 100) / 100 + ' ' + 'm';
  }
  /**
   * Función que genera el estilo del layer
   * @param text enabled (boolean), function (boolean)
   * @param zIndex zIndex of the layer (number)
   */
  getLayerStyle(text: { enabled: any; function?: any }, zIndex: string | number, color?: string): any {
    if (typeof zIndex === 'string') zIndex = parseInt(zIndex);
    let style: Style = Object.create(this.sldService['style' + (zIndex > 20 ? 20 : zIndex)]);
    if (color) {
      style.getStroke().setColor(color);
    }

    // Si la capa es más de 20, se pilla un color aleatorio
    if (zIndex >= 20) {
      style.getStroke().setColor(this.mapService.getRandomColor());
    }

    // add text
    if (text.enabled) {
      style.setText(
        new Text({
          font: '16px Arial, bold',
          overflow: true,
          fill: new Fill({
            color: '#000',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
          offsetX: -5,
          offsetY: 15,
        }),
      );
    }
    if (text.function) {
      let styleFunction = function (feature: Feature, resolution: any) {
        if (zIndex == 11) {
          if (feature.get('status') == 1 || feature.get('status') == 2) {
            style.getStroke().setColor('#FFFF00');
          } else if (feature.get('status') == 0 || feature.get('status') == 3) {
            style.getStroke().setColor('#FF0000');
          } else if (feature.get('status') == 4 || feature.get('status') == 5) {
            style.getStroke().setColor('#FF4500');
          }
        }
        if (text.enabled) {
          style.getText().setText(feature.get('id'));
          style.getText().setOverflow(false);
        }
        return style;
      };
      return styleFunction;
    }
    return style;
  }
  /**
   * Función para mostrar de color rojo el marcador seleccionado
   * @param input marcador
   * @returns style
   */
  highlightMarcadorStyle(input: any) {
    let image1 = new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: '#00FFFF',
        width: 2,
      }),
      fill: new Fill({
        color: '#00FFFF',
      }),
    });
    let image2 = new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: '#FF0000',
        width: 2,
      }),
      fill: new Fill({
        color: '#FF0000',
      }),
    });

    let layerStyle: Style = new Style({});

    return function (feature: any) {
      layerStyle.setImage(input == feature['values_'].feature_id ? image2 : image1);
      return layerStyle;
    };
  }

  /** OBSERVABLES */
  /**
   * Observable de la creación de marcadores que sirve de puente entre openlayers y el componente específico
   */
  createMarcadores(): Observable<any> {
    return this.subjectCreateMarcador.asObservable();
  }
  /**
   * Observable de la visualización de marcadores que sirve de puente entre openlayers y el componente específico
   */
  showMarcador(): Observable<any> {
    return this.subjectShowMarcador.asObservable();
  }
  /**
   * Observable de la eliminación de marcadores que sirve de puente entre openlayers y el componente específico
   */
  deleteMarcador(): Observable<any> {
    return this.subjectDeleteMarcador.asObservable();
  }
  /**
   * Observable de la creación de polígonos que sirve de puente entre openlayers y el componente específico
   */
  createPolygon(): Observable<any> {
    return this.subjectCreatePolygon.asObservable();
  }
  /**
   * Observable de la eliminación de polígonos que sirve de puente entre openlayers y el componente específico
   */
  deletePolygon(): Observable<any> {
    return this.subjectDeletePolygon.asObservable();
  }
  /**
   * Observable del check del polígono dibujado
   */
  checkPolygon(): Observable<any> {
    return this.subjectCheckPolygon.asObservable();
  }
  /**
   * Observable de la eliminación de polígonos que sirve de puente entre openlayers y el componente específico
   */
  getZoomToBBox(): Observable<any> {
    return this.zoomToBBox.asObservable();
  }
  /**
   * Observable de la eliminación de polígonos que sirve de puente entre openlayers y el componente específico
   */
  selectPolygon(): Observable<any> {
    return this.subjectSelectPolygon.asObservable();
  }
  /**
   * Observable de la comparación de polígonos. Se envían los datos de cuantos polígonos: son nuevos, son iguales, hay que eliminar, tienen errores de geometría, tienen intersecciones
   */
  comparePolygon(): Observable<any> {
    return this.subjectComparePolygon.asObservable();
  }

  /** Observable de la eliminación de polígonos que sirve de puente entre openlayers y el componente específico
   */
  capaCreated(): Observable<any> {
    return this.subjectCapaCreated.asObservable();
  }
  /**
   * Observable de la eliminación de polígonos que sirve de puente entre openlayers y el componente específico
   */
  capaLoaded(): Observable<any> {
    return this.subjectCapaLoaded.asObservable();
  }
  /**
   * Observable de la creacion de capas que sirve de puente entre openlayers y el componente específico
   */
  createLayer(): Observable<any> {
    return this.subjectCreateLayer.asObservable();
  }

  /** PETICIONES */
  getParcelaGeojson(body: any): Observable<JSON> {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/parcelasGeojson/filter`, body, this.httpOptions);
  }
  getParcelaJson(body: any): Observable<JSON> {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/parcelas/filter`, body, this.httpOptions);
  }
  getHistogramaMean(id: number, datos: string) {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/fechas/${id}/calculate_avg`, datos, this.httpOptions);
  }
  compareShape(area: number, body: any) {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/areas/${area}/compareshape`, body, this.httpOptions);
  }

  /**
   * Función que obtiene de una llamada a la api el pixel con las condiciones dadas
   * @param url url para llamar a la api
   * @returns el valor del pixel con las condiciones dadas
   */
  getValueRaster(url: string): Observable<FeatureCollection> {
    return this.http.get<FeatureCollection>(url);
  }

  convertGMStoGD(grades: number, minutes: number, seg: number) {
    return grades + minutes / 60 + seg / 3600;
  }

  convertStringToGMS(value: string) {
    let separate = value.split('°');
    const grades: any = separate.shift();
    separate = separate.join('').split("'");
    const minutes: any = separate.shift();
    separate = separate.join('').split('"');
    const seg: any = separate.shift();

    return {
      grades: parseFloat(grades),
      minutes: parseFloat(minutes),
      seg: parseFloat(seg),
    };
  }

  /**
   * Obtiene el mayor zIndex disponible (reserva posiciones para capa de ortofoto y adyacentes)
   * @param max máximo calculado
   * @param layers capas disponibles
   * @returns valor que se le atribuye a la capa
   */
  newMaxLayer(max: number, layers: any[]): number {
    let layerOrtofoto = [...layers].reverse().find((layer: BaseLayer) => layer.getProperties().producto == 'ortofoto');
    return layerOrtofoto ? layerOrtofoto.getZIndex() + 3 : max + 1;
  }

  /**
   * Get new id from features
   * @param map
   * @param zIndex
   * @returns
   */
  getNewId(map: Map, zIndex: number) {
    let existentFeatures = map
      .getLayers()
      .getArray()
      .find((el) => el.getZIndex() == zIndex)!
      ['values_'].source.getFeatures();
    return (
      (existentFeatures.some((obj: Feature) => obj.getProperties().feature_id != undefined)
        ? Math.max(
            ...existentFeatures
              .filter((obj: Feature) => obj.getProperties().feature_id != undefined)
              .map((obj: Feature) => obj.getProperties().feature_id),
          )
        : 0) + 1
    );
  }

  /**
   * Función que crea el feature dependiendo de si es marcador o polígono
   * @param zIndex index del layer
   * @param feature feature
   * @param attribute diferenciación entre marcador o polígono
   */
  createFeature(zIndex: number, feature: Feature<Geometry>, attribute: string, map: Map, idnax?: number) {
    if (!map) return;
    // Obtenemos el nuevo id
    let newId = this.getNewId(map, zIndex);

    // Añadimos informacion a la feature
    let found = this.findFeatureFromCoordinates(
      JSON.parse(this.compressionService.decompressString(localStorage.getItem('selectedShape')!)).features,
      feature.getProperties().geometry.flatCoordinates[0],
      feature.getProperties().geometry.flatCoordinates[1],
    );
    feature.setProperties({ feature_id: newId });
    found && feature.setProperties({ idnax: found.properties.idnax });

    // Añadimos la feature
    map
      .getLayers()
      .getArray()
      .find((el) => el.getZIndex() == zIndex)!
      ['values_'].source.addFeatures(feature);

    // Creamos la feature que se enviará al componente
    if (attribute == 'feature_id') {
      return this.createMarcadorFeature(newId, found ? found.properties.idnax : null);
    } else if (attribute == 'geometry') {
      if (idnax) {
        feature.setProperties({ idnax: idnax });
      }
      return this.createPolygonFeature(newId, feature);
    }
  }

  /**
   * Added ortophoto tiled to map
   * @param source
   * @param zIndex
   * @param map
   * @returns
   */
  addTileOrtofoto(source: any, zIndex: number, map: Map) {
    if (!map) return;
    /** creamos una capa fuente de tiles que apunta a geoserver*/
    let productSource = this.createTileWMS(
      source.url,
      source.crossOrigin,
      source.params,
      source.transition,
      'geoserver',
      false,
    );
    let tileLayer = this.createTileLayer(1, productSource, zIndex);

    /** Añadimos el layer al mapa */
    map.addLayer(tileLayer);

    // dirigir mapa hacia la capa seleccionada
    let [b, a, d, c]: number[] = source.bbox.split(',').map((e: any) => Number(e));

    source &&
      source.params &&
      source.params.LAYERS &&
      source.params.LAYERS.includes('ortofoto') &&
      this.mapService.zoomToLayer([a, b, c, d]);
    return map
      .getLayers()
      .getArray()
      .find((el) => el.getZIndex() === zIndex);
  }

  getMarksGeometryFromLayer(layer_id: number): string | null {
    try {
      let features = this.mapService
        .getMap()
        ?.getLayers()
        .getArray()
        .find((el) => el.getZIndex() == layer_id)
        ?.getProperties()
        ?.source?.getFeatures();
      let multipoint = this.calculateMultiPoint(features);
      return new WKT().writeGeometry(multipoint);
    } catch (e) {
      return null;
    }
  }
}
