import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MapaState } from './mapa.state';

export const MAPA_STATE_NAME = 'mapa';

const getMapaState = createFeatureSelector<MapaState>(MAPA_STATE_NAME);

export const getZonesArea = createSelector(getMapaState, (state) => {
  if (state.zonesArea) return { data: state.zonesArea, selected: state.zoneSelected };
  return null;
});

export const getharvestsArea = createSelector(getMapaState, (state) => {
  if (state.harvestsArea) return { data: state.harvestsArea, selected: state.harvestSelected };
  return null;
});

export const getAreaSelected = createSelector(getMapaState, (state) => {
  if (state) {
    return state.areaSelected;
  } else {
    return null;
  }
});

export const getZoneSelected = createSelector(getMapaState, (state) => {
  if (state) {
    return state.zoneSelected;
  } else {
    return null;
  }
});

export const getHarvestSelected = createSelector(getMapaState, (state) => {
  if (state) {
    return state.harvestSelected;
  } else {
    return null;
  }
});

export const getControlProducto = createSelector(getMapaState, (state) => {
  return state.controlProductos;
});

export const getControlParcelas = createSelector(getMapaState, (state) => {
  return state.controlParcelas;
});

export const getControlFiltros = createSelector(getMapaState, (state) => {
  return state.controlFiltros;
});

export const getToolStatus = (tool: string) =>
  createSelector(getMapaState, (state) => {
    return state[tool];
  });

export const getCompareSwipeValue = createSelector(getMapaState, (state) => {
  return state.compareSwipe;
});

export const getOpenNovedades = createSelector(getMapaState, (state) => {
  return state.openNovedades;
});

export const getProductsDisabled = createSelector(getMapaState, (state) => {
  return state.productsDisabled;
});
