import { Alert } from '@src/app/interfaces/alert';

export interface SharedState {
  showLoading: boolean;
  errorMessage: string;
  device: number;
  uniquesLimited: any[] | null;
  alert: Alert | null;
  myProgressBar: any;
  rangeDatesDatepicker: { calendar: string | null; values: (Date | null)[] };
}

export const initialStateShared: SharedState = {
  showLoading: false,
  errorMessage: '',
  device: 3,
  uniquesLimited: null,
  alert: null,
  myProgressBar: {
    display: false,
    value: 0,
  },
  rangeDatesDatepicker: { calendar: null, values: [] },
};
