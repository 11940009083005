import { FilterConfiguration, FilterElement } from '@src/app/models/filterModels/filters.model';

export interface FiltrosState {
  mostrarFiltros: boolean | null;
  filtros: FilterConfiguration[] | null;
  activeFilters: FilterElement[] | null;
  parcelasFiltered: any[] | null;
  parcelasManuallySelected: any[] | null;
}

export const initialStateFiltros: FiltrosState = {
  mostrarFiltros: false,
  filtros: [],
  activeFilters: null,
  parcelasFiltered: null,
  parcelasManuallySelected: null,
};
