<div id="container-search-input" (click)="$event.stopPropagation()">
  <div class="search-input">
    <input
      matInput
      autocomplete="off"
      id="input"
      [placeholder]="placeholderText"
      [(ngModel)]="inputText"
      (input)="changeFilteredList()"
    />
    <mat-icon *ngIf="clearInput && inputText !== ''" (click)="clickClearInput()">clear</mat-icon>
  </div>
  <mat-divider></mat-divider>
</div>
