import { ChangeDetectorRef, Component, Host, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCalendar, MatCalendarHeader, MatDatepicker, MatDatepickerIntl } from '@angular/material/datepicker';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Fecha } from '@src/app/objetos/fecha';
import { CalendarService } from '@src/app/servicios/calendar.service';
import { AppState } from '@src/app/store/app.state';
import { getRangeDatesDatepicker } from '@src/app/store/share/share.selector';
import { flagLoadDates, flagLoadDatesSuccess } from '@src/app/store/share/shared.actions';
import { isMoment, Moment } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss'],
})
export class CustomHeaderComponent extends MatCalendarHeader<any> implements OnInit, OnDestroy {
  private destroyed = new Subject<void>();
  actualViewDate: Date | null;
  // Fechas
  fechaSelected: Fecha;
  rangeDates: (Date | null)[] = [];
  disabledArrowRight: boolean = false;
  disabledArrowLeft: boolean = false;
  actualMovement: number = 0;
  loadingDates: boolean = false;
  rangeMultiYearActualView: number[] = [];
  inDashboard: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    _intl: MatDatepickerIntl,
    private store: Store<AppState>,
    calendar: MatCalendar<any>,
    private calendarService: CalendarService,
    private dateAdapter: DateAdapter<Moment>,
    private datepicker: MatDatepicker<Moment>,
    private actions: Actions,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    private cdr: ChangeDetectorRef,
  ) {
    super(_intl, calendar, dateAdapter, dateFormats, cdr);
    this.calendar.stateChanges.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.init();
    });
  }

  /**
   * Función de inicio que después se relaiza en un subscribe
   */
  init() {
    this.cdr.markForCheck();
    this.actualViewDate = isMoment(this.calendar.activeDate)
      ? this.calendar.activeDate.toDate()
      : this.calendar.activeDate instanceof Date
      ? new Date(this.calendar.activeDate)
      : null;
    this.rangeDates && this.rangeDates.length > 0 && this.actualViewDate && this.changeDisabledArrows();
    this.store.dispatch(flagLoadDates({ date: this.actualViewDate }));
  }

  /**
   * component initializated
   */
  ngOnInit(): void {
    this.init();
    // descomentar cuando se cambien las fechas
    this.inDashboard = window.location.href.includes('dashboard');
    /*this.store.select(getFechasProductSelected)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(value=>{ this.fechaSelected = value });*/
    // obtener rangos min y max del elemento seleccionado
    this.store
      .select(getRangeDatesDatepicker)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ calendar, values }) => {
        this.rangeDates = values?.filter((e) => e !== null);
        this.rangeDates.length && this.actualViewDate && this.changeDisabledArrows();
      });

    // escuchar a las fechas cargadas correctamente
    this.actions.pipe(ofType(flagLoadDatesSuccess), takeUntil(this.ngUnsubscribe)).subscribe(() => {
      if (this.actualMovement !== 0) {
        if (this.calendar.currentView === 'year') {
          this.previousNextMultiYearClicked('year', this.actualMovement);
        } else {
          this.calendar.activeDate =
            this.calendar.currentView === 'month'
              ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, this.actualMovement)
              : null;
          this.cdr.detectChanges();
        }
      }
      this.actualMovement = 0;
      this.loadingDates = false;
    });
  }

  get periodLabel() {
    this.calendarService.changeViewSubject.next(this.calendar.currentView);
    return this.dateAdapter
      .format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  /**
   * Changes the view to multi-year for year and month selection
   */
  yearViewClicked() {
    this.calendar.currentView = 'multi-year';
  }

  /**
   * Función que se ejecuta cuando se pulsa el botón previous
   */
  customPreviousNextClicked(type: number) {
    switch (this.calendar.currentView) {
      case 'month':
        // obtener ejemplo fecha de este mes
        let date: Date | null = isMoment(this.calendar.activeDate)
          ? this.calendar.activeDate.toDate()
          : this.calendar.activeDate instanceof Date
          ? new Date(this.calendar.activeDate)
          : null;
        date?.setMonth(date.getMonth() + type);
        this.actualViewDate = date;
        // cambiar disabled
        this.changeDisabledArrows();
        this.actualMovement = type;
        this.loadingDates = true;
        //this.store.dispatch(flagLoadDates({ date: this.actualViewDate }));
        this.calendar.monthSelected.emit(this.actualViewDate);
        break;
      case 'multi-year':
        this.previousNextMultiYearClicked('year', type);
        break;
      case 'year':
        this.calendar.yearSelected.emit(this.actualViewDate);
      default:
        break;
    }
  }

  /**
   * Fución que analiza los rangos y la vista actual para habilitar/deshabilitar botones de desplazamiento
   */
  changeDisabledArrows() {
    if (this.calendar.currentView === 'multi-year') {
      // alamacenar el rago actual si no se ha almacenado aún
      if (this.rangeMultiYearActualView.length === 0)
        this.rangeMultiYearActualView = [
          this.calendar.multiYearView._years[0][0].value,
          this.calendar.multiYearView._years[this.calendar.multiYearView._years.length - 1][
            this.calendar.multiYearView._years[this.calendar.multiYearView._years.length - 1].length - 1
          ].value,
        ];
      // comprobar años
      if (this.rangeDates[0])
        this.disabledArrowLeft = this.rangeMultiYearActualView[0] <= this.rangeDates[0].getFullYear();
      if (this.rangeDates[1])
        this.disabledArrowRight = this.rangeMultiYearActualView[1] >= this.rangeDates[1].getFullYear();
    } else {
      // condicion left mes
      if (this.rangeDates[0])
        this.disabledArrowLeft =
          this.rangeDates[0].getMonth() === this.actualViewDate?.getMonth() &&
          this.rangeDates[0].getFullYear() === this.actualViewDate.getFullYear();
      // condicion right mes
      if (this.rangeDates[1])
        this.disabledArrowRight =
          this.rangeDates[1].getMonth() === this.actualViewDate?.getMonth() &&
          this.rangeDates[1].getFullYear() === this.actualViewDate.getFullYear();
    }
  }

  /**
   * Previous multi year clicked
   * @param mode
   */
  previousNextMultiYearClicked(mode: 'year', type: number) {
    let [prev, next] = [
      this.dateAdapter.addCalendarYears(this.calendar.activeDate, type * 24),
      this.dateAdapter.addCalendarYears(this.calendar.activeDate, type * 48 - type),
    ];
    // rangos de la nueva vista multi-year
    this.rangeMultiYearActualView =
      prev instanceof Date && next instanceof Date
        ? [prev.getFullYear(), next.getFullYear()].sort((a, b) => a - b)
        : this.rangeMultiYearActualView;
    this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, type * 24);
  }

  /** Close datepicker */
  closeClicked() {
    this.datepicker.close();
  }

  /**
   * component destroyed
   */
  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
