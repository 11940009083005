export class Token {
  constructor(private token: string | null, private powerbi: string | null, private drive: string | null) {}

  getToken() {
    return this.token;
  }
  getTokenPowerBI() {
    return localStorage.getItem('tokenPowerBI');
  }
  getTokenDrive() {
    return this.drive;
  }
}
