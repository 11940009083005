import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_MISSING_HANDLER,
  TranslocoConfig,
  TranslocoMissingHandler,
  TranslocoService,
} from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '@src/environments/environment';
import { SharedModule } from 'src/app/shared/shared.module';
import { DownloadsViewModule } from './@pages/downloads-view/downloads-view.module';
import { UserEffects } from './@pages/inicio/state/user.effects';
import { AuthEffects } from './@pages/login/state/auth.effects';
import { ToolcontainerEffects } from './@pages/navigation/mapa/componentes/workspace/componentes/tool-container/state/tool-container.effects';
import { NavigationEffects } from './@pages/navigation/state/navigation.effects';
import { RecoverPasswordEffects } from './@pages/recover-password/state/recover-password.effects';
import { RegisterVerificationComponent } from './@pages/register-verification/register-verification.component';
import { RegisterModule } from './@pages/register/register.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { clearRequest } from './servicios/clearRequest.service';
import { CompressionService } from './servicios/compression.service';
import { ErrorInterceptorService } from './servicios/error-interceptor.service';
import { AppState, appReducer } from './store/app.state';
import { SharedEffects } from './store/share/shared.effects';
import { clearState } from './store/share/shared.reducer';

export const REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('appReducer');
export const metaReducers: MetaReducer<any>[] = [clearState];
export const reducerProvider = {
  provide: REDUCERS_TOKEN,
  useValue: appReducer,
};

export function preloadLang(transloco: TranslocoService) {
  return function () {
    transloco.load('es').toPromise();
    transloco.load('pt').toPromise();
    //transloco.load('en').toPromise();
  };
}

export class CustomHandler implements TranslocoMissingHandler {
  handle(key: string, config: TranslocoConfig) {
    return ' ';
  }
}

@NgModule({
  declarations: [AppComponent, RegisterVerificationComponent],
  imports: [
    SharedModule,

    BrowserAnimationsModule,
    HttpClientModule,

    /** components */
    AppRoutingModule,
    DownloadsViewModule,
    RegisterModule,

    /** ngrx */
    EffectsModule.forFeature([]),
    EffectsModule.forRoot([
      AuthEffects,
      UserEffects,
      NavigationEffects,
      SharedEffects,
      RecoverPasswordEffects,
      ToolcontainerEffects,
    ]),
    StoreModule.forRoot(REDUCERS_TOKEN, {
      metaReducers: [clearState],
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode // Pauses recording actions and state changes when the extension window is not open
    }),
  ],
  providers: [
    /** ngrx */
    reducerProvider,

    /** transloco */
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        availableLangs: ['en', 'es', 'pt'],
        reRenderOnLangChange: true,
        fallbackLang: 'es',
        defaultLang: 'es',
        missingHandler: {
          logMissingKey: false,
          useFallbackTranslation: true,
          allowEmpty: false,
        },
      } as TranslocoConfig,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: preloadLang,
      deps: [TranslocoService],
    },
    {
      provide: TRANSLOCO_MISSING_HANDLER,
      useClass: CustomHandler,
    },

    /** services */
    [DatePipe],
    clearRequest,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    CompressionService,

    /** Sentry */
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
