<app-loading-spinner *ngIf="showLoading | async"></app-loading-spinner>
<div id="container">
  <mat-icon id="close" (click)="closeTool()">close</mat-icon>
  <!-- imagen cabecera -->
  <div mat-dialog-title class="title">
    <img src="../../../../assets/images/NaxSolutionsIcon.png" height="30" />
  </div>
  <!-- cabecera status formulario -->
  <div class="status">
    <div
      [ngClass]="{
        'green-text': statusForm === 2,
        'red-text': statusForm === 1
      }"
      (click)="changeStatusForm(1)"
    >
      <mat-icon *ngIf="statusForm === 2">check_circle</mat-icon>
      <div *ngIf="statusForm === 1" class="square red-square">1</div>
      {{ 'register.datospersonales' | transloco }}
    </div>
    <div>
      <mat-icon>keyboard_arrow_right</mat-icon>
      <mat-icon>keyboard_arrow_right</mat-icon>
    </div>
    <div [ngClass]="{ 'red-text': statusForm === 2, 'default-text': true }">
      <div [ngClass]="{ 'red-square': statusForm === 2, square: true }">2</div>
      {{ 'register.datos_empresa' | transloco }}
    </div>
  </div>
  <!-- FORMULARIO -->
  <form *ngIf="statusForm === 1" [formGroup]="userData" (keydown.enter)="changeStatusForm(2, userData)">
    <div mat-dialog-content class="content">
      <!-- FORMULARIO PARTE 1-->
      <div>
        <!-- USUARIO INPUT -->
        <mat-form-field [ngClass]="{ 'form-invalid': !validators['user'] }" appearance="outline">
          <mat-label>{{ 'register.user' | transloco }}</mat-label>
          <input
            formControlName="usuario"
            matInput
            [placeholder]="'register.user' | transloco"
            (change)="checkValidUser('user', $event)"
          />
          <mat-hint *ngIf="!validators['user']">{{ 'register.usuario_existe' | transloco }}</mat-hint>
        </mat-form-field>
        <!-- CONTRASEÑA INPUT -->
        <mat-form-field id="password" appearance="outline">
          <mat-label>{{ 'register.contrasena' | transloco }}</mat-label>
          <input
            formControlName="password"
            [type]="typePassword"
            matInput
            [placeholder]="'register.contrasena' | transloco"
          />
          <mat-icon
            matSuffix
            (click)="typePassword = 'text'; $event.stopPropagation()"
            *ngIf="typePassword === 'password'"
            >remove_red_eye</mat-icon
          >
          <mat-icon
            matSuffix
            (click)="typePassword = 'password'; $event.stopPropagation()"
            *ngIf="typePassword === 'text'"
            >visibility_off</mat-icon
          >
          <mat-error *ngIf="userData.get('password')?.hasError('minlength'); else errorCaracteres">{{
            'register.contener_pass' | transloco
          }}</mat-error>
          <ng-template #errorCaracteres>
            <mat-error *ngIf="userData.get('password')?.hasError('contieneCaracter'); else errorMayusculas">{{
              'register.contener_letras' | transloco
            }}</mat-error>
          </ng-template>
          <ng-template #errorMayusculas>
            <mat-error *ngIf="userData.get('password')?.hasError('contieneMayuscula'); else errorDigito">{{
              'register.contener_may' | transloco
            }}</mat-error>
          </ng-template>
          <ng-template #errorDigito>
            <mat-error *ngIf="userData.get('password')?.hasError('contieneDigito'); else errorEspeciales">{{
              'register.contener_digito' | transloco
            }}</mat-error>
          </ng-template>
          <ng-template #errorEspeciales>
            <mat-error *ngIf="userData.get('password')?.hasError('contieneCaracteresEspeciales')">{{
              'register.contener_especial' | transloco
            }}</mat-error>
          </ng-template>
        </mat-form-field>
      </div>
      <!-- EMAIL INPUT -->
      <div>
        <mat-form-field [ngClass]="{ 'form-invalid': !validators['email'] }" appearance="outline">
          <mat-label>Email</mat-label>
          <input
            type="email"
            matInput
            placeholder="nax@example.com"
            formControlName="email"
            (input)="checkValidUser('email', $event)"
          />
          <mat-error *ngIf="userData.get('email')?.hasError('validarEmail')">{{
            'register.mail_ejemplo' | transloco
          }}</mat-error>
          <mat-hint *ngIf="!validators['email']">{{ 'register.mail_existe' | transloco }}</mat-hint>
        </mat-form-field>
        <!-- SELECT language -->
        <mat-form-field appearance="outline">
          <mat-label>{{ 'register.idioma' | transloco }}</mat-label>
          <mat-select formControlName="language">
            <mat-option *ngFor="let language of languages | keyvalue" [value]="language.key">
              {{ language.value | capitalize }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <!-- NOMBRE INPUT -->
        <mat-form-field appearance="outline">
          <mat-label>{{ 'register.nombre' | transloco }}</mat-label>
          <input formControlName="nombre" matInput placeholder="Nombre" />
        </mat-form-field>
        <!-- APELLIDOS INPUT -->
        <mat-form-field appearance="outline">
          <mat-label>{{ 'register.apellidos' | transloco }}</mat-label>
          <input formControlName="apellidos" matInput placeholder="Apellidos" />
        </mat-form-field>
      </div>
      <div class="phone">
        <!-- CÓDIGO TELÉFONO INPUT -->
        <mat-form-field appearance="outline">
          <mat-label>{{ 'register.codigo_fone' | transloco }}</mat-label>
          <mat-select
            (ngModelChange)="changePhoneCode($event.key)"
            formControlName="codePhone"
            (opened)="inputCode?.focus()"
            (focusout)="clearKeysCountriesFilter()"
          >
            <input
              class="searchCode"
              #inputCode
              [placeholder]="'register.buscar_fone' | transloco"
              type="search"
              (keyup)="onKeyCode($event)"
              formControlName="inputFilter"
            />
            <ng-container *ngFor="let key of keysCountriesFilter">
              <ng-container *ngIf="countries[key].phone | phoneCode : key as a">
                <mat-option *ngFor="let code of a" [value]="code">
                  {{ countries[key].emoji + ' +' + code.codeFormat + ' ' + countries[key].name }}
                </mat-option>
              </ng-container>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <!-- TELÉFONO INPUT -->
        <mat-form-field appearance="outline">
          <mat-label>{{ 'register.telefono' | transloco }}</mat-label>
          <input
            matInput
            (keypress)="controlTel($event)"
            (keyup)="addSeparador()"
            formControlName="tel"
            id="phone"
            type="tel"
            name="phone"
            [placeholder]="phoneFormat || 'Teléfono'"
          />
          <mat-error
            >{{ 'register.tel_error' | transloco }}
            {{ this.phoneFormat ? '(' + this.phoneFormat + ')' : '' }}</mat-error
          >
        </mat-form-field>
      </div>
      <div class="final-register">
        <button
          type="button"
          (click)="changeStatusForm(2, userData)"
          [disabled]="userData.invalid || !validators['user'] || !validators['email'] || proveUser"
          [ngClass]="{
            bad: (userData.invalid || !validators['user'] || !validators['email']) && proveForm
          }"
        >
          {{ 'register.continuar' | transloco }}
        </button>
        <p class="go-login">
          {{ 'register.yatienes_cuenta' | transloco }}
          <a [routerLink]="['/login']" style="margin-left: 0">{{ 'register.iniciar' | transloco }}</a>
        </p>
      </div>
    </div>
  </form>
  <form *ngIf="statusForm === 2" [formGroup]="empresaData" (keydown.enter)="register()">
    <div mat-dialog-content class="content content2">
      <!-- FORMLARIO PARTE 2 -->
      <!-- NOMBRE EMPRESA INPUT -->
      <mat-form-field appearance="outline">
        <mat-label>{{ 'register.nombre_empresa' | transloco }}</mat-label>
        <input formControlName="nombreEmpresa" matInput [placeholder]="'register.nombre_empresa' | transloco" />
      </mat-form-field>
      <!-- PAÍS INPUT -->
      <mat-form-field appearance="outline">
        <mat-label>{{ 'register.pais' | transloco }}</mat-label>
        <mat-select
          formControlName="pais"
          (ngModelChange)="clearKeysCountriesFilter()"
          (focusout)="clearKeysCountriesFilter()"
        >
          <input
            class="searchCode"
            type="search"
            formControlName="inputFilter"
            [placeholder]="'register.buscarpais' | transloco"
            (keyup)="onKeyCountry($event)"
          />
          <mat-option *ngFor="let key of keysCountriesFilter" [value]="countries[key].name">
            {{ countries[key].name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <!-- HECTÁREAS INPUT -->
        <mat-form-field appearance="outline">
          <mat-label>{{ 'register.hectares' | transloco }}</mat-label>
          <input
            formControlName="hectareas"
            type="number"
            max="999999"
            min="0"
            size="6"
            (input)="checkHectareas()"
            matInput
            [placeholder]="'register.hectares' | transloco"
          />
        </mat-form-field>
        <!-- CULTIVO INPUT -->
        <mat-form-field appearance="outline">
          <mat-label>{{ 'register.cultivo' | transloco }}</mat-label>
          <input formControlName="cultivo" matInput [placeholder]="'register.cultivo' | transloco" />
        </mat-form-field>
      </div>
      <!-- SELECT DISCOVERY -->
      <mat-form-field appearance="outline">
        <mat-label>{{ 'register.descubre_nax' | transloco }}</mat-label>
        <mat-select formControlName="discovery_way">
          <mat-option
            *ngFor="let valueRecom of enumRecomendacion | keyvalueenum"
            [value]="enumRecomendacion[valueRecom]"
          >
            {{ 'register.discover.' + valueRecom | transloco }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- TÉRMINOS INPUT -->
      <mat-checkbox [ngClass]="{ 'required-box': requiredCheck }" formControlName="terminos" color="primary">
        <mat-label>{{ 'register.terminos' | transloco }}</mat-label>
      </mat-checkbox>
      <a href="https://drive.google.com/file/d/1GvT5GHNw6OPbp9KBRnDPktpueuCpnh1k/view?usp=sharing" target="_blank">{{
        'register.confidenci' | transloco
      }}</a>
      <a href="https://drive.google.com/file/d/1HcZ8xbHsMBHWrD0nU_80Cx3dnce-k7oH/view?usp=sharing" target="_blank">{{
        'register.privacidad' | transloco
      }}</a>
      <div class="final-register">
        <button
          type="button"
          (click)="register()"
          [disabled]="empresaData.invalid || proveUser"
          [ngClass]="{ bad: empresaData.invalid && proveForm }"
        >
          {{ 'register.registrarme' | transloco }}
        </button>
        <p class="go-login">
          {{ 'register.yatienes_cuenta' | transloco }}
          <a [routerLink]="['/login']" style="margin-left: 0">{{ 'register.iniciar' | transloco }}</a>
        </p>
      </div>
    </div>
  </form>
</div>
