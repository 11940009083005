import { Historico } from '@src/app/objetos/historico';
import { Laboratorio } from '@src/app/objetos/laboratorio';
import { Parcela2 } from '@src/app/objetos/parcela2';
import { Riego } from '@src/app/objetos/riego';
import { AdminService } from '@src/app/servicios/api/admin.service';

export interface ParcelaState {
  historicos: Historico[] | null;
  operaciones: any | null;
  riegos: Riego[] | null;
  laboratorios: Laboratorio[] | null;
  operacionSelected: string;
  parcelaSelected: Parcela2 | null;

  variedades: string[] | null;
  cultivo: string[];
  tipoRiegos: number[];
  tipoOperaciones: string[];
}

export const initialStateParcelas: ParcelaState = {
  historicos: null,
  operaciones: null,
  laboratorios: null,
  riegos: null,
  operacionSelected: 'Parcela',
  parcelaSelected: null,

  //TRIBUTOS DE SELECT. SE TIENEN QUE LLAMAR IGUAL QUE EL CAMPO DE LA BD

  variedades: null,
  cultivo: [...AdminService.cultivesBBDD],
  tipoRiegos: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  tipoOperaciones: [
    'fertilizacion',
    'madurante',
    'potasio',
    'herbicidas',
    'fungicidas',
    'insecticidas',
    'escarificado',
    'altura',
    'malezas',
    'otros',
  ],
};
