import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-search-select-custom',
  templateUrl: './search-select-custom.component.html',
  styleUrls: ['./search-select-custom.component.scss'],
})
export class SearchSelectCustomComponent implements OnInit {
  @Input('placeholder') placeholderText: string = 'Search';
  @Input('list') list: any[] = [];
  @Input('clearInput') clearInput: boolean = true;
  @Input('fixInTop') fixInTop: boolean = true;
  @Input('searchValues') searchValues: string[] = ['id'];

  @Output('filtered') filteredEvent = new EventEmitter<any[]>();

  inputText: string = '';
  private matSelect;
  private matOption;
  private renderer;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(matSelect: MatSelect, matOption: MatOption, renderer: Renderer2) {
    this.matSelect = matSelect;
    this.matOption = matOption;
    this.renderer = renderer;
  }

  ngOnInit() {
    if (!this.matOption) {
      console.error('<app-search-select-custom> must be placed inside a <mat-option> element');
      return;
    }
    this.fixInTop && this.fixInTopOption();
    let elementOption = this.matOption._getHostElement();
    // drop checklist
    this.matOption.disabled = true;
    const checkBox = elementOption.childNodes[0];
    this.renderer.removeChild(elementOption, checkBox);
    //this.matOption._getHostElement().classList.add('mat-option-disabled');

    this.matSelect._closedStream.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      this.clickClearInput();
    });
  }

  ngOnChanges(changes: any) {
    this.filteredEvent.emit(changes.list.currentValue);
  }

  fixInTopOption() {
    let elementOption = this.matOption._getHostElement();
    elementOption.style.position = 'sticky';
    elementOption.style.top = '0';
    elementOption.style.backgroundColor = 'white';
    elementOption.style.zIndex = '2';
  }

  changeFilteredList() {
    let newList = this.list.filter((element) => {
      return this.searchValues.some(
        (label) =>
          element[label] !== undefined && element[label] !== null && (element[label] + '').includes(this.inputText),
      );
    });
    this.filteredEvent.emit(newList);
  }

  clickClearInput() {
    this.inputText = '';
    this.filteredEvent.emit(this.list);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
