import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';

export const AUTH_STATE_NAME = 'auth';

const getAuthState = createFeatureSelector<AuthState>(AUTH_STATE_NAME);

export const isAuthenticated = createSelector(getAuthState, (state) => {
  return state.token ? true : false;
});

export const getToken = createSelector(getAuthState, (state) => {
  return state.token ? state.token : null;
});

export const getValidations = createSelector(getAuthState, (state) => {
  return state.validations;
});

export const getRegisterStatus = createSelector(getAuthState, (state) => {
  return state.registerStatus;
});

export const getVerifyUser = createSelector(getAuthState, (state) => {
  return state.verifyUser;
});
