import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DownloadsService } from '@src/app/servicios/downloads.service';
import { AppState } from '@src/app/store/app.state';
import { loadAlert } from '@src/app/store/share/shared.actions';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver-es';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-dialog-alert',
  templateUrl: './dialogAlert.component.html',
  styleUrls: ['./dialogAlert.component.scss'],
})
export class DialogAlertComponent implements OnInit {
  @Input() public alert: any;
  downloableData: boolean = false;
  constructor(
    private store: Store<AppState>,
    private translocoService: TranslocoService,
    private downloadsService: DownloadsService,
  ) {}

  ngOnInit(): void {
    // Checkeamos si tiene data para cambiar el texto del botón de confirmación
    if (this.alert.data) {
      this.downloableData = this.downloadsService.isDialogDownload(this.alert.data);
      if (this.downloableData) {
        this.alert.info.confirmButtonText = this.translocoService.translate(`shared.dialogo.desgError`);
      }
    }
    // Lanzamos la alerta
    Swal.fire(this.alert.info).then((action) => {
      if (action.isConfirmed || action.isDismissed) {
        if (this.alert.data && this.downloableData) {
          this.downloadData(this.alert.data);
        }
        this.store.dispatch(loadAlert({ alert: null }));
      }
    });
  }

  /**
   * Función para descargar información complementaria del error
   * @param data erorres
   */
  downloadData(data: any) {
    // Formateamos el error para la función de downloadsService
    var error: any[] = [];

    if (data['type'] == 'FeatureCollection') {
      data.features.forEach((element: { id: string; properties: { errors: any } }) => {
        error.push({
          title: this.translocoService.translate(`shared.dialogo.errorId`) + element.id,
          data: element.properties.errors,
        });
      });
      this.downloadsService.downloadCsv('errores', error);
    } else {
      if (data instanceof Blob || data.constructor.name === 'String') {
        saveAs(data, 'error.csv');
      } else {
        data.constructor.name === 'Array' &&
          data.forEach((element: any) => {
            if (element.errors) {
              error.push({
                title: 'Error ' + element.id ? element.id : '',
                data: element.errors[0],
              });
            } else if (typeof element === 'object') {
              let todo = Object.entries(element);
              if (todo[0]) {
                let aux: any = todo[0][1];
                error.push({
                  title: this.translocoService.translate(`shared.dialogo.errorEn`) + todo[0][0],
                  data: aux[0],
                });
              }
            } else {
              error.push({
                title: this.translocoService.translate(`shared.dialogo.error`),
                data: element,
              });
            }
          });

        // Descargamos un csv con los errores
        this.downloadsService.downloadCsv('errores', error);
      }
    }
  }
}
