import { Pipe, PipeTransform } from '@angular/core';
import { FilterBasicData } from '../interfaces/FilterBasicData.interface';

@Pipe({
  name: 'checkedAttributeFilter',
})
export class CheckedAttributeFilterPipe implements PipeTransform {
  transform(data: FilterBasicData, filter: any[] | null): boolean {
    return filter
      ? filter.some((element) => {
          return (
            (element.table === data.operacion.toLocaleLowerCase() ||
              (element.table === 'filtro' && 'parcela' === data.operacion.toLocaleLowerCase())) &&
            element.attribute === data.atributo &&
            (element.value instanceof Array
              ? element.value.some((value: any) => value === data.valor)
              : element.value === data.valor)
          );
        })
      : false;
  }
}
