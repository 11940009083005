import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HistogramState } from './histogram.state';

export const HISTOGRAM_STATE_NAME = 'histogram';

const getHistogramState = createFeatureSelector<HistogramState>(HISTOGRAM_STATE_NAME);

export const getShowHistogram = createSelector(getHistogramState, (state) => {
  return state.showHistogram;
});

export const getScreenHistogram = createSelector(getHistogramState, (state) => {
  return state.selectedScreen;
});

export const getHistogramArray = createSelector(getHistogramState, (state) => {
  if (state.histogramArray) {
    return state.histogramArray;
  }
});

export const getHistogramInfo = createSelector(getHistogramState, (state) => {
  return state.histogramInfo;
});

export const getHistogramColors = createSelector(getHistogramState, (state) => {
  return state.histogramColors;
});

export const getHistogramCompareDate = createSelector(getHistogramState, (state) => {
  return state.dateCompared;
});

export const getPolygonHistogram = createSelector(getHistogramState, (state) => {
  return state.polygon;
});

export const getMediaHistogram = createSelector(getHistogramState, (state) => {
  return state.histogramMedia;
});
