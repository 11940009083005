import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterColumns',
})
export class FilterColumns implements PipeTransform {
  transform(array: any[], columnsData: Object): any[] {
    // 5 es el valor para no filtrar la columna
    return array.filter((element) => columnsData[element] < 5);
  }
}
