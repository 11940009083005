export enum DiscoveryWay {
  google = 1,
  instagram = 2,
  facebook = 3,
  youtube = 4,
  twitter = 5,
  linkedin = 6,
  recommendation = 7,
  fira = 9,
  other = 8,
}
