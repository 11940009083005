import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DashboardService } from '@src/app/servicios/dashboard.service';
import { exhaustMap, map } from 'rxjs/operators';
import { AppState } from '../app.state';
import { loadUniquesLimited, loadUniquesLimitedSuccess } from './shared.actions';

@Injectable()
export class SharedEffects {
  constructor(private store: Store<AppState>, private actions$: Actions, private dashboardService: DashboardService) {}

  loadUniquesLimited$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUniquesLimited),
      exhaustMap((action: any) => {
        let idArea;
        if (action.area.length == 1) {
          idArea = action.area[0].id;
        } else {
          idArea = [];
          action.area.map((value: { id: any }) => {
            idArea.push(value.id);
          });
        }

        let body = {
          atributo: [action.attribute],
          activo: true,
          limit: action.limit,
          filtro: action.value,
        };
        return this.dashboardService.getUniquesLimited(idArea, body).pipe(
          map((data) => {
            return loadUniquesLimitedSuccess({ values: data });
          }),
        );
      }),
    );
  });
}
