import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getUser } from '@src/app/@pages/inicio/state/user.selector';
import {
  getAreaSelected,
  getHarvestSelected,
  getZoneSelected,
} from '@src/app/@pages/navigation/mapa/state/mapa.selector';
import { Pagination } from '@src/app/interfaces/Admin/pagination';
import { Parcela2 } from '@src/app/objetos/parcela2';
import { MapaService } from '@src/app/servicios/api/map.service';
import { CapasService } from '@src/app/servicios/capas.service';
import { BodyStatsXMLNX, DownloadsService } from '@src/app/servicios/downloads.service';
import { DriveService } from '@src/app/servicios/drive.service';
import { ErrorFormatterService } from '@src/app/servicios/error-formatter.service';
import { FilterService } from '@src/app/servicios/filter.service';
import { OpenlayersService } from '@src/app/servicios/openlayers.service';
import { PolygonsService } from '@src/app/servicios/polygons.service';
import { ToolsService } from '@src/app/servicios/tools.service';
import { AppState } from '@src/app/store/app.state';
import { editProgressBar, loadAlert, setLoadingSpinner } from '@src/app/store/share/shared.actions';
import { ObservableInput, forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { editLayerAttribute, noData } from '../../openlayers/state/openlayers.actions';
import {
  getActiveFilters,
  getParcelasFiltered,
  getParcelasManuallySelected,
} from '../../workspace-inner-container/filtros/state/filtros.selector';
import {
  getFechasProductSelected,
  getProductSelected,
} from '../../workspace-inner-container/productos/state/productos.selector';
import {
  changeEmailDownload,
  changeEmailDownloadSuccess,
  createUploadProduct,
  deleteCapa,
  deleteCapaSuccess,
  deleteDriveFiles,
  downloadERA5,
  downloadLinkEnable,
  downloadMapaVariable,
  downloadRGB,
  downloadSHAPE,
  downloadTIFF,
  downloadXLSX,
  editUploadProduct,
  getCapaGeometries,
  getCapasGeometriesSuccess,
  getDataImagesDron,
  getDataImagesDronSuccess,
  getErrorDataImagesDron,
  getErrorDataImagesDronSuccess,
  getFilesDrive,
  getFilesDriveDownloadLink,
  getFilesDriveSuccess,
  getGeojsonDriveSuccess,
  getResumableImages,
  getZipDownload,
  getZipDownloadSuccess,
  loadCapaGeometries,
  loadCapas,
  loadCapasGeometriesSuccess,
  loadCapasSuccess,
  loadCompareShape,
  loadCompareShapeSuccess,
  removeDronErrorImage,
  removeDronErrorImageSuccess,
  removeDronImages,
  removeDronImagesSuccess,
  saveLogUpload,
  saveLogUploadSuccess,
  setCancelUpload,
  setLoadingImages,
  setPercentageUpload,
  updateShape,
  updateShapeSuccess,
  updateStateVariableTools,
  uploadCapa,
  uploadCapaGeometries,
  uploadChunkData,
  uploadChunkDataPause,
  uploadChunkDataSuccess,
  uploadPolygons,
  uploadPolygonsSuccess,
  uploadProductSuccess,
} from './tool-container.actions';
import { getCancelUpload, getCapaTitle, getPauseUpload, receivedDriveFiles } from './tool-container.selector';

@Injectable()
export class ToolcontainerEffects {
  MESSAGE_PROCESSING_DOWNLOAD = 'La descarga esta siendo procesada';
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private downloadsService: DownloadsService,
    private driveService: DriveService,
    private capasService: CapasService,
    private mapaService: MapaService,
    private polygonsService: PolygonsService,
    private filterService: FilterService,
    private toolsService: ToolsService,
    private snackbar: MatSnackBar,
    private openlayersService: OpenlayersService,
    private translocoService: TranslocoService,
    private errorFormatterService: ErrorFormatterService,
  ) {}

  /** DOWNLOAD COMPONENT */
  downloadERA5$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadERA5),
      withLatestFrom(this.store.select(getAreaSelected), this.store.select(getParcelasFiltered)),
      mergeMap(([{ dateBegin, dateEnd }, selectedArea, filteredField]) => {
        let body = {
          area_id: selectedArea?.id,
          start_date: dateBegin,
          end_date: dateEnd,
          filtered_plots: filteredField?.map((obj: any) => obj.idnax) ?? [],
        };

        return this.downloadsService.getERA5(body).pipe(
          map((data) => {
            this.store.dispatch(editProgressBar({ mode: 'query', display: false, value: 0 }));
            //if(!data.download_link) return downloadLinkEnable({ id: data.id });
            const blob = new Blob([data], { type: 'application/octet-stream' });
            this.downloadsService.downloadFile(blob, dateBegin + '_' + dateEnd + '_clima.csv');
            //window.open(data.download_link)
            return noData();
          }),
          catchError((error) => of(editProgressBar({ mode: 'query', display: false, value: 0 }))),
        );
      }),
    );
  });

  downloadTIFF$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadTIFF),
      withLatestFrom(
        this.store.select(getAreaSelected),
        this.store.select(getProductSelected),
        this.store.select(getParcelasFiltered),
      ),
      mergeMap((action: any) => {
        var filter: any = {
          fecha__range: [action[0].dateBegin, action[0].dateEnd],
          fk_producto__nombre: action[2].nombre,
          fk_producto__fk_area: action[1].id,
        };
        //Filtramos por ids (si tenemos filtros aplicados)
        if (action[3]) {
          filter['filtroparcelas'] = {
            idnax__in: action[3].map((obj: any) => obj.idnax),
          };
        }
        return this.downloadsService.getTiff(filter).pipe(
          map((data) => {
            this.store.dispatch(editProgressBar({ mode: 'query', display: false, value: 0 }));
            if (!data.download_link) return downloadLinkEnable({ id: data.id });
            /*const blob = new Blob([data], { type: 'application/octet-stream' });
                this.downloadsService.downloadFile(blob, action[0].dateBegin+'_'+action[0].dateEnd+'_rgb.zip')*/
            var popUp = window.open(data.download_link, '_blank');
            if (popUp == null || typeof popUp == 'undefined') {
              this.store.dispatch(
                loadAlert({
                  alert: {
                    info: {
                      icon: 'warning',
                      title: 'Ventanas emergentes bloqueadas',
                      text: 'Por favor, habilite las ventanas emergentes para este sitio en el navegador e inténtelo de nuevo',
                    },
                    data: null,
                  },
                }),
              );
            } else {
              popUp.focus();
            }
            return noData();
          }),
          catchError((error) => of(editProgressBar({ mode: 'query', display: false, value: 0 }))),
        );
      }),
    );
  });

  downloadRGB$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadRGB),
      withLatestFrom(
        this.store.select(getAreaSelected),
        this.store.select(getProductSelected),
        this.store.select(getParcelasFiltered),
      ),
      mergeMap((action: any) => {
        var filter: any = {
          fecha__range: [action[0].dateBegin, action[0].dateEnd],
          fk_producto__nombre: action[2].nombre,
          fk_producto__fk_area: action[1].id,
        };

        //Filtramos por ids (si tenemos filtros aplicados)
        if (action[3]) {
          filter['filtroparcelas'] = {
            idnax__in: action[3].map((obj: any) => obj.idnax),
          };
        }
        return this.downloadsService.getRGB(filter).pipe(
          map((data) => {
            this.store.dispatch(editProgressBar({ mode: 'query', display: false, value: 0 }));
            if (!data.download_link) return downloadLinkEnable({ id: data.id });
            /*const blob = new Blob([data], { type: 'application/octet-stream' });
                  this.downloadsService.downloadFile(blob, action[0].dateBegin+'_'+action[0].dateEnd+'_rgb.zip')*/
            var popUp = window.open(data.download_link, '_blank');
            if (popUp == null || typeof popUp == 'undefined') {
              this.store.dispatch(
                loadAlert({
                  alert: {
                    info: {
                      icon: 'warning',
                      title: 'Ventanas emergentes bloqueadas',
                      text: 'Por favor, habilite las ventanas emergentes para este sitio en el navegador e inténtelo de nuevo',
                    },
                    data: null,
                  },
                }),
              );
            } else {
              popUp.focus();
            }
            return noData();
          }),
          catchError((error) => of(editProgressBar({ mode: 'query', display: false, value: 0 }))),
        );
      }),
    );
  });

  downloadXSLX$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadXLSX),
      withLatestFrom(this.store.select(getAreaSelected), this.store.select(getParcelasFiltered)),
      mergeMap(([{ dateBegin, dateEnd, attribute }, areaSelected, parcelasFiltered]) => {
        let body: BodyStatsXMLNX = {
          start_date: dateBegin,
          end_date: dateEnd,
          area_id: areaSelected!.id,
        };
        //Filtramos por ids (si tenemos filtros aplicados)
        if (parcelasFiltered) {
          body.filtered_plots = parcelasFiltered?.map((obj: any) => obj.idnax);
        }
        return this.downloadsService.getStatsXSLX(body, attribute).pipe(
          map((data) => {
            this.store.dispatch(editProgressBar({ mode: 'query', display: false, value: 0 }));
            if (!data.download_link) return downloadLinkEnable({ id: data.id });
            /*const blob = new Blob([data], { type: 'application/octet-stream' });
              this.downloadsService.downloadFile(blob, action[0].dateBegin+'_'+action[0].dateEnd+'_rgb.zip')*/
            var popUp = window.open(data.download_link, '_blank');
            if (popUp == null || typeof popUp == 'undefined') {
              this.store.dispatch(
                loadAlert({
                  alert: {
                    info: {
                      icon: 'warning',
                      title: this.translocoService.translate('ventana_emergente.title'),
                      text: this.translocoService.translate('ventana_emergente.text'),
                    },
                    data: null,
                  },
                }),
              );
            } else {
              popUp.focus();
            }
            return noData();
          }),
          catchError((error) => of(editProgressBar({ mode: 'query', display: false, value: 0 }))),
        );
      }),
    );
  });

  downloadSHAPE$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadSHAPE),
      withLatestFrom(this.store.select(getAreaSelected), this.store.select(getParcelasFiltered)),
      mergeMap((action: any) => {
        var filter: any = {
          fk_area: action[1].id,
          activo: true,
        };
        //Filtramos por ids (si tenemos filtros aplicados)
        if (action[2]) {
          filter['idnax__in'] = action[2].map((obj: any) => obj.idnax);
        }

        return this.downloadsService.getShape(filter).pipe(
          map((data) => {
            this.store.dispatch(editProgressBar({ mode: 'query', display: false, value: 0 }));
            //if(!data.download_link) return downloadLinkEnable({ id: data.id });
            const blob = new Blob([data], { type: 'application/octet-stream' });
            this.downloadsService.downloadFile(blob, action[1].titulo + '_shape.zip');
            //window.open(data.download_link)
            return noData();
          }),
          catchError((error) => of(editProgressBar({ mode: 'query', display: false, value: 0 }))),
        );
      }),
    );
  });

  getZipDownload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getZipDownload),
      mergeMap((action) => {
        let { token, normalStatus } = action;
        return this.downloadsService.getZipDownload(token).pipe(
          map((data) => {
            if (data instanceof ArrayBuffer) {
              const blob = new Blob([data], {
                type: 'application/octet-stream',
              });
              this.downloadsService.downloadFile(blob, 'descarga.zip');
              this.store.dispatch(editProgressBar({ mode: 'query', display: false, value: 0 }));
              return getZipDownloadSuccess({
                status: true,
                normalStatus: normalStatus,
              });
            }
            return getZipDownloadSuccess({
              status: false,
              message: typeof data.error === 'string' ? data.error : 'Error inesperado',
              normalStatus: normalStatus,
            });
          }),
          catchError((error) => {
            return of(
              getZipDownloadSuccess({
                status: false,
                message: typeof error.error === 'string' ? error.error : 'Error inesperado',
                normalStatus: normalStatus,
              }),
            );
          }),
        );
      }),
    );
  });

  changeEmailDownload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changeEmailDownload),
      mergeMap((action) => {
        let { id, email } = action;
        return this.downloadsService.setEmailDownload(email, id).pipe(
          map((data) => {
            this.store.dispatch(
              loadAlert({
                alert: {
                  info: {
                    icon: 'success',
                    title: 'Email asignado correctamente',
                    text: 'Email para descarga asignado correctamente. Si no llegara ningún correo, revise su bandeja de spam',
                  },
                  data: null,
                },
              }),
            );
            return changeEmailDownloadSuccess({ status: true });
          }),
          catchError((error) => {
            this.store.dispatch(
              loadAlert({
                alert: {
                  info: {
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo asignar el email. Inténtelo de nuevo.',
                  },
                  data: null,
                },
              }),
            );
            return of(changeEmailDownloadSuccess({ status: false }));
          }),
        );
      }),
    );
  });

  getDownloadLinkFilesDrive$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getFilesDriveDownloadLink),
      mergeMap((action) => {
        return this.driveService.getDownloadLink(action.id).pipe(
          map((results: any) => {
            /** Disable loading */
            this.store.dispatch(
              updateStateVariableTools({
                variable: 'polygonLoading',
                value: false,
              }),
            );

            return getGeojsonDriveSuccess({ data: results });
          }),
          catchError((error) => {
            /** Disable loading */
            this.store.dispatch(
              updateStateVariableTools({
                variable: 'polygonLoading',
                value: false,
              }),
            );
            return of(getGeojsonDriveSuccess({ data: null }));
          }),
        );
      }),
    );
  });

  /** CAPAS COMPONENT */
  loadCapas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadCapas),
      withLatestFrom(this.store.select(getAreaSelected)),
      mergeMap((action: any) => {
        return this.downloadsService.getCapas(action[1].id).pipe(
          map((data) => {
            data.forEach((element) => {
              element.check = false;
              element.loading = false;
            });
            return loadCapasSuccess({ capa: data });
          }),
        );
      }),
    );
  });

  loadCapaGeometries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadCapaGeometries),
      mergeMap((action: any) => {
        if (action.capa && action.capa.length > 0) {
          return this.mapaService.getPersonalizableLayer(action.capa[0].id).pipe(
            map((data: any) => {
              return loadCapasGeometriesSuccess({ geometries: data });
            }),
            catchError((error) => {
              return of(loadCapasGeometriesSuccess({ geometries: null }));
            }),
          );
        } else {
          return of(noData());
        }
      }),
    );
  });

  getCapaGeometries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCapaGeometries),
      mergeMap((action: any) => {
        if (action.form) {
          return this.mapaService.postPersonalizableLayer(action.form).pipe(
            map((data: any) => {
              return getCapasGeometriesSuccess({ geometries: data });
            }),
            catchError((error) => {
              return of(getCapasGeometriesSuccess({ geometries: null }));
            }),
          );
        } else {
          return of(noData());
        }
      }),
    );
  });

  deleteCapa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteCapa),
      mergeMap((action) => {
        return this.capasService.deleteCapa(action.capa.id).pipe(
          map((data) => {
            return deleteCapaSuccess({ capa: action.capa });
          }),
          catchError((error) => of(deleteCapaSuccess({ capa: null }))),
        );
      }),
    );
  });

  uploadCapa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(uploadCapa),
      withLatestFrom(this.store.select(getAreaSelected), this.store.select(getCapaTitle)),
      mergeMap(([{ id, geometries, name }, area, capaTitle]: any) => {
        return this.capasService.addCapa(area.id, { nombre: name || capaTitle }).pipe(
          map((data) => {
            return uploadCapaGeometries({
              capa: {
                id: data['id'],
                nombre: data['nombre'],
                check: false,
                loading: false,
              },
              geometries: geometries,
            });
          }),
          catchError((error) => of(loadCapasSuccess({ capa: null }))),
        );
      }),
    );
  });

  uploadCapaGeometries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(uploadCapaGeometries),
      withLatestFrom(this.store.select(getAreaSelected)),
      mergeMap((action) => {
        var body = this.mapaService.featuresForUpload(action[0].capa.id, action[0].geometries);
        return this.capasService.addCapaGeometries(body).pipe(
          map((data) => {
            this.store.dispatch(
              editLayerAttribute({
                zIndex: 9,
                attribute: 'create',
                value: false,
              }),
            );
            this.store.dispatch(
              updateStateVariableTools({
                variable: 'addCapaTitle',
                value: null,
              }),
            );
            this.store.dispatch(
              updateStateVariableTools({
                variable: 'addLayerFeatures',
                value: null,
              }),
            );
            this.openlayersService.subjectCapaCreated.next(0);
            return loadCapasSuccess({ capa: [action[0].capa] });
          }),
        );
      }),
    );
  });

  /** MAPA VARIABLE COMPONENT */
  downloadMapaVariable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadMapaVariable),
      withLatestFrom(
        this.store.select(getFechasProductSelected),
        this.store.select(getActiveFilters),
        this.store.select(getParcelasManuallySelected),
        this.store.select(getAreaSelected),
        this.store.select(getZoneSelected),
        this.store.select(getHarvestSelected),
        this.store.select(getUser),
      ),
      mergeMap((action: any) => {
        var filter = action[3]
          ? { filtro: { idnax__in: action[3] } }
          : this.filterService.formatParcelaFilter(
              action[2],
              action[4],
              action[5],
              action[6],
              action[1],
              action[7].filtro,
            );

        var body = {
          id: action[1]?.id,
          filtroparcelas: filter?.filtro,
          rangos: action[0]?.rangos,
        };

        this.store.dispatch(
          updateStateVariableTools({
            variable: 'downloadingMapaVariable',
            value: true,
          }),
        );
        return this.downloadsService.getMapaVariable(body).pipe(
          map((data) => {
            const blob = new Blob([data], { type: 'application/octet-stream' });
            this.downloadsService.downloadFile(blob, 'mapavariable.zip');
            this.store.dispatch(
              updateStateVariableTools({
                variable: 'downloadingMapaVariable',
                value: false,
              }),
            );
            this.store.dispatch(setLoadingImages({ status: false }));
            return noData();
          }),
          catchError(() => {
            this.store.dispatch(setLoadingImages({ status: false }));
            return of(
              updateStateVariableTools({
                variable: 'downloadingMapaVariable',
                value: false,
              }),
            );
          }),
        );
      }),
    );
  });

  /** POLYGONS COMPONENT */
  uploadPolygons$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(uploadPolygons),
      withLatestFrom(this.store.select(getAreaSelected), this.store.select(getUser)),
      mergeMap((action: any) => {
        let previousShape = action[0].previousShape;
        action[0].polygons.forEach((element: any) => {
          element['activo'] = element['activo'] || false;
          element['alta_freq'] = element['alta_freq'] || false;
        });

        return this.polygonsService.bulkUploadPolygons(action[1].id, action[0].polygons).pipe(
          map((data) => {
            this.toolsService.resetPolygons();
            let geojson200errors: any[] = this.errorFormatterService.checkGeojsonError(data);
            if (geojson200errors.length > 0) {
              this.store.dispatch(
                loadAlert({
                  alert: {
                    info: {
                      icon: 'error',
                      title: 'Error',
                      text: 'Ha ocurrido un error al guardar los polígonos.',
                      html: null,
                      showConfirmButton: true,
                      showCancelButton: false,
                      width: '75%',
                    },
                    data: data,
                  },
                }),
              );
              return !previousShape ? uploadPolygonsSuccess({ result: false }) : updateShapeSuccess({ result: false });
            } else {
              /** restore cache shape */
              this.openlayersService.restoreShapeCache(action[1], action[2]);

              return !previousShape ? uploadPolygonsSuccess({ result: true }) : updateShapeSuccess({ result: true });
            }
          }),
          catchError((error) => {
            return of(
              !previousShape ? uploadPolygonsSuccess({ result: false }) : updateShapeSuccess({ result: false }),
            );
          }),
        );
      }),
      map((e) => e),
    );
  });

  updateShape$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateShape),
      mergeMap((action) => {
        /** Convert to parcela2 */
        let polygonsCreate: Parcela2[] = this.polygonsService.feature2Parcela(action.upload);
        let polygonsUpdate: Parcela2[] = this.polygonsService.feature2Parcela(action.update);

        if (polygonsUpdate.length) {
          return this.polygonsService.bulkEditPolygons(polygonsUpdate).pipe(
            map((data) => {
              return {
                action: updateShapeSuccess({ result: true }),
                polygonsCreate: polygonsCreate,
              };
            }),
            catchError((error) =>
              of({
                action: updateShapeSuccess({ result: false }),
                polygonsCreate: polygonsCreate,
              }),
            ),
          );
        }
        return of({ action: noData(), polygonsCreate: polygonsCreate });
      }),
      map(({ action, polygonsCreate }) => {
        // Añadimos los polígonos nuevos
        if (polygonsCreate.length) {
          return uploadPolygons({
            polygons: polygonsCreate,
            previousShape: true,
          });
        }

        this.toolsService.resetPolygons();
        return action;
      }),
    );
  });

  compareShape$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadCompareShape),
      mergeMap((action: any) => {
        if (action.idArea && action.form) {
          return this.polygonsService.compareShapePost(action.idArea, action.form).pipe(
            map((data: any) => {
              return loadCompareShapeSuccess({ data: data });
            }),
            catchError((error) => {
              return of(loadCompareShapeSuccess({ data: null }));
            }),
          );
        } else {
          return of(loadCompareShapeSuccess({ data: null }));
        }
      }),
    );
  });

  /** DRIVE */

  /** DRON */
  getDataImagesDron$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getDataImagesDron),
      withLatestFrom(this.store.select(getAreaSelected)),
      mergeMap((action: any) => {
        let { fecha, url } = action[0];
        const area = action[1];
        var body = fecha
          ? {
              date_created__lte: fecha,
              date_created__gte: fecha,
              type: 'orthophoto',
              errors: true,
              bounding_box__isnull: false,
            }
          : { type: 'orthophoto', errors: true, bounding_box__isnull: false };
        const peticion = url
          ? this.toolsService.getByUrl(url, body)
          : this.toolsService.getDataDronImages(area.id, body);
        return peticion.pipe(
          map((result: Pagination) => {
            this.store.dispatch(getDataImagesDronSuccess({ data: result }));
            return setLoadingImages({ status: false });
          }),
          catchError((error) => {
            this.store.dispatch(getDataImagesDronSuccess({ data: null }));
            return of(setLoadingImages({ status: false }));
          }),
        );
      }),
    );
  });

  getDataErrorImagesDron$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getErrorDataImagesDron),
      withLatestFrom(this.store.select(getAreaSelected)),
      mergeMap((action: any) => {
        if (action[1] && action[1].id) {
          return this.toolsService.getDronErrorImages(action[1].id, 'error').pipe(
            map((result: any) => {
              return getErrorDataImagesDronSuccess({ data: result });
            }),
            catchError((error) => {
              return of(getErrorDataImagesDronSuccess({ data: null }));
            }),
          );
        } else {
          return of(noData());
        }
      }),
    );
  });

  removeDronErrorImage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(removeDronErrorImage),
      withLatestFrom(this.store.select(getAreaSelected)),
      mergeMap((action: any) => {
        if (action[0] && action[0].id && action[1] && action[1].id) {
          return this.toolsService.deleteDronErrorImage(action[1].id, action[0].id).pipe(
            map((result: any) => {
              return removeDronErrorImageSuccess({ id: action[0].id });
            }),
            catchError((error) => {
              return of(noData());
            }),
          );
        } else {
          return of(noData());
        }
      }),
    );
  });

  removeDronImages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(removeDronImages),
      mergeMap((action: any) => {
        if (action.ids && action.areaId) {
          let swalRemove: any = Swal.fire({
            title: this.translocoService.translate(
              'mapa.componentes.workspace.componentes.tool_container.tool_template.dron_images.upload_images.removing',
            ),
            allowOutsideClick: false,
            allowEscapeKey: false,
            timerProgressBar: true,
            onOpen: () => {
              Swal.showLoading();
            },
          });
          return this.toolsService.deleteDronImages(action.areaId, action.ids).pipe(
            map((result: any) => {
              swalRemove.close();
              Swal.fire({
                icon: 'success',
                title: this.translocoService.translate(
                  'mapa.componentes.workspace.componentes.tool_container.tool_template.dron_images.upload_images.remove_succesful',
                ),
                showConfirmButton: false,
                timer: 3000,
              });
              return removeDronImagesSuccess();
            }),
            catchError((error) => {
              this.store.dispatch(
                loadAlert({
                  alert: {
                    info: {
                      icon: 'error',
                      title: this.translocoService.translate(
                        'mapa.componentes.workspace.componentes.tool_container.tool_template.dron_images.upload_images.remove_error',
                      ),
                      timer: 3000,
                      showConfirmButton: false,
                    },
                    data: null,
                  },
                }),
              );
              return of(noData());
            }),
          );
        } else {
          this.store.dispatch(
            loadAlert({
              alert: {
                info: {
                  icon: 'error',
                  title: this.translocoService.translate(
                    'mapa.componentes.workspace.componentes.tool_container.tool_template.dron_images.upload_images.remove_disabled',
                  ),
                  timer: 3000,
                  showConfirmButton: false,
                },
                data: null,
              },
            }),
          );
          return of(noData());
        }
      }),
    );
  });

  saveLogUpload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveLogUpload),
      mergeMap((action) => {
        let { data } = action;
        const peticiones = data.map((element) => this.mapaService.saveLogUpload(element));
        return forkJoin(peticiones).pipe(
          map((result) => {
            // se han guardado las imágenes
            this.store.dispatch(
              loadAlert({
                alert: {
                  info: {
                    icon: 'success',
                    title: this.translocoService.translate(
                      'mapa.componentes.workspace.componentes.tool_container.tool_template.dron_images.upload_images.title_confirm_upload',
                    ),
                    text: this.translocoService.translate(
                      'mapa.componentes.workspace.componentes.tool_container.tool_template.dron_images.upload_images.text_confirm_upload',
                    ),
                    showConfirmButton: true,
                  },
                  data: null,
                },
              }),
            );
            this.store.dispatch(saveLogUploadSuccess());
            return setLoadingSpinner({ status: false });
          }),
          catchError((error) => {
            this.store.dispatch(
              loadAlert({
                alert: {
                  info: {
                    icon: 'error',
                    title: this.translocoService.translate(
                      'mapa.componentes.workspace.componentes.tool_container.tool_template.dron_images.upload_images.error_subida',
                    ),
                    timer: 2000,
                    showConfirmButton: false,
                  },
                  data: null,
                },
              }),
            );
            this.store.dispatch(saveLogUploadSuccess());
            return of(setLoadingSpinner({ status: false }));
          }),
        );
      }),
    );
  });

  createUploadProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createUploadProduct),
      mergeMap((action) => {
        if (action.uploadId && action.data && action.areaId) {
          return this.mapaService.createUploadsProducts(action.areaId, action.uploadId, action.data).pipe(
            map((result: any) => {
              return uploadProductSuccess({ uploadProduct: result });
            }),
            catchError((error) => {
              this.store.dispatch(
                loadAlert({
                  alert: {
                    info: {
                      icon: 'error',
                      title: this.translocoService.translate(
                        'mapa.componentes.workspace.componentes.tool_container.tool_template.dron_images.upload_images.error_subida_uploads',
                      ),
                      timer: 3000,
                      showConfirmButton: false,
                    },
                    data: null,
                  },
                }),
              );
              return of(noData());
            }),
          );
        } else {
          return of(noData());
        }
      }),
    );
  });

  editUploadProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(editUploadProduct),
      mergeMap((action) => {
        if (action.uploadId && action.data && action.areaId) {
          return this.mapaService
            .editUploadsProducts(action.areaId, action.uploadId, action.productId, action.data)
            .pipe(
              map((result: any) => {
                return uploadProductSuccess({ uploadProduct: result });
              }),
              catchError((error) => {
                this.store.dispatch(
                  loadAlert({
                    alert: {
                      info: {
                        icon: 'error',
                        title: this.translocoService.translate(
                          'mapa.componentes.workspace.componentes.tool_container.tool_template.dron_images.upload_images.error_subida_uploads',
                        ),
                        timer: 3000,
                        showConfirmButton: false,
                      },
                      data: null,
                    },
                  }),
                );
                return of(noData());
              }),
            );
        } else {
          return of(noData());
        }
      }),
    );
  });

  /** DRIVE */
  uploadChunkData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(uploadChunkData),
      withLatestFrom(this.store.select(getCancelUpload), this.store.select(getPauseUpload)),
      mergeMap((action) => {
        let { files, filesSuccess } = action[0];
        const cancelUpload = action[1];
        const pauseUpload = action[2];
        if (cancelUpload) {
          this.store.dispatch(
            loadAlert({
              alert: {
                info: {
                  icon: 'success',
                  title: 'Subida cancelada',
                  showConfirmButton: true,
                },
                data: null,
              },
            }),
          );
          this.store.dispatch(setCancelUpload({ status: false }));
          this.store.dispatch(setPercentageUpload({ newPercentage: null }));
          return of(uploadChunkDataSuccess({ result: false, filesData: null }));
        }
        if (pauseUpload) return of(uploadChunkDataPause({ files: files, filesSuccess: filesSuccess }));
        var peticiones: any = files?.map((element: any) => {
          let startPointer = element['startPointer'] || 0;
          let endPointer = element['file'].size;
          let newStartPointer = Math.min(startPointer + element['cSize'], endPointer);
          let blob = new Blob([element['file'].slice(startPointer, newStartPointer)], { type: element['type'] });
          return this.driveService
            .putChunkDataResumable(element['url'], blob, startPointer, newStartPointer - 1, endPointer)
            .pipe(
              map((res) => {
                return { response: element, error: false, res: res };
              }),
              catchError((err) => {
                return of({ response: element, error: true, res: err });
              }),
            );
        });

        return forkJoin(peticiones).pipe(
          map((results: any) => {
            let aux_date = new Date(Date.now());
            // quitar el offset por la zona horaria, para así tenerlo normalizado
            aux_date = new Date(aux_date.getTime() + aux_date.getTimezoneOffset() * 60000);
            // string de la fecha
            let string_date = `${aux_date.getFullYear()}-${
              aux_date.getMonth() + 1 < 10 ? '0' + (aux_date.getMonth() + 1) : aux_date.getMonth() + 1
            }-${aux_date.getDate() < 10 ? '0' + aux_date.getDate() : aux_date.getDate()} ${
              aux_date.getHours() < 10 ? '0' + aux_date.getHours() : aux_date.getHours()
            }:${aux_date.getMinutes() < 10 ? '0' + aux_date.getMinutes() : aux_date.getMinutes()}:${
              aux_date.getSeconds() < 10 ? '0' + aux_date.getSeconds() : aux_date.getSeconds()
            }`;

            if (results.every((element: any) => !element.error)) {
              // resultado satisfacctorio
              let filesData: any = results.map((element: any) => {
                return {
                  driveid: element.res['id'],
                  format: element.res['mimeType'],
                  date_upload: string_date,
                  name: element.res['name'],
                };
              });
              if (filesSuccess && filesSuccess.length > 0) filesData = filesData.concat(filesSuccess);
              this.store.dispatch(setPercentageUpload({ newPercentage: 100 }));
              return uploadChunkDataSuccess({
                result: true,
                filesData: filesData,
              });
            } else {
              let percentages: any[] = [];
              // volver a mandar aquellos que han fallado
              var newsFiles = results
                .filter((element: any) => element.error)
                .map((element: any) => {
                  let { response, res }: any = element;
                  // almacenar porcentajes de todos los archivos
                  percentages.push(((response['startPointer'] || 0) + response['cSize']) / response['file'].size);
                  return {
                    ...response,
                    startPointer:
                      res.status === 308
                        ? Math.min((response['startPointer'] || 0) + response['cSize'], response['file'].size)
                        : response['startPointer'],
                  };
                });
              let filesData: any = results
                .filter((element: any) => !element.error)
                .map((element: any) => {
                  return {
                    driveid: element.res['id'],
                    format: element.res['mimeType'],
                    date_upload: string_date,
                    name: element.res['name'],
                  };
                });
              // por si es un error de permisos
              if (results.some((element: any) => element.res.status === 403))
                return uploadChunkDataSuccess({
                  result: true,
                  filesData: filesData,
                });
              if (filesSuccess && filesSuccess.length > 0) filesData = filesData.concat(filesSuccess);
              this.store.dispatch(
                setPercentageUpload({
                  newPercentage:
                    (percentages.reduce((previous, current) => (current += previous)) / percentages.length) * 100,
                }),
              );
              return uploadChunkData({
                files: newsFiles,
                filesSuccess: filesData,
              });
            }
          }),
        );
      }),
    );
  });

  getResumableImages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getResumableImages),
      mergeMap((action: any) => {
        let { files } = action;
        var peticiones = files.map((element: any) => {
          return this.driveService.getResumable(element['files_metadata']).pipe(
            map((res) => res),
            catchError((err) => 'error'),
          );
        });

        return forkJoin(peticiones).pipe(
          map((results: any) => {
            results.forEach((result: any, index: any) => {
              files[index]['url'] = result['headers'].get('location');
            });
            return uploadChunkData({ files: files });
          }),
          catchError((error) => {
            return of(noData());
          }),
        );
      }),
    );
  });

  deleteDriveFiles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteDriveFiles),
      withLatestFrom(this.store.select(receivedDriveFiles)),
      mergeMap((action) => {
        let existent_files = action[1];
        if (existent_files && existent_files.length > 0) {
          let petitions: ObservableInput<any>[] = [];
          existent_files.forEach((element) => {
            if (element.name.includes('edited')) {
              petitions.push(this.driveService.deleteFile(element.id));
            }
          });

          return forkJoin(petitions).pipe(
            map((results: any) => {
              return getResumableImages({ files: action[0].file });
            }),
            catchError((error) => {
              return of(noData());
            }),
          );
        } else {
          return of(getResumableImages({ files: action[0].file }));
        }
      }),
    );
  });

  getFilesDrive$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getFilesDrive),
      mergeMap((action) => {
        let { fileId } = action;
        return this.driveService.getFile(fileId).pipe(
          map((result: any) => {
            return getFilesDriveSuccess({ files: result ? [result] : null });
          }),
          catchError((error) => {
            /** Disable loading */
            this.store.dispatch(
              updateStateVariableTools({
                variable: 'polygonLoading',
                value: false,
              }),
            );
            return of(getFilesDriveSuccess({ files: null }));
          }),
        );
      }),
    );
  });
}
