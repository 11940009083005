export class Laboratorio {
  id: number | undefined;
  fecha: string | undefined;
  edad: number | undefined;
  brix: number | undefined;
  sacarosa: number | undefined;
  reductores: number | undefined;
  humedad: number | undefined;
  pureza: number | undefined;
  jugo: number | undefined;
  maduracion: number | undefined;

  desarrollo: number | undefined;
  fibra: number | undefined;
  floracion: number | undefined;
  identificador: string | undefined;
  indice_mad: number | undefined;
  peso: number | undefined;
  plagas: string | undefined;
  pol: number | undefined;
  programa: number | undefined;
  pureza_jugo: number | undefined;
  semana: number | undefined;
  quema_acc: number | undefined;
  trash: number | undefined;
  fk_parcela_id: number | undefined;

  constructor(
    id?: number,
    fecha?: string,
    edad?: number,
    brix?: number,
    sacarosa?: number,
    reductores?: number,
    humedad?: number,
    pureza?: number,
    jugo?: number,
    maduracion?: number,
    desarrollo?: number,
    fibra?: number,
    floracion?: number,
    identificador?: string,
    indice_mad?: number,
    peso?: number,
    plagas?: string,
    pol?: number,
    programa?: number,
    pureza_jugo?: number,
    semana?: number,
    quema_acc?: number,
    trash?: number,
    fk_parcela_id?: number,
  ) {
    this.id = id;
    this.fecha = fecha;
    this.edad = edad;
    this.brix = brix;
    this.sacarosa = sacarosa;
    this.reductores = reductores;
    this.humedad = humedad;
    this.pureza = pureza;
    this.jugo = jugo;
    this.maduracion = maduracion;
    this.desarrollo = desarrollo;
    this.fibra = fibra;
    this.floracion = floracion;
    this.identificador = identificador;
    this.indice_mad = indice_mad;
    this.peso = peso;
    this.plagas = plagas;
    this.pol = pol;
    this.programa = programa;
    this.pureza_jugo = pureza_jugo;
    this.semana = semana;
    this.quema_acc = quema_acc;
    this.trash = trash;
    this.fk_parcela_id = fk_parcela_id;
  }
}
