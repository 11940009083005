import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyvalueenum',
})
export class KeyvalueenumPipe implements PipeTransform {
  transform(value: any): Array<any> {
    let entries = Object.entries(value);
    return entries.slice(entries.length / 2).map((element) => element[0]);
  }
}
