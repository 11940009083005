import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CapasService {
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  fileToGeometrys(file: any) {
    return this.http.post(`${environment.databaseURL}/rest/geometrys`, file);
  }

  addCapa(idArea: number, body: any) {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/areas/${idArea}/capas`, body, this.httpOptions);
  }

  addCapaGeometries(body: any[]): Observable<JSON> {
    return this.http.post<JSON>(`${environment.databaseURL}/rest/geometrias/bulk_upload`, body, this.httpOptions);
  }

  getCapaGeometries(idCapa: number): Observable<JSON> {
    return this.http.get<JSON>(`${environment.databaseURL}/rest/capas/${idCapa}/geometrias`);
  }

  deleteCapa(idCapa: number) {
    return this.http.delete(`${environment.databaseURL}/rest/capas/${idCapa}`);
  }

  getSourceERA5() {
    return {
      url: `${environment.ip}/geoserver/general/wms`,
      body: {
        crossOrigin: 'anonymous',
        params: {
          LAYERS: `general:estaciones_era5`,
          TILED: true,
        },
        transition: 0,
      },
    };
  }
}
