import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-points',
  templateUrl: './loading-points.component.html',
  styleUrls: ['./loading-points.component.scss'],
})
export class LoadingPointsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
