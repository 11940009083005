import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoCommaPipe } from './NoCommaPipe.pipe';
import { SafePipe } from './safe.pipe';
import { SanitizeHtmlPipe } from './sanitizeHtml.pipe';
import { formatDatepickerDate } from './formatDatepickerDate.pipe';
import { unidadesAgrupacion } from './unidadesAgrupacion.pipe';
import { typeForPipe } from './typeFor.pipe';
import { transformAttributesPipe } from './transformAttributes.pipe';
import { SelectValuesPipe } from './selectValues.pipe';
import { optionsSliderProduct } from './optionsSliderProduct.pipe';
import { PageSizePipe } from './pageSize.pipe';
import { Capitalize } from './capitalize.pipe';
import { FilterColumns } from './filterColumns.pipe';
import { ShowFilterAdmin } from './ShowFilterAdmin.pipe';
import { ObtainKeys } from './obatinKeys.pipe';
import { TypeOf } from './typeof.pipe';
import { PhoneCode } from './phoneCode.pipe';
import { CheckedAttributeFilterPipe } from './checkedAttributeFilter.pipe';
import { KeyvalueenumPipe } from './keyvalueenum.pipe';
import { MonthByNumberPipe } from './monthByNumber.pipe';
import { FilterMarcadoresLength } from './filterMarcadoresLength.pipe';
import { DataFilterMarcadoresPipe } from './DataFilterMarcadores.pipe';

@NgModule({
  declarations: [
    NoCommaPipe,
    SafePipe,
    SanitizeHtmlPipe,
    formatDatepickerDate,
    unidadesAgrupacion,
    typeForPipe,
    transformAttributesPipe,
    SelectValuesPipe,
    optionsSliderProduct,
    PageSizePipe,
    Capitalize,
    FilterColumns,
    ShowFilterAdmin,
    ObtainKeys,
    TypeOf,
    PhoneCode,
    CheckedAttributeFilterPipe,
    KeyvalueenumPipe,
    MonthByNumberPipe,
    FilterMarcadoresLength,
    DataFilterMarcadoresPipe,
  ],
  imports: [CommonModule],
  exports: [
    NoCommaPipe,
    SafePipe,
    SanitizeHtmlPipe,
    formatDatepickerDate,
    unidadesAgrupacion,
    typeForPipe,
    transformAttributesPipe,
    SelectValuesPipe,
    optionsSliderProduct,
    PageSizePipe,
    Capitalize,
    FilterColumns,
    ShowFilterAdmin,
    ObtainKeys,
    TypeOf,
    PhoneCode,
    CheckedAttributeFilterPipe,
    KeyvalueenumPipe,
    CheckedAttributeFilterPipe,
    MonthByNumberPipe,
    FilterMarcadoresLength,
    DataFilterMarcadoresPipe,
  ],
})
export class MainPipe {}
