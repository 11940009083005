export const environment = {
  production: false,
  databaseURL: 'https://ns563777.ip-54-39-156.net:9000',
  ip: 'https://ns563777.ip-54-39-156.net:9521',
  portip: '443',
  powerbi: ['desarrollo', 'template_desarrollo'],
  bigMapsKey: 'ArOZmNAyKYv4SYYCCfVyANUf-ndwyBZ2y4qLfdaZoFDbXQ1PVhgOOm6eXrV9r0Py',
  DRON_RAW_FOLDER: '169bzzFntJQnPsCP6urD3zXLramYxOYa4', // carpeta RAW dron develop
  siteKeyCloudFlare: '0x4AAAAAAAEit2GF1D91ntvE',
};
