export enum PrecosechaSubtitles {
  MUESTREO_GENERAL = `Muestreo General`,
  MUESTREO_PRECOSECHA = `Muestreo de Precosecha`,
  MUESTREO_MALEZAS = `Muestreo de Malezas`,
  MUESTREO_DESPO = `Muestreo de Despoblamiento y Enfermedades`,
  MUESTREO_SUELO = `Muestreo de Suelo`,
}

export enum PrecosechaProducts {
  anomalias = 'anomalias',
  maleza = 'maleza',
}

export const ZINDEX_PRECOSECHA = 14;

export const SUBTITLES = [...Object.values(PrecosechaSubtitles)];

export const dataTranslatePrecosecha = 'mapa.componentes.workspace.componentes.tool_container.tool_template.precosecha';
export const formularioTareasTranslate =
  'mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas';

export interface rango {
  min: number;
  max: number;
  value: number;
}
