<div>
  <h2 class="title-token"><b>{{ data.title | titlecase }}</b></h2>
  <p class="parrafo" *ngIf="data.disabledText">{{ "downloads.parrafo" | transloco }}</p>
  <div style="width: 100%; display: flex; justify-content: center; padding-top: 2em">
    <button class="btn btn-dark" [ngStyle]="{'margin-right': data.verAdmin ? '0.5em' : '0em'}" (click)="backToApp()">
      {{ "downloads.volverApp" | transloco }}
    </button>
    <button class="btn btn-dark" *ngIf="data.verAdmin" style="margin-left: 0.5em" (click)="closeViewDescargas()">
      {{ "downloads.ver_descargas" | transloco }}
    </button>
  </div>
</div>
