import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '@src/app/store/app.state';
import { Observable, Subject } from 'rxjs';
import { countries } from 'countries-list';
import * as telData from 'country-telephone-data';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PhoneCode } from '@src/app/pipes/phoneCode.pipe';
import { registerStatus, registerUser } from '../login/state/auth.actions';
import { take, takeUntil } from 'rxjs/operators';
import { getRegisterStatus, getValidations } from '../login/state/auth.selector';
import { loadAlert, setLoadingSpinner } from '@src/app/store/share/shared.actions';
import { validarEmail } from './validaciones/validarEmail.validator';
import { contieneCaracter } from './validaciones/contieneCaracter.validator';
import { contieneCaracteresEspeciales } from './validaciones/contieneCaracteresEspeciales.validator';
import { contieneMayuscula } from './validaciones/contieneMayuscula.validator';
import { contieneDigito } from './validaciones/contieneDigito.validator';
import { DiscoveryWay } from '@src/app/enumerations/DiscoveryWay.enum';
import { RegisterVerificationComponent } from '../register-verification/register-verification.component';
import { Router } from '@angular/router';
import { getLoading } from '@src/app/store/share/share.selector';
import { UserService } from '@src/app/servicios/user.service';
import { TranslocoService } from '@ngneat/transloco';
import { GoogleTagService } from '@src/app/servicios/googletag.service';

@Component({
  selector: 'app-register-view',
  templateUrl: './register-view.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterViewComponent {
  dialogRef: any;

  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private router: Router,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    const html = document.getElementsByTagName('html')[0];
    html.classList.add('drop-scroll');
  }

  /**
   * Escucha el resultado del registro
   * @param result
   */
  resultRegister(result: any) {
    if (result && result['result']) {
      let dialogRegistro = this.openVerificationPopUp({
        title: this.translocoService.translate(`register.result_register.titulo`),
        subtitle: this.translocoService.translate(`register.result_register.subtitulo`),
        text: this.translocoService.translate(`register.result_register.text1`),
        sendBack: true,
        email: result['data']['email'],
      });
      dialogRegistro.afterClosed().subscribe((result) => {
        this.router.navigateByUrl('/login');
      });
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  /**
   * Función que crea un diálogo de una ventana de info sobre la verificación del usuario
   * @param data datos a pasar al dialogo
   * @returns un objeto del dialogo creado
   */
  openVerificationPopUp(data: any) {
    return this.dialog.open(RegisterVerificationComponent, {
      data: data,
    });
  }

  ngOnDestroy() {
    const html = document.getElementsByTagName('html')[0];
    html.classList.remove('drop-scroll');
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  statusForm: number = 1;
  keysCountries: string[] =
    Object.keys(countries); /* .sort((key1, key2) => countries[key1].name.localeCompare(countries[key2].name)); */
  keysCountriesFilter: string[] =
    Object.keys(countries); /* .sort((key1, key2) => countries[key1].name.localeCompare(countries[key2].name)); */
  countries = countries;
  countryKeySelected: string;
  typePassword: string = 'password';
  phoneFormat: any;
  nombreEmpresa: string;
  paisSelected: string;
  hectareas: number;
  cultivo: string;
  separador: string;
  proveForm: boolean = false;
  validators: any;
  checkForm: boolean = false;
  enumRecomendacion = DiscoveryWay;
  @Output() resultRegister = new EventEmitter<any>();
  showLoading: Observable<boolean>;
  proveUser: boolean = false;

  languages: { spanish: string; english: string; portuguese: string } = {
    spanish: 'español',
    english: 'english',
    portuguese: 'português',
  };

  phoneCodePipe: PhoneCode = new PhoneCode();

  requiredCheck: boolean = false;
  userData: UntypedFormGroup;
  empresaData: UntypedFormGroup;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private translocoService: TranslocoService,
    private store: Store<AppState>,
    private userService: UserService,
    private tagManager: GoogleTagService,
  ) {}

  ngOnInit() {
    this.showLoading = this.store.select(getLoading);
    // formulario user
    this.userData = new UntypedFormGroup({
      usuario: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        contieneCaracter,
        contieneCaracteresEspeciales,
        contieneMayuscula,
        contieneDigito,
      ]),
      email: new UntypedFormControl('', [Validators.required, Validators.email, validarEmail]),
      nombre: new UntypedFormControl('', Validators.required),
      apellidos: new UntypedFormControl('', Validators.required),
      inputFilter: new UntypedFormControl(''),
      language: new UntypedFormControl(null, Validators.required),
      codePhone: new UntypedFormControl('', Validators.required),
      tel: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
    });

    // formulario empresa
    this.empresaData = new UntypedFormGroup({
      nombreEmpresa: new UntypedFormControl('', Validators.required),
      pais: new UntypedFormControl('', Validators.required),
      inputFilter: new UntypedFormControl(''),
      hectareas: new UntypedFormControl('', Validators.required),
      cultivo: new UntypedFormControl('', Validators.required),
      terminos: new UntypedFormControl('', Validators.requiredTrue),
      discovery_way: new UntypedFormControl('', Validators.required),
    });

    this.listenInputFilter('inputFilter', 1);
    this.listenInputFilter('inputFilter', 2);

    this.store
      .select(getValidations)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.validators = value;
      });

    this.store
      .select(getRegisterStatus)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value && value['result'] !== null) {
          value['result']
            ? this.resultRegister.emit({
                result: true,
                data: { email: this.userData.get('email')?.value },
              })
            : this.store.dispatch(
                loadAlert({
                  alert: {
                    info: {
                      icon: 'error',
                      showConfirmButton: true,
                      confirmButtonText: this.translocoService.translate(`register.confirm_btn`),
                      title: this.translocoService.translate(`register.titulo_btn`),
                      text: value['message'] || '',
                    },
                    data: null,
                  },
                }),
              );
          // resetear resultado
          this.store.dispatch(registerStatus({ result: null }));
        }
        this.store.dispatch(setLoadingSpinner({ status: false }));
      });
  }

  /**
   * Escuchar los cambios para cuando la cadena del filtro sea vacía
   * @param name nombre del control
   */
  listenInputFilter(name: string, id: number) {
    let control: AbstractControl | null =
      id === 1 ? this.empresaData.get(name) : id === 2 ? this.userData.get(name) : null;

    if (control) {
      control.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
        if (!value.length) this.keysCountriesFilter = this.keysCountries;
      });
    }
  }
  /**
   * Función que checkea los campos del formulario de usuario
   * @param changeNum numero de formulario(1, 2)
   */
  checkUserForm(changeNum: number) {
    // que estén todos los campos, email correcto y que user y email no estén registrados
    this.userData.valid && this.validators['user'] && this.validators['email']
      ? (this.statusForm = changeNum)
      : this.buttonEffect();
  }

  /**
   * Función que cambia la sección del formulario que se muestra
   * @param changeNum número que especifica la sección del formulario
   */
  changeStatusForm(changeNum: number, form?: UntypedFormGroup) {
    !form
      ? (this.statusForm = changeNum)
      : setTimeout(() => {
          this.checkUserForm(changeNum);
        }, 500);
  }

  /**
   * Función que realiza la llamada a buscar si el usuari existe en el sistema
   * @param label etiqueta del valor a comprobar
   * @param text valr a buscar
   */
  checkValidUser(label: string, event: any) {
    let text: string = event.target.value;
    this.proveUser = true;
    this.userService
      .checkUserAndEmail(JSON.stringify({ [label]: text }))
      .pipe(take(1))
      .subscribe((value) => {
        this.proveUser = false;
        this.validators[label] = value.includes('no');
      });
  }

  /**
   * Activa efecto de error botón
   */
  buttonEffect() {
    // button effect flag
    this.proveForm = true;
    setTimeout(() => {
      this.proveForm = false;
    }, 3000);
  }

  /**
   * Función que filtra los códigos de teléfono
   * @param text texto del código a filtrar
   */
  onKeyCode(event: any) {
    let text: string = event.target.value;
    if (text === '' || text === '+') this.keysCountriesFilter = this.keysCountries;
    else {
      text = text.startsWith('+') ? text.replace('+', '') : text;
      this.keysCountriesFilter = this.keysCountries.filter((element) => countries[element].phone.includes(text));
      // if there are not results for code, searching by name
      if (!this.keysCountriesFilter?.length)
        this.keysCountriesFilter = this.keysCountries.filter((element) =>
          countries[element].name.toLowerCase().includes(text),
        );
    }
  }

  /**
   * Función que filtra los paises
   * @param text texto del código a filtrar
   */
  onKeyCountry(event: any) {
    let text: string = event.target.value;
    this.keysCountriesFilter =
      text === ''
        ? this.keysCountries
        : this.keysCountries.filter((element) => countries[element].name.toLowerCase().includes(text));
  }

  /**
   * Función que resetea el input de búsqueda
   * @param event
   */
  clearKeysCountriesFilter() {
    this.keysCountriesFilter = this.keysCountries;
    this.userData.get('inputFilter')?.reset('');
    this.empresaData.get('inputFilter')?.reset('');
  }

  /**
   * Función que formatea el formato del número del país seleccionado
   * @param event evento que contiene el formato de número del país seleccionado
   */
  changePhoneCode(event: any) {
    this.userData.get('tel')?.enable();
    // poner por defecto el pais seleccionado en el teléfono en el país
    this.empresaData.get('pais')?.reset(countries[event].name);
    let country = telData.allCountries.find((element) => element.iso2 === event.toLowerCase());
    this.phoneFormat = country?.format;
    if (this.phoneFormat) {
      this.separador = country?.format.includes('-') ? '-' : ' ';
      // Se comprueba si existe el formato del país
      if (country?.format.includes(')'))
        this.phoneFormat = country.format
          .split(')')[1]
          .split('')
          .map((element) => (element === '.' ? '5' : element))
          .join('');
      else {
        this.phoneFormat = country?.format.split(this.separador);
        let phoneFormatArray: any = this.phoneFormat;
        phoneFormatArray.shift();
        // añadir formato con números en vez de puntos para mayor visibilidad
        this.phoneFormat = phoneFormatArray
          .join(this.separador)
          .split('')
          .map((element: string) => (element === '.' ? '5' : element))
          .join('');
      }
      // cambiar validador del telefono al formato adecuado
      this.userData
        .get('tel')
        ?.setValidators([
          Validators.required,
          Validators.maxLength(this.phoneFormat.length),
          Validators.minLength(this.phoneFormat.length),
        ]);
      // actualizar control formulario
      this.userData.get('tel')?.updateValueAndValidity();
    }

    this.userData.get('tel')?.reset('');
    this.clearKeysCountriesFilter();
  }

  /**
   * Función que controla la entrada del input telefono
   * @param event evento del input del teléfono
   * @returns boolean: true si es un número y si aún no cumple el formato, false el resto
   */
  controlTel(event: any) {
    return (
      !isNaN(parseInt(event.key)) &&
      (!this.phoneFormat || this.userData.get('tel')?.value.length + 1 <= this.phoneFormat.length)
    );
  }

  /**
   * Función que comprueba la entrada y ajusta el contenido del input al formato
   * @param event evento input del teléfono
   */
  addSeparador() {
    if (this.phoneFormat) {
      let inputTel = this.userData
        .get('tel')
        ?.value.split('')
        .filter((element: string) => element !== this.separador);
      this.userData.get('tel')?.reset(
        this.phoneFormat.split('').reduce((actPhoneFormat: any, element: string) => {
          if (inputTel.length) {
            actPhoneFormat += element === this.separador ? this.separador : inputTel.shift();
          }
          return actPhoneFormat;
        }, ''),
      );
    }
  }

  /**
   * Controlar tamaño del iput de héctareas del registro
   */
  checkHectareas() {
    this.empresaData.controls['hectareas'].value > 999999 && this.empresaData.controls['hectareas'].setValue(999999);
    this.empresaData.controls['hectareas'].value < 0 && this.empresaData.controls['hectareas'].setValue(0);
  }

  /**
   * Función que realiza el registro en la plataforma
   */
  register() {
    // todos los campos rellenados y aceptado terminos a true
    if (this.empresaData.valid) {
      // se realizaría el registro
      // agrupamos datos formularios

      let user = {
        user: this.userData.get('usuario')?.value,
        password: this.userData.get('password')?.value,
        email: this.userData.get('email')?.value,
        nombre: this.userData.get('nombre')?.value,
        apellidos: this.userData.get('apellidos')?.value,
        phone:
          '+' +
          this.userData.get('codePhone')?.value.code +
          this.userData.get('tel')?.value.split(this.separador).join(''),
        empresa: this.empresaData.get('nombreEmpresa')?.value,
        pais: this.empresaData.get('pais')?.value,
        ha_empresa: this.empresaData.get('hectareas')?.value,
        cultivo: this.empresaData.get('cultivo')?.value,
        discovery_way: this.empresaData.get('discovery_way')?.value,
        language: this.userData.get('language')?.value,
      };

      this.store.dispatch(setLoadingSpinner({ status: true }));
      this.store.dispatch(registerUser({ data: user }));
      this.tagManager.sendEvent(
        'register',
        null,
        'register_event',
        'Register',
        null,
        'Botón',
        user,
        null,
        'Ejecución',
        'Activa',
        'Activación',
      );
    } else {
      this.buttonEffect();
      this.requiredCheck = !this.empresaData.get('terminos')?.value;
    }
  }

  /**
   * Función que cierra el diálogo
   */
  closeTool() {
    this.resultRegister.emit({ result: false });
  }

  /** DESTROY */
  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
