import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

const riegos = [
  'sin_riego',
  'con_riego',
  'goteo',
  'gravedad',
  'mecanizado',
  'pivote',
  'pivote_movil',
  'frontal_1',
  'frontal_2',
  'aspersion',
  'compuertas',
  'carrete',
  'parcela',
];

const MILISECONDS = 1000;
const SECONDS = 60;
const MINUTES = 60;
const HOURS = 24;

@Pipe({
  name: 'selectValues',
})
export class SelectValuesPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}
  transform(attribute: any, key: string, fs?: string): string {
    if (key == 'edad' && fs) {
      try {
        // diffDays --> miliseconds
        let diffDays = Math.abs(new Date().getTime() - new Date(fs).getTime());
        // transform miliseconds to days
        return `${attribute} ( ${Math.trunc(
          diffDays / (MILISECONDS * SECONDS * MINUTES * HOURS),
        )} ${this.translocoService.translate('dias')} )`;
      } catch (e) {
        return `${attribute}`;
      }
    }
    let prelabel = '',
      postlabel = attribute;
    switch (key) {
      case 'riego':
        prelabel = 'attributes.parcela.riego_options.';
        postlabel = riegos[attribute];
        break;
      case 'cultivo':
        prelabel = 'admin.cultivos.';
        break;
      case 'operacion':
        prelabel = 'attributes.operaciones.';
        break;
      default:
        break;
    }

    return `${prelabel}${postlabel}`;
  }
}
