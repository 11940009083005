import { Injectable } from '@angular/core';
import { FilterElement } from '../models/filterModels/filters.model';
import { Area } from '../objetos/area';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { Parcela2 } from '../objetos/parcela2';
import { tiposAtributosParcela } from '../enumerations/parcela';
import { tiposAtributosOperacion } from '../enumerations/operacion';
import { Operacion } from '../objetos/operacion';
import { Riego } from '../objetos/riego';
import { tiposAtributosRiego } from '../enumerations/riego';
import { Laboratorio } from '../objetos/laboratorio';
import { tiposAtributosLaboratorio } from '../enumerations/laboratorio';
import { Historico } from '../objetos/historico';
import { tiposAtributosHistorico } from '../enumerations/historico';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor(private store: Store<AppState>) {}

  /**
   * Función que formatea el body para la petición de filtrar parcelas a partir de los filtros seleccionados
   * @param filters Filtros seleccionados en la herramienta
   */
  formatParcelaFilter(
    filters: FilterElement[] | null,
    area: Area,
    zoneSelected: any,
    harvestSelected: any,
    fechaSelected: any,
    userFilter: any,
  ) {
    var body: any = { filtro: {} };
    if (filters || zoneSelected || harvestSelected || userFilter) {
      /** Añadimos todos los filtros del container */
      if (filters) {
        filters.forEach((filtro) => {
          let operation_aux = filtro.operation ? '__' + filtro.operation : '';

          let table_aux = filtro.table != 'filtro' ? filtro.table + '__' : '';

          let string = table_aux + filtro.attribute + operation_aux;

          body.filtro[string] = filtro.value;
        });
      }

      /** Añadimos el area seleccionada */
      if (Object.keys(body).find((el) => el == 'filtro')) {
        body.filtro.fk_area = area.id;
      } else {
        body.filtro = {
          fk_area: area.id,
        };
      }

      /** Añadimos los filtros de zona y zafra que puedan estar seleccionados en el submenu */
      if (zoneSelected != null) {
        body.filtro.zonas__in = [zoneSelected];
      }

      if (harvestSelected != null || (filters && filters.find((el) => el.table == 'historicos'))) {
        body.filtro.zafra__in = harvestSelected ? [harvestSelected] : undefined;
      } else {
        body.filtro.activo = body.filtro.activo !== undefined ? body.filtro.activo : true;
      }

      if (fechaSelected && filters && filters.find((el) => el.attribute == 'stats__mean')) {
        body.filtro['stats__fk_fecha'] = fechaSelected.id;
      }

      // Añadimos los filtros del usuario
      if (userFilter) {
        Object.keys(userFilter).forEach((element) => {
          if (body.filtro[element]) {
            body.filtro[element].concat(userFilter[element]);
          } else {
            body.filtro[element] = userFilter[element];
          }
        });
      }

      // Que solo devuelva el atributo idnax
      body.columns = ['idnax', 'fs', 'id'];
    } else {
      body = null;
    }
    return body;
  }

  /**
   * Get columns and type of object of operation label
   * @param operacion
   * @returns
   */
  getTipoColumnas(operacion: string): { columnas: string[]; tipo: any } {
    let columnas: string[] = [];
    let tipo: any;

    switch (operacion) {
      case 'Parcela':
        columnas = Object.keys(new Parcela2());
        tipo = tiposAtributosParcela;
        break;
      case 'Operaciones':
        columnas = Object.keys(new Operacion()).filter(
          (columna) => columna != 'comentario' && columna != 'descripcion',
        );
        tipo = tiposAtributosOperacion;
        break;
      case 'Riegos':
        columnas = Object.keys(new Riego());
        tipo = tiposAtributosRiego;
        break;
      case 'Laboratorio':
        columnas = Object.keys(new Laboratorio());
        tipo = tiposAtributosLaboratorio;
        break;
      case 'Historicos':
        columnas = Object.keys(new Historico());
        tipo = tiposAtributosHistorico;
        break;
      default:
        break;
    }
    return {
      columnas: columnas,
      tipo: tipo,
    };
  }
}
