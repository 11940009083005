import { Pagination } from '@src/app/interfaces/Admin/pagination';
import { Capa } from '@src/app/interfaces/Mapa/tools/capas';
import { Tool } from '@src/app/models/tool.model';
import { VideoData } from '../tools-template/videos/videos.component';

interface attributeTable {
  name: string | null;
  type: string;
  checked: boolean;
}

interface saveVariableMap {
  attributes: Array<attributeTable>;
  selected: Array<any>;
  downloadDisabled: boolean;
  masterChecked: boolean;
  indeterminate: boolean;
}

export interface toolContainerState {
  /** HERRAMIENTAS */
  activo: boolean;
  herramientas: Tool[];
  herramientaActiva: Tool;

  /** CAPAS */
  capas: Capa[];
  addLayerFeatures: number;
  addCapaTitle: string;

  /** MAPA VARIABLE */
  downloadingMapaVariable: boolean;
  dialogState: saveVariableMap;
  rangesState: Array<Object>;

  /** PRECOSECHA */
  marcadores: any[];
  marcadorSelected: any;

  /** VIDEOS */
  videosData: Array<VideoData>;

  /** TAREAS */
  tareaData: {
    tarea: any;
    marcadores: any[];
    id: number;
    parcelas: number[];
  };
  tareaRouted: any;

  /** POLYGONS */
  polygonsError: string[];
  polygonLoading: boolean;

  percentageUpload: number;
  savingTarea: boolean;

  /** DRON */
  paginationImages: Pagination | null;
  errorImages: Pagination | null;
  loadingImages: boolean;
  cancelUpload: boolean;
  pauseUpload: boolean;
  driveFiles: any[] | null;
  driveGeojson: any | null;
}

export const initialStateToolcontainer: toolContainerState = {
  /** HERRAMIENTAS */

  activo: false,
  herramientas: [
    {
      icon: 'video_library',
      title: 'videos',
      description: 'Visualizador de tutoriales, webinars y otros recursos de ayuda.',
      enabled: true,
      active: false,
      type: 1,
      badge: false,
      badge_text: null,
    },
    {
      icon: 'square_foot',
      title: 'medicion',
      description: 'Medir áreas, longitudes y perímetros sobre el mapa.',
      enabled: false,
      condicion: ['area'],
      active: false,
      type: 0,
      badge: false,
      badge_text: null,
    },
    {
      icon: 'create',
      title: 'crearPoligono',
      description: 'Añadir y eliminar polígonos del mapa dibujando o mediante archivos (SHP y KML).',
      enabled: false,
      condicion: ['area', 'user'],
      active: false,
      type: 1,
      badge: false,
      badge_text: null,
    },
    {
      icon: 'map',
      title: 'mapaVariable',
      description: 'Generación de un mapa variable en formato tiff a partir de productos y parcelas.',
      enabled: false,
      condicion: ['producto'],
      active: false,
      type: 1,
      badge: false,
      badge_text: null,
    },
    {
      icon: 'get_app',
      title: 'descargas',
      description: 'Descarga de imágenes, datos de productos, datos climatológicos y shapes.',
      enabled: false,
      condicion: ['producto'],
      active: false,
      type: 1,
      badge: false,
      badge_text: null,
    },
    {
      icon: 'assignment',
      title: 'tareas',
      description: 'Generar tareas con marcadores georeferenciados sobre las parcelas.',
      enabled: false,
      condicion: ['area'],
      active: false,
      type: 1,
      badge: false,
      badge_text: null,
    },
    {
      icon: 'spa',
      title: 'muestreo',
      description: 'Generación de puntos de muestreo inteligente a partir del valor de los productos seleccionados.',
      enabled: false,
      condicion: ['producto'],
      active: false,
      type: 1,
      badge: false,
      badge_text: null,
    },
    {
      icon: 'auto_awesome_motion',
      title: 'capas',
      description: 'Visualizador de capas externas (SHP y KML). Permite ocultar el mapa de fondo.',
      enabled: false,
      condicion: ['area'],
      active: false,
      type: 1,
      badge: false,
      badge_text: null,
    },
    {
      icon: 'satellite',
      title: 'dron_images',
      description: 'Visualizador de ortofotos y generación de imágenes de resiembra.',
      enabled: false,
      condicion: ['area', 'cliente9'],
      active: false,
      type: 1,
      badge: true,
      badge_text: 'beta',
    },
    //{ icon: 'join_full', title: 'compare_shape', description: 'Sustitución del shape del área completo.', enabled: false, condicion: ['area','user'], active: false, type: 1, badge: true, badge_text: 'beta'}
  ],
  herramientaActiva: null as any,

  /** CAPAS */
  capas: null as any,
  addLayerFeatures: null as any,
  addCapaTitle: null as any,

  /** MAPA VARIABLE */
  downloadingMapaVariable: false,
  dialogState: {
    attributes: [{ name: 'quantity', type: 'number', checked: false }],
    selected: [],
    downloadDisabled: true,
    masterChecked: false,
    indeterminate: false,
  },
  rangesState: [],

  /** PRECOSECHA */
  marcadores: null as any,
  marcadorSelected: null as any,

  /** VIDEOS */
  videosData: [],
  /** TAREAS */
  tareaData: {
    tarea: null,
    marcadores: null as any,
    id: null as any,
    parcelas: null as any,
  },
  tareaRouted: null,

  /** POLYGONS */
  polygonsError: null as any,
  polygonLoading: false,

  percentageUpload: 0,
  savingTarea: false,

  /** DRON */
  paginationImages: null,
  errorImages: null,
  loadingImages: false,
  cancelUpload: false,
  pauseUpload: false,
  driveFiles: null,
  driveGeojson: null,
};
