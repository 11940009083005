import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'optionsSliderProduct',
})
export class optionsSliderProduct implements PipeTransform {
  transform(options: Object): any {
    if (!options['stepsArray']) {
      let [floor, ceil] = Object.values(options);
      let diferencia = floor - ceil;
      let absoluto = Math.abs(diferencia);
      let step: number = absoluto <= 0.1 ? 0.001 : absoluto < 1 ? 0.01 : absoluto < 10 ? 0.1 : 1;
      return {
        floor: floor,
        ceil: ceil,
        step: step,
      };
    }
    return options;
  }
}
