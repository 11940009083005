import { ActionReducerMap } from '@ngrx/store';
import { UserReducer } from '../@pages/inicio/state/user.reducer';
import { USER_STATE_NAME } from '../@pages/inicio/state/user.selector';
import { UserState } from '../@pages/inicio/state/user.state';
import { AuthReducer } from '../@pages/login/state/auth.reducer';
import { AUTH_STATE_NAME } from '../@pages/login/state/auth.selector';
import { AuthState } from '../@pages/login/state/auth.state';
import { NavigationReducer } from '../@pages/navigation/state/navigation.reducer';
import { NAVIGATION_STATE_NAME } from '../@pages/navigation/state/navigation.selector';
import { NavigationState } from '../@pages/navigation/state/navigation.state';
import { RecoverPasswordReducer } from '../@pages/recover-password/state/recover-password.reducer';
import { RECOVER_PASSWORD_STATE_NAME } from '../@pages/recover-password/state/recover-password.selector';
import { RecoverPasswordState } from '../@pages/recover-password/state/recover-password.state';

import { SHARED_STATE_NAME } from './share/share.selector';
import { SharedReducer } from './share/shared.reducer';
import { SharedState } from './share/shared.state';

export interface AppState {
  [SHARED_STATE_NAME]: SharedState;
  [USER_STATE_NAME]: UserState;
  [AUTH_STATE_NAME]: AuthState;
  [NAVIGATION_STATE_NAME]: NavigationState;
  [RECOVER_PASSWORD_STATE_NAME]: RecoverPasswordState;
}

export const appReducer = {
  [SHARED_STATE_NAME]: SharedReducer,
  [USER_STATE_NAME]: UserReducer,
  [AUTH_STATE_NAME]: AuthReducer,
  [NAVIGATION_STATE_NAME]: NavigationReducer,
  [RECOVER_PASSWORD_STATE_NAME]: RecoverPasswordReducer,
};
