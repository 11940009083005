import { optionColor, optionEjex, optionEjey, optionName, optionTable, optionType } from '@src/app/enumerations/curve';
import { curveOptions } from '@src/app/models/curveModels/curve.model';

//interfaz de los datos con los que voy a trabajar, se guarda en el store.
export interface CurveState {
  selectedScreen: number;
  curveOptions: curveOptions;
  curveLegend: any[];
  curveInfo: any;
  cloudCoverage: number;
  curveArray: any[] | null;
  configurationCurve: { label: string; value: any } | null;
  points2delete: any[] | null;
  deletePoints: boolean;
}

const optionNames = optionName;
const optionTables = optionTable;
const optionEjexs = optionEjex;
const optionEjeys = optionEjey;
const optionColors = optionColor;
const optionTypes = optionType;
export const config_options = {
  climaOptions: [
    {
      check: false,
      name: optionNames.TEMPERATURA_MIN.valueOf(),
      table: optionTables.CLIMA,
      ejex: optionEjexs.CLIMA,
      ejey: optionEjeys.TMIN,
      attributes: false,
      color: optionColors.TEMPERATURA_MIN,
      type: optionTypes.TEMPERATURA_MIN,
    },
    {
      check: false,
      name: optionNames.TEMPERATURA_MED.valueOf(),
      table: optionTables.CLIMA,
      ejex: optionEjexs.CLIMA,
      ejey: optionEjeys.TMED,
      attributes: false,
      color: optionColors.TEMPERATURA_MED,
      type: optionTypes.TEMPERATURA_MED,
    },
    {
      check: false,
      name: optionNames.TEMPERATURA_MAX.valueOf(),
      table: optionTables.CLIMA,
      ejex: optionEjexs.CLIMA,
      ejey: optionEjeys.TMAX,
      attributes: false,
      color: optionColors.TEMPERATURA_MAX,
      type: optionTypes.TEMPERATURA_MAX,
    },
    {
      check: false,
      name: optionNames.TEMPERATURA_MIN_LOCAL.valueOf(),
      table: optionTables.ESTACIONES,
      ejex: optionEjexs.ESTACIONES,
      ejey: optionEjeys.TMIN_LOCAL,
      attributes: false,
      color: optionColors.TEMPERATURA_MIN_LOCAL,
      type: optionTypes.TEMPERATURA_MIN_LOCAL,
    },
    {
      check: false,
      name: optionNames.TEMPERATURA_MED_LOCAL.valueOf(),
      table: optionTables.ESTACIONES,
      ejex: optionEjexs.ESTACIONES,
      ejey: optionEjeys.TMED_LOCAL,
      attributes: false,
      color: optionColors.TEMPERATURA_MED_LOCAL,
      type: optionTypes.TEMPERATURA_MED_LOCAL,
    },
    {
      check: false,
      name: optionNames.TEMPERATURA_MAX_LOCAL.valueOf(),
      table: optionTables.ESTACIONES,
      ejex: optionEjexs.ESTACIONES,
      ejey: optionEjeys.TMAX_LOCAL,
      attributes: false,
      color: optionColors.TEMPERATURA_MAX_LOCAL,
      type: optionTypes.TEMPERATURA_MAX_LOCAL,
    },
    {
      check: false,
      name: optionNames.PRECIPITACION.valueOf(),
      table: optionTables.CLIMA,
      ejex: optionEjexs.CLIMA,
      ejey: optionEjeys.PRECIPITACION,
      attributes: false,
      color: optionColors.PRECIPITACION,
      type: optionTypes.PRECIPITACION,
    },
    {
      check: false,
      name: optionNames.RADIACION.valueOf(),
      table: optionTables.CLIMA,
      ejex: optionEjexs.CLIMA,
      ejey: optionEjeys.RADIACION,
      attributes: false,
      color: optionColors.RADIACION,
      type: optionTypes.RADIACION,
    },
    {
      check: false,
      name: optionNames.PRECIPITACION_LOCAL.valueOf(),
      table: optionTables.ESTACIONES,
      ejex: optionEjexs.ESTACIONES,
      ejey: optionEjeys.PRECIPITACION_LOCAL,
      attributes: false,
      color: optionColors.PRECIPITACION_LOCAL,
      type: optionTypes.PRECIPITACION_LOCAL,
    },
    {
      check: false,
      name: optionNames.RADIACION_LOCAL.valueOf(),
      table: optionTables.ESTACIONES,
      ejex: optionEjexs.ESTACIONES,
      ejey: optionEjeys.RADIACION_LOCAL,
      attributes: false,
      color: optionColors.RADIACION_LOCAL,
      type: optionTypes.RADIACION_LOCAL,
    },
    {
      check: false,
      name: optionNames.EVAPOTRANSPIRACION_LOCAL.valueOf(),
      table: optionTables.ESTACIONES,
      ejex: optionEjexs.ESTACIONES,
      ejey: optionEjeys.EVAPOTRANSPIRACION_LOCAL,
      attributes: false,
      color: optionColors.EVAPOTRANSPIRACION_LOCAL,
      type: optionTypes.EVAPOTRANSPIRACION_LOCAL,
    },
    {
      check: false,
      name: optionNames.EVAPOTRANSPIRACION.valueOf(),
      table: optionTables.CLIMA,
      ejex: optionEjexs.CLIMA,
      ejey: optionEjeys.EVAPOTRANSPIRACION,
      attributes: false,
      color: optionColors.EVAPOTRANSPIRACION,
      type: optionTypes.EVAPOTRANSPIRACION,
    },
  ],
  operationOptions: [
    {
      check: false,
      name: optionNames.RIEGOS.valueOf(),
      table: optionTables.RIEGOS,
      ejex: optionEjexs.RIEGOS,
      ejey: optionEjeys.RIEGOS,
      attributes: true,
      color: optionColors.RIEGOS,
      type: optionTypes.RIEGOS,
    },
    {
      check: false,
      name: optionNames.FERTILIZACION.valueOf(),
      table: optionTables.OPERACIONES,
      ejex: optionEjexs.OPERACIONES,
      ejey: optionEjeys.OPERACIONES,
      attributes: true,
      color: optionColors.FERTILIZACION,
      type: optionTypes.FERTILIZACION,
    },
    {
      check: false,
      name: optionNames.MADURANTE.valueOf(),
      table: optionTables.OPERACIONES,
      ejex: optionEjexs.OPERACIONES,
      ejey: optionEjeys.OPERACIONES,
      attributes: true,
      color: optionColors.MADURANTE,
      type: optionTypes.MADURANTE,
    },
    {
      check: false,
      name: optionNames.POTASIO.valueOf(),
      table: optionTables.OPERACIONES,
      ejex: optionEjexs.OPERACIONES,
      ejey: optionEjeys.OPERACIONES,
      attributes: true,
      color: optionColors.POTASIO,
      type: optionTypes.POTASIO,
    },
    {
      check: false,
      name: optionNames.HERBICIDAS.valueOf(),
      table: optionTables.OPERACIONES,
      ejex: optionEjexs.OPERACIONES,
      ejey: optionEjeys.OPERACIONES,
      attributes: true,
      color: optionColors.HERBICIDAS,
      type: optionTypes.HERBICIDAS,
    },
    {
      check: false,
      name: optionNames.FUNGICIDAS.valueOf(),
      table: optionTables.OPERACIONES,
      ejex: optionEjexs.OPERACIONES,
      ejey: optionEjeys.OPERACIONES,
      attributes: true,
      color: optionColors.FUNGICIDAS,
      type: optionTypes.FUNGICIDAS,
    },
    {
      check: false,
      name: optionNames.INSECTICIDAS.valueOf(),
      table: optionTables.OPERACIONES,
      ejex: optionEjexs.OPERACIONES,
      ejey: optionEjeys.OPERACIONES,
      attributes: true,
      color: optionColors.INSECTICIDAS,
      type: optionTypes.INSECTICIDAS,
    },
    {
      check: false,
      name: optionNames.ESCARIFICADO.valueOf(),
      table: optionTables.OPERACIONES,
      ejex: optionEjexs.OPERACIONES,
      ejey: optionEjeys.OPERACIONES,
      attributes: true,
      color: optionColors.ESCARIFICADO,
      type: optionTypes.ESCARIFICADO,
    },
    {
      check: false,
      name: optionNames.ALTURA.valueOf(),
      table: optionTables.OPERACIONES,
      ejex: optionEjexs.OPERACIONES,
      ejey: optionEjeys.OPERACIONES,
      attributes: true,
      color: optionColors.ALTURA,
      type: optionTypes.ALTURA,
    },
    {
      check: false,
      name: optionNames.OTROS.valueOf(),
      table: optionTables.OPERACIONES,
      ejex: optionEjexs.OPERACIONES,
      ejey: optionEjeys.OPERACIONES,
      attributes: true,
      color: optionColors.OTROS,
      type: optionTypes.OTROS,
    },
  ],
  labOptions: [
    {
      check: false,
      name: optionNames.BRIX.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.BRIX,
      attributes: false,
      color: optionColors.BRIX,
      type: optionTypes.BRIX,
    },
    {
      check: false,
      name: optionNames.JUGO.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.JUGO,
      attributes: false,
      color: optionColors.JUGO,
      type: optionTypes.JUGO,
    },
    {
      check: false,
      name: optionNames.PUREZA_JUGO.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.PUREZA_JUGO,
      attributes: false,
      color: optionColors.PUREZA_JUGO,
      type: optionTypes.PUREZA_JUGO,
    },
    {
      check: false,
      name: optionNames.MADURACION.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.MADURACION,
      attributes: false,
      color: optionColors.MADURACION,
      type: optionTypes.MADURACION,
    },
    {
      check: false,
      name: optionNames.HUMEDAD.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.HUMEDAD,
      attributes: false,
      color: optionColors.HUMEDAD,
      type: optionTypes.HUMEDAD,
    },
    {
      check: false,
      name: optionNames.POL.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.POL,
      attributes: false,
      color: optionColors.POL,
      type: optionTypes.POL,
    },
    {
      check: false,
      name: optionNames.PESO.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.PESO,
      attributes: false,
      color: optionColors.PESO,
      type: optionTypes.PESO,
    },
    {
      check: false,
      name: optionNames.SACAROSA.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.SACAROSA,
      attributes: false,
      color: optionColors.SACAROSA,
      type: optionTypes.SACAROSA,
    },
    {
      check: false,
      name: optionNames.REDUCTORES.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.REDUCTORES,
      attributes: false,
      color: optionColors.REDUCTORES,
      type: optionTypes.REDUCTORES,
    },
    {
      check: false,
      name: optionNames.PUREZA.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.PUREZA,
      attributes: false,
      color: optionColors.PUREZA,
      type: optionTypes.PUREZA,
    },
    {
      check: false,
      name: optionNames.TRASH.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.TRASH,
      attributes: false,
      color: optionColors.TRASH,
      type: optionTypes.TRASH,
    },
    {
      check: false,
      name: optionNames.FIBRA.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.FIBRA,
      attributes: false,
      color: optionColors.FIBRA,
      type: optionTypes.FIBRA,
    },
    {
      check: false,
      name: optionNames.DESARROLLO.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.DESARROLLO,
      attributes: false,
      color: optionColors.DESARROLLO,
      type: optionTypes.DESARROLLO,
    },
    {
      check: false,
      name: optionNames.INDICE_MAD.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.INDICE_MAD,
      attributes: false,
      color: optionColors.INDICE_MAD,
      type: optionTypes.INDICE_MAD,
    },
    {
      check: false,
      name: optionNames.SEMANA.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.SEMANA,
      attributes: false,
      color: optionColors.SEMANA,
      type: optionTypes.SEMANA,
    },
    {
      check: false,
      name: optionNames.QUEMA_ACC.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.QUEMA_ACC,
      attributes: false,
      color: optionColors.QUEMA_ACC,
      type: optionTypes.QUEMA_ACC,
    },
    {
      check: false,
      name: optionNames.FLORACION.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.FLORACION,
      attributes: false,
      color: optionColors.FLORACION,
      type: optionTypes.FLORACION,
    },
    {
      check: false,
      name: optionNames.HUMEDAD_SUELO.valueOf(),
      table: optionTables.LABORATORIO,
      ejex: optionEjexs.LABORATORIO,
      ejey: optionNames.HUMEDAD_SUELO,
      attributes: false,
      color: optionColors.HUMEDAD_SUELO,
      type: optionTypes.HUMEDAD_SUELO,
    },
  ],
};

// se inicializa la interfaz con valores nulos con todos los datos con los que quieres trabajar
export const initialStateCurve: CurveState = {
  selectedScreen: 1,
  curveOptions: JSON.parse(JSON.stringify(config_options)),
  curveLegend: [],
  curveInfo: null,
  cloudCoverage: 60,
  configurationCurve: null,
  curveArray: null,
  points2delete: null,
  deletePoints: false,
};
