import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DriveService {
  // falta id folder que no funciona por el tamaño
  public MIME_TYPE = 'application/vnd.google-apps.folder';

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'multipart/mixed' }),
  };

  constructor(private http: HttpClient) {}

  getTokenDrive(): Observable<string> {
    return this.http.get<string>(`${environment.databaseURL}/rest/getTokenGoogleDrive`);
  }

  createFolderTemp(metadata: any) {
    return this.http.post('https://www.googleapis.com/drive/v3/files', metadata);
  }

  getFile(fileId: string) {
    return this.http.get(`https://www.googleapis.com/drive/v3/files/${fileId}`);
  }

  searchFiles(search?: string) {
    return this.http.get(`https://www.googleapis.com/drive/v3/files${search ? '?q=' + search : ''}`);
  }

  getDownloadLink(id: string) {
    return this.http.get(`https://www.googleapis.com/drive/v3/files/${id}?alt=media`);
  }

  deleteFile(id: string): Observable<any> {
    return this.http.delete(`https://www.googleapis.com/drive/v3/files/${id}`);
  }

  editFile(id: string, body: any): Observable<any> {
    return this.http.put(`https://www.googleapis.com/drive/v3/files/${id}?uploadType=multipart`, body, {
      observe: 'response',
    });
  }

  getResumable(data: any) {
    return this.http.post('https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable', data, {
      observe: 'response',
    });
  }

  createFile(url: string, data: { size: any }) {
    const httpImageOptions = {
      headers: new HttpHeaders({
        'Content-Length': data.size,
        'X-Upload-Content-Length': data.size,
      }),
      observe: 'response' as const,
    };
    return this.http.post(url, data, httpImageOptions);
  }

  putChunkDataResumable(url: string, chunkData: Blob, start: any, end: number, size: any) {
    const httpImageOptions = {
      headers: new HttpHeaders({
        'Content-Range': `bytes ${start}-${end}/${size}`,
      }),
    };
    return this.http.put(url, chunkData, httpImageOptions);
  }
}
