import { Notification } from '@src/app/models/notification.model';
export interface NotificationsState {
  notifications: Notification[];
  lenghtNoCheck: number;
}

export const initialStateNotifications: NotificationsState = {
  notifications: [],
  lenghtNoCheck: 0,
};
