import { Injectable } from '@angular/core';
import { Area } from '../objetos/area';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AreaService {
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  public httpOptionsXML = {
    headers: new HttpHeaders({ 'Content-Type': 'application/xml' }),
  };
  public httpImageOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'image/jpeg' }),
  };

  constructor(private http: HttpClient) {}

  /** GET areas from cliente */
  getAreas(cliente: number): Observable<Area[]> {
    return this.http.get<Area[]>(`${environment.databaseURL}/rest/clientes/${cliente}/areas`);
  }

  getUniquesGeneral(area: number | number[], datos: Object | string): Observable<any> {
    let body: Object = typeof datos === 'string' ? JSON.parse(datos) : datos;
    body = {
      ...body,
      fk_area__in: typeof area === 'number' ? [area] : area,
    };
    //return this.http.post<any>(`${environment.databaseURL}/rest/areas/${area}/uniques`,datos,this.httpOptions);
    return this.http.post<any>(`${environment.databaseURL}/rest/uniques`, body, this.httpOptions);
  }
}
