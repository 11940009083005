import { Area } from '@src/app/objetos/area';

export interface NavigationState {
  areas_cliente: Area[] | null;
}
/**
 * Initial state
 */
export const initialStateNavigation: NavigationState = {
  areas_cliente: null,
};
