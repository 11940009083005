/* 
1: tipo number -> slider
2: tipo date -> slider date
3: tipo string -> checkbox 
4: tipo boolean -> checkbox boolean 
5: nada
*/

export enum tiposAtributosLaboratorio {
  id = 1,
  fecha = 2,
  edad = 1,
  brix = 1,
  sacarosa = 1,
  reductores = 1,
  humedad = 1,
  pureza = 1,
  jugo = 1,
  maduracion = 1,
  desarrollo = 1,
  fibra = 1,
  floracion = 1,
  identificador = 3,
  indice_mad = 1,
  peso = 1,
  plagas = 3,
  pol = 1,
  programa = 1,
  pureza_jugo = 1,
  semana = 1,
  quema_acc = 1,
  trash = 1,
  fk_parcela_id = 3,
}
