import { Injectable } from '@angular/core';
import { Area } from '../objetos/area';
import { Usuario } from '../objetos/usuario';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagService {
  constructor() {}

  addToDataLayer(data: Usuario | null) {
    window['dataLayer'].push({
      event: data ? 'login' : 'logout',
      user: data ? this.formatUserData(data) : undefined,
      cliente: data ? this.formatClientData(data.fk_cliente) : undefined,
    });
  }

  formatUserData(data: Usuario) {
    return {
      id: data.id,
      user: data.user,
      superuser: data.superuser,
      ver_admin: data.ver_admin,
    };
  }

  formatAreaData(data: Area) {
    return {
      //id: data.id,
      nombre: data.nombre,
      cultivo: data.cultivo,
    };
  }

  formatClientData(data: any) {
    return {
      id: data.id,
      workspace: data.workspace,
      empresa: data.empresa,
      alta_freq: data.alta_freq,
    };
  }

  sendEvent(
    event: string,
    area_selected: Area | null,
    id: string,
    container: string,
    subcontainer: string | null,
    element: string,
    content: string | Object,
    position: number | null,
    category: string,
    subcategory: string,
    action: string,
  ) {
    // Add area data
    if (area_selected) {
      window['dataLayer'].push({
        event: 'data',
        area: this.formatAreaData(area_selected),
      });
    } else {
      window['dataLayer'].push({
        event: 'delete',
        area: undefined,
      });
    }

    // Send measure event
    window['dataLayer'].push({
      event: event,
      engage: {
        id: id,
        container: container,
        subcontainer: subcontainer,
        element: element,
        content: event == 'register' ? content['user'] : content,
        position: position,
        action: action,
      },
      navigation: {
        category: category,
        subcategory: subcategory,
        family: null,
      },
      register: event == 'register' ? content : undefined,
    });

    // Delete info saved in data layer
    window['dataLayer'].push({
      event: 'delete',
      engage: undefined,
      navigation: undefined,
      register: undefined,
    });
  }
}
