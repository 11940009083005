import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'obtainKeys',
})
export class ObtainKeys implements PipeTransform {
  transform(obj: Object, oneNotError?: boolean): any {
    return oneNotError ? Object.keys(obj).find((element) => element !== 'error') : Object.keys(obj);
  }
}
