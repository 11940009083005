import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DownloadsService } from '@src/app/servicios/downloads.service';
import { AppState } from '@src/app/store/app.state';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { deleteDownloads, getDownloads, getDownloadsSuccess } from './downloads.actions';
import { getDataDownloads } from './downloads.selector';

@Injectable()
export class DownloadsEffects {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private downloadsService: DownloadsService,
    private snackbar: MatSnackBar,
  ) {}

  getDownloads$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getDownloads),
      withLatestFrom(this.store.select(getDataDownloads)),
      exhaustMap((actions) => {
        let [action, downloads] = actions;
        return this.downloadsService.getDownloads().pipe(
          map((data) => getDownloadsSuccess({ data: data })),
          catchError((error) =>
            of(
              getDownloadsSuccess({
                data: JSON.parse(JSON.stringify([...[downloads]])),
              }),
            ),
          ),
        );
      }),
    );
  });

  deleteDownloads$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteDownloads),
      withLatestFrom(this.store.select(getDataDownloads)),
      exhaustMap((action) => {
        const [{ id }, downloads] = action;
        return this.downloadsService.deleteDownload(id).pipe(
          map((data) => {
            this.snackbar.open('Descarga eliminada correctamente', '', {
              duration: 4000,
            });
            return getDownloads();
          }),
          catchError((err) => {
            this.snackbar.open('Error al eliminar la descarga', '', {
              duration: 4000,
              panelClass: 'danger-snackbar',
            });
            return of(
              getDownloadsSuccess({
                data: JSON.parse(JSON.stringify([...[downloads]])),
              }),
            );
          }),
        );
      }),
    );
  });
}
