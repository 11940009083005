import { createAction, props } from '@ngrx/store';
import { Fecha } from '@src/app/objetos/fecha';

// * Edit showHistogram
export const SHOW_HISTOGRAM = '[histogram page] showHistogram';

export const showHistogram = createAction(SHOW_HISTOGRAM, props<{ value: boolean }>());

// * Edit screen selected
export const CHANGE_SCREEN = '[histogram page] change screen';

export const changeScreen = createAction(CHANGE_SCREEN, props<{ value: number }>());

// * Load histogram
export const LOAD_HISTOGRAM = '[histogram page] load histogram';
export const LOAD_HISTOGRAM_SUCCESS = '[histogram page] load histogram success';

export const loadHistogram = createAction(
  LOAD_HISTOGRAM,
  props<{ compare: boolean; filter: boolean; date?: Fecha | null }>(),
);
export const loadHistogramSucess = createAction(LOAD_HISTOGRAM_SUCCESS, props<{ data: any; compare: boolean }>());

export const LOAD_AVERAGE_HISTOGRAM = '[histogram page] load average histogram';
export const loadAverageHistogram = createAction(LOAD_AVERAGE_HISTOGRAM);

// * Save compare date
export const LOAD_HISTOGRAM_COMPARE_DATE = '[histogram page] load histogram compare date';

export const loadHistogramCompareDate = createAction(
  LOAD_HISTOGRAM_COMPARE_DATE,
  props<{ date: Fecha | null | undefined }>(),
);

// * Edit configuration histogram
export const LOAD_HISTOGRAM_INFO = '[histogram page] load histogram info';

export const loadHistogramInfo = createAction(LOAD_HISTOGRAM_INFO, props<{ info: any; key: string }>());

// * Load histogram colors
export const LOAD_HISTOGRAM_COLORS = '[histogram page] load histogram colors';

export const loadHistogramColors = createAction(LOAD_HISTOGRAM_COLORS, props<{ data: any[] }>());

// * Download histogram
export const DOWNLOAD_HISTOGRAM = '[histogram page] download histogram ';

export const downloadHistogram = createAction(DOWNLOAD_HISTOGRAM);

export const RESET_HISTOGRAM_STATE = '[histogram page] reset histogram state ';

export const resetHistogramState = createAction(RESET_HISTOGRAM_STATE);

export const SET_POLYGON_HISTOGRAM = '[histogram page] set polygon histogram';
export const setPolygonHistogram = createAction(SET_POLYGON_HISTOGRAM, props<{ polygon: string | null }>());

export const SET_MEDIA_HISTOGRAM = '[histogram page] set media histogram';
export const setMediaHistogram = createAction(SET_MEDIA_HISTOGRAM, props<{ value: number | null }>());
