import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NavigationState } from './navigation.state';

export const NAVIGATION_STATE_NAME = 'navigation';

const getNavigationState = createFeatureSelector<NavigationState>(NAVIGATION_STATE_NAME);

export const getStateAreasCliente = createSelector(getNavigationState, (state) => {
  return state.areas_cliente;
});
