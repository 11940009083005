import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  addConfiguracion,
  getUsuariosFiltrados,
  listaUsuariosAsignados,
  saveTarea,
  scrollListaUsuariosAsignados,
} from '@src/app/@pages/navigation/tareas/componentes/boards/state/boards.action';
import { getListaUsuariosAsignados } from '@src/app/@pages/navigation/tareas/componentes/boards/state/boards.selector';
import { createTablero, loadTableros } from '@src/app/@pages/navigation/tareas/state/tareas.actions';
import { getTableros } from '@src/app/@pages/navigation/tareas/state/tareas.selector';
import { Tablero } from '@src/app/objetos/tablero';
import { AppState } from '@src/app/store/app.state';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { setSavingTarea, updateTareaData } from '../../state/tool-container.actions';
import { TranslocoService } from '@ngneat/transloco';
import { PrecosechaSubtitles } from '@src/app/enumerations/Precosecha.enum';
import { getAreaSelected } from '@src/app/@pages/navigation/mapa/state/mapa.selector';
import { Area } from '@src/app/objetos/area';
import { GoogleTagService } from '@src/app/servicios/googletag.service';

export interface TableroEmitter {
  id: number;
  name: string;
}

const dataFormluario = 'mapa.componentes.workspace.componentes.tool_container.tool_template.formulario_tareas';

@Component({
  selector: 'app-formulario-tareas',
  templateUrl: './formulario-tareas.component.html',
  styleUrls: ['./formulario-tareas.component.scss'],
})
export class FormularioTareasComponent implements OnInit, OnDestroy {
  @Input() isPrecosecha: boolean;
  @Input() tipoMuestreo: any;
  @Input() buttonBack: boolean = false;
  @Output() saveMarcadores: EventEmitter<any> = new EventEmitter();
  @Output('changeTablero') changeTableroEvent: EventEmitter<TableroEmitter> = new EventEmitter();
  @Output() clickBack: EventEmitter<boolean> = new EventEmitter();

  tableros: Observable<Tablero[]>;
  listaUsuarios: Observable<any>;
  selectedTablero: any = null;
  selectedTableroNombre: any = null;
  searchMiembro: string = '';
  debounce: Subject<string> = new Subject<string>();
  arrayMembersName: any[] = [];
  arrayMembersId: any[] = [];
  selectedArea: Area | null;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private store: Store<AppState>,
    private translocoService: TranslocoService,
    private tagManager: GoogleTagService,
  ) {
    this.debounce.pipe(debounceTime(500), distinctUntilChanged()).subscribe((debounce) => {
      this.searchMiembro = debounce;

      if (this.searchMiembro == '') {
        //dispatch a la que me saca todos inicial
        this.store.dispatch(listaUsuariosAsignados({ idTablero: this.selectedTablero }));
      } else {
        //dispatch a la que me saca lo de la búsqueda
        this.store.dispatch(
          getUsuariosFiltrados({
            texto: this.searchMiembro,
            idTablero: this.selectedTablero,
          }),
        );
      }
    });
  }

  ngOnInit(): void {
    this.tableros = this.store.select(getTableros);
    this.store.dispatch(loadTableros());

    this.isPrecosecha && this.gestionPrecosecha();

    /** Listen to selected area */
    this.store
      .select(getAreaSelected)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.selectedArea = value;
      });
  }

  goBack(): void {
    this.clickBack.emit(true);
  }

  private assignSelectedTablero(tableros: Tablero[], searched: string) {
    let tablero = tableros.find(
      (tablero) =>
        tablero.nombre.replace(/\s+/g, '').toLowerCase() ===
        this.translocoService.translate(searched).replace(/\s+/g, '').toLowerCase(),
    );
    if (tablero) {
      this.selectedTablero = tablero.id;
      this.selectedTableroNombre = this.translocoService.translate(searched);
    }
  }

  gestionPrecosecha() {
    this.tableros.pipe(takeUntil(this.ngUnsubscribe)).subscribe((tableros: Tablero[]) => {
      if (tableros.length > 0) {
        //Aquí sustituyo esto por un filtro buscando todos los tableros diferentes que puede haber ahora
        switch (this.tipoMuestreo) {
          case PrecosechaSubtitles.MUESTREO_PRECOSECHA:
            this.assignSelectedTablero(tableros, `${dataFormluario}.precosecha`);
            break;
          case PrecosechaSubtitles.MUESTREO_MALEZAS:
            this.assignSelectedTablero(tableros, `${dataFormluario}.malezas`);
            break;
          case PrecosechaSubtitles.MUESTREO_DESPO:
            this.assignSelectedTablero(tableros, `${dataFormluario}.despobEnfermedad`);
            break;
          case PrecosechaSubtitles.MUESTREO_SUELO:
            this.assignSelectedTablero(tableros, `${dataFormluario}.mues_suelo`);
            break;
          default: {
            this.selectedTablero = null;
            if (tableros.some((tablero) => tablero.nombre.replace(/\s+/g, '').toLowerCase() == 'analisisinteligente')) {
              this.selectedTablero = tableros.find(
                (tablero) => tablero.nombre.replace(/\s+/g, '').toLowerCase() == 'analisisinteligente',
              )?.id;
            }
          }
        }
        this.changeTableroEvent.emit({
          id: this.selectedTablero,
          name: this.selectedTableroNombre,
        });
      }
    });
  }

  miembrosOpened() {
    this.listaUsuarios = this.store.select(getListaUsuariosAsignados);
    this.store.dispatch(listaUsuariosAsignados({ idTablero: this.selectedTablero }));
  }

  search(event: any) {
    let evento: any = event.target.value;
    this.searchMiembro = evento;
    this.debounce.next(this.searchMiembro);
  }

  setSelectedTablero(evento: any) {
    this.selectedTablero = parseInt(evento.target.value);
    this.tableros.pipe(take(1)).subscribe((tableros) => {
      let tablero = tableros.find((tablero) => tablero.id === this.selectedTablero);
      this.selectedTableroNombre = tablero?.nombre;
      this.changeTableroEvent.emit({
        id: this.selectedTablero,
        name: this.selectedTableroNombre,
      });
    });
  }

  onScrollDown(url: any) {
    if (url) {
      this.store.dispatch(scrollListaUsuariosAsignados({ next: url, texto: this.searchMiembro }));
    }
  }

  addMember(user: any) {
    this.arrayMembersId.includes(user.id)
      ? this.arrayMembersId.splice(this.arrayMembersId.indexOf(user.id))
      : this.arrayMembersId.push(user.id);

    this.arrayMembersName.includes(user.user)
      ? this.arrayMembersName.splice(this.arrayMembersName.indexOf(user.user))
      : this.arrayMembersName.push(user.user);
  }

  onSubmit(form: NgForm) {
    this.store.dispatch(setSavingTarea({ status: true }));

    form.value['fecha'] = moment().format('YYYY-MM-DD');
    form.value['usuarios'] = this.arrayMembersId;

    Object.keys(form.value).forEach((key) => {
      if (form.value[key] === '') {
        delete form.value[key];
      }
    });

    // Guardamos la tarea y los marcadores en el state
    this.store.dispatch(updateTareaData({ attribute: 'tarea', value: form.value }));
    this.saveMarcadores.emit(form.value);

    this.tagManager.sendEvent(
      'execution',
      this.selectedArea,
      this.tipoMuestreo ? 'type_of_sampling' : 'create_tasks',
      'Herramientas',
      this.tipoMuestreo ? 'Muestreo Inteligente' : 'Tareas',
      'Botón',
      this.tipoMuestreo ? this.tipoMuestreo : 'Crear tarea',
      null,
      'Ejecución',
      'Activa',
      'Ejecución',
    );
  }

  /** DESTROY */
  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
