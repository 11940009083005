import { Token } from '@src/app/models/token.model';

export interface AuthState {
  token: Token | null;
  validations: any;
  registerStatus: any;
  verifyUser: boolean | null;
}
export const initialStateAuth: AuthState = {
  token: null,
  validations: {
    email: true,
    user: true,
    message: null,
  },
  registerStatus: null,
  verifyUser: null,
};
