import { Pipe, PipeTransform } from '@angular/core';
interface objReturn {
  key: string | undefined;
  code: string;
  codeFormat: string;
}
@Pipe({
  name: 'phoneCode',
})
export class PhoneCode implements PipeTransform {
  transform(codes: string, key?: string) {
    let code = codes.split(',');
    return code.map((element) => {
      let codeFormat = element.length > 3 ? element.charAt(0) + ` (${element.slice(1)})` : element;
      let obj: objReturn = {
        key: key,
        code: element,
        codeFormat: codeFormat,
      };
      return obj;
    });
  }
}
