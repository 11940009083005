import { Tablero } from '@src/app/objetos/tablero';

//interfaz de los datos con los que voy a trabajar, se guarda en el store.
export interface TableroState {
  tableros: Tablero[];
}

// se inicializa la interfaz con valores nulos con todos los datos con los que quieres trabajar
export const initialStateBoards: TableroState = {
  tableros: [],
};
