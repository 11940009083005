import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { loadUserSuccess } from '@src/app/@pages/inicio/state/user.actions';
import { getUser } from '@src/app/@pages/inicio/state/user.selector';
import { Usuario } from '@src/app/objetos/usuario';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppState } from '../../store/app.state';
import { AgreementService } from '../agreement.service';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class AgreementGuard implements CanActivate {
  user: Usuario | null;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private userService: UserService,
    private agreementService: AgreementService,
  ) {
    this.store.select(getUser).subscribe((value) => {
      this.user = value;
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Have user?
    if (this.user) {
      if (this.agreementService.getAgreementPermission(this.user)) return true;
      this.router.navigate(['/inicio'], { fragment: 'agreements' });
      return false;
    }

    // Get user
    return this.userService.getUser().pipe(
      map((value: Usuario) => {
        this.store.dispatch(loadUserSuccess({ user: value }));
        if (value && this.agreementService.getAgreementPermission(value)) return true;
        this.router.navigate(['/inicio'], { fragment: 'agreements' });
        return false;
      }),
      catchError((err) => {
        this.router.navigate(['/login']);
        return of(false);
      }),
    );
  }
}
