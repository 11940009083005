import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FiltrosState } from './filtros.state';

export const FILTROS_STATE_NAME = 'filtros';

const getFiltrosState = createFeatureSelector<FiltrosState>(FILTROS_STATE_NAME);

export const getMostrarFiltros = createSelector(getFiltrosState, (state) => {
  return state ? state.mostrarFiltros : null;
});

export const getAtributos = createSelector(getFiltrosState, (state) => {
  return state ? state.filtros : null;
});

export const getActiveFilters = createSelector(getFiltrosState, (state) => {
  return state ? state.activeFilters : null;
});

export const getParcelasFiltered = createSelector(getFiltrosState, (state) => {
  return state ? state.parcelasFiltered : null;
});
export const getParcelasManuallySelected = createSelector(getFiltrosState, (state) => {
  return state ? state.parcelasManuallySelected : null;
});
