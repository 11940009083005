export enum tiposAtributosParcela {
  kgnit1 = '1',
  kgnit2 = '1',
  soca = '1',
  area = '1',
  rend = '1',
  cmadur = '1',
  rend_est = '1',
  agua_disp = '1',
  agua_aprov = '1',
  agua_act = '1',
  rend_neto = '1',
  ha_corte = '1',
  rend_core = '1',
  rend_ind = '1',
  semana_mad = '1',
  riegos_pre = '1',
  riegos_pos = '1',
  fk_estacion = '1',
  fk_pixel = '3',
  zafra = '3',
  fe = '2',
  ff = '2',
  fi = '2',
  fnit1 = '2',
  fnit2 = '2',
  fs = '2',
  fc = '2',
  fmadur = '2',
  fagst = '2',
  fc_est = '2',
  fci = '2',
  fecha_max = '2',
  inicio_curva = '2',
  unidad_01 = '3',
  unidad_02 = '3',
  unidad_03 = '3',
  unidad_04 = '3',
  unidad_05 = '3',
  variedad = '3',
  cultivo = '3',
  pmadur = '3',
  riego = '3',
  zona_eco = '3',
  estrato = '3',
  tipo_cos = '3',
  obs_madur = '3',
  tercio = '3',
  zonas = '3',
  semillero = '4',
  cuadrante = '3',
  alta_freq = '4',
  activo = '4',
}
