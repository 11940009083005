import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, exhaustMap, take, takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { clearRequest } from './clearRequest.service';
import { Token } from '../models/token.model';
import { AppState } from '../store/app.state';
import { Store } from '@ngrx/store';
import { getToken } from '../@pages/login/state/auth.selector';
import { loadAlert } from '../store/share/shared.actions';
import { ErrorFormatterService } from './error-formatter.service';
import { Alert } from '../interfaces/alert';
import { logout } from '../@pages/login/state/auth.actions';
import Swal from 'sweetalert2';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(
    private store: Store<AppState>,
    private clearRequestService: clearRequest,
    private errorFormatterService: ErrorFormatterService,
    private translocoService: TranslocoService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(getToken).pipe(
      take(1),
      exhaustMap((token: Token | null) => {
        let request = req;
        if (request.url.search('powerbi') != -1) {
          request = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token?.getTokenPowerBI()}`,
            },
          });
        } else if (
          token &&
          request.url.split('/')[2].search(environment.portip) == -1 &&
          request.url.search('google') == -1
        ) {
          request = req.clone({
            setHeaders: {
              Authorization: `${token.getToken()}`,
            },
          });
        } else if (request.url.search('googleapis') != -1) {
          request = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token?.getTokenDrive()}`,
            },
          });
          return next.handle(request);
        }

        return next.handle(request).pipe(
          takeUntil(this.clearRequestService.onCancelPendingRequests()), //Cancelamos las peticiones pendientes en caso de que se quiera
          catchError(this.handleError<any>()),
        );
      }),
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      let enabled = this.errorFormatterService.enableError(error.status);

      if (enabled) {
        var alert: Alert = this.errorFormatterService.createAlert(error);
        this.store.dispatch(loadAlert({ alert: alert }));
        return throwError(error.error || error || '');
      } else {
        if (error.status === 0) {
          // status de error inesperado
          Swal.fire({
            title: this.translocoService.translate('errors.ups'),
            html:
              `<div style="text-align: center; padding: 1em 2em;">
            <p>` +
              this.translocoService.translate('errors.status0') +
              `</p>
            </div>`,
            icon: 'warning',
          });
        } else if (
          error.status == 403 &&
          (!window.document.location.href.includes('login') || typeof error.error == 'string')
        ) {
          Swal.fire({
            title: this.translocoService.translate('errors.permission_title'),
            text: typeof error.error == 'string' ? error.error : this.translocoService.translate('errors.permission'),
            icon: 'warning',
          }).then((result) => {
            this.store.dispatch(logout());
          });
        } else if (error.status === 602) {
          // error no matches swal
          this.errorFormatterService.showNoMatchesError(error);
        }
        return throwError(error);
        //return of(result as T)
      }
    };
  }
}
