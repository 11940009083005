import { Tarea } from '@src/app/objetos/tarea';
import Geometry from 'ol/geom/Geometry';

export interface MarcadoresInput {
  titulo: MarcadorInput;
  prioridad: MarcadorInput;
  tablero: MarcadorInput;
  usuario: MarcadorInput;
  estado: MarcadorInput;
}

export interface MarcadoresFilterObject {
  titulo: string | null;
  prioridad: string[] | null;
  tablero: number[] | null;
  usuario: number[] | null;
  estado: string[] | null;
}

export interface MarcadorInput {
  type: string;
  values: any;
}

export enum TypesMarcadores {
  TEXT = 'text',
  MULTISELECT = 'multiselect',
  SELECT = 'select',
}

export interface MarcadorFilterOutput {
  id: number;
  type: string;
  geometry: {
    type: string;
    coordinates: number[][];
  };
  properties: {
    tarea: Tarea;
    identificador: string[];
    value: number[];
  };
}

export class MarcadorFeature {
  geometry: Geometry | undefined;
  identificador: string[] | undefined;
  tarea: Tarea | undefined;
  value: number[] | undefined;
  public static readonly properties: string[] = ['geometry', 'identificador', 'tarea', 'value'];

  constructor(geometry?: Geometry, identificador?: string[], tarea?: Tarea, value?: number[]) {
    this.geometry = geometry;
    this.identificador = identificador;
    this.tarea = tarea;
    this.value = value;
  }

  public static isMarcadorFeature(obj: any): boolean {
    if (!obj) return false;
    const objLabels = Object.keys(obj);
    return (
      objLabels.length === MarcadorFeature.properties.length &&
      objLabels.every((key) => MarcadorFeature.properties.includes(key))
    );
  }
}
