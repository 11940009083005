import { Leyenda } from '@src/app/interfaces/leyenda';

export interface legendState {
  //leyendas principales
  leyendaOriginal: Leyenda | null;
  leyendaPersonalizada: Leyenda | null;
  leyendaAuxiliar: Leyenda | null;

  //leyendas secundarias
  leyendaPreview: Leyenda | null;
  leyendaComparada: Leyenda | null;

  //otros
  minMaxProducto: any;
}

export const initialStateLegend: legendState = {
  leyendaOriginal: null,
  leyendaPersonalizada: null,
  leyendaAuxiliar: null,

  leyendaPreview: null,
  leyendaComparada: null,

  minMaxProducto: null,
};
