<div class="container">
  <div class="container-header">
    <div *ngIf="step == 0"></div>
    <mat-icon *ngIf="step > 0" style="left: 0%" (click)="step = 0; changeInteractions(2)">keyboard_arrow_left</mat-icon>
    <span>{{ 'mapa.componentes.workspace.componentes.tool_container.tool_template.titulos.tareas' | transloco }}</span>
    <mat-icon class="icon" (click)="closeTool()">close</mat-icon>
  </div>
  <mat-tab-group
    class="tareas-tool-tab"
    mat-align-tabs="center"
    animationDuration="500ms"
    (selectedTabChange)="clearMarcadores(marcadores, $event)"
  >
    <mat-tab [label]="'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.crear' | transloco">
      <div class="container-body" *ngIf="step == 0 && !savingTarea">
        <section class="container-body-marcadores section-size">
          <div id="header">
            <span>
              {{
                'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.lista_marcadores'
                  | transloco
              }}
            </span>
          </div>
          <div style="display: flex; justify-content: center" *ngIf="marcadores.length == 0">
            <p>
              {{
                'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.add_marcadores' | transloco
              }}
            </p>
          </div>
          <div *ngFor="let marcador of marcadores">
            <button id="ojo" (click)="showPoint(marcador)">
              <mat-icon [inline]="true" [ngStyle]="{ color: remarkMark === marcador ? 'red' : 'white' }"
                >visibility</mat-icon
              >
            </button>
            <input
              class="input-size"
              type="text"
              style="color: white"
              [(ngModel)]="marcador.identificador"
              [disabled]="tareaRoute"
            />
            <mat-icon
              *ngIf="!tareaRoute"
              style="zoom: 0.75; cursor: pointer"
              class="delete-mark"
              (click)="deletePoint(marcador)"
              >delete</mat-icon
            >
            <div *ngIf="tareaRoute"></div>
          </div>
        </section>
        <section class="container-body-submit">
          <button
            *ngIf="!tareaRoute"
            [disabled]="marcadores.length == 0"
            (click)="step = 1; changeInteractions(1)"
            class="btn"
          >
            <span>{{
              'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.siguiente' | transloco
            }}</span>
          </button>
          <button *ngIf="tareaRoute" (click)="createNewTarea()" class="btn">
            <span>{{
              'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.nuevaTarea' | transloco
            }}</span>
          </button>
        </section>
      </div>
      <div *ngIf="step == 1 && !savingTarea">
        <app-formulario-tareas
          [buttonBack]="true"
          (clickBack)="goBackTasksSelect()"
          (saveMarcadores)="saveMarcadores($event)"
          (changeTablero)="setSelectedTablero($event)"
        >
        </app-formulario-tareas>
      </div>
      <div class="centrar-spinner" *ngIf="savingTarea">
        <mat-spinner></mat-spinner>
      </div>
    </mat-tab>
    <mat-tab
      [label]="
        'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.marcadores' | transloco
      "
      [disabled]="step === 1"
    >
      <ng-template matTabContent>
        <div class="tab-marcadores">
          <app-filter-marcadores></app-filter-marcadores>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
