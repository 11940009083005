import { Action, createReducer, on } from '@ngrx/store';
import { loadUserSuccess, reloadUser, updateUserSuccess } from './user.actions';
import { initialStateUser, UserState } from './user.state';

const _userReducer = createReducer(
  initialStateUser,
  on(loadUserSuccess, reloadUser, (state, action) => {
    return {
      ...state,
      user: action.user,
    };
  }),
  on(updateUserSuccess, (state, action) => {
    return {
      ...state,
      user: action.user,
    };
  }),
);

export function UserReducer(state: UserState | undefined, action: Action) {
  return _userReducer(state, action);
}
