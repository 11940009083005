import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '@src/app/store/app.state';
import { getLoading } from '@src/app/store/share/share.selector';
import { setLoadingSpinner } from '@src/app/store/share/shared.actions';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { validarEmail } from '../register/validaciones/validarEmail.validator';
import { checkUser, resendEmail } from '../login/state/auth.actions';
import { getValidations } from '../login/state/auth.selector';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-register-verification',
  templateUrl: './register-verification.component.html',
  styleUrls: ['./register-verification.component.scss'],
})
export class RegisterVerificationComponent implements OnInit {
  tiempo: number = 1;
  user: string;
  email: string;
  title: string;
  subtitle: string;
  text: string;
  sendBack: boolean = false;
  register: boolean = false;
  formEmail: boolean = false;
  showLoading: Observable<boolean>;
  validators: any;

  emailForm: UntypedFormGroup;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<RegisterVerificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translocoService: TranslocoService,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.showLoading = this.store.select(getLoading);
    this.user = this.data['user'];
    this.email = this.data['email'];

    this.title = this.data['title'];
    this.subtitle = this.data['subtitle'];
    this.text = this.data['text'];
    this.sendBack = this.data['sendBack'];
    this.register = this.data['register'];
    this.formEmail = this.data['formEmail'];

    if (this.formEmail) {
      this.store
        .select(getValidations)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((value) => {
          this.validators = value;
        });

      this.emailForm = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required, validarEmail]),
      });
    }
  }

  /**
   * Envía resultado
   */
  submit() {
    this.dialogRef.close({ result: true });
  }

  /**
   * Función que realiza la llamada a buscar si el usuari existe en el sistema
   * @param label etiqueta del valor a comprobar
   * @param text valr a buscar
   */
  checkValidUser(label: string, event: any) {
    let text: string = event.target.value;
    // comprobar user
    if (!this.emailForm.get('email')?.errors) this.store.dispatch(checkUser({ data: { [label]: text } }));
  }

  /**
   * Función que reenvia el email de verificación al usuario según el dato que se tenga de él (user, email)
   */
  resendEmail() {
    if (this.user) {
      this.store.dispatch(setLoadingSpinner({ status: true }));
      this.store.dispatch(resendEmail({ user: this.user }));
    } else if (this.email) {
      this.store.dispatch(setLoadingSpinner({ status: true }));
      this.store.dispatch(resendEmail({ email: this.email }));
    }
  }

  checkEmailForm() {
    this.store.dispatch(setLoadingSpinner({ status: true }));
    this.store.dispatch(checkUser({ data: { email: this.emailForm.get('email')?.value } }));
    setTimeout(() => {
      if (!this.emailForm.get('email')?.errors && !this.validators['email']) {
        this.email = this.emailForm.get('email')?.value;
        this.resendEmail();
      }
    }, 1000);
  }

  /**
   * Función que cierra la herramienta
   */
  closeTool() {
    this.dialogRef.close();
  }
}
