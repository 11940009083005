import { Fecha } from '@src/app/objetos/fecha';

//interfaz de los datos con los que voy a trabajar, se guarda en el store.
export interface HistogramState {
  selectedScreen: number;
  showHistogram: boolean;

  histogramArray: any[] | null;

  histogramColors: any[] | null;
  histogramInfo: any;
  histogramMedia: number | null;

  dateCompared: Fecha | null;
  polygon: string | null;
}

// se inicializa la interfaz con valores nulos con todos los datos con los que quieres trabajar
export const initialStateHistogram: HistogramState = {
  selectedScreen: 1,
  showHistogram: false,

  histogramArray: null,

  histogramColors: null,
  histogramInfo: null,

  dateCompared: null,
  polygon: null,
  histogramMedia: null,
};
