import { createAction, props } from '@ngrx/store';
import { IBbox } from '@src/app/interfaces/Mapa/openlayers/bbox';
import { ILayer } from '@src/app/interfaces/Mapa/openlayers/layer';

export const LOAD_BBOX_AREA = '[ openlayer page] request load data area';
export const LOAD_BBOX_AREA_SUCCESS = '[ openlayer page] request load data area success';

export const loadBBoxArea = createAction(LOAD_BBOX_AREA);
export const loadBBoxAreaSuccess = createAction(LOAD_BBOX_AREA_SUCCESS, props<{ bbox: IBbox }>());

export const SET_DATA_SELECTED_OPENLAYERS = '[ openlayer page] set data selected';

export const setSelectedDataOpenlayers = createAction(
  SET_DATA_SELECTED_OPENLAYERS,
  props<{ data: any; typeSelected: string }>(),
);

export const LOAD_FEATURES_AREA = '[ openlayer page] load features area ';
export const LOAD_FEATURES_AREA_SUCCESS = '[ openlayer page] load features area success';

export const loadFeaturesArea = createAction(LOAD_FEATURES_AREA, props<{ typeSelected: any }>());

export const loadFeaturesAreaSuccess = createAction(LOAD_FEATURES_AREA_SUCCESS, props<{ layer: ILayer }>());

export const NO_DATA = '[ openlayer page] no data';

export const noData = createAction(NO_DATA);

export const EDIT_LAYER_ATTRIBUTE = '[ openlayer page] edit layer attribute';

export const editLayerAttribute = createAction(
  EDIT_LAYER_ATTRIBUTE,
  props<{ zIndex: number; attribute: string; value: any }>(),
);

export const UPDATE_STATE_VARIABLE_OPENLAYERS = '[ openlayer page] update state variable openlayers';

export const updateStateVariableOpenlayers = createAction(
  UPDATE_STATE_VARIABLE_OPENLAYERS,
  props<{ variable: string; value: any }>(),
);

export const SET_ACTUAL_PIXEL = '[ openlayer page] set actual pixel';

export const setActualPixel = createAction(SET_ACTUAL_PIXEL, props<{ value: number | null }>());

export const LOAD_ACTUAL_PIXEL = '[ openlayer page] load actual pixel';

export const loadActualPixel = createAction(LOAD_ACTUAL_PIXEL, props<{ url: string }>());

export const CHANGE_MAP_CENTER = '[ openlayer page] change map center';
export const changeMapCenter = createAction(CHANGE_MAP_CENTER, props<{ x: any; y: any }>());

export const CHECK_DRAW_POLYGON = '[ openlayer page] check draw polygon';
export const checkDrawPolygon = createAction(
  CHECK_DRAW_POLYGON,
  props<{ zIndex: number; feature: any; attribute: string; geojson: any }>(),
);
