export enum TareaPriority {
  URGENTE = 3,
  ALTA = 2,
  NORMAL = 1,
  BAJA = 0,
}

export enum TareaActualState {
  PENDIENTE = 0,
  EN_PROCESO = 1,
  HECHO = 2,
}
