import { createAction, props } from '@ngrx/store';
import { Tablero } from '@src/app/objetos/tablero';
import { TableroInfo } from '@src/app/objetos/tableroinfo';

export const LOAD_TABLEROS = '[tableros page] get tableros';
export const LOAD_TABLEROS_SUCCESS = '[tableros page] get tableros success';

export const CREATE_TABLEROS = '[tableros page] create tableros';
export const CREATE_TABLEROS_SUCCESS = '[tableros page] create tableros success';

export const DELETE_TABLERO = '[tableros page] delete tablero';
export const DELETE_TABLERO_SUCCESS = '[tablero page] delete tablero success';

export const SAVE_TABLERO = '[tableros page] save tablero';

export const loadTableros = createAction(LOAD_TABLEROS);

export const loadTablerosSuccess = createAction(LOAD_TABLEROS_SUCCESS, props<{ todosTableros: Tablero[] }>());

export const createTablero = createAction(CREATE_TABLEROS, props<{ nombre: string }>());

export const createTableroSuccess = createAction(CREATE_TABLEROS_SUCCESS, props<{ nuevoTablero: Tablero }>());

export const deleteTablero = createAction(DELETE_TABLERO);

export const deleteTableroSuccess = createAction(DELETE_TABLERO_SUCCESS, props<{ tablero: Tablero }>());

export const saveTablero = createAction(SAVE_TABLERO, props<{ data: any }>());
