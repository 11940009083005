import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pageSize',
  pure: false,
})
export class PageSizePipe implements PipeTransform {
  transform(pageSize: Array<any>, limit: number): Array<any> {
    return pageSize.filter((element) => element <= limit);
  }
}
