import { Action, createReducer, on } from '@ngrx/store';
import { getAreaClienteSuccess } from './navigation.actions';
import { initialStateNavigation, NavigationState } from './navigation.state';

const _navigationReducer = createReducer(
  initialStateNavigation,
  on(getAreaClienteSuccess, (state, action) => {
    return {
      ...state,
      areas_cliente: action.areas_cliente,
    };
  }),
);

export function NavigationReducer(state: NavigationState | undefined, action: Action) {
  return _navigationReducer(state, action);
}
