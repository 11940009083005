import { createAction, props } from '@ngrx/store';
import { Novedad } from '@src/app/interfaces/Novedad.interface';
import { NovedadObject } from '@src/app/objetos/Novedad.object';

export const LOAD_DATA_AREA = '[ mapa page] request load data area';
export const LOAD_DATA_AREA_SUCCESS = '[ mapa page] request load data area success';

export const SET_CONTROL_EXPANSION = '[mapa page] change control expansion';

export const loadDataArea = createAction(
  LOAD_DATA_AREA,
  props<{ id: number; body: string; requestType: string; arrows?: boolean }>(),
);

export const loadDataAreaSuccess = createAction(
  LOAD_DATA_AREA_SUCCESS,
  props<{ data: string[] | null; requestType: string }>(),
);

export const SET_DATA_SELECTED_MAPA = '[ mapa page] set data selected';

export const setSelectedDataMapa = createAction(SET_DATA_SELECTED_MAPA, props<{ data: any; typeSelected: string }>());

//MAT-EXPANSIONS

export const setControlExpansion = createAction(
  SET_CONTROL_EXPANSION,
  props<{
    oculto?: boolean;
    expandido?: boolean;
    control?: string;
    visible?: boolean;
  }>(),
);

// HERRAMIENTAS SUBMENU
/** DACTIVE SUBMENU TOOL */
export const ACTIVE_TOOL = '[curve page] active tool ';

export const activeTool = createAction(ACTIVE_TOOL, props<{ tool: string }>());

export const LOAD_COMPARE_SWIPE_VALUE = '[curve page] load compare swipe value ';

export const loadCompareSwipeValue = createAction(LOAD_COMPARE_SWIPE_VALUE, props<{ value: number }>());

export const OPENED_NOVEDADES = '[ mapa page] opened novedades';
export const openedNovedades = createAction(OPENED_NOVEDADES);

export const LOAD_NOVEDAD = '[ mapa page] load novedad';
export const LOAD_NOVEDAD_SUCCESS = '[ mapa page] load novedad success';
export const loadNovedad = createAction(LOAD_NOVEDAD, props<{ id: number }>());

export const loadNovedadSuccess = createAction(LOAD_NOVEDAD_SUCCESS, props<{ novedad: NovedadObject }>());
