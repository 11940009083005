import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OpenlayersService } from '@src/app/servicios/openlayers.service';
import { ToolsService } from '@src/app/servicios/tools.service';
import { AppState } from '@src/app/store/app.state';
import { Subject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  setActiveTool,
  setSavingTarea,
  setToolcontainer,
  updateStateVariableTools,
} from '../../state/tool-container.actions';
import { getSavingTarea, getTareaRoute } from '../../state/tool-container.selector';
import { TranslocoService } from '@ngneat/transloco';
import { activeTool } from '@src/app/@pages/navigation/mapa/state/mapa.actions';
import { MapaService } from '@src/app/servicios/api/map.service';
import { Map } from 'ol';
import Draw from 'ol/interaction/Draw';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CompressionService } from '@src/app/servicios/compression.service';
import { TareasService } from '@src/app/servicios/tareas.service';
import { Coordinate } from 'ol/coordinate';
import { Point } from 'ol/geom';
import Feature from 'ol/Feature';
import { saveTarea } from '@src/app/@pages/navigation/tareas/componentes/boards/state/boards.action';
import { Actions, ofType } from '@ngrx/effects';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Area } from '@src/app/objetos/area';
import { getAreaSelected } from '@src/app/@pages/navigation/mapa/state/mapa.selector';

export const Z_INDEX_TASKS = 13;

@Component({
  selector: 'app-tareas',
  templateUrl: './tareas.component.html',
  styleUrls: ['./tareas.component.scss'],
})
export class TareasComponent implements OnInit, OnDestroy {
  @Input() data: any;
  mapInstance: Map = new Map({});
  layerTask: any;
  drawInteraction: Draw | undefined;
  marcadores: any[] = [];
  step: number = 0;

  tareaRoute: any = null;
  savingTarea: boolean | null = false;
  selectedTablero: { id: number; nombre: string } | null = null;

  remarkMark: {
    feature_id: number;
    identificador: string;
    parcela: number | null;
  } | null = null;

  private ngUnsubscribe: Subject<any> = new Subject();
  private selectedArea: Area | null = null;

  constructor(
    private store: Store<AppState>,
    private openlayersService: OpenlayersService,
    private toolsService: ToolsService,
    private mapService: MapaService,
    private compressionService: CompressionService,
    private tareaService: TareasService,
    private translocoService: TranslocoService,
    private actions: Actions,
  ) {}

  ngOnInit(): void {
    this.mapInstance = this.mapService.getMap();
    this.initializeMapMarks();

    this.store
      .select(getSavingTarea)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.savingTarea = value;
        this.changeInteractions(value ? 1 : 2);
      });

    this.store
      .select(getAreaSelected)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.selectedArea = value;
      });

    this.store
      .select(getTareaRoute)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.tareaRoute = value;
        if (this.tareaRoute) {
          // add elements to map
          let features = this.tareaRoute.geometry?.coordinates?.map((element: Coordinate, index: number) => {
            let f = new Feature(new Point(element));
            f.setProperties({ feature_id: index + 1 });
            this.marcadores.push({
              feature_id: index + 1,
              identificador: this.tareaRoute?.identificador ? this?.tareaRoute?.identificador[index] : null,
            });
            return f;
          });

          // Get layer
          let layer: VectorLayer<VectorSource> = this.mapInstance
            .getLayers()
            .getArray()
            .find((el) => el.getZIndex() == Z_INDEX_TASKS) as VectorLayer<VectorSource>;

          // Add features
          layer.getSource()!.addFeatures(features);

          // Zoom
          this.mapService.zoomToLayer(layer.getSource()?.getExtent());

          // Change interactions
          this.changeInteractions(1);
        }
      });
  }

  /**
   * Listen t event when board is selected in task form
   * @param tablero
   */
  setSelectedTablero(tablero: { id: number; name: string }) {
    this.selectedTablero = { id: tablero.id, nombre: tablero.name };
  }

  /**
   * Initialize view mark
   */
  initializeMapMarks() {
    this.layerTask = this.toolsService.getTasksLayer();
    this.mapInstance.addLayer(this.layerTask);

    let layer: any = this.mapInstance
      .getLayers()
      .getArray()
      .find((el) => el.getZIndex() === Z_INDEX_TASKS);
    this.drawInteraction = new Draw({
      source: layer.getSource(),
      type: 'Point',
      stopClick: true,
    });

    this.addInteractionMap();
  }

  /**
   * Add interaction view in map instance
   */
  addInteractionMap() {
    this.drawInteraction && this.mapInstance.addInteraction(this.drawInteraction);
    if (!this.mapInstance.getListeners('drawend')) {
      this.drawInteraction?.on('drawend', (e) => {
        let feature = e.feature;
        let newId = this.openlayersService.getNewId(this.mapInstance, Z_INDEX_TASKS);
        feature.setProperties({ feature_id: newId });
        // found idnax
        let foundIdnax = this.openlayersService.findFeatureFromCoordinates(
          JSON.parse(this.compressionService.decompressString(localStorage.getItem('selectedShape')!)).features,
          feature.getProperties().geometry.flatCoordinates[0],
          feature.getProperties().geometry.flatCoordinates[1],
        );
        foundIdnax && feature.setProperties({ idnax: foundIdnax.properties.idnax });
        // add feature to layer
        this.mapInstance
          .getLayers()
          .getArray()
          .find((el) => el.getZIndex() == Z_INDEX_TASKS)!
          ['values_'].source.addFeatures(feature);

        this.marcadores.push({
          feature_id: newId,
          identificador: 'Marcador' + newId,
          parcela: foundIdnax?.properties?.idnax || null,
        });
      });
    }
  }

  /**
   * Función para mostrar el punto seleccionado
   * @param point punto seleccionado
   */
  showPoint(point: { feature_id: number; identificador: string; parcela: number | null }) {
    this.remarkMark = point;
    let layer: any = this.mapInstance
      .getLayers()
      .getArray()
      .find((el) => el.getZIndex() === Z_INDEX_TASKS);
    layer.setStyle(this.openlayersService.highlightMarcadorStyle(point.feature_id));
  }

  /**
   * Función para eliminar el punto seleccionado
   * @param point punto seleccionado
   */
  deletePoint(point: { feature_id: number; identificador: string; parcela: number | null }) {
    // delete in map
    let layer: any = this.mapInstance
      .getLayers()
      .getArray()
      .find((el) => el.getZIndex() === Z_INDEX_TASKS);
    let features = layer?.getSource()?.getFeatures() || layer?.source?.getFeatures();
    let featureDelete = features?.find((el: any) => el['values_'].feature_id == point.feature_id);
    featureDelete && layer.getSource().removeFeature(featureDelete);
    // delete in component
    let ind = this.marcadores.findIndex((el) => el.feature_id == point.feature_id);
    ind !== -1 && this.marcadores.splice(ind, 1);
  }

  /**
   * Go back in form view
   */
  goBackTasksSelect() {
    this.step = 0;
    this.changeInteractions(2);
  }

  /**
   * Set map to initial tool state
   */
  removeLayerAndInfo() {
    this.layerTask?.getSource()?.dispose();
    this.layerTask && this.mapInstance.removeLayer(this.layerTask);
    this.layerTask = undefined;
    this.marcadores = [];
    this.drawInteraction && this.mapInstance.removeInteraction(this.drawInteraction);
  }

  /**
   * Clear selected marks when tab changes
   * @param marcadores actual marks
   */
  clearMarcadores(marcadores: any[], { index, tab }: MatTabChangeEvent) {
    if (index === 1) {
      this.changeInteractions(1);
      this.tareaRoute && this.store.dispatch(updateStateVariableTools({ variable: 'tareaRouted', value: null }));
      this.removeLayerAndInfo();
    } else if (index === 0) {
      this.step = 0;
      this.initializeMapMarks();
      this.changeInteractions(2);
    }
    // remove marks
    [...marcadores].forEach((point) => {
      this.deletePoint(point);
    });
  }

  /**
   * Función que habilita o deshabilita las interacciones del mapa
   * 1: deshabilita
   * 2: habilita
   */
  changeInteractions(value: 1 | 2) {
    if (value === 1) {
      this.drawInteraction && this.mapInstance.removeInteraction(this.drawInteraction);
    } else {
      this.drawInteraction && this.mapInstance.addInteraction(this.drawInteraction);
    }
  }

  private getMarksBody(): Object | null {
    if (!this.marcadores?.length) return null;
    return {
      parcelas: this.marcadores
        ?.map((obj: any) => obj.parcela)
        .filter((value: any, index: number, self: any[]) => self.indexOf(value) === index),
      identificador: this.marcadores?.map((obj: any) => obj.identificador),
      value: this.marcadores?.length ? this.marcadores?.map((obj: any) => obj.value) : null,
      geometry: this.openlayersService.getMarksGeometryFromLayer(Z_INDEX_TASKS),
      fk_area: this.selectedArea?.id || null,
    };
  }

  private resetComponentAfterSaveTarea() {
    let subjectEnd = new Subject();
    this.actions.pipe(ofType(setSavingTarea), takeUntil(subjectEnd)).subscribe(() => {
      this.step = 0;
      this.removeLayerAndInfo();
      this.initializeMapMarks();
      subjectEnd.next(true);
    });
  }

  /**
   * Función para guardar los marcadores (update tarea)
   */
  saveMarcadores(data: any) {
    this.resetComponentAfterSaveTarea();

    data = {
      ...data,
      marcador: this.getMarksBody(),
    };

    this.store.dispatch(
      saveTarea({
        boardId: this.selectedTablero?.id || null,
        datos: data,
      }),
    );
  }

  /**
   * Button new task clicked action
   */
  createNewTarea() {
    this.store.dispatch(updateStateVariableTools({ variable: 'tareaRouted', value: null }));
    this.removeLayerAndInfo();
    this.initializeMapMarks();
  }

  /**
   * Función que realiza las acciones necesarias (reseteo) al cerrar la herramienta
   */
  closeTool() {
    this.changeInteractions(1);
    this.store.dispatch(updateStateVariableTools({ variable: 'tareaRouted', value: null }));
    this.store.dispatch(setActiveTool({ tool: this.data }));
    this.store.dispatch(setToolcontainer());
  }

  /** DESTROY */
  ngOnDestroy(): void {
    this.layerTask?.getSource()?.dispose();
    this.layerTask && this.mapInstance.removeLayer(this.layerTask);
    this.store.dispatch(activeTool({ tool: 'tareasEnabled' }));
    this.changeInteractions(1);
    this.store.dispatch(updateStateVariableTools({ variable: 'tareaRouted', value: null }));
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
