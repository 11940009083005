<app-loading-spinner style="position: absolute; top: 0" *ngIf="showLoading | async"></app-loading-spinner>
<div id="container">
  <mat-icon id="close" (click)="closeTool()">close</mat-icon>
  <!-- imagen cabecera -->
  <div mat-dialog-title class="title">
    <img src="../../../../assets/images/NaxSolutionsIcon.png" height="30" />
  </div>
  <div class="content">
    <h2 *ngIf="title">{{ title }}</h2>
    <h4 *ngIf="subtitle">{{ subtitle }}</h4>
    <p *ngIf="text" id="text-content">
      {{ text }}
    </p>
    <h4 *ngIf="email && !formEmail">{{ data['email'] }}</h4>
    <form *ngIf="formEmail" [formGroup]="emailForm" (keydown.enter)="resendEmail()">
      <mat-form-field>
        <input
          matInput
          formControlName="email"
          [placeholder]="'register.introduce_mail' | transloco"
          required
          name="email"
          text="email"
          (change)="checkValidUser('email', $event)"
        />
        <mat-error *ngIf="emailForm.get('email')?.hasError('validarEmail')">{{
          'register.mail_ejemplo' | transloco
        }}</mat-error>
        <mat-hint *ngIf="validators['email']">{{ 'register.noexiste_mail' | transloco }}</mat-hint>
      </mat-form-field>
      <button class="send" (click)="checkEmailForm()">
        <mat-icon>send</mat-icon>
      </button>
    </form>
    <p *ngIf="sendBack" class="sendback">
      {{ 'register.no_llego' | transloco }} <br />
      <a (click)="resendEmail()">{{ 'register.volver_envio' | transloco }}</a>
    </p>
    <button *ngIf="register" (click)="submit()">
      {{ 'register.crear' | transloco }}
    </button>
  </div>
</div>
