import { createAction, props } from '@ngrx/store';
import { Downloads } from '@src/app/interfaces/Downloads/downloads.interface';

export const GET_DOWNLOADS = '[downloads page] get downloads';
export const GET_DOWNLOADS_SUCCESS = '[downloads page] get downloads success';
export const DELETE_DOWNLOADS = '[downloads page] delete downloads';

export const getDownloads = createAction(GET_DOWNLOADS);
export const getDownloadsSuccess = createAction(GET_DOWNLOADS_SUCCESS, props<{ data: Downloads[] }>());

export const deleteDownloads = createAction(DELETE_DOWNLOADS, props<{ id: number }>());
