import { Injectable } from '@angular/core';
import moment from 'moment';
import { Fecha } from '../objetos/fecha';

@Injectable({
  providedIn: 'root',
})
export class datepickerCustomHeaderService {
  constructor() {}

  /** FORMATING */
  formatDateUTC(fecha: any) {
    //Funcion para cambiar el formato de la fecha
    if (fecha && moment.isDate(fecha)) {
      fecha = moment(fecha)
        .toISOString(typeof fecha !== 'object')
        .split('T')[0];
      var v = fecha.split('-');
      var UTC = new Date(fecha).getUTCDate();
      return new Date(parseInt(v[0]), parseInt(v[1]) - 1, UTC);
    } else {
      return null;
    }
  }

  getAddMonths(mode: string, activo: Date, actual: Date, direction: number) {
    let addMonths: number = 1;
    if (mode == 'month') {
      // Si el mode es month, restamos los meses
      addMonths = activo.getMonth() - actual.getMonth();
    } else if (mode == 'year') {
      // Si el mode es year, restamos los años
      addMonths = activo.getFullYear() - actual.getFullYear();
    }
    // Si el resultado no es 1 (se pasa de enero a diciembre o viceversa), lo convertimos a 1 (12-el valor)
    if (Math.abs(addMonths) !== 1) addMonths = 12 - Math.abs(addMonths);
    // Según la dirección (previous o next) multiplicamos por 1 o -1
    addMonths = Math.abs(addMonths) * direction;
    return addMonths;
  }

  previousClicked(mode: 'month' | 'year', date: Date | undefined, fechasProduct: any) {
    // Restamos mes o año (dependiendo del mode)
    if (date && mode == 'month') {
      date.setMonth(date.getMonth() - 1);
    } else if (date && mode == 'year') {
      date.setFullYear(date.getFullYear() - 1);
    }

    if (
      !fechasProduct.find(
        (el: any) =>
          this.formatDateUTC(new Date(el.fecha))?.getMonth() == date?.getMonth() &&
          this.formatDateUTC(new Date(el.fecha))?.getFullYear() == date?.getFullYear(),
      )
    ) {
      // Pedimos el nuevo rango de fechas si no encontramos anteriormente esas en el array
      return date;
    }
    // Cambiamos la vista si ya estaban pedidas las fechas deseadas
    return null;
  }

  /**
   * Función que pide las fechas del nuevo mes y año seleccionados (al cambiar) hacia delante
   * @param mode modo de cambio
   */
  nextClicked(mode: 'month' | 'year', date: Date | undefined, fechasProduct: any) {
    var month_days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    // Añadimos mes o año (dependiendo del mode)
    if (date && mode == 'month') {
      if (date.getDate() == month_days[date.getMonth()]) {
        date.setDate(month_days[date.getMonth() + 1]);
      }
      date.setMonth(date.getMonth() + 1);
    } else if (date && mode == 'year') {
      date.setFullYear(date.getFullYear() + 1);
    }
    if (
      !fechasProduct.find(
        (el: { fecha: string | number | Date }) =>
          this.formatDateUTC(new Date(el.fecha))?.getMonth() == date?.getMonth() &&
          this.formatDateUTC(new Date(el.fecha))?.getFullYear() == date?.getFullYear(),
      )
    ) {
      // Pedimos el nuevo rango de fechas si no encontramos anteriormente esas en el array
      return date;
    }
    // Cambiamos la vista si ya estaban pedidas las fechas deseadas
    return null;
  }

  enableNextMonth(calendar_date: any, last_date: any) {
    var today = new Date();
    return !(calendar_date.getMonth() == today.getMonth() && calendar_date.getFullYear() == today.getFullYear());
  }

  enableNextYear(calendar_date: any, last_date: any) {
    return calendar_date < new Date(last_date.setFullYear(last_date.getFullYear() - 1));
  }

  /**
   * Funcion para encontrar la fecha más cercana de un array
   * @param selectedDate fecha a buscar
   * @param dates array de fechas
   */
  getClosestDate(selectedDate: string, dates: Fecha[]) {
    // pasamos a dates
    var date = new Date(selectedDate);
    var allDates = dates.map((obj) => new Date(obj.fecha));

    // las ordenamos de mayor a menor
    allDates.sort(function (a: Date, b: Date) {
      var distancea = Math.abs(date.getTime() - a.getTime());
      var distanceb = Math.abs(date.getTime() - b.getTime());
      return distancea - distanceb; // sort a before b when the distance is smaller
    });
    // devolvemos la primera del array
    return dates.find((el) => el.fecha === allDates[0].toISOString().split('T')[0]);
  }

  /**
   * Función que obtiene una fecha de un array de fechas a partir de otra
   * @param data array de fechas
   * @param fechaSelected fecha con la que se compara
   * @returns fecha
   */
  findFechaInArrray(data: Fecha[], fechaSelected: Fecha) {
    return data.some((el) => el.fecha === fechaSelected.fecha)
      ? data.find((el) => el.fecha === fechaSelected.fecha)
      : this.getClosestDate(fechaSelected.fecha, data);
  }

  selectFecha(fechaSelected: Fecha, data: Fecha[], select: number, productChanged: boolean, fechaToCompare?: Fecha) {
    if (fechaToCompare && Object.keys(fechaToCompare).length > 0) return this.findFechaInArrray(data, fechaToCompare);
    // si no existe fecha seleccionada, seleccionar la ultima fecha
    if (!fechaSelected) return data[data.length - 1];
    // si existe fecha seleccionada
    if (select) return select === 1 ? data[data.length - 1] : data[0];
    // si no ha cambiado el producto
    if (!productChanged) return null;
    // ha cambiado el producto, se busca si está la fecha anteriormente seleccionada
    return this.findFechaInArrray(data, fechaSelected);
  }
}
