import { createFeatureSelector, createSelector } from '@ngrx/store';
import { toolContainerState } from './tool-container.state';

export const TOOLCONTAINER_STATE_NAME = 'toolContainer';

const getToolcontainerState = createFeatureSelector<toolContainerState>(TOOLCONTAINER_STATE_NAME);

/** TOOLS */
export const getEstadoToolcontainer = createSelector(getToolcontainerState, (state) => {
  if (state) {
    return state.activo;
  } else {
    return null;
  }
});

export const getHerramientas = createSelector(getToolcontainerState, (state) => {
  if (state) {
    return state.herramientas;
  } else {
    return null;
  }
});

export const getHerramientaActiva = createSelector(getToolcontainerState, (state) => {
  if (state) {
    return state.herramientaActiva;
  } else {
    return null;
  }
});

/** AUX */

export const getAddLayerFeatures = createSelector(getToolcontainerState, (state) => {
  if (state) {
    return state.addLayerFeatures;
  } else {
    return null;
  }
});

/** MAPA VARIABLE */
export const getMapaVaribleDownloadStatus = createSelector(getToolcontainerState, (state) => {
  if (state) {
    return state.downloadingMapaVariable;
  } else {
    return null;
  }
});

export const getVariableMapDialogState = createSelector(getToolcontainerState, (state) => {
  return state.dialogState;
});

export const getRanges = createSelector(getToolcontainerState, (state) => {
  return state.rangesState;
});

/** CAPAS */
export const getCapas = createSelector(getToolcontainerState, (state) => {
  if (state) {
    return state.capas;
  } else {
    return null;
  }
});
export const getCapaTitle = createSelector(getToolcontainerState, (state) => {
  if (state) {
    return state.addCapaTitle;
  } else {
    return null;
  }
});

/** PRECOSECHA */
export const getMarcadores = createSelector(getToolcontainerState, (state) => {
  if (state) {
    return state.marcadores;
  } else {
    return null;
  }
});
export const getMarcadorSelected = createSelector(getToolcontainerState, (state) => {
  if (state) {
    return state.marcadorSelected;
  } else {
    return null;
  }
});

/** POLYGONS */
export const getPolygonsError = createSelector(getToolcontainerState, (state) => {
  if (state) {
    return state.polygonsError;
  } else {
    return null;
  }
});

export const getPolygonLoading = createSelector(getToolcontainerState, (state) => {
  if (state) {
    return state.polygonLoading;
  }
});

/** TAREAS */
export const getTareaData = createSelector(getToolcontainerState, (state) => {
  if (state) {
    return state.tareaData;
  } else {
    return null;
  }
});
export const getTareaRoute = createSelector(getToolcontainerState, (state) => {
  if (state) {
    return state.tareaRouted;
  } else {
    return null;
  }
});

// VIDEOS
export const getVideosData = createSelector(getToolcontainerState, (state) => {
  if (state) {
    return state.videosData;
  } else {
    return null;
  }
});

/** DRON */
export const getPercentageUpload = createSelector(getToolcontainerState, (state) => {
  return state ? state.percentageUpload : null;
});

export const getSavingTarea = createSelector(getToolcontainerState, (state) => {
  return state ? state.savingTarea : null;
});

export const getPaginationImages = createSelector(getToolcontainerState, (state) => {
  return state ? state.paginationImages : null;
});

export const getDronErrorImages = createSelector(getToolcontainerState, (state) => {
  return state ? state.errorImages : null;
});

export const getLoadingImages = createSelector(getToolcontainerState, (state) => {
  return state ? state.loadingImages : null;
});

export const getCancelUpload = createSelector(getToolcontainerState, (state) => {
  return state ? state.cancelUpload : null;
});

export const getPauseUpload = createSelector(getToolcontainerState, (state) => {
  return state ? state.pauseUpload : null;
});

export const receivedDriveFiles = createSelector(getToolcontainerState, (state) => {
  return state ? state.driveFiles : null;
});

export const receivedDriveGeojson = createSelector(getToolcontainerState, (state) => {
  return state ? state.driveGeojson : null;
});
