import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';
import { Token } from '../models/token.model';

const TOKEN = 'token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(user: string, password: string): Observable<string> {
    return this.http.post<any>(`${environment.databaseURL}/rest/login2`, {
      user: user,
      password: password,
    });
  }

  format(data: string): Token {
    return new Token(data, null, null);
  }

  setTokenDataInLocalStorage(token: Token): void {
    localStorage.setItem(TOKEN, JSON.stringify(token));
  }

  getTokenFromLocalStorage(): Token | null {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      const tokenJson = JSON.parse(token);
      const tokenObj = new Token(tokenJson.token, tokenJson.powerbi, tokenJson.drive);
      return tokenObj;
    } else {
      return null;
    }
  }

  removeLocalStorage() {
    localStorage.clear();
  }

  /**
   * Función para saber si se está utilizando móvil
   * @param nav_userAgentData
   * @returns estado de la acción
   */
  getDevicePlatform(nav_userAgentData: any) {
    return nav_userAgentData && nav_userAgentData.mobile ? 0 : 1;
  }
}
