import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { loadOptionCurve1 } from '../@pages/navigation/mapa/componentes/workspace/componentes/curve/state/curve.actions';
import { optionEjey } from '../enumerations/curve';
import { curveParameters } from '../models/curveModels/curve.model';
import { AppState } from '../store/app.state';
import { OpenlayersService } from './openlayers.service';

@Injectable({
  providedIn: 'root',
})
export class CurveService {
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  public subjectCurveStartDate = new Subject<any>();
  public subjectCurveDisabledTool = new Subject();

  products_show0 = [
    'anomalias',
    'balance_hidrico',
    'conformidad_fertilizado',
    'cosecha',
    'cosecha_af',
    'cosecha_filt',
    'cosechado',
    'deteccion_de_agua',
    'maleza',
    'frescura_canha',
    'planificacion_riego',
    'riego',
  ];

  constructor(private http: HttpClient, private store: Store<AppState>, private openlayersService: OpenlayersService) {}

  /** FUNCIONES */

  /**
   * Funcion que devuelve los parametros para la petición dependiendo del tipo
   * @param type curva, curva optima o curva de opciones
   * @param input datos para sacar los parametros
   * @returns
   */
  getPetitionParameters(type: number, input: any) {
    function compareDates(a: string | number | Date, b: string | number | Date) {
      if (new Date(a) > new Date(b)) return 1;
      if (new Date(a) < new Date(b)) return -1;
      return 0;
    }

    var parameters: curveParameters = {
      finish_date: null,
      init_date: null,
      cloud_coverage: null,
      idnax: null,
      fill: null,
      area: null,
      product: null,
      option: null,
    };

    parameters.area = input[1].id;

    switch (type) {
      case 0:
        parameters.cloud_coverage = input[5];
        parameters.fill = input[0].body.fill;
        if (input[3]) {
          parameters.product = this.getValidProductName(input[3].nombre, type);
        }

        // Calculamos cual debe ser la fecha final de la petición
        if (input[4] && input[0].body.fill) {
          parameters.finish_date = input[4];
        } else {
          parameters.finish_date = new Date().toISOString().split('T')[0];
        }

        // Calculamos cual debe ser la fecha inicial de la petición
        if (input[0].body.init_date) {
          parameters.init_date = input[0].body.init_date;
        } else {
          if (input[2]) {
            if (input[2].inicio_curva) {
              parameters.init_date = input[2].inicio_curva;
            } else if (input[2].cultivo == 'arroz' && input[2].fi) {
              parameters.init_date = input[2].fi;
            } else {
              parameters.init_date = input[2].fs;
            }
          }
        }

        if (!parameters.init_date) {
          var date_aux = new Date();
          parameters.init_date = new Date(date_aux.setMonth(date_aux.getMonth() - 6)).toISOString().split('T')[0];
        }

        // Calculamos cual debe ser el cloud_coverage de la petición
        if (!input[0].body.cloud_coverage) {
          parameters.cloud_coverage = input[5] * 0.01;
        } else {
          parameters.cloud_coverage = input[0].body.cloud_coverage * 0.01;
        }

        // Calculamos cual debe ser el idnax de la petición
        if (input[2] && !input[0].body.filter) {
          parameters.idnax = [input[2].idnax];
        } else {
          if (input[6]) {
            parameters.idnax = input[6].map((obj: { idnax: any }) => {
              return obj.idnax;
            });
          }
        }
        break;
      case 1:
        parameters.finish_date = input[4];
        parameters.init_date = input[0].date;
        parameters.idnax =
          input[7] && input[7].filter
            ? input[6].map((obj: { idnax: any }) => {
                return obj.idnax;
              })
            : [input[2].idnax];
        parameters.product = this.getValidProductName(input[3].nombre, type);
        parameters.fill = true;
        parameters.cloud_coverage = input[5] * 0.01;
        break;
      case 2:
        parameters.product = this.getValidProductName(input[3].nombre, type);
        parameters.fill = false;
        parameters.init_date = input[0].init_date;
        if (!parameters.init_date) {
          var date_aux = new Date();
          parameters.init_date = new Date(date_aux.setMonth(date_aux.getMonth() - 6)).toISOString().split('T')[0];
        }

        if (input[0].idnax) {
          parameters.idnax = input[0].idnax;
        } else if (input[2]) {
          parameters.idnax = [input[2].idnax];
        } else if (input[4] && input[4].length > 0) {
          parameters.idnax = input[4].map((obj: any) => {
            return obj.idnax;
          });
        }
        break;
      case 3:
        parameters.option = input[4];
        parameters.fill = input[0].fill;

        if (input[0].finish_date == false) {
          parameters.finish_date = null;
        } else {
          parameters.finish_date = input[3];
        }
        if (!parameters.finish_date) {
          parameters.init_date = new Date().toISOString().split('T')[0];
        }

        if (input[0].init_date) {
          parameters.init_date = input[0].init_date;
        } else {
          parameters.init_date = input[5];
        }
        if (!parameters.init_date) {
          var date_aux = new Date();
          parameters.init_date = new Date(date_aux.setMonth(date_aux.getMonth() - 6)).toISOString().split('T')[0];
        }

        // * Calculamos cuales deben ser los idnax
        if (input[6] && ((input[7] && input[7].filter) || input[0].filter)) {
          parameters.idnax = input[6].map((obj: { idnax: any }) => {
            return obj.idnax;
          });
        } else {
          parameters.idnax = [input[2].idnax];
        }
        break;
      default:
        break;
    }

    return parameters;
  }

  /**
   * Función que convierte el response de la petición en el dataset
   * @param type curva, curva optima o curva de opciones
   * @param curve response de la peticion
   * @returns
   */
  addCurve2Graph(
    type: number,
    curve: {
      data: any;
      option: any;
      forEach?: any;
      filter?: any;
      curvaoptima?: any;
    },
    product: string,
  ) {
    // * Función que formatea la data si es necesario para poder guardarlo en el array de curvas
    switch (type) {
      case 0:
        curve.forEach((element: any) => {
          if (element.mean || (element.mean == 0 && product && this.products_show0.includes(product))) {
            element['y'] = element.mean;
            element['x'] = element.fk_fecha__fecha ?? element.fecha;
            if (element.cloud_coverage) {
              element['nubes'] = element.cloud_coverage * 100 + '%';
            }
          }
          delete element['mean'];
          delete element['fk_fecha__fecha'];
          delete element['fecha'];
          delete element['cloud_coverage'];
        });

        var filtered = curve.filter((el: { [x: string]: null } | null) => {
          return el !== null && typeof el !== 'undefined' && el['x'] && el['y'] != null;
        });

        return {
          data: filtered,
          type: 'line',
          yAxisID: 'A',
          hidden: false,
          fill: false,
          pointRadius: 2,
          backgroundColor: this.getColorArray('#00ffff', filtered.length),
          borderColor: this.getColorArray('#00ffff', filtered.length),
          borderWidth: 1,
          pointStyle: 'circle',
          label: 'curve',
          showLine: false,
        };
      case 1:
        if (curve.curvaoptima) {
          return {
            data: curve.curvaoptima.valores,
            type: 'line',
            yAxisID: 'A',
            hidden: false,
            fill: false,
            pointRadius: 1,
            backgroundColor: '#00FF00',
            borderColor: '#00FF00',
            //borderWidth: 2,
            pointStyle: 'circle',
            label: 'optimcurve',
            //showLine: true
          };
        } else {
          return null;
        }
      case 2:
        if (curve) {
          if (curve.option.table == 'operaciones' || curve.option.table == 'riegos') {
            curve.data.forEach((element: any) => {
              if (!element.y && element.y != 0) {
                if (
                  (curve.option.table == 'operaciones' && element.area) ||
                  (curve.option.table == 'riegos' && element.ha_regadas)
                ) {
                  element.y = curve.option.table == 'operaciones' ? element.area : element.ha_regadas;
                  element.observacion = `El valor corresponde al área del ${
                    curve.option.table == 'operaciones' ? element.operacion : 'riego'
                  }`;
                } else {
                  element.y = 100;
                  element.observacion = `Valor de ${
                    curve.option.table == 'operaciones' ? element.operacion : 'riego'
                  } por defecto (no existe cantidad ni área)`;
                }
              }
            });
          } else if (curve.option.table == 'clima' && curve.option.name == 'evapotranspiracion') {
            curve.data.forEach((element: any) => {
              element.y = Math.abs(element.y);
              element.observacion = `Pérdida de ${element.y} mm. En otros apartados de la plataforma puede aparecer representado con valor negativo (pérdida).`;
            });
          }

          return {
            type: curve.option.type,
            data: curve.data,
            yAxisID: ['operaciones', 'laboratorio'].includes(curve.option.table) ? 'C' : 'B',
            hidden: false,
            fill: false,
            pointRadius: 1,
            backgroundColor: curve.option.color,
            borderColor: curve.option.color,
            borderWidth: 2,
            pointStyle: 'none',
            label: curve.option.name,
            showLine: true,
            barThickness: 4,
          };
        } else {
          return null;
        }
      default:
        break;
    }
  }

  /**
   * Función que formatea la petición dependiendo del tipo
   * @param type curva, curva optima o curva de opciones
   * @param body body de la peticion
   * @returns
   */
  formatPeticion(type: number, body: curveParameters) {
    // * Función que formatea la petición dependiendo de la curva
    // 0: curve, 1: optim curve, 2: options
    var petition: any;
    switch (type) {
      case 0:
        petition = {
          idArea: [body.area],
          filtroparcelas: {
            idnax__in: body.idnax,
          },
          filtro: {
            show: true,
            fk_fecha__fk_producto__nombre: body.product,
            fk_fecha__fecha__gte: body.init_date,
          },
          columns: body.idnax && body.idnax.length > 1 ? [] : ['id', 'cloud_coverage'],
        };
        if (body.finish_date) {
          petition.filtro.fk_fecha__fecha__lte = body.finish_date;
        }
        if (body.cloud_coverage != null && body.cloud_coverage >= 0) {
          petition.filtro.cloud_coverage__lte = Number(body.cloud_coverage);
        }
        return JSON.stringify(petition);
      case 1:
        petition = {
          ejex: body.init_date,
          areas: [body.area],
          filtroparcelas: {
            idnax__in: body.idnax,
          },
          filtro: {
            fk_producto__nombre__in: [body.product],
          },
        };
        return JSON.stringify(petition);
      case 2:
        petition = {
          ejex: body.option && body.option.ejex,
          ejey: body.option && body.option.ejey,
          tabla: body.option && body.option.table,
          atributos: body.option && body.option.attributes,
          filtro: {
            fi__gte: body.init_date,
          },
          filtroparcelas: {
            idnax__in: body.idnax,
          },
        };
        if (body.option && body.option.table == 'operaciones') {
          petition.filtro.operacion__icontains = body.option.name;
        }
        if (body.finish_date) {
          petition.filtro.ff__lte = body.finish_date;
        }
        if (body.option) petition = JSON.stringify(petition).replace('fi__gte', body.option.ejex + '__gte');
        if (body.option) petition = petition.replace('ff__lte', body.option.ejex + '__lte');
        return petition;
      default:
        break;
    }
  }

  /**
   * Función que formatea la petición dependiendo del tipo
   * @param type curva, curva optima o curva de opciones
   * @param body body de la peticion
   * @returns
   */
  formatPeticionCurve(body: curveParameters) {
    let petition: any = {
      areas: [body.area],
      filtered_plots: body.idnax ?? [],
      show: true,
      product_name: body.product,
      start_date: body.init_date,
    };
    if (body.finish_date) {
      petition.end_date = body.finish_date;
    }

    if (body.cloud_coverage != null && body.cloud_coverage >= 0) {
      petition.cloud_coverage_max = 0.6;
    }
    return JSON.stringify(petition);
  }

  /**
   * Función que obtiene la info de la herramienta de curva
   * @param optimcurve curva optima
   * @param parcela parcela
   * @returns información
   */
  getCurveInfo(optimcurve: any, parcela: any, filter: any) {
    var info: any = {};

    if (optimcurve) {
      info.variedad = optimcurve.variedad;
      info.mes = optimcurve.mes;
      info.rend = optimcurve.rend;
      info.riego = optimcurve.riego;
      info.soca = optimcurve.soca;
      info.zona_eco = optimcurve.zona_eco;
      info.corregir = optimcurve.corregir;
      info.optimId = optimcurve.id;
    }
    if (parcela) {
      info.id = parcela.id;
      info.fs = parcela.fs;
      info.fi = parcela.fi;
      info.edad = parcela.edad;
      // Añadimos la edad en días
      if (parcela.fs) {
        const date = new Date();
        date.setMonth(date.getMonth() + 1); // 👈️ months are 0-based

        let resta =
          date.getTime() -
          new Date(
            parseInt(parcela.fs.split('-')[0]),
            parseInt(parcela.fs.split('-')[1]),
            parseInt(parcela.fs.split('-')[2]),
          ).getTime();

        info.edad = this.openlayersService.getEdad(parcela.fs) + ` (${Math.trunc(resta / (1000 * 60 * 60 * 24))} dias)`;
      }
    }
    info.filter = filter;
    return info;
  }

  /**
   * Función que pide para una nueva parcela las curvas de las opciones seleccionadas
   * @param array datasets actuales de la curva
   */
  preloadCurves(array: any[], filter: any) {
    array.forEach((element: { label: string; data: string | any[] }) => {
      if (element.label != 'curve' && element.label != 'optimcurve') {
        var date =
          element.data.length > 0
            ? element.data[0].x
            : array.some((el) => el.label == 'curve')
            ? array.find((el) => el.label == 'curve').data[0].x
            : null;
        this.store.dispatch(
          loadOptionCurve1({
            init_date: date,
            finish_date: false,
            fill: null,
            option: element.label,
            filter: filter,
          }),
        );
      }
    });
  }

  /** HERRAMIENTAS */

  /**
   * Función que encuentra el parent key y lo devuelve
   * @param object objecto
   * @param son elemento sobre el que buscar
   * @returns
   */
  findParentKey(object: { [x: string]: any[] }, son: any) {
    var keys = Object.keys(object);
    keys.forEach((element) => {
      var find = object[element].find((x: any) => x == son);
      if (find) {
        return element;
      }
    });
    return null;
  }

  /**
   * Función que devuelve un color random
   * @returns color
   */
  getRandomColor() {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  }

  getColorArray(color: string, l: number) {
    var arr = [];
    for (var i = 0; i < l; i++) {
      arr.push(color);
    }
    if (arr.length == 0) {
      arr.push(color);
    }
    return arr;
  }

  /**
   * Función que formatea el nombre del producto para pedir la curva
   * @param name nombre producto
   * @returns nombre correcto del producto
   */
  getValidProductName(name: any, type: number) {
    var validName = name;
    validName = validName.replace('_filt', '');
    validName = validName.replace('_optimo', '');
    if (type == 2) {
      validName = validName.replace('_model', '');
    }

    // RESTRICTION FOR CHANGING AGA_EN_SUELO PRODUCT TO AGUA PRODUCT
    /* if (validName == 'agua_en_suelo') {
      validName = 'agua';
    } */
    return validName;
  }

  formatTooltipOperation(input: string): string {
    let response: string = input;
    if (input != 'riegos') {
      response = 'operaciones';
    }

    return response;
  }

  /**
   * Función que convierte un dataset para poder mostrarlo en el csv descargable
   * @param data dataset
   * @returns dataset formateado
   */
  convertGraph2Download(data: any) {
    var result: any = [];
    data.forEach((element: { data: any[]; hidden: any; label: string }) => {
      if (element.data.length > 0 && !element.hidden) {
        Object.keys(element.data[0]).forEach((element2) => {
          if (element2 == 'x' || element2 == 'y' || element2 == 'area') {
            let values = element.data.map((item: { [x: string]: any }) => {
              return item[element2];
            });
            result.push({
              title: element.label + ' ' + element2,
              data: values,
            });
          }
        });
      }
    });
    return result;
  }

  /** SUBJECTS */

  changeStartDate(): Observable<any> {
    return this.subjectCurveStartDate.asObservable();
  }

  /** PETICIONES */

  /**
   * Petición de la curva (azul)
   * @param filter body
   * @returns curve
   */
  getCurve(filter: string | undefined): Observable<any> {
    return this.http.post<any>(`${environment.databaseURL}/rest/dashboardCurvaFilter`, filter, this.httpOptions);
  }

  /**
   * Petición de la curva optima (verde)
   * @param filter body
   * @returns optim curve
   */
  getOptimCurve(filter: string | undefined): Observable<any> {
    return this.http.post<any>(`${environment.databaseURL}/rest/curvaFilterOptim2`, filter, this.httpOptions);
  }

  /**
   * Función de las curvas de opciones
   * @param filter body
   * @returns option curve
   */
  getOptionCurve(filter: string | undefined): Observable<any> {
    return this.http.post<any>(`${environment.databaseURL}/rest/curva`, filter, this.httpOptions);
  }

  /**
   * Función para reportar una curva
   * @param optimCurveId id de la curva
   * @param body body
   * @returns response del reporte
   */
  reportCurve(optimCurveId: any, body: string) {
    return this.http.put(`${environment.databaseURL}/rest/curvasOptimas/${optimCurveId}`, body, this.httpOptions);
  }

  /**
   * Función para ocultar un punto
   * @param idPunto id
   * @param datos
   * @returns
   */
  editPuntoCurva(datos: any) {
    return this.http.put(`${environment.databaseURL}/rest/stats/bulk_update`, datos, this.httpOptions);
  }

  /**
   * Calculate max value in specific data axis and return double value
   * @param axis axis which data belongs
   * @param type of the axis (bar, line...)
   * @param datasets data in chart
   * @returns max value in all visible specific axis
   */
  changeMaxGraph(axis: string, datasets: any[]): number | null {
    let elements = datasets.filter((e) => e.yAxisID === axis && !e.hidden);
    let maxs = elements.map((element) => {
      let values: number[] = element.data.length
        ? element.data
            .map((e: any) => e['y'] || e[optionEjey[element.label.toUpperCase()]])
            .filter((e: any) => e !== undefined)
        : [];
      return values.length ? Math.max(...values) : -1;
    });
    maxs = maxs.filter((e) => e !== -1);
    return !maxs.length ? null : Math.floor(Math.max(...maxs) * 1.25) || 0;
  }

  /**
   * Calculate min value in specific data axis and return double value
   * @param axis axis which data belongs
   * @param type of the axis (bar, line...)
   * @param datasets data in chart
   * @returns min value in all visible specific axis
   */
  changeMinGraph(axis: string, datasets: any[]): number | null {
    let elements = datasets.filter((e) => e.yAxisID === axis && !e.hidden);
    let mins = elements.map((element) => {
      let values: number[] = element.data.length
        ? element.data
            .map((e: any) => e['y'] || e[optionEjey[element.label.toUpperCase()]])
            .filter((e: any) => e !== undefined)
        : [];
      return values.length ? Math.min(...values) : +1;
    });
    mins = mins.filter((e) => e !== +1);
    return !mins.length ? null : Math.floor(Math.min(...mins)) - Math.abs(Math.floor(Math.min(...mins) * 1.5)) || 0;
  }
}
