import { NgModule } from '@angular/core';
import { RegisterComponent, RegisterViewComponent } from './register.component';
import { SharedModule } from '@src/app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from '../login/state/auth.effects';
import { SharedEffects } from '@src/app/store/share/shared.effects';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [RegisterComponent, RegisterViewComponent],
  imports: [SharedModule, RouterModule, EffectsModule.forFeature([AuthEffects, SharedEffects])],
  entryComponents: [RegisterComponent],
})
export class RegisterModule {}
