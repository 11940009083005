<div id="marcadores-filter-content">
  <div id="marcadores-filter">
    <h5>
      {{
        'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.filtro'
          | transloco
          | titlecase
      }}
    </h5>
    <div class="filter-body">
      <div class="inputs-marcadores">
        <mat-form-field>
          <mat-select
            [(ngModel)]="labelInput"
            [disabled]="isLoading.general"
            (selectionChange)="auxFilter['titulo'] = filter['titulo']"
          >
            <ng-container *ngFor="let option of inputs | keyvalue">
              <mat-option
                [value]="option.key"
                *ngIf="option.key !== 'usuario' || (option.key === 'usuario' && verTareaUser)"
              >
                {{
                  'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.' + option.key
                    | transloco
                    | titlecase
                }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <ng-container *ngIf="inputs[labelInput].type !== TypesMarcadores.TEXT; else inputTitle">
          <ng-container
            *ngIf="
              !['tablero', 'usuario'].includes(labelInput) ||
                isLoading['tablero'] ||
                (labelInput !== 'usuario' && labelInput === 'tablero' && inputs.tablero.values?.length) ||
                isLoading['usuario'] ||
                (labelInput !== 'tablero' && labelInput === 'usuario' && inputs.usuario.values?.length);
              else noDataList
            "
          >
            <mat-form-field>
              <mat-label *ngIf="isLoading[labelInput]">
                <div class="select-placeholder-container">
                  <span style="margin-left: 0.2em"
                    >{{
                      'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.cargando'
                        | transloco
                        | titlecase
                    }}...</span
                  >
                  <mat-spinner class="spinner" diameter="20"></mat-spinner>
                </div>
              </mat-label>
              <mat-label *ngIf="!isLoading[labelInput]" style="margin-left: 0.2em">
                {{
                  'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.selecciona'
                    | transloco
                    | titlecase
                }}
                {{
                  'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.' + labelInput
                    | transloco
                }}
              </mat-label>
              <mat-select
                [(ngModel)]="auxFilter[labelInput]"
                [disabled]="isLoading[labelInput] || isLoading.general"
                [multiple]="inputs[labelInput].type === TypesMarcadores.MULTISELECT"
                (selectionChange)="changeFilter(labelInput, $event.value)"
                (closed)="filteredUsers = inputs[labelInput].values"
              >
                <ng-container *ngIf="labelInput === 'usuario'; else defaultOptions">
                  <mat-option id="search-users">
                    <app-search-select-custom
                      [list]="inputs[labelInput].values"
                      [searchValues]="['nombre']"
                      [placeholder]="
                        ('admin.userShared.permission.search' | transloco) +
                        ' ' +
                        ('mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.' +
                          labelInput | transloco)
                      "
                      (filtered)="filteredUsers = $event"
                    >
                    </app-search-select-custom>
                  </mat-option>
                  <mat-option
                    *ngFor="let option of inputs[labelInput].values"
                    [value]="option.id"
                    [ngStyle]="{
                      display: !filteredUsers.includes(option) ? 'none' : 'flex'
                    }"
                  >
                    {{ option.nombre | titlecase }}
                  </mat-option>
                </ng-container>
                <ng-template #defaultOptions>
                  <mat-option *ngFor="let option of inputs[labelInput].values" [value]="option.id">
                    {{
                      ['prioridad', 'estado'].includes(labelInput)
                        ? ('tablas.' + option.nombre.replaceAll(' ', '_') | transloco | titlecase)
                        : (option.nombre | titlecase)
                    }}
                  </mat-option>
                </ng-template>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </ng-container>
        <ng-template #noDataList>
          <div class="no-data-marks">
            <span>{{
              'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.no_data_marks'
                | transloco
            }}</span>
          </div>
        </ng-template>
        <ng-template #inputTitle>
          <mat-form-field class="input-form-tareas">
            <input
              matInput
              type="text"
              [disabled]="isLoading.general"
              #inputFilter
              [(ngModel)]="auxFilter[labelInput]"
              [placeholder]="
                ('mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.input_titulo'
                  | transloco) + '...'
              "
              (input)="changeFilter(labelInput, inputFilter.value)"
              (keyup.enter)="inputFilter.value !== '' && searchMarcadoresFilter()"
            />
          </mat-form-field>
        </ng-template>
        <div id="container-buttonfilter">
          <button
            class="button-marcadores"
            *ngIf="!isLoading.general; else filterLoading"
            [title]="
              'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.filtrar'
                | transloco
            "
            [disabled]="!(auxFilter | filterMarcadoresLength) || isLoading.general || isLoading.tareas"
            (click)="searchMarcadoresFilter()"
          >
            <ng-container>
              <mat-icon class="icon-search">search</mat-icon>
            </ng-container>
          </button>
          <ng-template #filterLoading>
            <mat-spinner></mat-spinner>
          </ng-template>
        </div>
      </div>
      <div id="applicated-filter" *ngIf="filter | filterMarcadoresLength">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <span class="title-filter"
              ><span>{{
                'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.filtros_aplicados'
                  | transloco
                  | titlecase
              }}</span
              ><span>: {{ filter | filterMarcadoresLength }}</span></span
            >
            <mat-icon
              [ngClass]="{
                'delete-trash': true,
                'delete-trash-disabled': isLoading.general || isLoading.tareas
              }"
              (click)="!isLoading.general && !isLoading.tareas && clearFilter(); $event.stopPropagation()"
              title="Borrar filtros"
              >delete</mat-icon
            >
          </mat-expansion-panel-header>
          <main>
            <ng-container *ngFor="let filtro of filter | keyvalue">
              <div class="container-specific-filter" *ngIf="filtro.value !== null">
                <span
                  [title]="
                    (filtro.key | titlecase) + ': ' + (filtro.value | dataFilterMarcadores : filtro.key : inputs)
                  "
                >
                  <span [title]=""
                    ><b>{{
                      'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.' +
                        filtro.key
                        | transloco
                        | titlecase
                    }}</b></span
                  >
                  <span id="value-filter" *ngIf="inputs[filtro.key]"
                    >:
                    <ng-container *ngIf="inputs[filtro.key].type !== TypesMarcadores.TEXT; else textNormalFilter">
                      <ng-container
                        *ngFor="let name of filtro.value | dataFilterMarcadores : filtro.key : inputs; last as isLast"
                      >
                        {{
                          ['usuario', 'tablero'].includes(filtro.key)
                            ? (name | slice : 0 : 2)
                            : filtro.key === 'prioridad'
                            ? ('tablas.' + (TareaPriority[name] | lowercase) | transloco | titlecase)
                            : filtro.key === 'estado'
                            ? ('tablas.' + name.replaceAll(' ', '_') | transloco | titlecase)
                            : name
                        }}
                        {{ !isLast ? ', ' : '' }}
                      </ng-container>
                    </ng-container>
                    <ng-template #textNormalFilter>
                      {{ filtro.value }}
                    </ng-template>
                  </span>
                </span>
                <mat-icon
                  [ngClass]="{
                    'delete-trash': true,
                    'delete-trash-disabled': isLoading.general || isLoading.tareas
                  }"
                  (click)="!isLoading.general && !isLoading.tareas && clearFilter(filtro.key); $event.stopPropagation()"
                  title="Borrar filtro"
                  >delete</mat-icon
                >
              </div>
            </ng-container>
          </main>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div id="downloads-marcadores">
    <h5>
      {{
        'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.descargas'
          | transloco
          | titlecase
      }}
    </h5>
    <div id="container-buttons">
      <button
        class="button-marcadores button-download-tareas"
        [disabled]="!tareas || !tareas.length || isLoading.tareas || isLoading.general"
        (click)="downloadFileTareas()"
      >
        <span class="ellipsis">
          <mat-spinner *ngIf="isLoading.tareas"></mat-spinner>
          {{
            !isLoading.tareas
              ? ('mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.tareas'
                | transloco
                | titlecase)
              : ('mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.descargando'
                | transloco
                | titlecase)
          }}
        </span>
      </button>
      <button
        class="button-marcadores"
        [disabled]="!marcadores?.features?.length || isLoading.tareas || isLoading.general"
        (click)="downloadFileMarcadores()"
      >
        <span class="ellipsis">
          {{
            'mapa.componentes.workspace.componentes.tool_container.tool_template.tareas.marcadores.marcadores'
              | transloco
              | titlecase
          }}
        </span>
      </button>
    </div>
  </div>
</div>
