import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFilterFn, MatCalendarCellClassFunction, MatDatepicker } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getCurveInfo } from '@src/app/@pages/navigation/mapa/componentes/workspace/componentes/curve/state/curve.selector';
import { loadHistogram } from '@src/app/@pages/navigation/mapa/componentes/workspace/componentes/histogram/state/histogram.actions';
import { getHistogramInfo } from '@src/app/@pages/navigation/mapa/componentes/workspace/componentes/histogram/state/histogram.selector';
import { getParcelaSelected } from '@src/app/@pages/navigation/mapa/componentes/workspace/componentes/workspace-inner-container/parcelas/state/parcelas.selector';
import {
  changeEnableChangeDate,
  loadDataAreaFechas,
  setSelectedDataMapaProduct,
} from '@src/app/@pages/navigation/mapa/componentes/workspace/componentes/workspace-inner-container/productos/state/productos.actions';
import {
  getFechasAvaiable,
  getFechasAvaiableCompare,
  getFechasCompareProduct,
  getFechasCompareProductSelected,
  getFechasProduct,
  getFechasProductSelected,
} from '@src/app/@pages/navigation/mapa/componentes/workspace/componentes/workspace-inner-container/productos/state/productos.selector';
import { getAreaSelected, getToolStatus } from '@src/app/@pages/navigation/mapa/state/mapa.selector';
import { Fecha } from '@src/app/objetos/fecha';
import { CalendarService } from '@src/app/servicios/calendar.service';
import { datepickerCustomHeaderService } from '@src/app/servicios/datepickerCustomHeader.service';
import { AppState } from '@src/app/store/app.state';
import { getRangeDatesDatepicker } from '@src/app/store/share/share.selector';
import { flagLoadDates, flagLoadDatesSuccess, setRangeDatepicker } from '@src/app/store/share/shared.actions';
import moment, { isMoment, Moment } from 'moment';
import { of, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CustomHeaderComponent } from './custom-header/custom-header.component';
import { Observable } from 'ol';
import { GoogleTagService } from '@src/app/servicios/googletag.service';
import { Area } from '@src/app/objetos/area';

/** FORMATO DEL INPUT DE FECHA */
const MY_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'yyyy-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'yyyy-MM-DD',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

@Component({
  selector: 'app-custom-calendar',
  templateUrl: './custom-calendar.component.html',
  styleUrls: ['./custom-calendar.component.scss'],
  //encapsulation: ViewEncapsulation.Emulated,
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CustomCalendarComponent implements OnInit, OnDestroy, OnChanges {
  // Inputs
  @Input() toolUsed: number; //1: calendario principal, 2: calendario comparar, 3: histograma, 4: descargas
  @Input() inputDate: any;
  @Input() inputDateStart: any;
  @Input() inputDateEnd: any;
  @Input() arrows: boolean = false;
  @Input() climateInfo: boolean = false;
  @Input() disabled: boolean = false;
  @Input() clearButton: boolean = false;
  @Input() color: string = 'white';
  @Input() title: string | null = null;
  @Input() range: boolean = false;

  @Output() changeInputDate: EventEmitter<any> = new EventEmitter();
  @Output() changeInputDateStart: EventEmitter<any> = new EventEmitter();
  @Output() changeInputDateEnd: EventEmitter<any> = new EventEmitter();
  @Output() clearInputDate: EventEmitter<any> = new EventEmitter();

  today: Date | null = null;
  datesRange: (Date | null)[] = [];
  // Components
  customHeader = CustomHeaderComponent;
  // Info
  showInfo: boolean = false;
  // otros datos
  histogramInfo: any;
  histogramEnabled: boolean;
  curveInfo: any;
  curveEnabled: boolean;
  parcelaSelected: any;
  isDateChanging: boolean = false;
  statusArrow: number = -1;
  calendarView: string = '';
  loadedDates: boolean = false;
  areaSelected: Area | null;

  // Fechas
  fechaSelected: Fecha | null;
  @Input() fechasProduct: any;
  rangeDates: string[] = [];
  dateFilter = (date: Date | null): boolean => {
    return false;
  };

  @Input() dateFilterCustom: any;
  dateFilterRangeAvaiable = (date: Date | null): boolean => {
    return false;
  };
  dateFilterProduct = (d: Date | null): boolean => {
    if (d) {
      var dateString = moment(isMoment(d) ? d.toDate() : d)
        .toISOString(true)
        .split('T')[0];
      return this.fechasProduct && this.fechasProduct.some((value: { fecha: string }) => value.fecha === dateString);
    }
    return false;
  };
  dateClassProduct: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    const date = new Date(cellDate);
    if (date && this.fechasProduct) {
      var dateString = moment(isMoment(date) ? date.toDate() : date)
        .toISOString(true)
        .split('T')[0];
      let fecha_data = this.fechasProduct.find(
        (value: { fecha: string } | string) =>
          (typeof value === 'object' && value.fecha === dateString) || value === dateString,
      );
      if (fecha_data && view === 'month') {
        // falta alta_freq
        /*return (fecha_data.cloud_coverage >= 0.85) ? 'noClass' : 
          (fecha_data.cloud_coverage >= 0.4) ? 'noClass' : 'noClass';*/
        return 'defaultClass';
      }
    }
    return '';
  };

  private ngUnsubscribe: Subject<any> = new Subject();

  // Constructor
  constructor(
    private store: Store<AppState>,
    private calendarService: CalendarService,
    private cdr: ChangeDetectorRef,
    private actions: Actions,
    private myDatePikcerCustomService: datepickerCustomHeaderService,
    private tagManager: GoogleTagService,
  ) {}

  ngOnInit(): void {
    this.today = this.myDatePikcerCustomService.formatDateUTC(new Date(new Date()));
    // cambio de color de datpicker
    document.documentElement.style.setProperty('--colorInput', this.color || 'white');
    if (this.toolUsed <= 4) {
      if (!this.disabled) {
        this.disabled = true;
        var changeDisabled = true;
      }
      /** escuchamos la informacion del histograma */
      this.store
        .select(getHistogramInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((value) => {
          this.histogramInfo = value;
        });
      this.store
        .select(getParcelaSelected)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((value) => {
          this.parcelaSelected = value;
        });
      // obtenemos el estado de la herrmienta histograma
      this.store
        .select(getToolStatus('histogramEnabled'))
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((value) => {
          this.histogramEnabled = value;
        });

      /** escuchamos la informacion del histograma */
      this.store
        .select(getCurveInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((value) => {
          this.curveInfo = value;
        });
      // obtenemos el estado de la herrmienta histograma
      this.store
        .select(getToolStatus('curveEnabled'))
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((value) => {
          this.curveEnabled = value;
        });

      // Escuchar las fechas del producto principal
      this.store
        .select(this.toolUsed === 2 ? getFechasCompareProduct : getFechasProduct)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((fechas: any) => {
          this.fechasProduct = fechas;
          if (fechas) {
            // asignar valor al input
            this.inputDate = [1, 2].includes(this.toolUsed)
              ? this.inputDate || new Date(fechas[fechas.length - 1].fecha)
              : this.inputDate || null;
            if (changeDisabled) this.disabled = false;
            this.changeInputDate.emit(this.inputDate);
            if (this.statusArrow !== -1) {
              // cambiar fecha si se ha cambiado con las flechas
              let index = this.fechasProduct.findIndex(
                (element: { fecha: string | number | Date }) =>
                  new Date(element.fecha) === this.inputDate || element.fecha === this.inputDate,
              );
              if (index >= 0 && index < this.fechasProduct.length) {
                // está dentro del rango, lo cambiamos
                (this.statusArrow === 0 && index--) || (this.statusArrow === 1 && index++);
                this.inputDate = new Date(this.fechasProduct[index].fecha);
                this.changeInputDate.emit(this.inputDate);
                this.changeDate();
              }
            }
            // Filter
            this.dateFilterProduct = (d: Date | null): boolean => {
              if (d) {
                var dateString = moment(isMoment(d) ? d.toDate() : d)
                  .toISOString(true)
                  .split('T')[0];
                return (
                  this.fechasProduct &&
                  this.fechasProduct.some((value: { fecha: string }) => value.fecha === dateString)
                );
              }
              return false;
            };
            this.dateFilter = this.dateFilterProduct;

            // Class
            this.dateClassProduct = (cellDate, view) => {
              const date = new Date(cellDate);
              if (date) {
                var dateString = moment(isMoment(date) ? date.toDate() : date)
                  .toISOString(true)
                  .split('T')[0];
                let fecha_data = this.fechasProduct.find((value: Fecha) => value.fecha === dateString);
                if (fecha_data && view === 'month') {
                  // falta alta_freq
                  return 'defaultClass';
                  /*return (fecha_data.cloud_coverage >= 0.85) ? 'noClass' : 
                  (fecha_data.cloud_coverage >= 0.4) ? 'noClass' : 'noClass';*/
                }
                return 'transparentClass';
              }
              return 'transparentClass';
            };
          } else {
            this.dateFilterProduct = (date: Date | null): boolean => {
              return false;
            };
            this.dateClassProduct = (cellDate, view) => {
              return 'transparentClass';
            };
          }
          this.statusArrow = -1;
          this.isDateChanging = false;
          this.cdr.detectChanges();
          this.store.dispatch(flagLoadDatesSuccess());
        });

      // Escuchar la fecha seleccionada
      this.store
        .select(this.toolUsed === 2 ? getFechasCompareProductSelected : getFechasProductSelected)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((value: any) => {
          this.fechaSelected = value;
          this.inputDate = [1, 2].includes(this.toolUsed) ? (value ? value.fecha : value) : null;
          this.changeInputDate.emit(this.inputDate);
        });

      // Escuchar la fecha seleccionada
      this.store
        .select(this.toolUsed === 2 ? getFechasAvaiableCompare : getFechasAvaiable)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((value) => {
          this.datesRange = value
            ? Object.values(value).map((element) => this.myDatePikcerCustomService.formatDateUTC(new Date(element)))
            : [];
          this.rangeDates = value ? Object.values(value).map((element) => element) : [];
          this.store.dispatch(
            setRangeDatepicker({
              calendar: this.title,
              values: this.datesRange,
            }),
          );
          this.dateFilterRangeAvaiable = value
            ? (d: any): any => {
                if (d) {
                  if (isMoment(d)) d = d.toDate();
                  var year = d.getFullYear();
                  return year >= this.datesRange[0]!.getFullYear() && year <= this.datesRange[1]!.getFullYear();
                } else {
                  false;
                }
              }
            : (date: any): boolean => {
                return false;
              };
          this.cdr.detectChanges();
        });
    }

    !(this.toolUsed <= 4) &&
      this.store
        .select(getRangeDatesDatepicker)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(({ calendar, values }) => {
          this.datesRange = this.title === calendar ? values : this.datesRange;
        });

    /**
     * Escuchamos el cambio de view
     * Solo realiza el renderizado si relamente es un cambio de vista
     */
    this.calendarService
      .changeView()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.calendarView = value;

        switch (value) {
          case 'multi-year':
            if (this.dateFilter !== this.dateFilterRangeAvaiable) {
              this.dateFilter = this.dateFilterRangeAvaiable;
              // para cambiar la vista
              this.cdr.detectChanges();
            }
            break;
          case 'month':
            if (this.dateFilterCustom) {
              if (this.dateFilter !== this.dateFilterCustom) {
                this.dateFilter = this.dateFilterCustom;
                this.cdr.detectChanges();
              }
            } else if (this.dateFilter !== this.dateFilterProduct) {
              this.dateFilter = this.dateFilterProduct;
              // para cambiar la vista
              this.cdr.detectChanges();
            }
            break;
          default:
            break;
        }
      });

    /**
     * Escucha el evento de moverse por el datepicker.
     * Se mueve de mes en mes de un año en concreto.
     */
    this.actions.pipe(ofType(flagLoadDates), takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      let { date }: any = value;
      if (date) {
        if (this.toolUsed <= 4) {
          let monthLoaded =
            this.fechasProduct &&
            this.fechasProduct.some(
              (element: { fecha: string }) => new Date(element.fecha).getFullYear() === date.getFullYear(),
            );
          if (!monthLoaded && !this.loadedDates) {
            this.store.dispatch(
              loadDataAreaFechas({
                requestType: this.toolUsed === 2 ? 'fechasCompareProduct' : 'fechasProduct',
                /*month: today.getMonth()+1, */
                year: date.getFullYear(),
                /*select: select, */
                productChanged: false,
                notValueChange: true,
              }),
            );
            this.loadedDates = true;
          } else {
            this.loadedDates = false;
            this.store.dispatch(flagLoadDatesSuccess());
          }
        } else {
          this.store.dispatch(flagLoadDatesSuccess());
        }
      }
    });
    // establecer función para vista de año
    if (!(this.toolUsed <= 4)) {
      this.dateFilterRangeAvaiable = (d: any): any => {
        if (d) {
          d = isMoment(d) ? d.toDate() : d;
          var year = d.getFullYear();
          return (
            this.datesRange &&
            this.datesRange.length > 1 &&
            year >= this.datesRange[0]!.getFullYear() &&
            year <= this.datesRange[1]!.getFullYear()
          );
        }
      };
    }

    // Obtenemos el area si antes se ha seleccionado
    this.store
      .select(getAreaSelected)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.areaSelected = value;
      });
  }

  ngOnChanges() {
    this.dateFilter = !this.dateFilterCustom ? this.dateFilterProduct : this.dateFilterCustom;
  }

  changeDateRange(value: any, input: 'start' | 'end') {
    switch (input) {
      case 'start':
        this.changeInputDateStart.emit(value);
        break;
      case 'end':
        this.changeInputDateEnd.emit(value);
        break;
    }
  }

  /**
   * Función que cambia el input a formato yyyy-mm-dd
   */
  changeDate(value?: 'start' | 'end') {
    if (!value) {
      this.inputDate = this.calendarService.changeToFormatDate(this.inputDate);

      var fecha = this.fechasProduct.find((element: any) =>
        element.fecha ? element.fecha === this.inputDate : element === this.inputDate,
      );

      if (fecha) {
        switch (this.toolUsed) {
          case 1:
            this.store.dispatch(
              setSelectedDataMapaProduct({
                data: fecha,
                typeSelected: 'fechaSelected',
              }),
            );
            this.histogramEnabled &&
              this.parcelaSelected &&
              this.store.dispatch(
                loadHistogram({
                  filter: this.histogramInfo?.filter || false,
                  compare: false,
                }),
              );
            break;
          case 2:
            this.store.dispatch(
              setSelectedDataMapaProduct({
                data: fecha,
                typeSelected: 'fechaCompareSelected',
              }),
            );
          default:
            this.changeInputDate.emit(this.inputDate);
            break;
        }
      }
    } else {
      if (value === 'start') {
        this.inputDateStart = this.calendarService.changeToFormatDate(this.inputDateStart);
        this.changeInputDateStart.emit(this.inputDateStart);
      } else {
        this.inputDateEnd = this.calendarService.changeToFormatDate(this.inputDateEnd);
        this.changeInputDateEnd.emit(this.inputDateEnd);
      }
    }
  }

  /**
   * Función que se llama al cambiar la fecha pr las flechas
   * @param type string que define el array de los elementos en el state
   * @param typeSelected string que define el elemento seleccionado en el state
   * @param typeArrow tipo de flecha seleccionada (0: izq, 1: der)
   * @param date fecha actual seleccionada (no ha cambiado aún)
   * @param fechas array de fechas del elemento (puede variar)
   */
  getNewDate(
    type: string,
    typeSelected: string,
    typeArrow: number,
    date: string,
    fechas: Fecha[],
    notChange?: boolean,
  ) {
    let findIt: boolean;
    var index = fechas.findIndex((el) => el.fecha === date);
    index == -1 ? (findIt = false) : (findIt = true);
    typeArrow === 0 ? index-- : index++;
    this.statusArrow = typeArrow;

    if (
      !findIt ||
      index < 0 ||
      (index >= fechas.length &&
        fechas.length > 0 &&
        this.rangeDates &&
        fechas[fechas.length - 1].fecha !== this.rangeDates[1])
    ) {
      // pedir nuevas fechas
      // Si el nuevo índice está fuera de las fechas del producto, pedimos las fechas del nuevo mes (anterior o posterior)
      var date_aux: Date | null = this.myDatePikcerCustomService.formatDateUTC(new Date(date));

      // para asegurar fecha correcta
      date_aux!.getDate() > 28 && date_aux!.setDate(date_aux!.getDate() - 5);
      let today: Date | null = this.myDatePikcerCustomService.formatDateUTC(
        new Date(date_aux!.setMonth(index < 0 ? date_aux!.getMonth() - 1 : date_aux!.getMonth() + 1)),
      );
      this.store.dispatch(
        loadDataAreaFechas({
          requestType: type,
          /*month: today.getMonth()+1, */
          year: today?.getFullYear(),
          /*select: select, */
          productChanged: false,
          notValueChange: ![1, 2].includes(this.toolUsed),
        }),
      );
    } else if (!(index >= fechas.length)) {
      if (!notChange) {
        this.store.dispatch(changeEnableChangeDate({ value: true }));
        this.store.dispatch(
          setSelectedDataMapaProduct({
            data: fechas[index],
            typeSelected: typeSelected,
          }),
        );
      } else {
        this.inputDate = new Date(fechas[index].fecha);
        this.changeInputDate.emit(this.inputDate);
      }
      this.statusArrow = -1;
      this.isDateChanging = false;
    }
  }

  /**
   * Función que escucha el click de las flechas exteriores
   * @param typeArrow tipo de flecha seleccionada (0: izq, 1: der)
   * @param date fecha actual
   */
  changeDateArrows(typeArrow: number, date_input: Date) {
    let date: string = moment(date_input).toISOString(true).split('T')[0];
    this.isDateChanging = true;
    if (date) {
      // reconocer qué tipo de flecha se ha clicado
      switch (this.toolUsed) {
        case 1:
        case 3:
          this.getNewDate('fechasProduct', 'fechaSelected', typeArrow, date, this.fechasProduct, this.toolUsed !== 1);
          // ver si el histograma está habilitado, pedir el nuevo
          this.toolUsed === 1 &&
            this.histogramEnabled &&
            this.parcelaSelected &&
            this.store.dispatch(
              loadHistogram({
                filter: this.histogramInfo?.filter || false,
                compare: false,
              }),
            );
          break;
        case 2:
          this.getNewDate('fechasCompareProduct', 'fechaCompareSelected', typeArrow, date, this.fechasProduct);
          break;
        default:
          break;
      }
    }
  }

  /**
   * Función que valora si cargar más fechas o no del año seleccionado
   * @param firstDate primera fecha del año seleccionado
   */
  loadDatesYear(firstDate: Date) {
    if (this.toolUsed <= 4) {
      var year = firstDate.getFullYear();
      // busca alguna fecha que coincida con el año
      var yearLoaded = this.fechasProduct.some(
        (element: { fecha: string }) => parseInt(element.fecha.split('-')[0]) === year,
      );
      yearLoaded &&
        this.store.dispatch(
          loadDataAreaFechas({
            requestType: this.toolUsed === 2 ? 'fechasCompareProduct' : 'fechasProduct',
            /*month: today.getMonth()+1, */
            year: year,
            /*select: select, */
            productChanged: false,
            notValueChange: ![1, 2].includes(this.toolUsed),
          }),
        );
    }
  }

  /**
   * Función que valora si cargar más fechas o no del mes o año seleccionado
   * @param firstDate primera fecha del año seleccionado
   */
  loadDates(date: Date | Moment) {
    if (date) {
      if (isMoment(date)) date = date.toDate();
      if (this.toolUsed <= 4) {
        var year = date.getFullYear();
        // busca alguna fecha que coincida con el año
        var yearLoaded = this.fechasProduct.some(
          (element: { fecha: string }) => parseInt(element.fecha.split('-')[0]) === year,
        );
        !yearLoaded
          ? this.store.dispatch(
              loadDataAreaFechas({
                requestType: this.toolUsed === 2 ? 'fechasCompareProduct' : 'fechasProduct',
                /*month: today.getMonth()+1, */
                year: date.getFullYear(),
                /*select: select, */
                productChanged: false,
                notValueChange: true,
              }),
            )
          : this.store.dispatch(flagLoadDatesSuccess());
      } else {
        this.store.dispatch(flagLoadDatesSuccess());
      }
    }
  }

  /**
   * Función que habilita o deshabilita la vista de info
   * @param value valor nuevo de mostrar la info
   */
  showDateInfo(value: any) {
    if (value)
      this.tagManager.sendEvent(
        'engage',
        this.areaSelected,
        'info_date',
        'Submenú',
        null,
        'Botón',
        'Información de la fecha',
        null,
        'Análisis',
        'Activa',
        'Información',
      );
    this.showInfo = value;
  }

  /**
   * Emite evento cuando se quiere limpiar el input
   */
  clearInput() {
    this.inputDate = null;
    this.inputDateStart = null;
    this.inputDateEnd = null;
    this.clearInputDate.emit();
  }

  /**
   * Actualizar los rangos específicos de cada datepicker (nunca va a haber dos abiertos a la vez)
   */
  uploadRanges() {
    this.toolUsed <= 4 && this.store.dispatch(setRangeDatepicker({ calendar: this.title, values: this.datesRange }));
  }

  /**
   * CLOSE TOOL
   */
  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
