// El tipo de dispositivo que esta en ejecución.
export enum optionName {
  TEMPERATURA_MIN_LOCAL = 'tmin_local',
  TEMPERATURA_MED_LOCAL = 'tmed_local',
  TEMPERATURA_MAX_LOCAL = 'tmax_local',
  TEMPERATURA_MIN = 'tmin',
  TEMPERATURA_MED = 'tmed',
  TEMPERATURA_MAX = 'tmax',
  PRECIPITACION_LOCAL = 'precip_local',
  RADIACION_LOCAL = 'radiation_local',
  EVAPOTRANSPIRACION_LOCAL = 'evapotrans_local',
  EVAPOTRANSPIRACION = 'evapotranspiracion',
  PRECIPITACION = 'precip',
  RADIACION = 'radiation',
  RIEGOS = 'riegos',
  FERTILIZACION = 'fertilizacion',
  MADURANTE = 'madurante',
  POTASIO = 'potasio',
  HERBICIDAS = 'herbicidas',
  FUNGICIDAS = 'fungicidas',
  INSECTICIDAS = 'insecticidas',
  ESCARIFICADO = 'escarificado',
  ALTURA = 'altura',
  OTROS = 'otros',
  BRIX = 'brix',
  JUGO = 'jugo',
  PUREZA_JUGO = 'pureza_jugo',
  MADURACION = 'maduracion',
  HUMEDAD = 'humedad',
  POL = 'pol',
  PESO = 'peso',
  SACAROSA = 'sacarosa',
  REDUCTORES = 'reductores',
  PUREZA = 'pureza',
  TRASH = 'trash',
  FIBRA = 'fibra',
  DESARROLLO = 'desarrollo',
  INDICE_MAD = 'indice_mad',
  SEMANA = 'semana',
  QUEMA_ACC = 'quema_acc',
  FLORACION = 'floracion',
  PROGRAMA = 'programa',
  HUMEDAD_SUELO = 'humedad_suelo',
}

export enum optionTable {
  CLIMA = 'clima',
  ESTACIONES = 'clima_estaciones',
  OPERACIONES = 'operaciones',
  RIEGOS = 'riegos',
  LABORATORIO = 'laboratorio',
}

export enum optionEjex {
  CLIMA = 'fecha',
  ESTACIONES = 'fecha',
  OPERACIONES = 'fecha_inicio',
  RIEGOS = 'fecha',
  LABORATORIO = 'fecha',
}

export enum optionEjey {
  TMIN = 'tmin',
  TMED = 'tmed',
  TMAX = 'tmax',
  TMIN_LOCAL = 'tmin',
  TMED_LOCAL = 'tmed',
  TMAX_LOCAL = 'tmax',
  PRECIPITACION = 'precip',
  RADIACION = 'radiation',
  PRECIPITACION_LOCAL = 'precip',
  RADIACION_LOCAL = 'radiation',
  EVAPOTRANSPIRACION_LOCAL = 'evapotrans',
  EVAPOTRANSPIRACION = 'et0',
  OPERACIONES = 'cantidad',
  RIEGOS = 'lamina_riego',
}

export enum optionColor {
  TEMPERATURA_MIN_LOCAL = '#f7e7ce',
  TEMPERATURA_MED_LOCAL = '#c19a6b',
  TEMPERATURA_MAX_LOCAL = '#ed9121',
  TEMPERATURA_MIN = '#d891ef',
  TEMPERATURA_MED = '#ff93c9',
  TEMPERATURA_MAX = '#FF0000',
  PRECIPITACION_LOCAL = '#5f9ea0',
  RADIACION_LOCAL = '#e9d66b',
  EVAPOTRANSPIRACION_LOCAL = '#c1ffc1',
  EVAPOTRANSPIRACION = '#739973',
  PRECIPITACION = '#0000FF',
  RADIACION = '#E4D31B',
  RIEGOS = '#c7dcc7',
  FERTILIZACION = '#a60532',
  MADURANTE = '#dfaf49',
  POTASIO = '#60725f',
  HERBICIDAS = '#6db56c',
  FUNGICIDAS = '#822d7e',
  INSECTICIDAS = '#9f6b14',
  ESCARIFICADO = '#66f0d1',
  ALTURA = '#66f0d1',
  OTROS = '#eea6c7',
  BRIX = '#EE22CC',
  JUGO = '#AA77EE',
  PUREZA_JUGO = '#c6d645',
  MADURACION = '#992040',
  HUMEDAD = '#be9b7b',
  POL = '#6082b6',
  PESO = '#a98166',
  SACAROSA = '#BBAA11',
  REDUCTORES = '#D0D0D0',
  PUREZA = '#FB542B',
  TRASH = '#a98166',
  FIBRA = '#a60532',
  DESARROLLO = '#b76e79',
  INDICE_MAD = '#45d7b7',
  SEMANA = '#c7c0ae',
  QUEMA_ACC = '#bb00ff',
  FLORACION = '#bb00ff',
  PROGRAMA = '#cb99c9',
  HUMEDAD_SUELO = '#d8bdc1',
}

export enum optionType {
  TEMPERATURA_MIN_LOCAL = 'line',
  TEMPERATURA_MED_LOCAL = 'line',
  TEMPERATURA_MAX_LOCAL = 'line',
  TEMPERATURA_MIN = 'line',
  TEMPERATURA_MED = 'line',
  TEMPERATURA_MAX = 'line',
  PRECIPITACION_LOCAL = 'bar',
  RADIACION_LOCAL = 'line',
  EVAPOTRANSPIRACION_LOCAL = 'bar',
  EVAPOTRANSPIRACION = 'bar',
  PRECIPITACION = 'bar',
  RADIACION = 'line',
  RIEGOS = 'bar',
  FERTILIZACION = 'bar',
  MADURANTE = 'bar',
  POTASIO = 'bar',
  HERBICIDAS = 'bar',
  FUNGICIDAS = 'bar',
  INSECTICIDAS = 'bar',
  ESCARIFICADO = 'bar',
  ALTURA = 'bar',
  OTROS = 'bar',
  BRIX = 'bar',
  JUGO = 'bar',
  PUREZA_JUGO = 'bar',
  MADURACION = 'bar',
  HUMEDAD = 'bar',
  POL = 'bar',
  PESO = 'bar',
  SACAROSA = 'bar',
  REDUCTORES = 'bar',
  PUREZA = 'bar',
  TRASH = 'bar',
  FIBRA = 'bar',
  DESARROLLO = 'bar',
  INDICE_MAD = 'bar',
  SEMANA = 'bar',
  QUEMA_ACC = 'bar',
  FLORACION = 'bar',
  PROGRAMA = 'bar',
  HUMEDAD_SUELO = 'bar',
}
