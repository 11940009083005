<div class="spinner-grow pointDark" role="status">
  <span class="visually-hidden"></span>
</div>
<div class="spinner-grow pointLight" role="status">
  <span class="visually-hidden"></span>
</div>
<div class="spinner-grow pointDark" role="status">
  <span class="visually-hidden"></span>
</div>
<div class="spinner-grow pointLight" role="status">
  <span class="visually-hidden"></span>
</div>
<div class="spinner-grow pointDark" role="status">
  <span class="visually-hidden"></span>
</div>
<div class="spinner-grow pointLight" role="status">
  <span class="visually-hidden"></span>
</div>
<div class="spinner-grow pointDark" role="status">
  <span class="visually-hidden"></span>
</div>
<div class="spinner-grow pointLight" role="status">
  <span class="visually-hidden"></span>
</div>
