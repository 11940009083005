import { Pipe, PipeTransform } from '@angular/core';
import { Capitalize } from './capitalize.pipe';
import { TranslocoService } from '@ngneat/transloco';
@Pipe({
  name: 'showFilterAdmin',
})
export class ShowFilterAdmin implements PipeTransform {
  capitalize = new Capitalize();
  constructor(private translocoService: TranslocoService) {}
  transform(filter: Object, column?: string, label?: string): string {
    let columns,
      message = '';
    if (column && filter[column] !== undefined && filter[column] !== null && typeof filter[column] !== 'object') {
      let showColumn;
      if (column.includes('fk_parcela__')) {
        showColumn = column.split('__')[1];
      } else {
        column.includes('fk_contacto') ? (showColumn = column.split('__')[1]) : (showColumn = column.split('__')[0]);
      }

      // try to translate attributes
      let valueJsonAttribute = translateConcretAttributes(
        filter[column],
        showColumn.toLocaleLowerCase(),
        label?.toLocaleLowerCase(),
      );
      // translate column attributes
      let valueJsonColumn = `attributes.${
        label === 'parcelas'
          ? 'parcela'
          : label === 'manejos'
          ? 'operaciones'
          : label === 'laboratorio'
          ? 'laboratorios'
          : label
      }.${showColumn}`;
      let translateColumn = this.translocoService.translate(valueJsonColumn);
      if (valueJsonColumn !== translateColumn && translateColumn !== ' ') showColumn = translateColumn;
      // if not exists translate, the default value is the filter content
      let translateAttribute = this.translocoService.translate(valueJsonAttribute);
      if (valueJsonAttribute === translateAttribute || translateAttribute === ' ') translateAttribute = filter[column];

      return `${this.capitalize.transform(showColumn, label)}: ${translateAttribute}`;
    }

    column && filter[column] !== undefined && filter[column] !== null
      ? (columns = { ...filter['filtro'] })
      : (columns = filter);
    if (typeof columns === 'object') {
      for (let key of Object.keys(columns)) {
        let values = key.split('__');
        let column = values[0] === 'fk_parcela' ? 'parcela ' + values[1] : values[0];
        message += column + ': ' + columns[key] + '; ';
      }
      return message;
    }
    return columns;
  }
}

const translateConcretAttributes = (value: any, key: string, label: string | undefined): string => {
  if (label === 'parcelas' && key === 'riego') return 'attributes.parcela.riego_options.' + value;
  if (key === 'cultivo') return 'admin.cultivos.' + value;
  if (label === 'manejos' && key === 'operacion') return 'admin.tiposManejo.' + value;
  if (label === 'productos' && key === 'agrupacion') return 'admin.agrupaciones.' + value;
  return value;
};
