import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import Swal from 'sweetalert2';
import { Alert } from '../interfaces/alert';

@Injectable({
  providedIn: 'root',
})
export class ErrorFormatterService {
  constructor(private translocoService: TranslocoService) {}

  /** Función que determina si debemos mostrar el error por pantalla
   * @param status código del error
   */
  enableError(status: number): boolean {
    return status >= 400 && status != 403 && status !== 604 && status !== 602;
  }

  /**
   * Función que genera la alerta a partir del error
   * @param error error recibido
   * @returns alerta
   */
  createAlert(error: HttpErrorResponse): Alert {
    var alert: Alert = {
      info: {
        title: this.translocoService.translate('errors.ups'),
        icon: this.getIconByStatus(error.status),
        text: this.getTextByString(error),
        html: this.getTextByString(error).includes('\n')
          ? `<div>${this.getTextByString(error).replaceAll('\n', '<br>')}<div>`
          : null,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        showCancelButton: this.getCancelButtonByStatus(error.status),
      },
      status: error.status,
      //active: true,
      data: this.getDataByStatus(error),
    };
    return alert;
  }

  /**
   * Función que devuelve el tipo de icono dependiendo del código del error
   * @param status codigo del error
   * @returns string del icon
   */
  getIconByStatus(status: number): string {
    if (status >= 400 && status < 500) {
      return 'warning';
    } else if (status >= 500 && status < 600) {
      return 'question';
    }
    return 'error';
  }

  /**
   * Función que devuelve la explicación del error dependiendo de lo que se devuelva desde el back
   * @param error explicación devuelta por el back
   * @returns explicación correcta
   */
  getTextByString(error: { status: any; error?: any }): string {
    try {
      if (error && error.error instanceof ArrayBuffer) {
        var enc = new TextDecoder('utf-8');
        error.error = [enc.decode(error.error)];
      } else if (error.error && error.error.error) {
        error.error = error.error.error;
      }
    } catch (error) {
      return this.translocoService.translate('errors.downloads');
    }

    if (error && error.error) {
      // en error 404 error.error es object y da fallo
      if (error.status == 500) return this.translocoService.translate('errors.resend');
      if (error.error && error.error.detail) return error.error.detail;
      if (typeof error.error === 'string' && (error.error.includes('<') || error.error.includes('>'))) {
        return this.translocoService.translate('errors.resend');
      } else if (error.status < 600) {
        if (error.error && error.error.constructor.name === 'Object') {
          // obtener datos del error
          return Object.entries(error.error).reduce((act, [key, value]) => {
            return act + key + ': ' + value + '\n';
          }, '');
        } else if (error.error && error.error.constructor.name === 'String') return error.error;
        return this.translocoService.translate('errors.download');
      }
    }

    return '';
  }

  getCancelButtonByStatus(status: any): boolean {
    return false;
  }

  showNoMatchesError(error: any) {
    if (error?.error) {
      if (error.error instanceof Blob) {
        error.error.text().then((e: any) => {
          if (typeof e === 'string') {
            Swal.fire({
              icon: 'error',
              title: this.translocoService.translate('errors.ups'),
              text: e.replaceAll('"', ''),
            });
          }
        });
      }
    }
  }

  /**
   * Función que devuelve la data en caso de que sea error propio. Para poder descargarla.
   * @param error data a descargar
   * @returns
   */
  getDataByStatus(error: HttpErrorResponse): any {
    if (error.status != 500 && (error.status >= 600 || ['object', 'string'].includes(error.error))) {
      return error.error;
    }
  }

  checkGeojsonError(geojson: any) {
    let errors: any[] = [];
    if (geojson['features'] && geojson['features'].length > 0) {
      geojson['features'].forEach((element: any) => {
        if (element.properties.errors) {
          errors.push(element.properties.errors);
        } else if (element.errors) {
          errors.push(element.errors);
        } else if (element.properties.error) {
          errors.push(element.properties.error);
        } else if (element.error) {
          errors.push(element.error);
        }
      });
    }

    return errors;
  }

  /**
   * Explicación de los códigos de error:
   * 400-499: Client error (The request contains bad syntax or cannot be fulfilled)
   * 500-599: Server Error (The server failed to fulfill an apparently valid request)
   * 600-699: Functionality Error (The server failed to return correct data because of the input)
   * 601: Error fileToGeojson (deivi)
   * 602: Error descarga datos admin (deivi). Para los filtros aplicados, no hay información que devover
   *
   * 604: Error en el token para descargar datos del mapa
   */

  /**
   * Explicación de los tipos de error:
   * 0: Mostrar solo botón de confirmación
   * 1: Mostrar botón de confirmar y botón de cancelar
   */
}
