import { Pagination } from '@src/app/interfaces/Admin/pagination';

export interface AdminState {
  pagination: Pagination | null;
  load: { show: boolean };
  selectedArea: number | any;
  estaciones: any;
  saveState: boolean;
  tab_selected: number;
  variedades: Object;
  appliedFilter: Object;
  csvErrors: Object[];
  createCsvErrors: boolean;
}

export const initialStateAdmin: AdminState = {
  pagination: null,
  load: { show: false },
  estaciones: null,
  selectedArea: null,
  saveState: false,
  variedades: {},
  tab_selected: 0,
  appliedFilter: {},
  csvErrors: [],
  createCsvErrors: false,
};
