import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml',
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(v: string, type?: string): SafeHtml | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'url':
        return this._sanitizer.bypassSecurityTrustUrl(v);
      case 'resourceUrl':
        return this._sanitizer.bypassSecurityTrustResourceUrl(v);
      default:
        return this._sanitizer.bypassSecurityTrustHtml(v);
    }
  }
}
