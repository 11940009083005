import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AgreementService } from '@src/app/servicios/agreement.service';
import { AuthService } from '@src/app/servicios/auth.service';
import { DriveService } from '@src/app/servicios/drive.service';
import { GoogleTagService } from '@src/app/servicios/googletag.service';
import { UserService } from '@src/app/servicios/user.service';
import { AppState } from '@src/app/store/app.state';
import { setLoadingSpinner } from '@src/app/store/share/shared.actions';

import { forkJoin, ObservableInput, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { loadUser } from '../../inicio/state/user.actions';
import { noData } from '../../navigation/mapa/componentes/workspace/componentes/openlayers/state/openlayers.actions';
import {
  authTokenDrive,
  authTokenDriveSuccess,
  autoLogin,
  autoLoginFail,
  loginStart,
  loginSuccess,
  logout,
  logoutSuccess,
  checkUser,
  setValidations,
  registerUser,
  registerStatus,
  resendEmail,
  verifyUser,
  verifyResult,
  agreementSign,
  agreementSignSuccess,
} from './auth.actions';
import { initialStateAuth } from './auth.state';

@Injectable()
export class AuthEffects {
  constructor(
    private userService: UserService,
    private driveService: DriveService,
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router,
    private googleTagService: GoogleTagService,
    private agreementService: AgreementService,
  ) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginStart),
      exhaustMap((action) => {
        return this.authService.login(action.user, action.password).pipe(
          map((data) => {
            const token = this.authService.format(data);
            this.authService.setTokenDataInLocalStorage(token);
            this.store.dispatch(loginSuccess({ token }));
            return loadUser();
          }),
          catchError((errResp) => {
            return of(setLoadingSpinner({ status: false }));
          }),
        );
      }),
    );
  });

  autoLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(autoLogin),
      mergeMap((action) => {
        let { notRedirect } = action;
        if (window.location.href.includes('login') && window.location.href.includes('autologin=false')) {
          localStorage.removeItem('token');
          return of(noData());
        }
        const token = this.authService.getTokenFromLocalStorage();
        if (token != null) {
          this.store.dispatch(loginSuccess({ token }));
          // es downloads y se ejecuta esto
          !notRedirect && this.router.navigate(['/area/mapa']);
          return of(loadUser());
        } else {
          if (
            (window.location.href.split('login')[1] && window.location.href.split('login')[1].includes('?token')) ||
            (window.location.href.split('recover-password')[1] &&
              window.location.href.split('recover-password')[1].includes('?token')) ||
            window.location.href.includes('/register')
          ) {
            return of(noData());
          } else {
            this.router.navigate(['/login']);
            return of(autoLoginFail());
          }
        }
      }),
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logout),
      mergeMap((action) => {
        this.googleTagService.addToDataLayer(null);
        this.authService.removeLocalStorage();
        this.router.navigate(['/login']);
        return of(logoutSuccess({ token: initialStateAuth.token }));
      }),
    );
  });

  checkUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(checkUser),
      mergeMap((action) => {
        let { data, isLogin } = action;
        let body: any = {};
        let label: string = '';
        if (data['user']) label = 'user';
        else if (data['email']) label = 'email';

        body[label] = action.data[label];
        return this.userService.checkUserAndEmail(JSON.stringify(body)).pipe(
          map((result) => {
            return setValidations({
              validations: { [label]: result.includes('no'), message: result },
              label: label,
              isLogin: isLogin,
            });
          }),
          catchError((error) => {
            return of(
              setValidations({
                validations: { [label]: false },
                label: label,
                isLogin: isLogin,
              }),
            );
          }),
        );
      }),
    );
  });

  registerUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(registerUser),
      mergeMap((action) => {
        let { data } = action;
        return this.userService.register(JSON.stringify(data)).pipe(
          map((result) => {
            return typeof result === 'string' && result !== 'string'
              ? registerStatus({
                  result: false,
                  message:
                    typeof result === 'object'
                      ? Object.entries(result).reduce((act, element) => {
                          return act + element[0] + ': ' + element[1] + '\n';
                        }, '')
                      : '',
                })
              : registerStatus({ result: true });
          }),
          catchError((error) => {
            return of(
              registerStatus({
                result: false,
                message:
                  typeof error === 'object'
                    ? Object.entries(error).reduce((act, element) => {
                        return act + element[0] + ': ' + element[1] + '\n';
                      }, '')
                    : error !== 'string'
                    ? error
                    : '',
              }),
            );
          }),
        );
      }),
    );
  });

  resendEmailUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resendEmail),
      mergeMap((action) => {
        let { user, email } = action;
        let body: any = {};
        if (user) body['user'] = user;
        else if (email) body['email'] = email;
        return this.userService.resendVerify(JSON.stringify(body)).pipe(
          map((result) => {
            return setLoadingSpinner({ status: false });
          }),
          catchError((error) => {
            return of(setLoadingSpinner({ status: false }));
          }),
        );
      }),
      catchError((error) => {
        return of(setLoadingSpinner({ status: false }));
      }),
    );
  });

  verifyUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(verifyUser),
      mergeMap((action) => {
        let { token } = action;
        return this.userService.verifyUser(token).pipe(
          map((result) => {
            return verifyResult({
              result: result.toLowerCase().includes('verificado'),
            });
          }),
          catchError((error) => {
            return of(verifyResult({ result: false }));
          }),
        );
      }),
    );
  });

  agreementSign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(agreementSign),
      mergeMap((action) => {
        let { privacy_nax, privacy_fira, confidency_nax } = action;
        var petitions: ObservableInput<any>[] = [];
        if (privacy_nax)
          petitions.push(
            this.agreementService.agreementSign({
              issuer: 'nax',
              type: 'privacy',
            }),
          );
        if (privacy_fira)
          petitions.push(
            this.agreementService.agreementSign({
              issuer: 'fira',
              type: 'privacy',
            }),
          );
        if (confidency_nax)
          petitions.push(
            this.agreementService.agreementSign({
              issuer: 'nax',
              type: 'confidency',
            }),
          );
        return forkJoin(petitions).pipe(
          map((result) => {
            return agreementSignSuccess({ success: true });
          }),
          catchError((error) => {
            Swal.fire({
              icon: 'error',
              title: 'Error al aceptar los términos',
            });
            return of(logout());
          }),
        );
      }),
    );
  });

  authTokenDrive$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authTokenDrive),
      mergeMap((action) => {
        return this.driveService.getTokenDrive().pipe(
          map((result: string) => {
            return authTokenDriveSuccess({ tokenDrive: result });
          }),
        );
      }),
    );
  });
}
