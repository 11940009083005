import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { initialStateUser } from '@src/app/@pages/inicio/state/user.state';
import { initialStateAuth } from '@src/app/@pages/login/state/auth.state';
import { initialStateAdmin } from '@src/app/@pages/navigation/admin/state/admin.state';
import { initialStateUserTable } from '@src/app/@pages/navigation/admin/tablefactory/table-usuario/state/usertable.state';
import { initialStateNotifications } from '@src/app/@pages/navigation/components/notifications/state/notifications.state';
import { initialStateDashboard } from '@src/app/@pages/navigation/dashboard/state/dashboard.state';
import { initialStateCurve } from '@src/app/@pages/navigation/mapa/componentes/workspace/componentes/curve/state/curve.state';
import { initialStateHistogram } from '@src/app/@pages/navigation/mapa/componentes/workspace/componentes/histogram/state/histogram.state';
import { initialStateLegend } from '@src/app/@pages/navigation/mapa/componentes/workspace/componentes/legend/state/legend.state';
import { initialStateOpenlayers } from '@src/app/@pages/navigation/mapa/componentes/workspace/componentes/openlayers/state/openlayers.state';
import { initialStateToolcontainer } from '@src/app/@pages/navigation/mapa/componentes/workspace/componentes/tool-container/state/tool-container.state';
import { initialStateFiltros } from '@src/app/@pages/navigation/mapa/componentes/workspace/componentes/workspace-inner-container/filtros/state/filtros.state';
import { initialStateParcelas } from '@src/app/@pages/navigation/mapa/componentes/workspace/componentes/workspace-inner-container/parcelas/state/parcelas.state';
import { initialStateMapa } from '@src/app/@pages/navigation/mapa/state/mapa.state';
import { initialStateNavigation } from '@src/app/@pages/navigation/state/navigation.state';
import { initialStateTareas } from '@src/app/@pages/navigation/tareas/componentes/boards/state/boards.state';
import { initialStateBoards } from '@src/app/@pages/navigation/tareas/state/tareas.state';
import {
  loadAlert,
  loadUniquesLimitedSuccess,
  setErrorMesage,
  setLoadingSpinner,
  setPlatformDevice,
  editProgressBar,
  setRangeDatepicker,
} from './shared.actions';
import { initialStateShared, SharedState } from './shared.state';

const _shareReducer = createReducer(
  initialStateShared,
  on(setLoadingSpinner, (state, action) => {
    return {
      ...state,
      showLoading: action.status,
    };
  }),

  on(setErrorMesage, (state, action) => {
    return {
      ...state,
      errorMessage: action.message,
    };
  }),

  on(setPlatformDevice, (state, action) => {
    return {
      ...state,
      device: action.device,
    };
  }),

  on(loadUniquesLimitedSuccess, (state, action) => {
    return {
      ...state,
      uniquesLimited: action.values,
    };
  }),

  on(loadAlert, (state: any, action) => {
    return {
      ...state,
      alert: action.alert,
    };
  }),
  on(setRangeDatepicker, (state: any, { calendar, values }) => {
    return {
      ...state,
      rangeDatesDatepicker: {
        calendar: calendar,
        values: values,
      },
    };
  }),
  on(editProgressBar, (state, { display, value, mode, label }) => {
    let newProgressBar = JSON.parse(JSON.stringify(state.myProgressBar));
    newProgressBar.display = display;
    newProgressBar.value = value;
    newProgressBar.mode = mode;
    newProgressBar.label = label ? label : newProgressBar.label;
    return {
      ...state,
      myProgressBar: newProgressBar,
    };
  }),
);
export function SharedReducer(state: SharedState | undefined, action: Action) {
  return _shareReducer(state, action);
}

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  // MetaReducer que se ejecuta antes de cada reducer. Se usa par
  // eliminar todos los datos que se han ido guardando en el state.
  //  Solo se dejan los datos que hay en shared de momento.
  return function (state, action) {
    if (action.type === '[ auth page] auto logout success') {
      var shared_aux = Object.assign({}, state.shared);
      state = {
        shared: shared_aux,
        user: initialStateUser,
        auth: initialStateAuth,
        admin: initialStateAdmin,
        notifications: initialStateNotifications,
        dashboard: initialStateDashboard,
        mapa: initialStateMapa,
        curve: initialStateCurve,
        histogram: initialStateHistogram,
        legend: initialStateLegend,
        openlayers: initialStateOpenlayers,
        toolcontainer: initialStateToolcontainer,
        filtros: initialStateFiltros,
        parcelas: initialStateParcelas,
        navigation: initialStateNavigation,
        boards: initialStateBoards,
        tareas: initialStateTareas,
        userTable: initialStateUserTable,
      };

      //return reducer(state,action)
      return reducer(undefined, action);
    }
    return reducer(state, action);
  };
}
