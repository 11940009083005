import { createAction, props } from '@ngrx/store';
import { Fecha } from '@src/app/objetos/fecha';
import { Producto } from '@src/app/objetos/producto';

/** PRODUCT */
export const LOAD_DATA_AREA_PRODUCTS = '[ productos page] request load data area products';
export const LOAD_PRODUCTS_AREA_SUCCESS = '[ productos page] request load produtcs area success';

export const loadDataAreaProducts = createAction(
  LOAD_DATA_AREA_PRODUCTS,
  props<{ id: number; body: string; requestType: string; arrows?: boolean }>(),
);

export const loadProductsAreaSuccess = createAction(
  LOAD_PRODUCTS_AREA_SUCCESS,
  props<{ data: Producto[] | null; requestType: string }>(),
);

export const SET_DATA_SELECTED_MAPA_PRODUCT = '[ productos page] set data selected';

export const setSelectedDataMapaProduct = createAction(
  SET_DATA_SELECTED_MAPA_PRODUCT,
  props<{ data: any; typeSelected: string }>(),
);

/** FECHAS */
export const LOAD_DATA_AREA_FECHAS = '[ productos page] request load data area fechas';

export const loadDataAreaFechas = createAction(
  LOAD_DATA_AREA_FECHAS,
  props<{
    requestType: string;
    year: number | undefined;
    productChanged: boolean;
    notValueChange?: boolean;
  }>(),
);

// PRODUCTO
export const LOAD_FECHAS_PRODUCTO_SUCCESS = '[ productos page] request load fechas producto success';

export const loadFechasProductoSuccess = createAction(
  LOAD_FECHAS_PRODUCTO_SUCCESS,
  props<{ data: Fecha[]; requestType: string; productChanged: boolean }>(),
);

export const CHANGE_ENABLE_CHANGE_DATE = '[productos page] change enable change date';

export const changeEnableChangeDate = createAction(CHANGE_ENABLE_CHANGE_DATE, props<{ value: boolean }>());

export const CHANGE_PIDIENDO_DATE = '[productos page] change pidiendo date';

export const changePidiendoDate = createAction(CHANGE_PIDIENDO_DATE, props<{ value: boolean }>());

export const RESET_PRODUCT_STATE = '[ productos page] reset product state';

export const resetProductState = createAction(RESET_PRODUCT_STATE);

export const LOAD_FECHAS_AVAIABLE = '[ productos page] load fechas avaiable';
export const LOAD_FECHAS_AVAIABLE_SUCCESS = '[ productos page] load fechas avaiable success';

export const loadFechasAvaiable = createAction(LOAD_FECHAS_AVAIABLE, props<{ idProducto: number; compare: boolean }>());
export const loadFechasAvaiableSuccess = createAction(
  LOAD_FECHAS_AVAIABLE_SUCCESS,
  props<{ fechas: any[] | null; variable: string }>(),
);

export const LOAD_PRODUCT_SOURCE = '[ productos page] load product source';

export const loadProductSource = createAction(LOAD_PRODUCT_SOURCE, props<{ source: any }>());

/** INFO */
export const LOAD_DATE_INFO = '[productos page] load date info';
export const LOAD_DATE_INFO_SUCCESS = '[productos page] load date info success';

export const loadDateInfo = createAction(LOAD_DATE_INFO);

export const loadDateInfoSuccess = createAction(LOAD_DATE_INFO_SUCCESS, props<{ info: any }>());

/** OTHER */
export const SET_LOADING_PRODUCTS = '[productos page] set loading products';
export const setLoadingProducts = createAction(SET_LOADING_PRODUCTS, props<{ loading: boolean; label?: string }>());
