/* 
1: tipo number -> slider
2: tipo date -> slider date
3: tipo string -> checkbox 
4: tipo boolean -> checkbox boolean 
5: nada
*/

export enum tiposAtributosRiego {
  fecha = 2,
  ha_regadas = 1,
  operacion = 1,
  lamina_riego = 1,
  horas = 1,
  hora_inic = 1,
  fecha_fin = 2,
  fk_parcela_id = 3,
}
