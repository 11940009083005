<div class="example-header">
  <!-- legend -->
  <!-- <div class="legend" *ngIf="!inDashboard">
    <div style="background-color:dodgerblue; padding: 0 0.5em;">
      Nubosidad alta
    </div>
    <div style="background-color:lightsalmon; padding: 0 0.5em;">
      {{ "shared.custom.nubeMedia" | transloco }}
    </div>
    <div style="background-color:lightgray; padding: 0 0.5em;">
      {{ "shared.custom.nubeBaja" | transloco }}
    </div>
  </div> -->
  <div class="header-menu">
    <!-- date selected + year view -->
    <button
      mat-raised-button
      class="date-button"
      (click)="yearViewClicked()"
      [disabled]="calendar.currentView == 'multi-year' || calendar.currentView == 'year'"
    >
      <span class="example-header-label">{{ periodLabel }}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <div class="arrow-buttons">
      <!-- previous month -->
      <button
        mat-icon-button
        style="outline: none"
        [disabled]="calendar.currentView === 'year' || disabledArrowLeft || loadingDates"
        (click)="customPreviousNextClicked(-1)"
      >
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <!-- next month -->
      <button
        mat-icon-button
        style="outline: none"
        [disabled]="calendar.currentView === 'year' || disabledArrowRight || loadingDates"
        (click)="customPreviousNextClicked(1)"
      >
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </div>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="loadingDates"></mat-progress-bar>
