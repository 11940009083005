import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TareaState } from './boards.state';

export const TAREAS_STATE_NAME = 'tareas';

const getTareasState = createFeatureSelector<TareaState>(TAREAS_STATE_NAME);

export const getTareas = createSelector(getTareasState, (state) => {
  return {
    pendientes: state.tareasPendientes,
    proceso: state.tareasProceso,
    hechas: state.tareasHechas,
  };
});

export const getTableroActual = createSelector(getTareasState, (state) => {
  return state.tableroActual;
});

export const getInformeCampo = createSelector(getTareasState, (state) => {
  return state.tableroActual.configuraciones;
});

export const getDataFiltros = createSelector(getTareasState, (state) => {
  return state.dataFiltros;
});

export const getOpciones = createSelector(getTareasState, (state) => {
  return state.opcionesConfiguraciones;
});

export const getListaUsuariosAsignados = createSelector(getTareasState, (state) => {
  return state.listaUsuariosAsignados;
});

export const getInformacionTarea = createSelector(getTareasState, (state) => {
  return state.selectedTarea;
});

export const getLoadingImage = createSelector(getTareasState, (state) => {
  return state.loadingImage;
});
