// El tipo de dispositivo que esta en ejecución.
export enum tabHeader {
  USUARIO = 'usuarios',
  AREAS = 'areas',
  PARCELAS = 'parcelas',
  PRODUCTOS = 'productos',
  RIEGOS = 'riegos',
  LABORATORIO = 'laboratorio',
  HISTORICOS = 'historicos',
  MANEJOS = 'manejos',
  ESTACIONES = 'estaciones',
  CLIMAS = 'clima',
}

export enum tabindex {
  USUARIO = 0,
  AREAS = 1,
  PARCELAS = 2,
  PRODUCTOS = 3,
  RIEGOS = 4,
  LABORATORIO = 5,
  HISTORICOS = 6,
  MANEJOS = 7,
  ESTACIONES = 8,
  CLIMAS = 9,
}
export enum filtersTable {
  INPUTFILTER = 'filtrar',
  SELECTORAREA = 'selectorArea',
  SELECTORESTACION = 'selectorEstacion',
}

/**
 * DATA TYPES IN EACH COLUMN
 * 0. string
 * 1. boolean
 * 2. date
 * 3. number
 * 4. other => (list)
 * 5. filter no available
 */

export const columnsTable = {
  usuarios: {
    user: 0,
    password: 5,
    email: 4,
    ver_dashboard: 1,
    ver_tarea: 1,
    ver_admin: 1,
    editar_parcela: 1,
    areas: 5,
    nombre: 4,
    apellidos: 4,
    phone: 4,
    pais: 4,
    cultivo: 4,
    language: 4,
    last_login: 2,
    actions: 5,
  },
  areas: {
    //nombre: 0,
    titulo: 0,
    superficie: 5,
    id_label: 0,
    unidad_01: 0,
    unidad_02: 0,
    unidad_03: 0,
    unidad_04: 0,
    unidad_05: 0,
    reinicio: 1,
    zafra_cont: 1,
    freinicio: 2,
  },
  parcelas: {
    id: 0,
    unidad_01: 0,
    unidad_02: 0,
    unidad_03: 0,
    unidad_04: 0,
    unidad_05: 0,
    variedad: 4,
    edad: 5,
    fs: 2,
    fc: 2,
    fci: 2,
    fi: 2,
    rend: 3,
    cultivo: 4,
    area: 3,
    area_ef: 3,
    soca: 3,
    fe: 2,
    semillero: 1,
    zafra: 3,
    fagst: 2,
    fc_est: 2,
    riego: 3,
    rend_est: 3,
    zonas: 0,
    zona_eco: 3,
    fk_pixel: 5,
    agua_disp: 3,
    agua_aprov: 3,
    agua_act: 3,
    rend_neto: 3,
    ha_corte: 3,
    fecha_max: 2,
    estrato: 0,
    tipo_cos: 0,
    rend_core: 3,
    rend_ind: 3,
    semana_mad: 3,
    obs_madur: 0,
    cuadrante: 0,
    tercio: 3,
    riegos_pre: 3,
    riegos_pos: 3,
    activo: 1,
    alta_freq: 1,
    fk_estacion: 5,
    inicio_curva: 2,
  },
  productos: {
    nombre: 0,
    titulo: 0,
    show: 1,
    agrupacion: 4,
  },
  riegos: {
    id: 0,
    fk_parcela_id: 3,
    id_parcela_actual: 0,
    fecha: 2,
    ha_regadas: 3,
    operacion: 3,
    lamina_riego: 3,
    horas: 3,
    hora_inic: 3,
    fecha_fin: 2,
  },
  laboratorio: {
    id: 0,
    fk_parcela_id: 3,
    id_parcela_actual: 0,
    fecha: 2,
    edad: 5,
    brix: 3,
    sacarosa: 3,
    reductores: 3,
    humedad: 3,
    humedad_suelo: 3,
    identificador: 0,
    pureza: 3,
    jugo: 3,
    maduracion: 3,
    peso: 3,
    pol: 3,
    pureza_jugo: 3,
    trash: 3,
    fibra: 3,
    desarrollo: 3,
    indice_mad: 3,
    semana: 3,
    quema_acc: 3,
    floracion: 3,
    programa: 3,
    plagas: 3,
  },
  historicos: {
    id: 3,
    fk_parcela_id: 3,
    id_parcela_actual: 0,
    cultivo: 4,
    variedad: 0,
    zafra: 3,
    soca: 3,
    fs: 2,
    fc: 2,
    fi: 2,
    fe: 2,
    rend: 3,
    tah: 3,
    sacarosa: 3,
    espacio_surcos: 3,
  },
  manejos: {
    id: 3,
    fk_parcela_id: 3,
    id_parcela_actual: 0,
    operacion: 4,
    descripcion: 0,
    comentario: 0,
    producto: 0,
    cantidad: 3,
    fecha_inicio: 2,
    fecha_fin: 2,
    area: 3,
    codigo: 0,
    tipo_aplicacion: 0,
    equipo_aplicacion: 0,
  },
  estaciones: {
    identificador: 3,
    nombre: 0,
  },
  clima: {
    fecha: 2,
    tmax: 3,
    tmed: 3,
    tmin: 3,
    precip: 3,
    radiation: 3,
    evapotrans: 3,
  },
};

export const filterCsvTable = {
  usuarios: ['productos', 'areas', 'filtro', 'fk_cliente', 'fk_tablero'],
  areas: ['padre', 'epsg_proj', 'epsg_code', 'subarea', 'terminado', 'agrupacion'],
  parcelas: ['fk_area', 'area', 'edad', 'area_ef', 'fk_pixel'],
};
