import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';
import { Tablero } from '../objetos/tablero';
import { TableroInfo } from '../objetos/tableroinfo';
import { Tarea } from '../objetos/tarea';

@Injectable({
  providedIn: 'root',
})
export class TareasService {
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, private translocoService: TranslocoService) {}

  /** VARIABLES */
  /**
   * Bodys para las configuraciones automáticas de ciertos tableros
   * @returns Precosecha, Malezas, Muestreo de Suelo, Despoblamiento y Enfermedades
   */
  bodysConfiguraciones() {
    return [
      [
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.precosecha.edad',
        )}","tipo":"number","agrupacion":"general"}`,
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.precosecha.fecha_muestreo',
        )}","tipo":"date","agrupacion":"general"}`,
      ],

      [
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.malezas.tipo_maleza',
        )}","tipo":"text","agrupacion":"general"}`,
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.malezas.fecha_muestreo',
        )}","tipo":"date","agrupacion":"general"}`,
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.malezas.area_afectada',
        )}","tipo":"number","agrupacion":"general"}`,
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.malezas.tratamiento',
        )}","tipo":"selector","agrupacion":"general"}`,
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.malezas.producto_aplicado',
        )}","tipo":"text","agrupacion":"general"}`,
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.malezas.cantidad_aplicada',
        )}","tipo":"number","agrupacion":"general"}`,
      ],

      [
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.suelo.nitrogeno',
        )}","tipo":"number","agrupacion":"general"}`,
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.suelo.fecha_muestreo',
        )}","tipo":"date","agrupacion":"general"}`,
      ],

      [
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.despYenf.tipo',
        )}","tipo":"selector","agrupacion":"general"}`,
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.despYenf.fecha_muestreo',
        )}","tipo":"date","agrupacion":"general"}`,
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.despYenf.descripcion',
        )}","tipo":"text","agrupacion":"general"}`,
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.despYenf.area_afectada',
        )}","tipo":"number","agrupacion":"general"}`,
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.despYenf.severidad',
        )}","tipo":"selector","agrupacion":"general"}`,
        `{"nombre":"${this.translocoService.translate(
          'tablas.configuraciones.predeterminadas.despYenf.tratamiento',
        )}","tipo":"text","agrupacion":"general"}`,
      ],
    ];
  }

  /**
   * Variables de opciones para ciertas configuraciones
   * @param value Configuración
   * @returns Opciones según la configuración
   */
  opcionesTableros(value: { nombre: any; tipo?: string; id: any }) {
    switch (value.nombre) {
      case 'Tratamiento':
      case 'Treatment':
      case 'Tratamento':
        return [
          {
            fk_configuracion_id: value.id,
            opcion: this.translocoService.translate(
              'tablas.configuraciones.predeterminadas.selectores.tratamiento.manual',
            ),
          },
          {
            fk_configuracion_id: value.id,
            opcion: this.translocoService.translate(
              'tablas.configuraciones.predeterminadas.selectores.tratamiento.quimico',
            ),
          },
          {
            fk_configuracion_id: value.id,
            opcion: this.translocoService.translate(
              'tablas.configuraciones.predeterminadas.selectores.tratamiento.ninguno',
            ),
          },
          {
            fk_configuracion_id: value.id,
            opcion: this.translocoService.translate(
              'tablas.configuraciones.predeterminadas.selectores.tratamiento.otro',
            ),
          },
        ];
      case 'Tipo':
      case 'Type':
        return [
          {
            fk_configuracion_id: value.id,
            opcion: this.translocoService.translate('tablas.configuraciones.predeterminadas.selectores.tipo.quema'),
          },
          {
            fk_configuracion_id: value.id,
            opcion: this.translocoService.translate(
              'tablas.configuraciones.predeterminadas.selectores.tipo.anegamiento',
            ),
          },
          {
            fk_configuracion_id: value.id,
            opcion: this.translocoService.translate('tablas.configuraciones.predeterminadas.selectores.tipo.maleza'),
          },
          {
            fk_configuracion_id: value.id,
            opcion: this.translocoService.translate('tablas.configuraciones.predeterminadas.selectores.tipo.sequía'),
          },
          {
            fk_configuracion_id: value.id,
            opcion: this.translocoService.translate('tablas.configuraciones.predeterminadas.selectores.tipo.plaga'),
          },
          {
            fk_configuracion_id: value.id,
            opcion: this.translocoService.translate(
              'tablas.configuraciones.predeterminadas.selectores.tipo.despoblamiento',
            ),
          },
          {
            fk_configuracion_id: value.id,
            opcion: this.translocoService.translate('tablas.configuraciones.predeterminadas.selectores.tipo.otros'),
          },
        ];
      case 'Severidad':
      case 'Severity':
      case 'Gravidade':
        return [
          {
            fk_configuracion_id: value.id,
            opcion: this.translocoService.translate('tablas.configuraciones.predeterminadas.selectores.severidad.leve'),
          },
          {
            fk_configuracion_id: value.id,
            opcion: this.translocoService.translate(
              'tablas.configuraciones.predeterminadas.selectores.severidad.media',
            ),
          },
          {
            fk_configuracion_id: value.id,
            opcion: this.translocoService.translate(
              'tablas.configuraciones.predeterminadas.selectores.severidad.grave',
            ),
          },
        ];
    }
  }

  /** añadir */
  addTareaMarcador(
    areaId: any,
    datos: {
      fk_tarea: any;
      parcelas: any;
      identificador: any;
      geometry: any;
      value: any;
    },
  ) {
    return this.http.post(`${environment.databaseURL}/rest/areas/${areaId}/marcadores`, datos, this.httpOptions);
  }

  addTarea(fk_tablero: string | number | null, datos: any) {
    return this.http.post(`${environment.databaseURL}/rest/tableros/${fk_tablero}/tareas`, datos, this.httpOptions);
  }

  addConfiguracion(fk_tablero: string | null, datos: any): Observable<JSON> {
    return this.http.post<JSON>(
      `${environment.databaseURL}/rest/tableros/${fk_tablero}/configuraciones`,
      datos,
      this.httpOptions,
    );
  }

  addOpcionesBulk(datos: { fk_configuracion_id: any; opcion: any }[]) {
    return this.http.post(`${environment.databaseURL}/rest/opciones/bulk_upload`, datos, this.httpOptions);
  }

  updateOpcionesBulk(datos: { id: any; opcion: any }[]) {
    return this.http.put(`${environment.databaseURL}/rest/opciones/bulk_update`, datos, this.httpOptions);
  }

  updateBulkListaAsignados(datos: { id: any; fk_tablero: string | null }[]) {
    return this.http.put(`${environment.databaseURL}/rest/usuarios/bulk_update`, datos, this.httpOptions);
  }

  addValoresConfigBulk(idTarea: any, datos: { fk_configuracion: string; valor?: unknown; fk_opcion?: unknown }[]) {
    return this.http.post(
      `${environment.databaseURL}/rest/tareas/${idTarea}/configuraciones/bulk_upload`,
      datos,
      this.httpOptions,
    );
  }

  addTablero(fk_cliente: any, datos: any) {
    return this.http.post(`${environment.databaseURL}/rest/clientes/${fk_cliente}/tableros`, datos, this.httpOptions);
  }

  /** obtener */
  getTareasFilter(fk_tablero: string | null, body: { filtro: any }) {
    return this.http.post<Tarea[]>(
      `${environment.databaseURL}/rest/tableros/${fk_tablero}/tareasFilter`,
      body,
      this.httpOptions,
    );
  }

  getTareas(fk_tarea: number): Observable<Tarea> {
    return this.http.get<Tarea>(`${environment.databaseURL}/rest/tareasdetail/${fk_tarea}`);
  }

  getUsuariosAsignarTablero(idTablero: any) {
    return this.http.get(`${environment.databaseURL}/rest/tableros/${idTablero}/usuariospage`);
  }

  getPaginatorAsignados(url: any) {
    return this.http.get(`${environment.databaseURL}${url}`);
  }

  getScrollPaginatorAsignados(url: any, datos: { filtro: { user__icontains: string } }) {
    return this.http.post(`${environment.databaseURL}${url}`, datos, this.httpOptions);
  }

  getUsuariosFiltrados(idTablero: any, datos: { filtro: { user__icontains: string } }) {
    return this.http.post(
      `${environment.databaseURL}/rest/tableros/${idTablero}/usuariospage`,
      datos,
      this.httpOptions,
    );
  }

  getDatosInformeCampo(id_tarea: any) {
    return this.http.get(`${environment.databaseURL}/rest/tareas/${id_tarea}/configuraciones`);
  }

  getImageTarea(idTarea: any) {
    return this.http.get(`${environment.databaseURL}/rest/tareasdetail/${idTarea}/imagen`);
  }

  getTableros(fk_cliente: number): Observable<Tablero[]> {
    return this.http.get<Tablero[]>(`${environment.databaseURL}/rest/clientes/${fk_cliente}/tableros`);
  }

  getTableroInfo(fk_tablero: any): Observable<TableroInfo[]> {
    return this.http.get<TableroInfo[]>(`${environment.databaseURL}/rest/tableros/${fk_tablero}`);
  }

  /** eliminar */
  deleteTarea(tarea_id: any) {
    return this.http.delete(`${environment.databaseURL}/rest/tareas/${tarea_id}`);
  }

  deleteConfiguracion(fk_config: any) {
    return this.http.delete(`${environment.databaseURL}/rest/configuraciones/${fk_config}`);
  }

  deleteOpcionesBulk(datos: any) {
    return this.http.delete(`${environment.databaseURL}/rest/opciones/bulk_delete`, datos);
  }

  deleteValoresConfigBulk(idTarea: any, datos: any) {
    return this.http.post(
      `${environment.databaseURL}/rest/tareas/${idTarea}/configuraciones/bulk_delete`,
      datos,
      this.httpOptions,
    );
  }

  deleteTablero(idTablero: number | null) {
    return this.http.delete(`${environment.databaseURL}/rest/tableros/${idTablero}`);
  }

  /** editar */
  editTarea(tarea_id: any, datos: string) {
    return this.http.put(`${environment.databaseURL}/rest/tareas/${tarea_id}`, datos, this.httpOptions);
  }

  editTablero(tablero_id: string | null, datos: string) {
    return this.http.put(`${environment.databaseURL}/rest/tableros/${tablero_id}`, datos, this.httpOptions);
  }

  editConfiguracion(fk_configuracion: any, datos: string) {
    return this.http.put(
      `${environment.databaseURL}/rest/configuraciones/${fk_configuracion}`,
      datos,
      this.httpOptions,
    );
  }

  /** descargar */
  descargarInformacionTareas(idTablero: string | null): Observable<any[]> {
    return this.http.get<any[]>(`${environment.databaseURL}/rest/tableros/${idTablero}/tareasexport`);
  }
}
