<!-- LOADER -->
<div>
  <div id="preloader">
    <div id="loader"></div>
  </div>
</div>
<div id="nameLogo" style="z-index: 1000">
  <div style="margin-bottom: 5vh; font-size: 30px; font-family: 'Open Sans', sans-serif; font-weight: 800">NAX</div>
</div>
<div id="backdrop">
  <div id="back"></div>
</div>
<div id="no-interactive-backdrop"></div>
