/* 
1: tipo number -> slider
2: tipo date -> slider date
3: tipo string -> checkbox 
4: tipo boolean -> checkbox boolean 
5: nada
*/

export enum tiposAtributosHistorico {
  id = 1,
  cultivo = 3,
  variedad = 3,
  zafra = 3,
  soca = 1,
  fs = 2,
  fc = 2,
  fi = 2,
  fe = 2,
  rend = 1,
  rend_est = 1,
  tah = 1,
  tah_ind = 1,
  tah_core = 1,
  fk_parcela_id = 3,
  sacarosa = 1,
  espacio_surcos = 1,
}
