import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurveState } from './curve.state';

export const CURVE_STATE_NAME = 'curve';

const getCurveState = createFeatureSelector<CurveState>(CURVE_STATE_NAME);

export const getScreen = createSelector(getCurveState, (state) => {
  return state.selectedScreen;
});

export const getCurveArray = createSelector(getCurveState, (state) => {
  if (state.curveArray) {
    return state.curveArray;
  }
});

export const getCurveFirstDate = (label: string) =>
  createSelector(getCurveState, (state) => {
    if (state.curveArray) {
      var d = state.curveArray.find((x) => x.label == label);
      if (d) {
        if (d.data.length > 0) {
          var date = new Date(d.data[0].x);
          date.setDate(date.getDate() - 1);
          return date.toISOString().split('T')[0];
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  });

export const getCurveOptions = createSelector(getCurveState, (state) => {
  return state.curveOptions;
});

export const getSingleOption = (label: string) =>
  createSelector(getCurveState, (state: any) => {
    if (state.curveOptions) {
      var opt = null;
      var keys = Object.keys(state.curveOptions);
      keys.forEach((element1) => {
        state.curveOptions[element1].find((element2: any) => {
          if (element2.name == label) {
            opt = element2;
          }
        });
      });
      return opt;
    } else {
      return null;
    }
  });

export const getCurveLegend = createSelector(getCurveState, (state) => {
  return state.curveLegend;
});

export const getCurveInfo = createSelector(getCurveState, (state) => {
  return state.curveInfo;
});

export const getCurveCloudCoverage = createSelector(getCurveState, (state) => {
  return state.cloudCoverage;
});

export const getConfigurationCurve = createSelector(getCurveState, (state) => {
  return state.configurationCurve;
});

export const getPoints2Delete = createSelector(getCurveState, (state) => {
  return state.points2delete;
});

export const getDeletePoints = createSelector(getCurveState, (state) => {
  return state.deletePoints;
});
