import { createAction, props } from '@ngrx/store';
import { FilterConfiguration } from '@src/app/models/filterModels/filters.model';
import { Area } from '@src/app/objetos/area';

export const SET_MOSTRAR_FILTROS = '[filtros page] set mostrar filtros';
export const setMostrarFiltros = createAction(SET_MOSTRAR_FILTROS, props<{ mostrarFiltros: boolean }>());

export const SAVE_ATRIBUTOS = '[filtros page] save atributos';
export const saveAtributos = createAction(SAVE_ATRIBUTOS, props<{ atributos: any[] }>());

export const GET_VALORES = '[filtros page] get valores';
export const getValores = createAction(
  GET_VALORES,
  props<{ atributo: FilterConfiguration; index: number; operacion: string }>(),
);

export const GET_VALORES_SUCCESS = '[filtros page] get valores success';
export const getValoresSuccess = createAction(
  GET_VALORES_SUCCESS,
  props<{ valores: any; index: number; tipo: number; rangeValue?: any[] }>(),
);

export const UPDATE_ACTIVE_FILTER = '[filtros page] update active filter';
export const updateFilter = createAction(UPDATE_ACTIVE_FILTER, props<{ filtroAuxiliar: any }>());

export const DELETE_ACTIVE_FILTER = '[filtros page] delete active filter';
export const deleteFilter = createAction(DELETE_ACTIVE_FILTER, props<{ indice?: number }>());

export const RESTAURAR_FILTRO = '[filtros page] restaurar filtro';
export const restaurarFiltro = createAction(RESTAURAR_FILTRO, props<{ atributo: any; checkboxes: any }>());

export const SEND_FILTER_PARCELAS = '[filtros page] send filter parcelas';
export const SEND_FILTER_PARCELAS_SUCCESS = '[filtros page] send filter parcelas success';
export const sendFilterParcelas = createAction(SEND_FILTER_PARCELAS, props<{ filter: boolean }>());
export const sendFilterParcelasSuccess = createAction(SEND_FILTER_PARCELAS_SUCCESS, props<{ data: any }>());

export const ADD_MANUAL_PARCELA_SELECTION = '[filtros page] add manual parcela selection';
export const addManualParcelaSelection = createAction(ADD_MANUAL_PARCELA_SELECTION, props<{ data: any }>());

export const GET_BBDD_GEOJSON = '[filtros page] get bbdd geojson';
export const getBBDDGeojson = createAction(GET_BBDD_GEOJSON, props<{ area: Area | null; products: boolean }>());

export const RESET_FILTROS_STATE = '[filtros page] reset filtros state';
export const resetFiltrosState = createAction(RESET_FILTROS_STATE);
