// import 'hammerjs';
import { enableProdMode, isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

/** Sentry */
if (!~window.location.href.indexOf('localhost')) {
  Sentry.init({
    dsn: 'https://e766997b6c064f67b8d3dea41948f9b0@sentry.naxsolutions.com/5',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [environment.databaseURL],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    environment: isDevMode() ? 'development' : 'production',
    denyUrls: ['localhost', '127.0.0.1'],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.25,

    // Capture Replay for 10% of all sessions,
    replaysSessionSampleRate: 0.1,
    // plus for 50% of sessions with an error
    replaysOnErrorSampleRate: 0.5,
    defaultIntegrations: [new Sentry.Replay({ maskAllText: false, blockAllMedia: false })],
  });
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
