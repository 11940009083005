import { Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslocoService } from '@ngneat/transloco';
import { Router, UrlSerializer } from '@angular/router';
import moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommonService {
  constructor(
    private clipboard: Clipboard,
    private router: Router,
    private serializer: UrlSerializer,
    private translocoService: TranslocoService,
    private domSanitizer: DomSanitizer,
  ) {}

  /**
   * Copy textToCopy to clipboard
   * @param textToCopy
   */
  copyTextToClipboard(textToCopy: string): void {
    this.clipboard.copy(textToCopy);
  }

  /**
   * Function to translate an objet to a determinate language
   * @param object objetc to translate
   * @param language translation language
   */
  translateObject(object: any, id: string, language: string): Object {
    this.translocoService.setActiveLang(language);
    const keyValues = Object.keys(object).map((key) => {
      const newKey = this.translocoService.translate('object.' + id + '.' + key) || key;
      return { [newKey]: object[key] };
    });
    return Object.assign({}, ...keyValues);
  }

  /* Get url string with query params in correct formar
   * @param url url string to concatenate
   * @param obj query params
   * @returns new url formatted
   */
  serializeParamsQuery(url: string, obj: Object = {}): string {
    obj = JSON.parse(JSON.stringify(obj));
    let entries = Object.entries(obj);
    entries.forEach(([key, value]) => {
      if (value === null || value === undefined) delete obj[key];
    });
    const tree = this.router.createUrlTree([url], { queryParams: obj });
    return this.serializer.serialize(tree);
  }

  /**
   * Transform moment date to string yyyy-mm-dd
   * @param date
   */
  convertMomentDateToString(date: any): string {
    return moment(date).toISOString(true).split('T')[0];
  }

  /**
   * Transform array object to string in csv format
   * @param obj array object to convert
   * @param delimitator char delimitator between columns. Default: ;
   * @returns string with all csv file
   */
  convertObjectToCsvString(obj: Object[] = [], delimiter: string = ';'): string {
    if (!obj?.length) return '';
    const replacer = (key: string, value: any) => (value === null ? '' : value);
    const header = Object.keys(obj[0]);
    let csv = obj.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(delimiter));
    csv.unshift(header.join(delimiter));
    return csv.join('\r\n');
  }

  /**
   * Convert image file to a string base64
   * @param image
   * @returns observable with result
   */
  converImageFileToBase64(image: File): Observable<string | 'error'> {
    const type = image.type;
    let result = new ReplaySubject<string>(1);
    let reader = new FileReader();
    reader.readAsBinaryString(image);
    reader.onloadend = (event) => result.next(`data:${type};base64,` + btoa(event?.target?.result?.toString() || ''));
    reader.onerror = (error) => 'error';
    return result;
  }
}
