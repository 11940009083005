import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedState } from './shared.state';

export const SHARED_STATE_NAME = 'shared';

const getShareState = createFeatureSelector<SharedState>(SHARED_STATE_NAME);

export const getLoading = createSelector(getShareState, (state) => {
  return state.showLoading;
});

export const getErrorMessage = createSelector(getShareState, (state) => {
  return state.errorMessage;
});
export const getPlatformDevice = createSelector(getShareState, (state) => {
  return state.device;
});

export const getUniquesLimited = createSelector(getShareState, (state) => {
  return state.uniquesLimited;
});

export const getAlert = createSelector(getShareState, (state) => {
  return state.alert;
});

export const getProgressBar = createSelector(getShareState, (state) => {
  return state.myProgressBar;
});

export const getRangeDatesDatepicker = createSelector(getShareState, (state) => {
  return state.rangeDatesDatepicker;
});
