import { createAction, props } from '@ngrx/store';
import { Alert } from '@src/app/interfaces/alert';
import { Area } from '@src/app/objetos/area';

export const SET_LOADING_ACTION = '[shared state] set loading spinner';
export const SET_ERROR_MESSAGE = '[shared state] set error message';
export const SET_PLATFORM_DEVICE = '[shared state] set platform device';

export const setLoadingSpinner = createAction(SET_LOADING_ACTION, props<{ status: boolean }>());

export const setErrorMesage = createAction(SET_ERROR_MESSAGE, props<{ message: string }>());
export const setPlatformDevice = createAction(SET_PLATFORM_DEVICE, props<{ device: number }>());

// * Edit Uniques Limited
export const LOAD_UNIQUESLIMITED = '[shared state] load uniquesLimited';
export const LOAD_UNIQUESLIMITED_SUCCESS = '[shared state] load uniquesLimited success';

export const loadUniquesLimited = createAction(
  LOAD_UNIQUESLIMITED,
  props<{ value: string; attribute: string; limit: number; area: Area[] }>(),
);

export const loadUniquesLimitedSuccess = createAction(LOAD_UNIQUESLIMITED_SUCCESS, props<{ values: string[] }>());

/** ALERT */
export const LOAD_ALERT = '[shared state] load alert';

export const loadAlert = createAction(LOAD_ALERT, props<{ alert: Alert | null }>());

/** Edit ProgressBar */
export const EDIT_PROGRESSBAR = '[dashboardFilters page] edit progressBar';

export const editProgressBar = createAction(
  EDIT_PROGRESSBAR,
  props<{ mode: string; display: boolean; value: number; label?: string }>(),
);

export const SET_RANGE_DATEPICKER = '[shared page] set range datepicker';
export const setRangeDatepicker = createAction(
  SET_RANGE_DATEPICKER,
  props<{ calendar: string | null; values: (Date | null)[] }>(),
);

export const FLAG_LOAD_DATES = '[shared page] flag load dates';
export const flagLoadDates = createAction(FLAG_LOAD_DATES, props<{ date: Date | null }>());

export const FLAG_LOAD_DATES_SUCCESS = '[shared page] flag load dates success';
export const flagLoadDatesSuccess = createAction(FLAG_LOAD_DATES_SUCCESS);
