import { Pipe, PipeTransform } from '@angular/core';
import { MarcadoresInput } from '../interfaces/Mapa/tools/MarcadoresInput.interface';

@Pipe({
  name: 'dataFilterMarcadores',
})
export class DataFilterMarcadoresPipe implements PipeTransform {
  transform(values: any, label: string, data: MarcadoresInput): any[] {
    if (!['usuario', 'tablero', 'estado'].includes(label)) return values;
    let valuesData: { type: string; values: { id: number; nombre: string }[] } = data[label];
    if (!valuesData) return [];
    return values.map((element: any) => {
      let objName: { id: number; nombre: string } = valuesData.values.find((a) => a.id === element)
        ? valuesData.values.find((a) => a.id === element)
        : element;
      return objName !== undefined ? objName.nombre : element;
    });
  }
}
