import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecoverPasswordService {
  constructor(private http: HttpClient) {}

  /**
   * Llamada que comprueba que el token sigue activo
   * @param token cadena de texto
   * @returns estado
   */
  verifyToken(token: string) {
    return this.http.post<any>(`${environment.databaseURL}/rest/usuarios/validate_password_token`, { token: token });
  }

  /**
   * Llamada para cambiar la contraseña con el token para habilitarlo
   * @param password nueva contraseña
   * @param token cadena de texto de validación
   * @returns estado de la acción
   */
  restorePassword(password: string, token: string) {
    return this.http.post<any>(`${environment.databaseURL}/rest/usuarios/restore_password`, {
      token: token,
      password: password,
    });
  }
}
