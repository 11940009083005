export class Operacion {
  area: number | undefined;
  cantidad: number | undefined;
  codigo: string | undefined;
  comentario: string | undefined;
  descripcion: string | undefined;
  equipo_aplicacion: string | undefined;
  fecha_fin: Date | undefined;
  fecha_inicio: Date | undefined;
  id: number | undefined;
  operacion: string | undefined;
  producto: string | undefined;
  tipo_aplicacion: string | undefined;
  fk_parcela_id: number | undefined;

  constructor(
    area?: number,
    cantidad?: number,
    codigo?: string,
    comentario?: string,
    descripcion?: string,
    equipo_aplicacion?: string,
    fecha_fin?: Date,
    fecha_inicio?: Date,
    id?: number,
    operacion?: string,
    producto?: string,
    tipo_aplicacion?: string,
    fk_parcela_id?: number,
  ) {
    this.area = area;
    this.cantidad = cantidad;
    this.codigo = codigo;
    this.comentario = comentario;
    this.descripcion = descripcion;
    this.equipo_aplicacion = equipo_aplicacion;
    this.fecha_fin = fecha_fin;
    this.fecha_inicio = fecha_inicio;
    this.id = id;
    this.operacion = operacion;
    this.producto = producto;
    this.tipo_aplicacion = tipo_aplicacion;
    this.fk_parcela_id = fk_parcela_id;
  }
}
