import { createAction, props } from '@ngrx/store';
import { Notification } from '@src/app/models/notification.model';
import { Usuario } from '@src/app/objetos/usuario';

export const GET_NOTIFICATIONS_USER = '[notifications page] get notifications user';
export const LOAD_NOTIFICATIONS = '[notifications page] get notifications';
export const LOAD_NOTIFICATIONS_SUCCESS = '[notifications page] load success notifications';
export const UPDATE_NOTIFICATIONS = '[notifications page] update notification';
export const UPDATE_NOTIFICATIONS_SUCCESS = '[notifications page] update notification sucess';
export const DELETE_NOTIFICATIONS = '[notifications page] delete notification';
export const DELETE_NOTIFICATIONS_SUCCESS = '[notifications page] delete notification sucess';
export const DELETE_ALL_NOTIFICATIONS = '[notifications page] delete all notifications';
export const DELETE_ALL_NOTIFICATIONS_SUCCESS = '[notifications page] delete all notifications success';

export const loadNotifications = createAction(LOAD_NOTIFICATIONS, props<{ user: Usuario }>());

export const getNotificationsUser = createAction(GET_NOTIFICATIONS_USER);

export const loadNotificationsSuccess = createAction(
  LOAD_NOTIFICATIONS_SUCCESS,
  props<{ notifications: Notification[]; lenghtNoCheck: number }>(),
);

export const updateNotifications = createAction(UPDATE_NOTIFICATIONS, props<{ notification: Notification }>());

export const updateNotificationsSuccess = createAction(
  UPDATE_NOTIFICATIONS_SUCCESS,
  props<{ notification: Notification; lenghtNoCheck: number }>(),
);

export const deleteNotification = createAction(DELETE_NOTIFICATIONS, props<{ id: number; visto: boolean }>());
export const deleteNotificationSuccess = createAction(
  DELETE_NOTIFICATIONS_SUCCESS,
  props<{ id: number; lenghtNoCheck: number }>(),
);

export const deleteAllNotification = createAction(DELETE_ALL_NOTIFICATIONS);
export const deleteAllNotificationSuccess = createAction(DELETE_ALL_NOTIFICATIONS_SUCCESS);
