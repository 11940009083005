import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
})
export class Capitalize implements PipeTransform {
  transform(text: string, label?: string): string {
    switch (text) {
      case 'fk_parcela_id':
      case 'fk_parcela':
        return 'idnax';
      case 'id':
        return `ID${label ? ' ' + label.toLowerCase() || '' : ''}`;
      case 'fs':
        return 'Fecha Siembra';
      case 'fc':
        return 'Fecha Corte';
      case 'fe':
        return 'Fecha Emergencia';
      case 'id_parcela_actual':
        return 'Id parcela';
      case 'espacio_surcos':
        return 'Espacio surcos';
      case 'sacarosa':
        return 'Sacarosa %';
      case 'fi':
        return 'Fecha Inundación';
      case 'rend':
        return 'Rendimiento';
      case 'quema_acc':
        return 'Quema accidental';
      case 'last_login':
        return 'Última conexión';
      case 'id_label':
        return 'id';
      case 'freinicio':
        return 'Fecha de reinicio';
      case 'zafra_cont':
        return 'Zafra continua';
      case 'reinicio':
        return 'Reinicio automático';
      case 'area_ef':
        return 'Area efectiva';
      case 'reinicio':
        return 'Reinicio automático';
      case 'fc_est':
        return 'F.Cosecha Estimada';
      case 'rend_est':
        return 'Rend. Estimado';
      case 'ver_dashboard':
        return 'Ver dashboard';
      case 'ver_tarea':
        return 'Ver tarea';
      case 'ver_admin':
        return 'Ver admin';
      case 'editar_parcela':
        return 'Editar Parcelas';
      case 'zona_eco':
        return 'Zona Eco';
      case 'agua_disp':
        return 'Agua Disponible';
      case 'agua_aprov':
        return 'Agua aprovechable';
      case 'agua_act':
        return 'Agua actual';
      case 'rend_neto':
        return 'Rendimiento Neto';
      case 'ha_corte':
        return 'Ha. corte';
      case 'tipo_cos':
        return 'Tipo cosecha';
      case 'rend_core':
        return 'Rendimiento core';
      case 'rend_ind':
        return 'Rendimiento Ind';
      case 'semana_mad':
        return 'Semana madurante';
      case 'show':
        return 'Habilitado';
      case 'lamina_riego':
        return 'Lámina riego';
      case 'pureza_jugo':
        return 'Pureza jugo';
      case 'indice_mad':
        return 'Indice mad';
      case 'tah':
        return 'TAH';
      case 'fecha_inicio':
        return 'Fecha Inicio';
      case 'fecha_fin':
        return 'Fecha Fin';
      case 'will_expire':
        return 'Fecha Expiración';
      case 'date_created':
        return 'Fecha Creación';
      case 'date_download':
        return 'Fecha Descarga';
      case 'type':
        return 'Tipo';
      case 'format':
        return 'Formato';
      case 'size':
        return 'Tamaño';
      case 'recomendacion':
        return 'Recomendación';
      case 'alta_freq':
        return 'Alta freq.';
      default:
        return text ? (text.charAt(0).toLocaleUpperCase() + text.slice(1)).replace(/_/gi, ' ') : text;
    }
  }
}
