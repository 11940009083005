import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DownloadsViewComponent } from './@pages/downloads-view/downloads-view.component';
import { RegisterViewComponent } from './@pages/register/register.component';
import { AgreementGuard } from './servicios/guards/agreement.guard';

const routes: Routes = [
  {
    path: `login`,
    loadChildren: () => import('src/app/@pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: `area`,
    loadChildren: () => import('src/app/@pages/navigation/navigation.module').then((m) => m.NavigationModule),
    canActivate: [AgreementGuard],
  },
  {
    path: `inicio`,
    loadChildren: () => import('src/app/@pages/inicio/inicio.module').then((m) => m.InicioModule),
  },
  {
    path: `recover-password`,
    loadChildren: () =>
      import('src/app/@pages/recover-password/recover-password.module').then((m) => m.RecoverPasswordModule),
  },
  {
    path: `downloads`,
    component: DownloadsViewComponent,
    canActivate: [AgreementGuard],
  },
  {
    path: `register`,
    component: RegisterViewComponent,
  },
  { path: `**`, redirectTo: `login`, pathMatch: `full` },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
