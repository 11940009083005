<div
  class="calendarContainer"
  [ngStyle]="{
    'justify-content': !arrows && !climateInfo ? 'center' : 'space-between'
  }"
>
  <!-- previous date -->
  <mat-icon
    *ngIf="arrows && inputDate"
    class="icons"
    [ngClass]="{
      icons: true,
      'disabled-arrow': (datesRange && rangeDates[0] === inputDate) || disabled || isDateChanging
    }"
    (click)="datesRange && rangeDates[0] !== inputDate && changeDateArrows(0, inputDate)"
    >keyboard_arrow_left</mat-icon
  >

  <!-- calendar -->
  <mat-form-field
    appareance="legacy"
    id="matform-date"
    class="matform-date-width"
    *ngIf="!range; else rangeDatepickerView"
  >
    <!--input-->
    <input
      matInput
      readonly
      [(ngModel)]="inputDate"
      (dateChange)="changeDate()"
      [ngClass]="{
        'inputStyle input-minwidth': true,
        'input-disabled': disabled
      }"
      [matDatepicker]="datePicker"
      [matDatepickerFilter]="dateFilter"
      (click)="!disabled && datePicker.open()"
      [max]="datesRange!.length > 1 ? datesRange[1] : today"
      [min]="datesRange!.length > 0 ? datesRange[0] : null"
      [disabled]="disabled"
    />
    <!-- calendar open button-->
    <mat-icon
      [inline]="true"
      style="margin-top: 0.2em"
      matSuffix
      [ngClass]="{
        'disabled-arrow': disabled || isDateChanging,
        iconButton: true
      }"
      (click)="!(disabled || isDateChanging) && datePicker.open()"
    >
      today
    </mat-icon>
    <!-- <mat-datepicker-toggle matSuffix [for]="datePicker" (click)="showDateInfo(false)"></mat-datepicker-toggle>-->
    <mat-datepicker
      #datePicker
      [dateClass]="dateClassProduct"
      [disabled]="disabled"
      [calendarHeaderComponent]="customHeader"
      (yearSelected)="loadDates($event)"
      (monthSelected)="loadDates($event)"
      (opened)="showDateInfo(false); uploadRanges()"
    >
    </mat-datepicker>
  </mat-form-field>

  <ng-template #rangeDatepickerView>
    <!-- range caledar -->
    <mat-form-field appareance="legacy" id="matform-date">
      <!--input-->
      <mat-date-range-input
        [rangePicker]="datePickerRange"
        [dateFilter]="dateFilter"
        [max]="datesRange!.length > 1 ? datesRange[1] : today"
        [min]="datesRange!.length > 0 ? datesRange[0] : null"
        (click)="!(disabled || isDateChanging) && datePickerRange.open()"
        [ngStyle]="{
          cursor: !(disabled || isDateChanging) ? 'pointer' : 'default'
        }"
      >
        <input
          matStartDate
          matInput
          readonly
          [(ngModel)]="inputDateStart"
          (dateChange)="changeDate('start')"
          [ngClass]="{ inputStyle: true, 'input-disabled': disabled }"
          [max]="datesRange!.length > 1 ? datesRange[1] : today"
          [min]="datesRange!.length > 0 ? datesRange[0] : null"
          [disabled]="disabled"
        />
        <input
          matEndDate
          matInput
          readonly
          [(ngModel)]="inputDateEnd"
          (dateChange)="changeDate('end')"
          [ngClass]="{ inputStyle: true, 'input-disabled': disabled }"
          [max]="datesRange!.length > 1 ? datesRange[1] : today"
          [min]="datesRange!.length > 0 ? datesRange[0] : null"
          [disabled]="disabled"
        />
      </mat-date-range-input>
      <!-- calendar open button-->
      <mat-icon
        [inline]="true"
        style="margin-top: 0.2em"
        matSuffix
        [ngClass]="{
          'disabled-arrow': disabled || isDateChanging,
          iconButton: true
        }"
        (click)="!(disabled || isDateChanging) && datePickerRange.open()"
      >
        today
      </mat-icon>
      <!-- <mat-datepicker-toggle matSuffix [for]="datePicker" (click)="showDateInfo(false)"></mat-datepicker-toggle>-->
      <mat-date-range-picker
        #datePickerRange
        [dateClass]="dateClassProduct"
        [disabled]="disabled"
        [calendarHeaderComponent]="customHeader"
        (yearSelected)="loadDates($event)"
        (monthSelected)="loadDates($event)"
        (opened)="showDateInfo(false); uploadRanges()"
      >
      </mat-date-range-picker>
    </mat-form-field>
  </ng-template>

  <!-- next date -->
  <mat-icon
    *ngIf="arrows && inputDate"
    [ngClass]="{
      icons: true,
      'disabled-arrow': (datesRange && rangeDates[1] === inputDate) || disabled || isDateChanging
    }"
    (click)="datesRange && rangeDates[1] !== inputDate && changeDateArrows(1, inputDate)"
    >keyboard_arrow_right</mat-icon
  >

  <!-- info button -->
  <!--<mat-icon *ngIf="inputDate && climateInfo && fechaSelected && (fechaSelected.cloud_coverage < 0.4 || fechaSelected.cloud_coverage >= 0.85)" 
    [ngClass]="{'icons icons-clima': true, 'color-sunny': fechaSelected.cloud_coverage < 0.4 && showInfo, 
    'color-cloud': fechaSelected.cloud_coverage >= 0.85 && showInfo }" 
    (click)="showDateInfo(!showInfo)">
        {{ (fechaSelected.cloud_coverage < 0.4) ? 'sunny' : (fechaSelected.cloud_coverage >= 0.85) ? 'cloud' : ''}}
    </mat-icon>
    -->

  <!-- LINK PARA EL ICONO -->
  <!--<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
    <span (click)="showDateInfo(!showInfo)"
    [ngClass]="{'material-symbols-outlined icons icons-clima': true, 'color-cloudy-sunny': showInfo }" 
    *ngIf="inputDate && climateInfo && fechaSelected && fechaSelected.cloud_coverage >= 0.4 && fechaSelected.cloud_coverage < 0.85">
        partly_cloudy_day
        </span>
    -->
  <mat-icon
    *ngIf="inputDate && climateInfo && fechaSelected"
    [ngClass]="{ 'icons icons-clima': true }"
    (click)="showDateInfo(!showInfo)"
    >info</mat-icon
  >
  <mat-icon
    *ngIf="clearButton && (inputDate || inputDateStart || inputDateEnd)"
    [ngClass]="{ icons: true, 'disabled-arrow': disabled || isDateChanging }"
    (click)="!(disabled || isDateChanging) && clearInput()"
    >close</mat-icon
  >
  <app-calendar-info class="info-climate-container" *ngIf="showInfo"></app-calendar-info>
</div>
