import { NgModule } from '@angular/core';
import { DialogTokenExpiredDownloads, DownloadsViewComponent } from './downloads-view.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { EffectsModule } from '@ngrx/effects';
import { DownloadsReducer } from './state/downloads.reducer';
import { DOWNLOADS_STATE_NAME } from './state/downloads.selector';
import { StoreModule } from '@ngrx/store';
import { AuthEffects } from '../login/state/auth.effects';
import { DownloadsEffects } from './state/downloads.effects';
import { ToolcontainerEffects } from '../navigation/mapa/componentes/workspace/componentes/tool-container/state/tool-container.effects';
import { SharedModule } from '@src/app/shared/shared.module';

@NgModule({
  declarations: [DownloadsViewComponent, DialogTokenExpiredDownloads],
  imports: [
    SharedModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    StoreModule.forFeature(DOWNLOADS_STATE_NAME, DownloadsReducer),
    EffectsModule.forFeature([AuthEffects, DownloadsEffects, ToolcontainerEffects]),
  ],
  entryComponents: [DialogTokenExpiredDownloads, MatProgressBarModule],
})
export class DownloadsViewModule {}
