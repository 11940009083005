export enum LegendType {
  agua = 'ramp',
  agua_filt = 'ramp',
  agua_optimo = 'values',
  analisis_avance_de_riego = 'intervals',
  analisis_de_agua = 'intervals',
  analisis_de_avance = 'intervals',
  anomalias = 'values',
  anomalias_af = 'values',
  azul = 'ramp',
  balance_hidrico = 'ramp',
  biomasa_seca = 'ramp',
  calidad_pasto = 'ramp',
  clorofila = 'ramp',
  clorofila_filt = 'ramp',
  clorofila_optimo = 'values',
  confor_ferti_al_suelo = 'intervals',
  confor_ferti_foliar = 'intervals',
  confor_ferti_fraccionamiento = 'ramp',
  conformidad_fertilizado = 'values',
  conformidad_riego = 'ramp',
  consolidado_al_suelo = 'values',
  consolidado_foliar = 'values',
  consolidado_fraccionamiento = 'values',
  cosecha = 'values',
  cosecha_af = 'values',
  cosechado = 'values',
  cosecha_filt = 'values',
  cuando_fertilizar_al_suelo = 'values',
  cuando_fertilizar_foliar = 'values',
  cuando_fertilizar_fraccionamiento = 'values',
  cuanto_fertilizado = 'values',
  deteccion_de_agua = 'values',
  eficiencia_fertilizado = 'ramp',
  evapotranspiration = 'ramp',
  fertilizado_al_suelo = 'values',
  fertilizado_foliar = 'values',
  fertilizado_fraccionamiento = 'values',
  frescura_canha = 'values',
  humedad = 'ramp',
  humedad_filt = 'ramp',
  humedad_grano = 'ramp',
  imagen_real_af = 'ramp',
  inundacion = 'ramp',
  inundacion_filt = 'ramp',
  inundacion_modelo = 'ramp',
  inundacion_optico = 'ramp',
  inundacion_rgb = 'ramp',
  lai = 'ramp',
  lai_filt = 'ramp',
  lai_optimo = 'values',
  lci = 'ramp',
  maduracion = 'ramp',
  maduracion_filt = 'ramp',
  maleza = 'values',
  maleza_temporal = 'values',
  msavi2 = 'ramp',
  ndmi = 'ramp',
  ndvi = 'ramp',
  ndvi_af = 'ramp',
  ndvi_filt = 'ramp',
  ndvi_optimo = 'values',
  ndwi = 'ramp',
  nir = 'ramp',
  nitrogenado = 'intervals',
  ortofoto = 'ramp',
  planificacion_riego = 'ramp',
  produccion = 'ramp',
  real = 'ramp',
  riego = 'values',
  rojo = 'ramp',
  rojo_cercano = 'ramp',
  rvi = 'ramp',
  rvi2 = 'ramp',
  shp = 'ramp',
  tch = 'ramp',
  verde = 'ramp',
  vh = 'ramp',
}

export enum LegendEdit {
  agua = 1,
  agua_filt = 1,
  agua_optimo = 0,
  analisis_avance_de_riego = 0,
  analisis_de_agua = 0,
  analisis_de_avance = 0,
  anomalias = 0,
  anomalias_af = 0,
  azul = 0,
  balance_hidrico = 1,
  biomasa_seca = 1,
  calidad_pasto = 0,
  clorofila = 1,
  clorofila_filt = 1,
  clorofila_optimo = 0,
  confor_ferti_al_suelo = 1,
  confor_ferti_foliar = 1,
  confor_ferti_fraccionamiento = 1,
  conformidad_fertilizado = 0,
  conformidad_riego = 0,
  cosecha = 0,
  cosecha_af = 0,
  cosechado = 0,
  cosecha_filt = 0,
  cuando_fertilizar_al_suelo = 0,
  cuando_fertilizar_foliar = 0,
  cuando_fertilizar_fraccionamiento = 0,
  cuanto_fertilizado = 0,
  deteccion_de_agua = 0,
  eficiencia_fertilizado = 1,
  evapotranspiration = 1,
  fertilizado_al_suelo = 0,
  fertilizado_foliar = 0,
  fertilizado_fraccionamiento = 0,
  frescura_canha = 1,
  humedad = 1,
  humedad_filt = 1,
  humedad_grano = 1,
  imagen_real_af = 0,
  inundacion = 1,
  inundacion_filt = 1,
  inundacion_modelo = 1,
  inundacion_optico = 1,
  inundacion_rgb = 1,
  lai = 1,
  lai_filt = 1,
  lai_optimo = 0,
  lci = 1,
  maduracion = 1,
  maduracion_filt = 1,
  maleza = 0,
  maleza_temporal = 0,
  msavi2 = 1,
  ndmi = 1,
  ndvi = 1,
  ndvi_af = 1,
  ndvi_filt = 1,
  ndvi_optimo = 0,
  ndwi = 1,
  nir = 0,
  nitrogenado = 0,
  ortofoto = 0,
  planificacion_riego = 0,
  produccion = 1,
  real = 0,
  riego = 0,
  rojo = 0,
  rojo_cercano = 0,
  rvi = 1,
  rvi2 = 1,
  shp = 0,
  tch = 1,
  verde = 0,
  vh = 0,
}
