import { Action, createReducer, on } from '@ngrx/store';
import { getDownloadsSuccess } from './downloads.actions';
import { DownloadsState, initialStateDownloads } from './downloads.state';

const _downloadsReducer = createReducer(
  initialStateDownloads,
  on(getDownloadsSuccess, (state, action) => {
    const { data } = action;
    return {
      ...state,
      data: data,
    };
  }),
);

export function DownloadsReducer(state: DownloadsState | undefined, action: Action) {
  return _downloadsReducer(state, action);
}
