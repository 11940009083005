import { AuthState, initialStateAuth } from './auth.state';
import {
  authTokenDriveSuccess,
  loginSuccess,
  logoutSuccess,
  registerStatus,
  setValidations,
  verifyResult,
} from './auth.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { Token } from '@src/app/models/token.model';

const _authReducer = createReducer(
  initialStateAuth,
  on(loginSuccess, (state, action) => {
    return {
      ...state,
      token: action.token,
    };
  }),
  on(logoutSuccess, (state, action) => {
    return {
      ...state,
    };
  }),
  on(setValidations, (state, action) => {
    let { validations, label } = action;
    return {
      ...state,
      validations: {
        ...state.validations,
        [label]: validations[label],
        message: validations['message'],
      },
    };
  }),
  on(registerStatus, (state, action) => {
    let { result, message } = action;
    return {
      ...state,
      registerStatus: {
        result: result,
        message: message,
      },
    };
  }),
  on(verifyResult, (state, action) => {
    let { result } = action;
    return {
      ...state,
      verifyUser: result,
    };
  }),
  on(authTokenDriveSuccess, (state, action) => {
    const { token } = state;
    const { tokenDrive } = action;
    let copyToken: Token | null = token ? new Token(token.getToken(), token.getTokenPowerBI(), tokenDrive) : null;
    return {
      ...state,
      token: copyToken,
    };
  }),
);

export function AuthReducer(state: AuthState, action: Action) {
  return _authReducer(state, action);
}
