export class Parcela2 {
  idnax: number;
  id: string;
  unidad_01: string;
  unidad_02: string;
  unidad_03: string;
  unidad_04: string;
  unidad_05: string;
  variedad: string;
  fs: Date;
  fc: Date;
  rend: number;
  cultivo: string;
  area: number;
  soca: number;
  fi: Date;
  ff: Date;
  fe: Date;
  semillero: boolean;
  zafra: number;
  edad: number;
  fc_est: Date;
  fci: Date;
  riego: number;
  rend_est: number;
  zonas: string;
  zona_eco: number;
  agua_disp: number;
  agua_aprov: number;
  agua_act: number;
  rend_neto: number;
  ha_corte: number;
  fecha_max: Date;
  estrato: string;
  tipo_cos: string;
  rend_core: number;
  rend_ind: number;
  semana_mad: number;
  obs_madur: string;
  cuadrante: string;
  tercio: number;
  riegos_pre: number;
  riegos_pos: number;
  fk_pixel: number;
  activo: boolean;
  alta_freq: boolean;
  inicio_curva: Date | null;
  resiembra_pct: number;
  constructor() {
    this.idnax = 0;
    this.id = '';
    this.unidad_01 = '';
    this.unidad_02 = '';
    this.unidad_03 = '';
    this.unidad_04 = '';
    this.unidad_05 = '';
    this.variedad = '';
    this.edad = 0;
    this.fs = new Date();
    this.fc = new Date(500000000000);
    this.rend = 0;
    this.cultivo = '';
    this.area = 0;
    this.soca = 0;
    this.fi = new Date();
    this.ff = new Date();
    this.fe = new Date();
    this.semillero = false;
    this.zafra = 0;
    this.fc_est = new Date();
    this.fci = new Date();
    this.riego = 0;
    this.rend_est = 0;
    this.zonas = '';
    this.zona_eco = 0;
    this.agua_disp = 0;
    this.agua_aprov = 0;
    this.agua_act = 0;
    this.rend_neto = 0;
    this.ha_corte = 0;
    this.fecha_max = new Date();
    this.estrato = '';
    this.tipo_cos = '';
    this.rend_core = 0;
    this.rend_ind = 0;
    this.semana_mad = 0;
    this.obs_madur = '';
    this.cuadrante = '';
    this.ha_corte = 0;
    this.tercio = 0;
    this.riegos_pre = 0;
    this.riegos_pos = 0;
    this.fk_pixel = 0;
    this.activo = false;
    this.alta_freq = false;
    this.inicio_curva = new Date();
  }
}
