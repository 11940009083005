import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.state';

export const USER_STATE_NAME = 'user';

const getUserState = createFeatureSelector<UserState>(USER_STATE_NAME);

export const getUser = createSelector(getUserState, (state) => {
  return state.user;
});

export const getAreas = createSelector(getUserState, (state) => {
  if (state.user) return state.user.areas;
  return null;
});

export const getPermisosTareas = createSelector(getUserState, (state) => {
  if (state.user) return state.user.ver_tarea;
  return null;
});
