import { applyedFilter } from '@src/app/models/dashboardModels/filtersDashboard.model';
import { OptionsDashboard } from '@src/app/models/dashboardModels/optionsDashboard.model';
import { graphDataDefault } from '@src/app/models/dashboardModels/graphDataDashboard.model';
import { Area } from '@src/app/objetos/area';

//interfaz de los datos con los que voy a trabajar, se guarda en el store.
export interface DashboardState {
  selectedArea: Area[] | null;
  comparar: number | null;

  // Duplicar
  selectedFecha: Date | null;
  selectedFecha2: Date | null;
  applyedFilters: applyedFilter[] | null;
  applyedFilters2: applyedFilter[] | null;
  loading: boolean;
  loading2: boolean;
  options: OptionsDashboard;
  options2: OptionsDashboard;

  // Mantener pero modificar
  fechas: /*Object[]*/ string[] | null;
  group: number;
  hidricoGraphs: graphDataDefault[] | null;
  saludGraphs: graphDataDefault[] | null;
  desarrolloGraphs: graphDataDefault[] | null;
  produccionGraphs: graphDataDefault[] | null;
  graficasGrupo1: graphDataDefault[] | null;
  graficasGrupo2: graphDataDefault[] | null;
}

// se inicializa la interfaz con valores nulos con todos los datos con los que quieres trabajar
export const initialStateDashboard: DashboardState = {
  selectedArea: null,
  selectedFecha: null,
  selectedFecha2: null,
  applyedFilters: null,
  applyedFilters2: null,
  options: {
    variedades: null,
    cultivos: null,
    soca: null,
    zafra: null,
  },
  options2: {
    variedades: null,
    cultivos: null,
    soca: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    zafra: null,
  },
  fechas: null,
  group: 1,
  hidricoGraphs: null,
  saludGraphs: null,
  desarrolloGraphs: null,
  produccionGraphs: null,
  graficasGrupo1: null,
  graficasGrupo2: null,
  comparar: 0,
  loading: false,
  loading2: false,
};
