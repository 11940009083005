import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMarcadoresLength',
})
export class FilterMarcadoresLength implements PipeTransform {
  transform(obj: Object): number {
    if (!obj) return 0;
    return Object.entries(obj).filter(([key, value]) => value !== null).length;
  }
}
